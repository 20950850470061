import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Svg } from '../../styles/CommonStyles';

export const IconNotification = ({ color, link, onClick = () => { }, textTooltip = "Agregar Comentario" }) => {
    return (

        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-editar`}>
                    {textTooltip}
                </Tooltip>
            }
        >
            <Svg color={color} link={link} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><rect className="a" width="24" height="24" />
                <path className="b" d="M22.738,16.659a2.026,2.026,0,0,1-2.026,2.026H8.553L4.5,22.738V6.526A2.026,2.026,0,0,1,6.526,4.5H20.712a2.026,2.026,0,0,1,2.026,2.026Z" transform="translate(-1.5 -0.119)" />
            </Svg>
        </OverlayTrigger>

    )
}
