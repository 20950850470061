import { Col, Row } from 'react-bootstrap'
import Select from 'react-select';
import { StyleCheckbox } from '../styles/CommonStyles';
import { styleTheme } from '../styles/SelectTheme';
import { StyleProperty } from '../../src/pages/style';
import ColorPicker from "../components/ColorPicker";
import { useEffect, useState } from "react";

function IndicatorForm(props) {

    const currentMonth = useState(parseInt(localStorage.getItem('month')));
    const currentYear = useState(parseInt(localStorage.getItem('year')));

    const handleIndicator = (event) => {

        const variable = (event.target.name.split("-"))[0];
        let temp = JSON.parse(JSON.stringify(props.typeInd));

        temp[props.index][variable] = event.target.value;
           
        props.setForm({
            ...props.form,
            [props.nameTypeInd]: temp
        })

    }

    const handleOtherOptions = (event) => {

        const variable = (event.target.name.split("-"))[0];
        let indicators = JSON.parse(JSON.stringify(props.typeInd));

        if (event.target.checked) {
            indicators[props.index][variable] = true;
            if (variable === "multiStages") {
                if( !indicators[props.index].hasOwnProperty('stage') || Object.keys(indicators[props.index]['stage']).length === 0 ) {
                    indicators[props.index]['stage'] = [];
                } else {
                    indicators[props.index]['stage'] = [indicators[props.index]['stage']];
                }        
            }
        } 
        else {
            indicators[props.index][variable] = false;
            if (variable === "multiStages") {
                if( !indicators[props.index].hasOwnProperty('stage') || Object.keys(indicators[props.index]['stage']).length === 0 ) {
                    indicators[props.index]['stage'] = {};
                } else {
                    indicators[props.index]['stage'] = indicators[props.index]['stage'][0];
                }
                
            }
        } 
        
        props.setForm({
            ...props.form,
            [props.nameTypeInd]: indicators
        })
    }

    const handleOptionsStages = (event) => {
        const indexStage = (event.target.name.split("-"))[1];
        const variable = (event.target.name.split("-"))[0];
        let temp = JSON.parse(JSON.stringify(props.typeInd));

        temp[props.index].stage[indexStage][variable] = event.target.value;

        console.log(temp)

        if(variable === "numYears") {
            temp[props.index].stage[indexStage].infoYears = [];
            for(let i=1; i <= parseInt(event.target.value); i++) {
                temp[props.index].stage[indexStage].infoYears.push({
                    labelYear: "",
                    colorYear: '#777273'
                }) 
            }
        }
        
        props.setForm({
            ...props.form,
            [props.nameTypeInd]: temp
        })
    }

    const handleInfoYear = (event) => {
        const indexStage = (event.target.name.split("-"))[1];
        const variable = (event.target.name.split("-"))[0];
        const arr = (event.target.name.split("-"))[3];
        const indexY = (event.target.name.split("-"))[4];
        let temp = JSON.parse(JSON.stringify(props.typeInd));

        temp[props.index].stage[indexStage][arr][indexY][variable] = event.target.value;
        props.setForm({
            ...props.form,
            [props.nameTypeInd]: temp
        })

    }

    const handleChecksStages = (event) => {
        const indexStage = (event.target.name.split("-"))[2];
        const variable = (event.target.name.split("-"))[0];
        let temp = JSON.parse(JSON.stringify(props.typeInd));

        temp[props.index].stage[indexStage][variable] = event.target.checked;
        
        props.setForm({
            ...props.form,
            [props.nameTypeInd]: temp
        })
    }

    const selectBarStage = (event) => {

        let temp = JSON.parse(JSON.stringify(props.typeInd));

        temp[props.index].stage = event;

        props.setForm({
            ...props.form,
            [props.nameTypeInd]: temp
        })
    }

    const selectStageLabelBar = (data, index, index_type) => {
        let temp = JSON.parse(JSON.stringify(props.typeInd));
        temp[index_type].stage[index]['stageBarSelected'] = data;

        props.setForm({
            ...props.form,
            [props.nameTypeInd]: temp
        })
    }

    return (

        <div>
            <StyleCheckbox className="custom-checkbox ml-4 my-3">
                <input className="custom-control-input" type="checkbox" checked={props.ind.multiStages} name={"multiStages-" + props.nameTypeInd + "-" + props.index} id={"multiStages-" + props.nameTypeInd + "-" + props.index} onChange={handleOtherOptions} />
                <label className="custom-control-label" htmlFor={"multiStages-" + props.nameTypeInd+ "-" + props.index}>
                    ¿Agregar multiples escenarios?
                </label>
            </StyleCheckbox>    
            <div className="form-group">
                <Row>
                    <Col><b>Escenario</b></Col>
                    <Col>
                        <Select
                            isMulti={props.ind.multiStages}
                            value={props.ind.stage}
                            options={props.stages}
                            onChange={selectBarStage}
                            theme={theme => (styleTheme(theme))}
                        />
                    </Col>
                </Row>
            </div>

            {
                !props.ind.multiStages && 
                    <>
                    <div className="form-group">
                        <Row>
                            <Col><b>Label del Indicador</b></Col>
                            <Col>
                                <input type="text" className="form-control" id={"labelIndicator-" + props.index} name={"labelIndicator-" + props.index} onChange={handleIndicator} value={props.ind.labelIndicator} />
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row>
                            <Col><b>Tipo de BarChart</b></Col>
                            <Col>
                                <select className="form-control" id={"type-ind-" + props.index} name={"type-ind-" + props.index} value={props.ind.type} onChange={handleIndicator}>
                                    <option key="0" value="PorDefecto">Seleccionar ...</option>
                                    <option key="1" value="bar">Columna</option>
                                    <option key="2" value="line">Línea</option>
                                </select>
                            </Col>
                        </Row>
                        {
                            props.ind.type === 'line' &&
                            <Row>
                                <Col>
                                    <StyleCheckbox className="custom-checkbox ml-4 my-3">
                                        <input className="custom-control-input" type="checkbox" checked={props.ind.borderDash} name={"borderDash-" + props.nameTypeInd + "-" + props.index} id={"borderDash-" + props.nameTypeInd + "-" + props.index} onChange={handleOtherOptions} />
                                        <label className="custom-control-label" htmlFor={"borderDash-" + props.nameTypeInd+ "-" + props.index}>
                                            ¿Línea segmentada?
                                        </label>
                                    </StyleCheckbox>
                                </Col>
                            </Row>
                        }
                        <Row>   
                            <Col>
                                <StyleCheckbox className="custom-checkbox ml-4 my-3">
                                    <input className="custom-control-input" type="checkbox" checked={props.ind.otherAxis} name={"otherAxis-" + props.nameTypeInd + "-" + props.index} id={"otherAxis-" + props.nameTypeInd + "-" + props.index} onChange={handleOtherOptions} />
                                    <label className="custom-control-label" htmlFor={"otherAxis-"+ props.nameTypeInd + "-" + props.index}>
                                        ¿Añadir al otro eje?
                                    </label>
                                </StyleCheckbox>
                            </Col>
                        </Row>

                    </div>
                </>
            }
            {
                props.ind.multiStages && props.ind.stage.length > 0 &&
                props.ind.stage.map( (aux, index) => {
                    return (
                        <StyleProperty>
                            <h5>{"Propiedades " + aux.name}</h5>
                            <div className="form-group">
                                <Row>
                                    <Col><b>Label del Escenario</b></Col>
                                    <Col>
                                        <input type="text" className="form-control" id={"labelStage-" + index + '-' + props.index} name={"labelStage-" + index + '-' + props.index} onChange={handleOptionsStages} value={aux.labelStage} />
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-group">
                                <Row>
                                    <Col><b>Color del Escenario</b></Col>
                                    <Col>
                                        <ColorPicker name={"colorStage-color-" + props.index + '-' + index} nameTypeInd={props.nameTypeInd} typeInd={props.typeInd} value={aux.color} form={props.form} setForm={props.setForm} />
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-group">
                                <Row>
                                    <Col><b>Tipo de BarChart</b></Col>
                                    <Col>
                                        <select className="form-control" id={"type-" + index + '-' + props.index} name={"type-" + index + '-' + props.index} value={aux.type} onChange={handleOptionsStages}>
                                            <option key="0" value="PorDefecto">Seleccionar ...</option>
                                            <option key="1" value="bar">Columna</option>
                                            <option key="2" value="line">Línea</option>
                                        </select>
                                    </Col>
                                </Row>
                                {
                                    aux.type === 'line' &&
                                    <StyleCheckbox className="custom-checkbox ml-4 mt-3">
                                        <input className="custom-control-input" type="checkbox" checked={aux.borderDash} name={"borderDash-" + props.nameTypeInd + "-" + index + '-' + props.index} id={"borderDash-" + props.nameTypeInd + "-" + index + '-' + props.index} onChange={handleChecksStages} />
                                        <label className="custom-control-label" htmlFor={"borderDash-" + props.nameTypeInd + "-" + index + '-' + props.index}>
                                            ¿Línea segmentada?
                                        </label>
                                    </StyleCheckbox>
                                }
                                <Row>   
                                    <Col>
                                        <StyleCheckbox className="custom-checkbox ml-4 mt-3">
                                            <input className="custom-control-input" type="checkbox" checked={aux.otherAxis} name={"otherAxis-" + props.nameTypeInd + "-" + index + '-' + props.index} id={"otherAxis-" + props.nameTypeInd + "-" + index + '-' + props.index} onChange={handleChecksStages} />
                                            <label className="custom-control-label" htmlFor={"otherAxis-" + props.nameTypeInd + "-" + index + '-' + props.index}>
                                                ¿Añadir al otro eje?
                                            </label>
                                        </StyleCheckbox>
                                    </Col>
                                    <Col>
                                        {
                                            props.nameTypeInd === 'indicators' && props.form.option === "currentYear" && currentMonth[0] !== 11 &&
                                            <StyleCheckbox className="custom-checkbox ml-4 mt-3">
                                                <input className="custom-control-input" type="checkbox" checked={aux.completeYearInd} name={"completeYearInd-" + props.nameTypeInd + "-" + index + '-' + props.index} id={"completeYearInd-" + props.nameTypeInd + "-"  + index + '-' + props.index} onChange={handleChecksStages} />
                                                <label className="custom-control-label" htmlFor={"completeYearInd-" + props.nameTypeInd + "-" + index + '-' + props.index}>
                                                    Año completo
                                                </label>
                                            </StyleCheckbox>
                                        }   
                                        {
                                            props.nameTypeInd === 'indicators' && props.form.option === 'currentYear' && currentMonth[0] === 11 && 
                                            <div className="container alert alert-danger my-2 mx-0 my-2 text-center" role="alert">
                                                La opción de completar año se activa cuando el mes actual no es Diciembre
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <StyleCheckbox className="custom-checkbox ml-4 mt-3">
                                    <input className="custom-control-input" type="checkbox" checked={aux.dataLabelTop} name={"dataLabelTop-" + props.nameTypeInd + "-" + index + '-' + props.index} id={"dataLabelTop-" + props.nameTypeInd + "-"  + index + '-' + props.index} onChange={handleChecksStages} />
                                    <label className="custom-control-label" htmlFor={"dataLabelTop-" + props.nameTypeInd + "-" + index + '-' + props.index}>
                                        Añadir escenario (Top)
                                    </label>
                                </StyleCheckbox>
                                
                            </div>

                            {
                                aux.dataLabelTop &&
                                <>
                                <div className="form-group">
                                    <Row>
                                        <Col sm={3}><b>Escenario</b></Col>
                                        <Col sm={9}>
                                            <Select
                                                value={aux.stageBarSelected}
                                                options={props.stages}
                                                onChange={data => selectStageLabelBar(data, index, props.index)}
                                                theme={theme => (styleTheme(theme))}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row>
                                        <Col><b>Nombre del Label</b></Col>
                                        <Col>
                                            <input type="text" className="form-control" id={"nameLabel-" + index + '-' + props.index} name={"nameLabel-" + index + '-' + props.index} onChange={handleOptionsStages} value={aux.nameLabel} />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group my-0">
                                    <Row>
                                        <Col><b>Color del Label</b></Col>
                                        <Col>
                                            <ColorPicker name={"colorStage-colorLabel-" + props.index + '-' + index} nameTypeInd={props.nameTypeInd} typeInd={props.typeInd} value={aux.colorLabel} form={props.form} setForm={props.setForm} />
                                        </Col>
                                    </Row>
                                </div>
                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                    <input className="custom-control-input" name={"percent-" + props.nameTypeInd + "-" + index + '-' + props.index} type="checkbox" checked={aux.percent} id={"percent-" + props.nameTypeInd + "-" + index + '-' + props.index} onChange={handleChecksStages} />
                                    <label className="custom-control-label" htmlFor={"percent-" + props.nameTypeInd + "-" + index + '-' + props.index}>
                                        Agregar porcentaje
                                    </label>
                                </StyleCheckbox>
                                </>
                            }
                            {
                                props.nameTypeInd === 'indicators' && props.form.option === 'currentYear' &&
                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                    <input className="custom-control-input" name={"otherYears-"+ props.nameTypeInd + "-" + index + '-' + props.index} type="checkbox" checked={aux.otherYears} id={"otherYears-"+ props.nameTypeInd + "-" + index + '-' + props.index} onChange={handleChecksStages} />
                                    <label className="custom-control-label" htmlFor={"otherYears-"+ props.nameTypeInd + "-" + index + '-' + props.index}>
                                        Incluir años anteriores
                                    </label>
                                </StyleCheckbox>
                            }

                            {
                                aux.otherYears &&
                                <>
                                <div className="form-group">
                                    <Row>
                                        <Col><b>Cantidad de años</b></Col>
                                        <Col>
                                            <input type="number" className="form-control" name={"numYears-" + index + '-' + props.index} id={"numYears-" + index + '-' + props.index} onChange={handleOptionsStages} value={aux.numYears} />
                                        </Col>
                                    </Row>
                                </div>
                                {
                                    parseInt(aux.numYears) > 0 &&
                                    aux.infoYears.map( (year, indexY) => {
                                        return(
                                            <StyleProperty>
                                                <h5>{currentYear[0] - aux.numYears + indexY}</h5>
                                                <div className="form-group">
                                                    <Row>
                                                        <Col><b>Label del año</b></Col>
                                                        <Col>
                                                            <input type="text" className="form-control" id={"labelYear-" + index + '-' + props.index + "-infoYears-" + indexY} name={"labelYear-" + index + '-' + props.index + "-infoYears-" + indexY} onChange={handleInfoYear} value={year.labelYear} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="form-group">
                                                    <Row>
                                                        <Col><b>Color del año</b></Col>
                                                        <Col>
                                                            <ColorPicker name={"colorYearStage-" + props.index + '-' + index + "-infoYears-" + indexY} nameTypeInd={props.nameTypeInd} typeInd={props.typeInd}  value={year.colorYear} form={props.form} setForm={props.setForm} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                
                                            </StyleProperty>
                                        )
                                    })
                                }
                                </>
                            } 
                            
                        </StyleProperty>  
                    )
                })
            }
           
        </div>
    )
}

export default IndicatorForm;