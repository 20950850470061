import { Spinner } from "react-bootstrap"
import { StyleLoading } from "./style"


export const Loading = ({ height, text = "Cargando...." }) => {
    return (
        <StyleLoading height={height}>
            <Spinner animation="border" /><h3>{text}</h3>
        </StyleLoading>
    )
}