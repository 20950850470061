import React from "react";
import { Stage, Layer, Group, Text, Rect, Transformer,Image } from 'react-konva';

class TransformerComponent extends React.Component {
    
    componentDidMount() {
      this.checkNode();
    }
    componentDidUpdate() {
      this.checkNode();
    }
  
    onTransformStart(){
      console.log('onTransformStart');
    } 
  
    onTransform(){
      console.log('onTransform');
    } 
  
    onTransformEnd(e,props){
      console.log(props)
      if(e.target.attrs!== undefined && e.target.attrs.name!== undefined){
        let posX = e.target.attrs.x;
        let posY = e.target.attrs.y;
        let rotation = e.target.attrs.rotation;
        props.changePos(e.target.attrs.name.split('-')[1], posX, posY, rotation)
      }
  
    }  
    checkNode() {
      // here we need to manually attach or detach Transformer node
      const stage = this.transformer.getStage();
      const { selectedShapeName } = this.props;
  
      var selectedNode = stage.findOne("." + selectedShapeName);
      // do nothing if selected node is already attached
      if (selectedNode === this.transformer.node()) {
        return;
      }
      if (selectedNode) {
        const type = selectedNode.getType();
        if ( type !== "Group") {
          selectedNode = selectedNode.findAncestor("Group");
        }
        // attach to another node
        this.transformer.attachTo(selectedNode);
      } else {
        // remove transformer
        this.transformer.detach();
      }
  
      this.transformer.getLayer().batchDraw();
    }
    render() {
      return (
        <Transformer
          ref={node => {
            this.transformer = node;
          }}
          resizeEnabled={false}
          transformstart={this.onTransformStart}
          transform={this.onTransform}
          transformend={this.onTransformEnd}
          onTransformEnd={(event)=>{
            this.onTransformEnd(event,this.props)
          }}
        />
      );
    }
}

export default TransformerComponent;