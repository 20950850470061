import React, { useState, useEffect } from "react";
import Utils from '../../utils';
import { Container } from 'react-bootstrap';
import CreateTableCostumer from "./CreateTableCostumer";

function GetDataTable({config}) {
    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(false);

    useEffect(() => {   
        setFlag(false);
        const view = config.hasOwnProperty('view') && config.view !== "PorDefecto" ? config.view : localStorage.getItem('view');
        const year = localStorage.getItem('year');
        const month = parseInt(localStorage.getItem('month')) + 1;
        let info_values = [];
        
        Utils.Petition.get(`/stages_relation/codes/${['R', 'VR']}/type/${view}`,
            (response) => {
                config.stages = response.data;
                let stages = response.data;
                config.indicators.map( ind => {
                    stages.map( stage => {
                        if( stage.code === 'R') {
                            info_values.push("'" + ind.id + "-" + stage.idstage + "-" + year + "-" + month + "'")
                            info_values.push("'" + ind.id + "-" + stage.idstage + "-" + (year-1) + "-" + month + "'")
                        } else {
                            info_values.push("'" + ind.id + "-" + stage.idstage + "-" + year + "-" + month + "'")
                        }
                    });
                });
                
                Utils.Petition.get( '/indicator-value/info_values/' + info_values, 
                    (responseM) => {
                        for (let i=0; i < responseM.data.length; i++) {
                            if( responseM.data[i].value === null ) {
                                responseM.data[i].value = 0;
                            }
                        }
                        setData(responseM.data)
                        console.log(responseM.data)
                        setFlag(true)
                    }  
                )   
            }
        )
    }, []);
    return (
        <Container>
            <h3 className="mb-4 text-center">{config.title}</h3>
            {
                flag && data.length > 0 && <CreateTableCostumer dataConfig={config} data={data} />
            }
        </Container>
    )
};

export default GetDataTable;