import { useState } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Utils from "../../utils";
import CustomModal from '../../components/CustomModal';
import { styleTheme } from "../../styles/SelectTheme";
import { AlertForm, ICON } from "../../utils/SweetAlert";
import { Button, ButtonMin } from "../../styles/CommonStyles";
import { IconTrash } from "../../components/iconos/IconTrash";
import { IconNotification } from "../../components/iconos/IconNotification";
import { IconDown } from "../../components/iconos/IconDown";
import { IconUp } from "../../components/iconos/IconUp";

function Indicators({ form, setForm, indexPerspective, indexObjective, indicatorData, setIndicatorData }) {

    const [editIndex, setEditIndex] = useState(-1);
    const [showAddForm, setShowAddForm] = useState(false);
    const [formIndicator, setFormIndicator] = useState({ indicator: {} })
    const [showModal, setShowModal] = useState(false);
    const [indicatorModal, setIndicatorModal] = useState(0);
    const [formComment, setFormComment] = useState({
        comment: ''
    })
    const handleShowAddForm = () => {
        setEditIndex(-1);
        setShowAddForm(!showAddForm);
        setFormIndicator({ indicator: {} });

    }
    const loadIndicators = (inputValue) => {

        if (inputValue.length > 2) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    Utils.Petition.get(
                        '/indicators/name-code-indicator/' + inputValue,
                        (response) => {
                            resolve(response.data)
                        }
                    )
                }, 100);
            });
        }

        return new Promise((resolve) => resolve([]));
    };
    const getindicatorName = (id) => {
        const indicator = indicatorData.filter(indicator => indicator.id === id);
        if (indicator.length) return indicator[0].name;
        return id;
    }
    const selectIndicators = data => {
        let _form = { ...form };
        _form.data[indexPerspective].data[indexObjective].data.push(data.id);
        if (!indicatorData.includes(data.id)) {
            Utils.Petition.get(
                '/indicators/idindicators/' + data.id, (response) => {
                    setIndicatorData([...indicatorData, ...response.data]);
                }
            );
        }
        setForm(_form);
        setFormIndicator({ indicator: {} });
    };
    const handleDelete = (index) => {
        let _form = JSON.parse(JSON.stringify(form)) //{ ...form };
        _form.data[indexPerspective].data[indexObjective].data.splice(index, 1);
        setForm(_form);
        
    }
    const handleOrderUp = (index) => {
        let _form = JSON.parse(JSON.stringify(form));
        _form.data[indexPerspective].data[indexObjective].data[index - 1] = _form.data[indexPerspective].data[indexObjective].data[index];
        _form.data[indexPerspective].data[indexObjective].data[index] = form.data[indexPerspective].data[indexObjective].data[index - 1];
        setForm(_form);
    }
    const handleOrderDown = (index) => {
        let _form = JSON.parse(JSON.stringify(form));
        _form.data[indexPerspective].data[indexObjective].data[index + 1] = _form.data[indexPerspective].data[indexObjective].data[index];
        _form.data[indexPerspective].data[indexObjective].data[index] = form.data[indexPerspective].data[indexObjective].data[index + 1];
        setForm(_form);
    }
    const handleCommentModal = (indicator) => {
        setIndicatorModal(indicator);
        Utils.Petition.get('/comments/type-model/indicator/type-id/' + indicator + '/year/' + localStorage.getItem('year') + '/month/' + localStorage.getItem('month') + '/view/' + localStorage.getItem('view'), (response) => {
            if (response) {
                setFormComment({ comment: response.data.comment });
            } else {
                setFormComment({ comment: '' });
            }
            setShowModal(true);
        })
    }
    const handleFormComment = (event) => {
        setFormComment({ ...formComment, [event.target.name]: event.target.value })
    }
    const handleSaveComment = () => {
        Utils.Petition.post('/comments', {
            comment: formComment.comment,
            year: localStorage.getItem('year'),
            month: localStorage.getItem('month'),
            typeModel: 'indicator',
            idtype: indicatorModal,
            view: localStorage.getItem('view'),
        },
        (response) => {
            AlertForm(ICON.SUCCESS, response.message);
            setShowModal(false);
        })
    }
    return (
        <>
            <ButtonMin onClick={handleShowAddForm} className="mb-3 ml-3">Nuevo Indicador</ButtonMin>
            <div>
                {
                    showAddForm &&
                    <Form>
                        <Button variant="close" className="close" onClick={() => { setShowAddForm(false) }}>×</Button>
                        <Form.Group>
                            <AsyncSelect
                                value={formIndicator.indicator}
                                cacheOptions
                                defaultOptions
                                loadOptions={loadIndicators}
                                onChange={selectIndicators}
                                theme={theme => (styleTheme(theme))}
                            />
                        </Form.Group>
                    </Form>
                }
            </div>
            <div className="bg-white p-3">
                {
                    form.data[indexPerspective].data[indexObjective].data.map((indicator, index) => {
                        return (
                            <div key={index}>
                                <Row>
                                    <Col sm={6} className="mb-2 font-weight-bold small">
                                        {getindicatorName(indicator)}
                                    </Col>
                                    <Col sm={6} className="text-right">
                                        {index !== 0 && <IconUp link onClick={() => handleOrderUp(index)} />}
                                        {form.data[indexPerspective].data[indexObjective].data.length - 1 !== index && <IconDown link onClick={() => handleOrderDown(index)} />}
                                        <IconNotification color="green" link onClick={() => handleCommentModal(indicator)} />
                                        <IconTrash link onClick={() => handleDelete(index)} />
                                    </Col>
                                </Row>
                            </div>
                        )
                    })
                }
            </div>
            {
                showModal && indicatorModal !== 0 &&
                <CustomModal title={'Comentario del indicador: ' + getindicatorName(indicatorModal)} show={showModal} setShow={setShowModal} handleSaveButton={handleSaveComment} saveButtonText="Guardar comentario">
                    <Form>
                        <h5>Comentario</h5>
                        <textarea className="form-control" onChange={handleFormComment} id="comment" name="comment" value={formComment.comment} />
                    </Form>
                </CustomModal>
            }
        </>
    );
}
export default Indicators;