import ImportData from './importData';
import HistoricalImport from './historicalImport';
import ViewDataFile from './viewDataFile';

const MassiveImportIndicators = {
    ImportData,
    HistoricalImport,
    ViewDataFile
};

export default MassiveImportIndicators;