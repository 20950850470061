import styled from 'styled-components';

export const FormCustom = styled.form`

    & > div {
        display: block;
        align-items: center;
        width: 60%;
        margin: auto;
        margin-bottom: 1rem;
    }
    & label {
        font-weight: bold;
    }
`;

export const CustomAttributes = styled.div`
    border: 2px solid var(--c-orange);
    border-radius: 0.5rem;
    box-shadow: 0px 0px 9px 4px rgb(0 0 0 / 20%);
    margin: 0.5rem 0;
    overflow: ${props => props.overflow ? props.overflow: "auto"};
    height: 30rem;

   & .content {
       display: flex;
       margin: 0.2rem 1rem;
        & .checkbox {
            flex: 0 0 180px;
        }

        & .select {
            flex: 1;
        }
    }

    & > h3 {
        padding: 1rem 0;
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        border-bottom: thin solid lightgray;
        z-index: 2;
    }
`;

export const MarginFooter = styled.div`
    padding-top: 0rem;
`;

export const StyleInfo = styled.div`
    border: 2px solid var(--c-orange);
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 1rem 0;
`;

export const StyleData = styled.div`
    border: 2px solid var(--c-darkgray);
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-bottom: 0;
    margin: 1rem 0;
`;

export const TableStyle = styled.table`
  & thead th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  
}

& thead th:first-child {
    position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  top:0;
  z-index: 10;
  background-color: var(--c-blue);
}

& tbody th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
    background-color: green;
    color: white;
    font-weight: bold;
}
`;

export const CustomCard = styled.div`
    height: 140px;
    margin-bottom: 3rem;
`;