import { useEffect } from "react";
import { useLocation } from "react-router";
import { Loading } from "./components/Loading";
import Utils from "./utils";


function RedirectAppValidator({ setLogged }) {
    const location = useLocation();
    useEffect(() => {
        Utils.ValidateSesionApp(location.pathname);
    }, []);
    return <Loading text="Validando sesión..." />
}
export default RedirectAppValidator;