import {  useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomModal from "../../CustomModal";
import { AlertForm, ICON } from "../../../utils/SweetAlert";
import Indicator from "./Indicator";
import Calculator from "../Calculator";
/* import { Button as CustomBtn } from "../../../styles/CommonStyles"; */

export const Compare = ({ disabledBtnIndicator = false, formula, setFormula, isView = false, component }) => {
    const [showModal, setShowModal] = useState(false);
    
    const [valueA, setValueA] = useState("");
    const [valueB, setValueB] = useState("");
    const [expressionA, setExpressionA] = useState("");
    const [expressionB, setExpressionB] = useState("");
    const [expressionDefaultA, setExpressionDefaultA] = useState("");
    const [expressionDefaultB, setExpressionDefaultB] = useState("");
    const [showCalcModal, setShowCalcModal] = useState(false);
    const [showCalcModalB, setShowCalcModalB] = useState(false);

    useEffect(() => {
        if(component && isView) {
            let {expresion} = component;
            let split = expresion.split(";");
            setValueA(split[1]);
            setValueB(split[2]);
            setExpressionDefaultA(split[3].replaceAll("H", "||"));
            setExpressionDefaultB(split[4].replaceAll("H", "||"));
        }
    }, [component, isView ])

    const handleModal = () => {
        setShowModal(true)
    }
    
    const handleSaveButton = (event) => {
        event.preventDefault();
        if(valueA && valueB && expressionA && expressionB) {
            let compare_formula = `@A>B;${valueA};${valueB};${expressionA.trim()};${expressionB.trim()}`;
            let concatenation = (formula) ? formula + '||' : '';
            setFormula(concatenation + compare_formula);
            clearForm();
            setShowModal(false);
        } else {
            AlertForm(ICON.WARNING, 'Hay campos vacios', "Por favor completar los campos faltantes");
        }
    }

    const clearForm = () => {

    setValueA("");
    setValueB("");
    setExpressionA("");
    setExpressionB("");
    setExpressionDefaultA("");
    setExpressionDefaultB("");
    }
    const handleSaveExpressionA = (expression) => { 
        
        setExpressionDefaultA(expression);
        
        let replace = expression.replaceAll("||", 'H');
        replace = replace.replace("H", "");
        setExpressionA(replace);
        setShowCalcModal(false);
    }

    const handleSaveExpressionB = (expression) => { 
        
        setExpressionDefaultB(expression);
        let replace = expression.replaceAll("||", 'H');
        replace = replace.replace("H", "");
        setExpressionB(replace);
        setShowCalcModalB(false);
    }


    return (
        <span>
            <Button variant="warning" className={isView ? "mx-2": " mb-2 mx-2"} disabled={disabledBtnIndicator} onClick={handleModal}>A {">"} B</Button>
            <CustomModal title={isView ? "Visualización A > B": "Comparacion A y B"} size="lg" show={showModal} setShow={setShowModal} handleSaveButton={isView ? false :handleSaveButton} saveButtonText="Agregar">
                <Form.Group>
                    <p>Valor A</p>
                    <Row>
                        {!isView && <Col sm={3}>
                            <Indicator formula={valueA} setFormula={setValueA} isCompare={true}/>
                        </Col>}
                        <Col sm={isView ? 12: 9}>
                            <Calculator isDisplay={true} textDisplay={valueA} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <p>Valor B</p>
                    <Row>
                        {!isView && <Col sm={3}>
                            <Indicator formula={valueB} setFormula={setValueB} isCompare={true} />
                        </Col>}
                        <Col sm={isView ? 12: 9}>
                            <Calculator isDisplay={true} textDisplay={valueB} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <p>Si A es mayor que B</p>
                    <Row>
                        {!isView && <Col sm={3}>
                            <Button variant="warning" className="mb-2" disabled={disabledBtnIndicator} onClick={() => setShowCalcModal(true) }>Agregar una expresion</Button>
                        </Col>}
                        <Col sm={isView ? 12: 9}>
                            <Calculator isDisplay={true} textDisplay={expressionDefaultA} />
                        </Col>
                    </Row>
                    
                    <CustomModal title={"Escribir expresion A"} show={showCalcModal} size="lg" setShow={setShowCalcModal}>
                        {showCalcModal === true && <Calculator handleAfterSave={handleSaveExpressionA} isCompare={true} />}
                    </CustomModal>

                </Form.Group>
                <Form.Group>
                    <p>Si B es mayor que A</p>
                    <Row>
                        {!isView && <Col sm={3}>
                            <Button variant="warning" className="mb-2" disabled={disabledBtnIndicator} onClick={() => setShowCalcModalB(true) }>Agregar una expresion</Button>
                        </Col>}
                        <Col sm={isView ? 12: 9}>
                            <Calculator isDisplay={true} textDisplay={expressionDefaultB} />
                        </Col>
                    </Row>
                    
                    <CustomModal title={"Escribir expresion B"} show={showCalcModalB} size="lg" setShow={setShowCalcModalB}>
                        {showCalcModalB === true && <Calculator handleAfterSave={handleSaveExpressionB} isCompare={true} />}
                    </CustomModal>
                </Form.Group>
            </CustomModal>
        </span>
    );
}
