import React, { useState, useEffect } from "react";
import Utils from '../../utils';
import { Container } from 'react-bootstrap';
import BarChartUser from './BarChartUser'

function Graphic({ config }) {
    const [data, setData] = useState([]);
    const [idstages, setIdstages] = useState([]);

    useEffect(() => {
        const view = localStorage.getItem('view');
        let contador = 0;
        let today = new Date(localStorage.getItem('year'), localStorage.getItem('month'))
        let to = '';
        if (today.getMonth() + 1 < 10) {
            to = today.getFullYear() + '-0' + (today.getMonth() + 1) + '-01';
        } else {
            to = today.getFullYear() + '-' + (today.getMonth() + 1) + '-01';
        }
        const from = today.getFullYear() + '-01-01';
        let aux = [];
        let aux_2 = [];

        for( let i = 0; i< config.stages.length; i++) {
            const stage = config.stages[i];
            let idstage = -1;

            for (let j = 0; j < stage.view.length; j++) {
                if (stage.view[j] === view) {
                    idstage = stage.value[j];
                    aux_2.push(idstage)
                }
            }
          
            const end_point = `/indicator-value/indicator/${config.indicator.id}/stage/${idstage}/range/${from}/${to}`;
            Utils.Petition.get(end_point,
                (response) => {
                    const data = {
                        indicator: config.indicator.id,
                        stage: idstage,
                        data: response.data
                    }
                    aux.push(data)
                    contador++;
                    if(contador === config.stages.length) {
                        console.log(aux)
                        setData(aux)
                    }
                }
            )
        };

        setIdstages(aux_2)
        
    }, [])

    return (
        <Container>
            <h4 className="mb-4">{config.title === "" ? config.indicator.name : config.title}</h4>
            {
                idstages.length && data.length > 0 && <BarChartUser dataConfig={config} data={data} stages={idstages}/>
            }

        </Container>
    )
}

export default Graphic;