import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Reports from './pages/reports';
import Approvals from './pages/approvals';
import Menu from './pages/menu';
import ValueMap from './pages/value-map';
import AutoLogin from './pages/Autologin';
import MasterPage from './MasterPage';
import UserGraphic from './pages/userGraphic';
import Table from './pages/table';
import './styles/scss/index.scss';
import React, { useEffect, useState } from 'react';
import RedirectAppValidator from './RedirectAppValidator';
import StrategicMap from './pages/strategic-map';
import PlantillaWeb from './pages/PlantillaWeb';
import TableCostumer from './pages/tableCostumer';
import AutoLoginToken from './pages/AutologinToken';
import Utils from './utils';
import FormIndicatorRelevant from './pages/indicatorRelevant/FormIndicatorRelevant';
function App() {

  const [logged, setLogged] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(()=>{
    if(!window.location.href.includes('/autologin/token') && localStorage.getItem('token_user')){
      Utils.Petition.get('/users/validate-token',(response)=>{
        if(!localStorage.getItem('idfilter')){
          localStorage.setItem('idfilter', response.data.idfilter);
          localStorage.setItem('filter', response.data.filter);
        }
        localStorage.setItem('iddepartment', response.data.user.iddepartment);
        localStorage.setItem('idposition', response.data.user.idposition);
        localStorage.setItem('token_user', response.data.token);
        localStorage.setItem('iduser', response.data.user.id);
        localStorage.setItem('idportal', response.data.idportal);
        localStorage.setItem('portal', response.data.portal);
        localStorage.setItem('business', response.data.config.name);
        localStorage.setItem('config', JSON.stringify(response.data.config));
        setLogged(true);
        setLoaded(true);
      })
    }else{
      setLoaded(true);
    }
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        { loaded && (
          <>
          <Route path="/approvals/:guid/:approval" component={() => <Approvals.Form />} /> 
          {
          logged ?
          <React.Fragment>
            {/* Páginas de popups */}
            <Route path="/indicator-relevant/module/:id" component={() => <FormIndicatorRelevant />} />
            <Route path="/plantilla-web/module/:id" component={() => <PlantillaWeb.Form />} />
            <Route path="/reports/module/:id" component={() => <Reports.Form />} />
            <Route path="/user-graphic/module/:id" component={() => <UserGraphic.Form />} />
            <Route path="/table/module/:id/idpage/:idpage" component={() => <Table.Form />}/>
            <Route path="/tableCostumer/module/:id/idpage/:idpage" component={() => <TableCostumer.Form />}/>
            <Route path="/strategic-map/module/:id" component={() => <StrategicMap.Form />} />
            <Route path="/value-map/module/:id" component={() => <ValueMap.FormMap />} />
            <Route path="/menu/module/:id" component={() => <Menu.Form />} />
            <Route path="/" exact={true} component={() => <Redirect to="/admin" />} />
            {/* Páginas del administrator */}
            <Route path="/admin" component={() => <MasterPage logged={logged} setLogged={setLogged} />} />
          </React.Fragment>
          :
          <React.Fragment>
            <Route path="/autologin/data/:data/url/:url" component={() => <AutoLogin setLogged={setLogged} />} />
            <Route path="/autologin/token/:token/year/:year/month/:month/view/:view/site/:site/iframe/:iframe" component={() => <AutoLoginToken logged={logged} setLogged={setLogged} />} />
          </React.Fragment>
          }
          </>
          
        )}
        {
          //!logged && <Route component={() => <RedirectAppValidator />} />
          
        }
      </Switch>
    </BrowserRouter>

  );
}

export default App;
