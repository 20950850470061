import { useState } from "react";
import { FaRedo } from "react-icons/fa";
import { IconCheck } from "../../../components/iconos/IconCheck";
import { IconClose } from "../../../components/iconos/IconClose";
import { IconEdit } from "../../../components/iconos/IconEdit";
import { IconSave } from "../../../components/iconos/IconSave";
import { IconTrash } from "../../../components/iconos/IconTrash";
import { IconUndo } from "../../../components/iconos/IconUndo";
import { ButtonLoading } from "../../../components/Loading/ButtonLoading";
import { Theme1 } from "../../../styles/Theme1";
import Utils from "../../../utils";
import { AlertConfirm, ICON } from "../../../utils/SweetAlert";

function ItemPortal({businesses, setBusinesses, index, indexPortal, handleEditPortal}){
    const [editMode, setEditMode] = useState(false);
    const [portalForm, setPortalForm] = useState({
        name:businesses[index].portals[indexPortal].name,
    })
    const handleEditMode = ()=> {
        setEditMode(true);
        
    }
    const handleSave = ()=>{
        setEditMode(false);
        let name = portalForm.name;
        Utils.Petition.post('/portals/update', {id:businesses[index].portals[indexPortal].id, name});
        let _businesses = [...businesses];
        _businesses[index].portals[indexPortal].name=name;
        setBusinesses(_businesses);
    }
    const handleDelete = ()=>{
        AlertConfirm(ICON.WARNING,"Una vez actualices la página no podrás recuperar el contenido", "¿Seguro deseas continuar?", deleteFunction(index) )
    }
    const deleteFunction = () => {
        let _delete = '1';
        Utils.Petition.post('/portals/update', {id:businesses[index].portals[indexPortal].id, delete:_delete});
        let _businesses = [...businesses];
        _businesses[index].portals[indexPortal].delete=_delete;
        setBusinesses(_businesses);
    }
    const handleRecover = () => {
        let _delete = '0';
        Utils.Petition.post('/portals/update', {id:businesses[index].portals[indexPortal].id, delete:_delete});
        let _businesses = [...businesses];
        _businesses[index].portals[indexPortal].delete=_delete;
        setBusinesses(_businesses);
    }
    const handleDisabled=()=>{
        let disable = '1';
        Utils.Petition.post('/portals/update', {id:businesses[index].portals[indexPortal].id, disable});
        let _businesses = [...businesses];
        _businesses[index].portals[indexPortal].disable=disable;
        setBusinesses(_businesses);
    }
    const handleEnabled=()=>{
        let disable = '0';
        Utils.Petition.post('/portals/update', {id:businesses[index].portals[indexPortal].id, disable});
        let _businesses = [...businesses];
        _businesses[index].portals[indexPortal].disable=disable;
        setBusinesses(_businesses);
    }
    const handleChangeForm=(event)=>{
        setPortalForm({[event.target.name]: event.target.value});
    }
    return (
        <tr key={businesses[index].portals[indexPortal].id} className={businesses[index].portals[indexPortal].delete ==='1' ? 'deleted':'normal'}>
            <td>
                {!editMode && <label>{businesses[index].portals[indexPortal].name}</label>}
                {editMode && <input type="text" className="form-control" name="name" value={portalForm.name} onChange={handleChangeForm} />}
                &nbsp;
                {
                    businesses[index].portals[indexPortal].process_portal  && businesses[index].portals[indexPortal].process_portal.status==='En proceso' &&  <span className="badge badge-secondary"><ButtonLoading text='Copiando contenido...' /></span>
                }
                {
                    businesses[index].portals[indexPortal].process_portal  && businesses[index].portals[indexPortal].process_portal.status==='Con errores' &&  <span className="badge badge-danger">{businesses[index].portals[indexPortal].process_portal.status}</span>
                }
            </td>
            <td>
            {!editMode && <IconEdit link onClick={()=>handleEditPortal(indexPortal)} /> }

            {businesses[index].portals[indexPortal].disable==='0' && <IconClose link color="red" onClick={handleDisabled} />}
            {businesses[index].portals[indexPortal].disable==='1' && <IconCheck link color="green" onClick={handleEnabled} />}
            {businesses[index].portals[indexPortal].delete==='0' && <IconTrash link color="red" title="Eliminar" onClick={handleDelete} />}
            {businesses[index].portals[indexPortal].delete==='1' && <IconUndo link textTooltip="Recuperar" onClick={handleRecover} />}

            </td>
        </tr>
    )
}
export default ItemPortal;