import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Svg } from '../../styles/CommonStyles';

export const IconUp = ({ color, link, onClick = () => { }, textTooltip = "Subir" }) => {
    return (

        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-editar`}>
                    {textTooltip}
                </Tooltip>
            }
        >
            <Svg color={color} link={link} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><rect className="a" width="24" height="24" />
                <path className="b" d="M6,0,0,6,1.41,7.41,6,2.83l4.59,4.58L12,6Z" transform="translate(6 8)" />
            </Svg>
        </OverlayTrigger>

    )
}
