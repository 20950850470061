import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { Loading } from "../components/Loading";

function AutoLogin({ setLogged }) {
    const [loaded, setLoaded] = useState(false);
    const { data, url } = useParams();
    useEffect(() => {

        const _data = JSON.parse(decodeURIComponent(data));
        Object.keys(_data).map(_localVar => {
            localStorage.setItem(_localVar, _data[_localVar]);
        });
        setLogged(true);
        setLoaded(true);
    }, []);

    return (
        loaded ?
            (
                url ? <Redirect to={decodeURIComponent(url)} /> : <Redirect to="/admin" />
            )
            : <Loading />

    );


}
export default AutoLogin;