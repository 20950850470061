import styled from "styled-components";

export const TooltipIcon = styled.div`
    position: relative;
    &:hover .show {
            display: block;
        }
    & .show {
        position: absolute;
        min-width: 60px;
        bottom: 30px;
        left: 0px;
        z-index: 20;
        box-shadow: 0px 0px 20px 0 rgb(0 0 0 / 20%);
        display: none;
        background-color: white;
        padding: 0.3rem;
        border-radius: 0.5rem;
        background-color: black;
        color: white;
        font-weight: bold;
        text-align: center;
        & p {
            margin: 0;
            font-size: 0.9rem;
        }

        
    }

`;