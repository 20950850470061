import styled from 'styled-components';

export const CustomTd = styled.td`

    & svg {
        color: var(--c-green);
        font-size: 1.2rem;
        cursor: pointer;
    }
        
    & svg:hover {
        opacity: 0.5
    }
`;