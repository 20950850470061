import { Accordion } from "react-bootstrap";
import styled from "styled-components";

export const AccordionStyle = styled(Accordion)`
    
    & .btn:focus {
        box-shadow: none;
    }
    & .btn-link {
        color: black;
    }
    & .btn-link:focus {
        text-decoration: none;
    }
`;