import { useEffect, useState } from 'react';
import { BlockPicker } from 'react-color';
import Utils from '../utils';

function ColorPicker(props) {
    const [background, setBackground] = useState(props.value || '#777273');
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const colors = Utils.getConfigPortal('graphic_palette');
    
    useEffect(() => {
        
        if ((props.name.split("-"))[0] === 'labelInd') {
            const id = (props.name.split("-"))[2];
            const variable = (props.name.split("-"))[1];
            let temp = JSON.parse(JSON.stringify(props.typeInd));

            temp[id][variable] = background;

            props.setForm({
                ...props.form,
                [props.nameTypeInd]: temp
            })

        } else if ((props.name.split("-"))[0] === 'colorStageUser') {
            const index = (props.name.split('-'))[1];
            let temp = JSON.parse(JSON.stringify(props.form.stages));
            temp[index].color = background;

            props.setForm({
                ...props.form,
                stages: temp
            })

        } else if ((props.name.split("-"))[0] === 'otherOptionColor') {
            const index = (props.name.split('-'))[2];
            const variable = (props.name.split('-'))[1];
            let temp = JSON.parse(JSON.stringify(props.typeInd));
            temp[index][variable] = background;

            props.setForm({
                ...props.form,
                [props.nameTypeInd]: temp
            })

        } else if (props.name === "colorSerie"){
            props.setSerieColor(background)
        } else if (props.name === 'color-ind') {
            let temp = JSON.parse(JSON.stringify(props.form.indicator));
            temp.color = background;
            props.setForm({
                ...props.form,
                indicator: temp
            });

        } else if( (props.name.split("-"))[0] === 'colorStage') {
            let temp = JSON.parse(JSON.stringify(props.typeInd));
            const variable = (props.name.split("-"))[1];
            const idInd = (props.name.split("-"))[2];
            const idStage = (props.name.split("-"))[3];

            temp[idInd].stage[idStage][variable] = background;

            props.setForm({
                ...props.form,
                [props.nameTypeInd]: temp
            });

        } else if ( (props.name.split("-"))[0] === 'colorYear' ) {
            const variable = (props.name.split("-"))[0];
            let temp = JSON.parse(JSON.stringify(props.typeInd));
            const indexI = (props.name.split("-"))[1];
            const arr = (props.name.split("-"))[2];
            const indexY = (props.name.split("-"))[3];

            temp[indexI][arr][indexY][variable] = background;
            props.setForm({
                ...props.form,
                [props.nameTypeInd]: temp
            });

        } else if ( (props.name.split("-"))[0] === 'colorYearStage' ) {
            let temp = JSON.parse(JSON.stringify(props.typeInd));
            const indexI = (props.name.split("-"))[1];
            const indexS = (props.name.split("-"))[2];
            const arr = (props.name.split("-"))[3];
            const indexY = (props.name.split("-"))[4];

            temp[indexI].stage[indexS][arr][indexY].colorYear = background
            props.setForm({
                ...props.form,
                [props.nameTypeInd]: temp
            });

        } else if( props.name.split("-")[0] === "waterfall"){
            let variable = props.name.split("-")[1];
            props.setForm({
                ...props.form,
                [variable]: background
            });

            
        } else if (props.name.split("-")[0] === "otherIndOptionColor") {
            let temp = JSON.parse(JSON.stringify(props.typeInd));
            const index = (props.name.split("-"))[2];
            const indexI = (props.name.split("-"))[3];
            const variable = (props.name.split("-"))[1];
            temp[`${index}`].indicator[indexI][variable] = background;

            props.setForm({
                ...props.form,
                [props.nameTypeInd]: temp
            });
        } else if(props.name === "IndicatorStage") {

            let findIndicatorStage = props.form.indicatorsStages.find(stage => stage.serieLabel === props.serie && stage.columnLabel === props.columm);
            if(findIndicatorStage) {
                let tempIS = {...findIndicatorStage};
                tempIS.background = background;
                let filterIS = props.form.indicatorsStages.filter(stage => !(stage.serieLabel === props.serie && stage.columnLabel === props.columm));
                props.setForm({
                    ...props.form,
                    indicatorsStages: [...filterIS, tempIS]
                })
            }
        } else {
            props.setForm({
                ...props.form,
                [props.name]: background
            });
        }

    }, [background])


    const handleChangeComplete = (color) => {
        setBackground(color.hex);
    }

    const handleFocus = (event) => {
        event.preventDefault()
        setDisplayColorPicker(true);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const popover = {
        position: 'absolute',
        zIndex: '2',
        top: '-320px'
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    return (
        <div className="container-picker">
            <div className="input-group mb-3">
                <input key="llave" type="text" name={props.name} onFocus={handleFocus} className="form-control" value={props.value} />
                <div className="input-group-append">
                    <button className="btn" onClick={handleFocus} style={{ backgroundColor: props.value, borderLeftColor: '#ced4da', zIndex: '0' }}></button>
                </div>
            </div>
            {
                displayColorPicker ?
                    <div style={popover}>
                        <div style={cover} onClick={handleClose} />
                        <BlockPicker colors={colors} color={background} onChangeComplete={handleChangeComplete} />
                    </div>
                    : null
            }
        </div>
    );
}
export default ColorPicker;