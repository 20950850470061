import React, { useState, useEffect } from "react";
import Select from "react-select";
import Utils from "../../../utils";
import Calculator from "../../../components/calculator/Calculator";
import { FaSave, FaEraser } from "react-icons/fa";
//Buttons
import { IconEdit } from "../../../components/iconos/IconEdit";
import { IconTrash } from "../../../components/iconos/IconTrash";

//popUps
import CustomModal from "../../../components/CustomModal";
import { Button, Container, StyleSelect } from "../../../styles/CommonStyles";
import { Theme1 } from "../../../styles/Theme1";
import { styleTheme } from "../../../styles/SelectTheme";
import { IconCalculator } from "../../../components/iconos/IconCalculator";
import { IconCheck } from "../../../components/iconos/IconCheck";
import { IconClose } from "../../../components/iconos/IconClose";
import { Loading } from "../../../components/Loading";
import { AlertConfirm, AlertForm, ICON } from "../../../utils/SweetAlert";
import { ButtonLoading } from "../../../components/Loading/ButtonLoading";
import { useConfigRole } from "../../../Hooks/configRole";

function List() {
  //data table
  const [dataStages, setDataStages] = useState([]);

  //Loaders States
  const [tableIsLoaded, setTableIsLoaded] = useState(false);

  //React Select States
  const [typeOptions, setTypeOptions] = useState([
    { label: "Texto", value: "Text" },
    { label: "Numerico", value: "Number" },
  ]);

  //dataConfig
  const [idStage, setIdStage] = useState("");
  const [nameStage, setNameStage] = useState("");
  const [typeList, setTypeList] = useState("");

  //states popUps
  const [showModal, setShowModal] = useState(false);

  //stateVars
  const [stageSend, setStageSend] = useState("");
  const [isStageSend, setIsStageSend] = useState(false);

  //loading 
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const [business, setBusinesses] = useState([]);
  const [idBusiness, setIdBusiness] = useState(localStorage.getItem('idbusiness'));
  const { configRoleByIdModule } = useConfigRole(6);
  useEffect(() => {
    //List of stages
    let urlStages = `/stages/stages-client/idbusiness/${idBusiness}`;
    Utils.Petition.get(
      urlStages,
      (res) => {
        setDataStages(res.data);
        setTableIsLoaded(true);
      },
      (err) => {
        console.log(err);
      }
    );
    Utils.Petition.get('/business', function(response){
        setBusinesses(response.data);        
    });
  }, []);


  //function handler for popup
  const handleShowModal = (event, id) => {
    event.preventDefault()
    setShowModal(true);
    setStageSend(id)
    setIsStageSend(true)
  };

  //function that init or updates the view of the list

  const handleNameStage = (event) => {
    setNameStage(event.target.value);
  };

  const handleTypeList = (event) => {
    setTypeList(event);
  };

  const updateView = () => {
    let urlStages = `/stages/stages-client/idbusiness/${idBusiness}`; //obtener por id bisne
    console.log("Actualizacion de Vista");
    Utils.Petition.get(
      urlStages,
      (res) => {
        setTableIsLoaded(false);
        setDataStages(res.data);
        setTableIsLoaded(true);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const deleteData = (event, id) => {
    event.preventDefault();
    console.log("Eliminacion");
    let urlStage = `/stages/delete-stage/${id}`; //obtener por id bisne
    Utils.Petition.delete(
      urlStage,
      (res) => {
        AlertForm(ICON.SUCCESS, `El escenario  ha sido eliminado`);

      },
      (err) => {
        console.log(err);
        AlertForm(ICON.ERROR, "No se elimino la información", "Intentelo nuevamente");
      }
    );
    updateView();
    cleanFields();
  };

  const saveDB = (event) => {
    event.preventDefault();
    setIsLoadingSave(true);
    //congigurates the body that will be send to de endpoint
    const config = {
      name: nameStage,
      type: typeList.value,
      hide: 0
      //este deberia ingresrlo el admin
    };
    let validateDuplicate = dataStages.find(el => el.name.toLowerCase() === nameStage.toLowerCase());
    console.log("Configuracion Guardada", config);

    //only updates the data if the object contains the id of atribute
    //if id not exist the function going to create a new atribute
    if (idStage) {
      if(validateDuplicate.id !== idStage) {
        setIsLoadingSave(false);
        return AlertForm(ICON.WARNING,"Existe un Escenario con este mismo nombre por favor valide nuevamente", "" );
      }
      
     
      config.id = idStage;
      Utils.Petition.post(
        `/stages/update-stage/`,
        config,
        (res) => {
          AlertForm(ICON.SUCCESS, "Actualizado Correctamente");
        },
        (err) => {
          console.log(err);
          AlertForm(ICON.ERROR, "No se actualizo la información", "Intentelo nuevamente");
        }, () => {
          setIsLoadingSave(false);
        }
      );
    } else {
      if(validateDuplicate) {
        setIsLoadingSave(false);
        return AlertForm(ICON.WARNING,"Existe un Escenario con este mismo nombre por favor valide nuevamente", "" );
      }
      Utils.Petition.post(
        "/stages",
        config,
        (res) => {
          AlertForm(ICON.SUCCESS, "Guardado Correctamente");
        },
        (err) => {
          console.log("el error", err);
          AlertForm(ICON.ERROR, "No se guardo la información", "Intentelo nuevamente");
        }, () => {
          setIsLoadingSave(false);
        }
      );
    }
    updateView();
    cleanFields();
  };

  const cleanFields = (e) => {
    if (e) e.preventDefault();
    //dataConfig restart
    setIdStage("");
    setNameStage("");
    setTypeList(null);
  };

  const fillFields = (e, id, name, listType) => {
    e.preventDefault();
    //dataConfig fill with id
    setIdStage(id);
    setNameStage(name);
    setTypeList(
      listType === "Text"
        ? { label: "Texto", value: "Text" }
        : { label: "Numerico", value: "Number" }
    );
  };

  const handleHide = (key, id) => {
    let data = [...dataStages]
    // console.log(dataStages[key])
    data[key].hide = data[key].hide !== "1" ? "1" : "0";
    setDataStages(data)

    Utils.Petition.post('/stages/update', data[key],
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  const handleChangeBusiness = (event) => {
      setIdBusiness(event.target.value)
      let urlStages = `/stages/stages-client/idbusiness/${event.target.value}`;
      Utils.Petition.get(
        urlStages,
        (res) => {
          setDataStages(res.data);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  return (
    <Container>
      <div className="d-flex flex-wrap m-4">
        <div className="container-fluid mb-2">
          <div className="card">
            <div className="card-header">
              <b>Administrador de escenarios</b>
            </div>
            {(configRoleByIdModule.create || idStage) && <div className="card-body">
              <form>
                <input
                  type="text"
                  className="form-control"
                  placeholder="id"
                  value={idStage}
                  hidden
                />
                <div className="form-group">
                  <label>Nombre </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre del escenario"
                    onChange={handleNameStage}
                    value={nameStage}
                  />
                </div>
                {
                  <div className="form-group">
                    <label>Tipo</label>
                    <Select
                      placeholder="Seleccione un Opci&oacute;n"
                      options={typeOptions}
                      onChange={handleTypeList}
                      value={typeList}
                      theme={theme => (styleTheme(theme))}
                    />
                  </div>
                }
                <Button onClick={saveDB} className="mr-2" disabled={isLoadingSave}>
                  {isLoadingSave ? <ButtonLoading text="Guardando..." /> : <>Guardar  <FaSave /></>} 
                </Button>

                <Button color={Theme1.grayText} className="mr-2" onClick={cleanFields}>
                  Limpiar <FaEraser />
                </Button>
              </form>
            </div> }
          </div>
        </div>
        <div className="container-fluid">
          <h2 className="py-5 text-center">Lista de Escenarios</h2>
          {business.length > 1 && 
              <StyleSelect margin="1rem" onChange={handleChangeBusiness} value ={idBusiness}>
                  {
                      business.map((e,i)=>{
                          return(
                              <option key={i} value={e.id}>{e.name}</option>
                          )
                      })
                  }
              
              </StyleSelect>
          }
          <table className="table table-striped">
            <thead className="bg-dark text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
                <th scope="col">Tipo</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {tableIsLoaded &&
                dataStages.map((value, key) => (
                  <tr key={key}>
                    <td >{value.id}</td>
                    <td>{value.name}</td>
                    <td>{value.type === "Text" ? "Texto" : "Numerico"}</td>
                    <td>
                      {configRoleByIdModule.update && <IconEdit
                        link
                        onClick={(e) =>
                          fillFields(e, value.id, value.name, value.type)
                        }
                      />}
                      {
                        configRoleByIdModule.delete && <IconTrash
                        link
                        onClick={(e) =>
                          AlertConfirm(ICON.WARNING,
                            "¿Estás seguro que deseas eliminar este item?", "",
                            () => deleteData(e, value.id)
                          )}
                        /> 
                      }
                      
                      {configRoleByIdModule.update && <span onClick={()=>handleHide(key, value.id)}>
                      {value.hide === "1" ? <IconCheck link textTooltip="Mostrar" color={Theme1.success}/> : <IconClose link textTooltip="Ocultar" color={Theme1.red}/>}
                      </span>}
                     
                      {configRoleByIdModule.update && <IconCalculator link onClick={(e) => handleShowModal(e, value.id)}/>}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!tableIsLoaded && <Loading />}
          {tableIsLoaded && !dataStages.length && "No hay resultados."}

          <CustomModal
            title={"Calcular Valores"}
            show={showModal}
            setShow={setShowModal}
            size="lg"
          >
            {showModal && isStageSend && <Calculator type="stage" idstage={stageSend} />}
          </CustomModal>
        </div>
      </div>
    </Container>
  );
}

export default List;
