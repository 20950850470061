import { useState, useEffect } from 'react';
import Utils from "../../utils";
import { Container,  Table } from "react-bootstrap";
import { ButtonMin } from '../../styles/CommonStyles';
const TYPE_FILE = {
    data: '/data-files/id',
    error: '/import-errors/id'
};

function ViewSummarize ({id}) {
    
    
    const [dataFile, setDataFile] = useState([]);
    const [dataError, setDataError] = useState([]);
    const [flag, setFlag] = useState(false);
    const [msg, setMsg] = useState('');
    const [msgError, setMsgError] = useState('');

    useEffect( () => {
        Utils.Petition.get( `${TYPE_FILE.data}/${id}`,
            (response) => {
                if(response.data) {
                    setDataFile(response.data);
                } else {
                    setMsg('No hay información')
                }
            }
        );

        Utils.Petition.get( `${TYPE_FILE.error}/${id}`,
            (response) => {
                if(response.data) {
                    setDataError(response.data);
                } else {
                    setMsgError('No hay información')
                }
            }
        );
    }, [id])

    return (
        <Container fluid>
            <ul className="nav justify-content-center my-3">
                <li className="nav-item mr-2">
                    <ButtonMin onClick={() => {setFlag(false)}} className="nav-link">Resultado del cargue</ButtonMin>
                </li>
                <li className="nav-item">
                    <ButtonMin onClick={ () => {setFlag(true)}} className="nav-link">Errores del cargue</ButtonMin>
                </li>
            </ul>
            {
                !flag && 
                <Container fluid  style={{overflow: "auto", maxHeight: "70vh"}}>
                    <Table hover bordered style={{position: "relative"}}>
                        <thead className="bg-dark text-white" style={{position: "sticky", top: 0, left: 0}}>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Código</th>
                                <th scope="col">Nombre del Indicador</th>
                                <th scope="col">Ene</th>
                                <th scope="col">Feb</th>
                                <th scope="col">Mar</th>
                                <th scope="col">Abr</th>
                                <th scope="col">May</th>
                                <th scope="col">Jun</th>
                                <th scope="col">Jul</th>
                                <th scope="col">Ago</th>
                                <th scope="col">Sep</th>
                                <th scope="col">Oct</th>
                                <th scope="col">Nov</th>
                                <th scope="col">Dic</th>
                            </tr>
                        </thead>
                        <tbody style={{fontSize: "12px"}}>
                            {
                                msg === "" &&
                                dataFile.map( (data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index}</td>
                                            <td>{data.code?data.code:""}</td>
                                            <td>{data.name?data.name:""}</td>
                                            <td>{data.Ene?data.Ene:""}</td>
                                            <td>{data.Feb?data.Feb:""}</td>
                                            <td>{data.Mar?data.Mar:""}</td>
                                            <td>{data.Abr?data.Abr:""}</td>
                                            <td>{data.May?data.May:""}</td>
                                            <td>{data.Jun?data.Jun:""}</td>
                                            <td>{data.Jul?data.Jul:""}</td>
                                            <td>{data.Ago?data.Ago:""}</td>
                                            <td>{data.Sep?data.Sep:""}</td>
                                            <td>{data.Oct?data.Oct:""}</td>
                                            <td>{data.Nov?data.Nov:""}</td>
                                            <td>{data.Dic?data.Dic:""}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    {
                        msg !== "" &&
                        <div className="alert alert-danger text-center" role="alert">
                            {msg}
                        </div>
                    }
                    {
                        dataFile.length === 0  && msg === "" && 
                        <div className="alert alert-warning text-center" role="alert">
                            No hay datos 
                        </div>
                    }
                </Container>
            }
            {
                flag && 
                <Container fluid>
                    <Table hover bordered>
                        <thead className="bg-dark text-white">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Código</th>
                                <th scope="col">Nombre del Indicador</th>
                                <th scope="col">Fila del Archivo</th>
                                <th scope="col">Observación</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                msgError === "" &&
                                dataError.map( (error, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index}</td>
                                            <td>{error.code}</td>
                                            <td>{error.name}</td>
                                            <td>{error.row}</td>
                                            <td>{error.comment}</td>
                                        </tr>
                                    )
                                })
                            }
                            
                        </tbody>

                    </Table>
                    {
                        msgError !== "" &&
                        <div className="alert alert-danger text-center" role="alert">
                            {msgError} 
                        </div>
                    }
                    {
                        dataError.length === 0  && msgError === "" && 
                        <div className="alert alert-warning text-center" role="alert">
                            No hay datos 
                        </div>
                    }
                </Container>
            }
        </Container>
        
    )
}

export default ViewSummarize;