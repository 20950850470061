import { Svg } from '../../styles/CommonStyles';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const IconTrash = ({ color = "red", link, onClick = () => { }, hover, textTooltip = "Eliminar" }) => {
  return (

    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-delete`}>
          {textTooltip}
        </Tooltip>
      }
    >
      <Svg color={color} link={link} onClick={onClick} hover={hover} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><rect className="a" width="24" height="24" />
        <g transform="translate(-1.435 -0.508)">
          <path className="b" d="M13.5,13.5H15v9.007H13.5Z" transform="translate(-2.996 -3.744)" />
          <path className="b" d="M20.25,13.5h1.5v9.007h-1.5Z" transform="translate(-5.242 -3.744)" />
          <path className="b" d="M4.5,6.75v1.5H6V23.262a1.5,1.5,0,0,0,1.5,1.5H19.511a1.5,1.5,0,0,0,1.5-1.5V8.251h1.5V6.75Zm3,16.512V8.251H19.511V23.262Z" transform="translate(0 -1.498)" />
          <path className="b" d="M13.5,2.25h6v1.5h-6Z" transform="translate(-2.996)" />
        </g>
      </Svg>
    </OverlayTrigger>
  )
}
