import { useEffect } from "react";
import { useState } from "react";
import { FaRedo } from "react-icons/fa";
import { StyleInput, StyleSelect } from "../../styles/CommonStyles";
import Utils from "../../utils";
import { AlertConfirm, ICON } from "../../utils/SweetAlert";
import { IconTrash } from '../../components/iconos/IconTrash';
import { IconEdit } from '../../components/iconos/IconEdit';
import { IconSave } from "../../components/iconos/IconSave";
import { IconUndo } from "../../components/iconos/IconUndo";

function Item({ templates, data, configRol }) {
    const [page, setPage] = useState({});
    const [editMode, setEditMode] = useState(false);
    useEffect(() => {
        setPage({ ...data });
    }, []);
    console.log(page);
    const handleEditMode = () => {
        setEditMode(true);
    }

    const confirmDelete = () => {
        setPage({ ...page, deleted: '1' });
        Utils.Petition.post('/pages/update', { id: page.id, deleted: '1' });
    }
    const handleDelete = () => {
        AlertConfirm(ICON.WARNING, "Una vez actualices la página no podrás recuperar el contenido", "¿Seguro deseas continuar?", confirmDelete, "Eliminar");
    }
    const handleRecover = () => {
        setPage({ ...page, deleted: '0' });
        Utils.Petition.post('/pages/update', { id: page.id, deleted: '0' });
    }
    const handleChangeForm = (event) => {
        const { name, value } = event.target;
        if (name === 'template') {
            AlertConfirm(ICON.WARNING, "Se perderan los módulos que se encuentren en otra sección", "¿Seguro deseas continuar?", () => setPage({ ...page, [name]: value }), "Si");
        } else {
            setPage({ ...page, [name]: value })
        }
    }
    const handleSave = () => {
        setEditMode(false);
        Utils.Petition.post('/pages/update', page);
    }
    return (
        <tr className={page.deleted === '1' ? 'deleted' : 'normal'}>
            <td><b>{page.id}</b></td>
            <td>
                {!editMode && <label>{page.name}</label>}
                {editMode && <StyleInput type="text" name="name" value={page.name} onChange={handleChangeForm} />}
            </td>
            <td>
                {!editMode && <label>{page.template}</label>}
                {
                    editMode &&
                    <StyleSelect value={page.template} name="template" onChange={handleChangeForm}>
                        {
                            templates.map((template) => {
                                return <option key={template.code} value={template.code}>{template.name}</option>
                            })
                        }
                    </StyleSelect>
                }
            </td>
            <td>
                {!editMode && <label>{page.path}</label>}
                {editMode && <StyleInput type="text" value={page.path} name="path" onChange={handleChangeForm} />}
            </td>
            <td>
                {!editMode && configRol.update && <IconEdit link onClick={handleEditMode} />}
                {editMode && <IconSave link onClick={handleSave} />}
                {page.deleted === '0' && configRol.delete && <IconTrash color="red" link onClick={handleDelete} />}
                {page.deleted === '1' && <IconUndo textTooltip="Recuperar" link onClick={handleRecover} />}
            </td>
        </tr>
    )
}
export default Item;