import styled from 'styled-components';

export const DisplayCalculator = styled.div`
    text-align: center;
    background: var(--c-orange-opacity);
    border: 1px solid var(--c-orange);
    border-radius: 0.5rem;
    padding: 5px;
    min-height: 2rem;
    & > span {
        font-weight: bold;
        font-size: 1.2rem;
    }

`;

export const ButtonsStyle = styled.div`
    & button {
        font-weight: bold;
    }
`;

export const ContainerCalc = styled.div`
    height: ${props => props.display ? "auto": "70vh"};
    padding: ${props => props.display ? "0": " 1.5rem 2rem"};;
`;

export const StyleExpression = styled.div`
    border: thin solid #888888;
    background-color: #f6b77e;
    border-radius: 0.5rem;
    display: inline-block;
    margin: 0rem 0.5rem 0.2rem 0;
    & > div {
        font-size: 0.8rem;
        font-weight: bold;
        border-left: thin solid #888;
        padding: 0.4rem;
        display: inline-block;
        &:first-child {
            border-left: none;
        }
    }
`;