import { useState, useEffect } from 'react';
import Utils from "../../utils";
import { Col, Row, Form, Table, Pagination } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import CustomModal from '../../components/CustomModal';
import ViewGraphic from './Components/ViewGraphic';
import IndicatorRelations from './Components/IndicatorRelations';
import { Link } from 'react-router-dom';
import { Container, ContainerForm, StyleCheckbox, Button } from '../../styles/CommonStyles';
import { styleTheme } from '../../styles/SelectTheme';
import { MarginFooter } from './styles';
import { ButtonLoading } from '../../components/Loading/ButtonLoading';
import { IconEdit } from '../../components/iconos/IconEdit';
import { IconTrash } from '../../components/iconos/IconTrash';
import { IconData } from '../../components/iconos/IconData';
import { IconGraph } from '../../components/iconos/IconGraph';
import { AlertConfirm, AlertForm, ICON } from '../../utils/SweetAlert';
import { IconConfig } from '../../components/iconos/IconConfig';
import { FaSearch } from 'react-icons/fa'
import { useConfigRole } from '../../Hooks/configRole';
function Search() {

    const END_POINTS = {
        dictionary: '/dictionary',
        filter: '/filter',
        atribute: '/indicator-atribute',
        option: '/list-options'
    }

    const [search, setSearch] = useState([]);
    const [searchPagination, setSearchPagination] = useState(null)
    const [advancedOptions, setAdvancedOptions] = useState([]);
    const [dataIndicators, setDataIndicators] = useState([]);
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalRelations, setShowModalRelations] = useState(false);
    const [indicator, setIndicator] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [infoSelect, setInfoSelect] = useState({});
    const [infoOptionSelect, setInfoOptionSelect] = useState({});
    const [pageActive, setPageActive] = useState(null);
    const { validateView } = useConfigRole();

    const filter_name = Utils.getConfigPortal('filter_name');

    const [form, setForm] = useState({
        dictionary: '',
        name: '',
        nameConcat: '',
        code: '',
        filter: '',
        measure: '',
        responsible: '',
        myIndicators: 0,
        advancedFilter: 0,
        advancedOptions: [],
        indexOptions: [],
        cant_options: ""
    })
    useEffect(() => {
        let temp = {};

        Utils.Petition.get(
            END_POINTS['atribute'],
            (response) => {
                setAdvancedOptions(response.data)
                const optionSelect = response.data.filter(aux => aux.object_type === 'list' || aux.object_type === 'list-multiple');
                let temp_options = {};
                for (let i = 0; i < optionSelect.length; i++) {
                    Utils.Petition.get(
                        END_POINTS.option + '/select_name/id/' + optionSelect[i].id,
                        (response) => {
                            response.data.unshift({
                                id: "",
                                value: "",
                                label: "Ninguno"
                            })
                            temp_options[optionSelect[i].id] = response.data
                            if (Object.keys(temp_options).length === optionSelect.length) {
                                setInfoOptionSelect(temp_options);
                            }
                        }   
                    );
                }
            }   
        );

        Utils.Petition.get(
            END_POINTS['dictionary'] + '/select_name',
            (response) => {
                response.data.unshift({
                    id: "",
                    value: "",
                    label: "Ninguno"
                })
                temp.dictionary = response.data;
                if (Object.keys(temp).length === 2) {
                    setInfoSelect(temp)
                }
            }   
        );
        Utils.Petition.get(
            END_POINTS['filter'] + '/select_name',
            (response) => {
                response.data.unshift({
                    id: "",
                    value: "",
                    label: "Ninguno"
                })
                temp.filter = response.data;
                if (Object.keys(temp).length === 2) {
                    setInfoSelect(temp)
                }
            }   
        );

    }, [])

    useEffect(() => {
        if (searchPagination === null && search) {
          getSearch(1);
        }
    
      }, [searchPagination, search]);
    
      useEffect(() => {
        if (!search) return;
        getSearch(1);
      }, [search])


    const handleForm = (event) => {
        let value = event.target.value;

        setForm({
            ...form,
            [event.target.name]: value
        });
    };

    const loadInfo = ({ input, type, flag = '' }) => {

        return new Promise((resolve) => {
          setTimeout(() => {
            Utils.Petition.get(
              END_POINTS[type] + '/select_name/' + flag,
              (response) => {
                let temp = response.data
                temp.unshift({
                  id: "",
                  value: "",
                  label: "Ninguno"
                })
                resolve(temp)
              }
            )
          }, 100);
        });
      };

    const loadUsers = (input) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                Utils.Petition.get(
                    '/users/select_name/' + input,
                    (response) => {
                        let temp = response.data
                        temp.unshift({
                            id: "",
                            value: "",
                            label: "Ninguno"
                        })
                        resolve(temp)
                    }
                )
            }, 100);
        });
    };

    const selectInfo = ({ data, type, list = 0 }) => {

        let variable = data.id;
        if (type === 'dictionary') variable = data.value;
        if (type === 'advancedOptions') {
            let options = form.advancedOptions;
            let i = 0;
            while (i < options.length) {
                if (options[i].idlist === list) {
                    options.splice(i, 1);
                    i--;
                };
                i++;
            }
            if (variable !== "") {
                variable = options.concat(data);
            } else {
                variable = options;
            }
        }

            setForm({
            ...form,
            [type]: variable
        })
    };

    const handleOtherOptions = (event, type) => {

        let flag = 0;
        if (event.target.checked) flag = 1;

        setForm({
            ...form,
            [type]: flag
        })
    };

    const updateTable = () => {
        let cadena = '';

        if (form.dictionary !== "" && form.name !== "") cadena = form.dictionary + " " + form.name;
        else if (form.dictionary !== "") cadena = form.dictionary;
        else cadena = form.name;

        if (form.responsible === "") form.responsible = "%%"
        if (form.filter === "") form.filter = "%%"

        if (form.advancedOptions.length > 0) {
            form.indexOptions = form.advancedOptions.map(option => "'" + option.idlist + '-' + option.id + "'");
            form.cant_options = form.advancedOptions.length;
        } else {
            form.indexOptions = ["'%%'"];
            form.cant_options = "%%";
        };

        form.nameConcat = cadena;
        Utils.Petition.post(
            '/indicators/filtro', form,
            (response) => {
                if (response.data.length === 0) {
                    setMessage('No se encontró información');
                } else {
                    setMessage('');
                }
                setSearch(response.data)               
            },
            (error) => {
                if (error.response) {
                    setMessage(error.response.data.message);
                } else {
                    setMessage('La aplicación está presentando problemas, por favor intente más tarde');
                }
            },
            () => {
                setIsLoading(false);
            }
        );
        form.nameConcat = "";
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        updateTable();

    }

    const handlePopup = (item) => {
        setIndicator(item)
        setShowModal(true);
    }

    const handlePopupRelations = (item) => {
        Utils.Petition.get(
            '/indicator-relations/id/' + item.id,
            (response) => {
                setIndicator(item)
                setDataIndicators(response.data)
                setShowModalRelations(true);
            }
        );
    }

    const deleteIndicator = (id) => {
        Utils.Petition.post('/indicators/deleteIndicator/' + id, {},
            (response) => {
                AlertForm(ICON.SUCCESS, response.message);
                updateTable();
            }
        );
    }
    const handleDelete = (item) => {
        AlertConfirm(ICON.WARNING, `Eliminar Indicador N° ${item.code}?`, 'Estas Seguro', () => deleteIndicator(item.id))

    }
    const getPages = () => {
        if (search) {
          const last = search.length / 20;
          let active = parseInt(pageActive);
    
          let pages = [];
          let numberPage = active < 11 ? 1 : active - 10;
          for (let i = 1; i <= 20; i++) {
            if (numberPage < last) {
              pages.push(
                <Pagination key={numberPage}>
                  <Pagination.Item
                    id={numberPage}
                    active={numberPage === active}
                    onClick={handlePagination}
                  >
                    {numberPage}
                  </Pagination.Item>
                </Pagination>
              );
              numberPage++;
            }
          }
          return pages;
        }
      };
    
      const handlePagination = (e) => {
        if (e.target.id) {
          getSearch(e.target.id);
        } else if (e.target.dataset.name) {
          if (e.target.dataset.name === 'first') {
            getSearch(1);
          } else if (e.target.dataset.name === 'prev') {
            if (pageActive - 1 >= 1) {
              getSearch(pageActive - 1);
            }
          } else if (e.target.dataset.name === 'next') {
            if (pageActive + 1 <= search.length / 20) {
              getSearch(pageActive + 1);
            }
          } else if (e.target.dataset.name === 'last') {
            getSearch(search.length / 20);
          }
        }
      };
    
      const getSearch = (numberPage) => {
        const arr = [];
        const start = (numberPage - 1) * 20;
        const end = start + 20;
        for (let idx = start; idx < end; idx++) {
          arr.push(search[idx]);
        }
        const filterArr = arr.filter(el => el !== undefined);
        if (search.length === 0) {
          setSearchPagination([]);
        } else {
          setSearchPagination(filterArr);
        }
    
        setPageActive(numberPage);
      };


    return (
        <Container>
            <ContainerForm width="600px">
            <div className="card">
            <div className="card-header">
              <b>Búsqueda de Indicadores</b>
            </div>
                <div className="card-body">
                <Form className="my-3" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <Row>
                            <Col md={4}>Diccionario</Col>
                            <Col md={8}>
                                <Select
                                    cacheOptions
                                    defaultOptions
                                    options={infoSelect.dictionary}
                                    onChange={e => selectInfo({ data: e, type: 'dictionary' })}
                                    placeholder='Seleccione una Opción'
                                    theme={theme => (styleTheme(theme))}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row>
                            <Col md={4}>Nombre</Col>
                            <Col md={8}>
                                <input type="text" className="form-control" id="name" name="name" onChange={handleForm} />
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row>
                            <Col md={4}>Código Indicador</Col>
                            <Col md={8}>
                                <input type="text" className="form-control" id="code" name="code" onChange={handleForm} />
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row>
                            <Col md={4}>{filter_name}</Col>
                            <Col md={8}>
                                <Select
                                    cacheOptions
                                    defaultOptions
                                    options={infoSelect.filter}
                                    onChange={e => selectInfo({ data: e, type: 'filter' })}
                                    placeholder='Seleccione una Opción'
                                    theme={theme => (styleTheme(theme))}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <Row>
                            <Col md={4}>Responsable</Col>
                            <Col md={8}>
                                <AsyncSelect
                                    isDisabled={form.myIndicators === 1}
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadUsers}
                                    onChange={e => selectInfo({ data: e, type: 'responsible' })}
                                    placeholder='Seleccione una Opción'
                                    theme={theme => (styleTheme(theme))}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group text-center">
                        <Row>
                            <Col>
                                <StyleCheckbox className="custom-checkbox">
                                    <input className="custom-control-input" type="checkbox" name="misIndicadores" id="misIndicadores" onChange={e => handleOtherOptions(e, 'myIndicators')} />
                                    <label className="custom-control-label" htmlFor="misIndicadores">
                                        Mis Indicadores
                                    </label>
                                </StyleCheckbox>
                            </Col>
                            <Col>
                                <StyleCheckbox className="custom-checkbox">
                                    <input className="custom-control-input" type="checkbox" name="advancedFilter" id="advancedFilter" onChange={e => handleOtherOptions(e, 'advancedFilter')} />
                                    <label className="custom-control-label" htmlFor="advancedFilter">
                                        Filtro Avanzado
                                    </label>
                                </StyleCheckbox>
                            </Col>
                        </Row>
                    </div>
                    {   
                        form.advancedFilter === 1 &&
                        advancedOptions.map((option, index) => {
                            return (
                                <div className="form-group" key={index}>
                                    { 
                                        (option.object_type === 'list' || option.object_type === 'list-multiple') && 
                                        <Row>
                                            <Col md={4}>{option.name}</Col>
                                            <Col md={8}>
                                                    <Select
                                                        isMulti={option.object_type === 'list-multiple'}
                                                        cacheOptions
                                                        defaultOptions
                                                        options={infoOptionSelect[option.id]}
                                                        onChange={e => selectInfo({ data: e, type: 'advancedOptions', list: option.id })}
                                                        placeholder='Seleccione una Opción'
                                                        theme={theme => (styleTheme(theme))}
                                                    />
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        option.object_type === 'text' &&
                                        <Row>
                                            <Col md={4}>{option.name}</Col>
                                            <Col md={8}>
                                                <input type="text" className="form-control" id={"text-" + option.id} name={"text-" + option.id} onChange={e => selectInfo({ data: { id: e.target.value, idlist: option.id }, type: 'advancedOptions', list: option.id })} />
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            )
                        })
                    }
                    <div className="text-center">
                        <Button type="submit" disabled={isLoading}>{isLoading ? <ButtonLoading text="Buscando..." /> : <>Buscar <FaSearch /></>} </Button>
                    </div>
                </Form>
                </div>
                {
                    message !== '' &&
                    <div className="alert alert-danger text-center" role="alert">
                        {message}
                    </div>
                } 
                <MarginFooter></MarginFooter>
                </div>
            </ContainerForm>
            {
                searchPagination?.length > 0 &&
                <Container padding="0px">
                    <Table bordered className="table-striped">
                        <thead className="bg-dark text-white text-center">
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">{filter_name}</th>
                                <th scope="col">Unit Medida</th>
                                <th scope="col">Código</th>
                                <th colSpan={5} scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchPagination.map((item, index) => {
                                    return (
                                      <tr key={index} className="text-center">
                                        <td className="text-left"><b>{item.name}</b></td>
                                        <td className="text-left">{item.region}</td>
                                        <td>{item.description ? item.description : "N/A"}</td>
                                        <td>{item.code}</td>
                                        {/* actions */}
                                        {validateView(1, "update") && <td><Link to={"/admin/indicators/update/" + item.id} ><IconEdit link /> </Link></td>}
                                        {validateView(1, "delete") && <td><IconTrash color="red" onClick={() => handleDelete(item)} link /></td>}
                                        {validateView(2) && <td><Link to={`/admin/indicator/id/${item.id}`} ><IconData link color="green" /> </Link></td>}
                                        <td><IconGraph link onClick={() => { handlePopup(item) }} /></td>
                                        {validateView(1, "asociar_indicadores") && <td><IconConfig link onClick={() => { handlePopupRelations(item) }} /></td>}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </Table>

                    <Pagination className='justify-content-center'>
                        <Pagination.First
                        data-name='first'
                        onClick={handlePagination}
                        />
                        <Pagination.Prev
                        data-name='prev'
                        onClick={handlePagination}
                        />
                        {getPages()}
                        <Pagination.Next
                        data-name='next'
                        onClick={handlePagination}
                        />
                        <Pagination.Last
                        data-name='last'
                        onClick={handlePagination}
                        />
                    </Pagination>
                    <CustomModal title={"Gráfica del Indicador"} show={showModal} setShow={setShowModal} size="lg">
                        {
                            showModal &&
                            <ViewGraphic indicator={indicator} codes={['R', 'P', 'CE']} />
                        }
                    </CustomModal>
                    <CustomModal title={"Indicadores Relacionados"} size="lg" show={showModalRelations} setShow={setShowModalRelations}>
                        {
                            showModalRelations &&
                            <IndicatorRelations indicator={indicator} data={dataIndicators} updateInfo={handlePopupRelations}/>
                        }
                    </CustomModal>
                </Container>
            }
        </Container>
    )
}
export default Search;