import React, { Fragment, useState, useEffect, useRef } from "react";
import Select from "react-select";
import { AlertForm, ICON } from "../../../utils/SweetAlert";
import { Button, StyleCheckbox } from "../../../styles/CommonStyles";
import { styleTheme } from "../../../styles/SelectTheme";
import Utils from "../../../utils";
import { CustomFormStyles } from "./styles";

import { FaSave } from "react-icons/fa";

function CustomForm({ dataSaved, moduleId }) {
  let imageTemplate = useRef(null);
  let [check, setCheck] = useState(dataSaved.data?.newTabTemplate);
  let [selectedOption, setSelectedOption] = useState("");
  let [url, setUrl] = useState([]);
  let [selectedSite, setSelectedSite] = useState("");
  const [nameImg, setNameImg] = useState("Escoga un archivo...");
  const [optionTemplate, setOptionTemplate] = useState([
    { value: 1, label: "Card Simple" },
    { value: 2, label: "Card Horizontal" },
    { value: 3, label: "Card Vertical" },
    { value: 4, label: "Banner" },
  ]);
  let [selectedLinkType, setSelectedLinkType] = useState("");
  const [optionLink, setOptionLink] = useState([
    { value: 1, label: "Enlace Externo" },
    { value: 2, label: "Enlace del Sitio" },
  ]);

  let [data, setData] = useState("");
  let [imageData, setImageData] = useState("");

  useEffect(() => {
    Utils.Petition.get(
      "/pages/portal/" + localStorage.getItem("idportal"),
      (response) => {
        const data = response.data;
        let url = [];
        for (const key in data) {
          let page = data[key];
          url.push({ label: page.name, value: page.path });
        }
        setUrl(url);
      }
    );

    handleChargeData();
  }, []);

  useEffect(() => {
    if (!url.length || !dataSaved || !dataSaved.data) return;
    setSelectedSite(
      url.filter((el) => el.value === dataSaved.data.linkTemplate)
    );
  }, [dataSaved, url]);


  const handleChargeData = () => {
    console.log("data guardada", dataSaved);
    if (dataSaved.data) {
      console.log({oi:optionLink.filter((el)=>el.value===2)[0]});
      if(dataSaved.data.selectedSite){
        setSelectedLinkType(optionLink.filter((el)=>el.value===2)[0]);
      }else{
        setSelectedLinkType(optionLink.filter((el)=>el.value===1)[0]);
      }
      setData({
        headerTemplate: dataSaved.data.headerTemplate,
        descriptionTemplate: dataSaved.data.descriptionTemplate,
        imageTemplate: dataSaved.data.imageTemplate,
        linkTemplate: dataSaved.data.linkTemplate,
        linkDescription: dataSaved.data.linkDescription,
        newTabTemplate: dataSaved.data.newTabTemplate,
        typeTemplate: dataSaved.data.typeTemplate,
        selectedSite: dataSaved.data.selectedSite,
      });

    } else {
      setData({
        headerTemplate: "",
        descriptionTemplate: "",
        imageTemplate: "",
        linkTemplate: "",
        linkDescription: "",
        newTabTemplate: false,
        typeTemplate: "",
      });
    }
  };

  const handleSelectedSite = (event) => {
    setSelectedSite(event);
  };

  const handleSelectedLinkType = (event) => {
    setSelectedLinkType(event);
  };

  const handleSelectedOption = (event) => {
    setSelectedOption(event.value);
  };

  const handleCheck = (event) => {
    // establece el check
    setCheck(!check);

    //setData check
    //envio del check a la data
    setData({
      ...data,
      [event.target.name]: !check,
    });
  };

  const handleInputChange = (event) => {
    //event.preventDefault();
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  //Unsed image upload petition
  /*const handleImageInput = (event) => {
    event.preventDefault();
    console.log("image", event, imageTemplate.current.files[0])
    setNameImg(imageTemplate.current.files[0].name);
    Utils.Petition.postFile(
      "/imageUpload/file",
      { image: imageTemplate.current.files[0] },
      (res) => {

        setData({
          ...data,
          imageTemplate: res.upImage,
        });
      },
      (err) => {
        console.log(err.response);
      }
    );
  };*/

  const base64convert = (file) => {
    Array.from(file).forEach((file) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        let convertedImage = reader.result;
        setImageData(convertedImage);
        setNameImg(file.name)
      };
    });
  };

  const saveInDB = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // if link is an site of page data.linktemplate going to be the selected sit
    data.linkTemplate = selectedSite.value
      ? selectedSite.value
      : data.linkTemplate;

    data.selectedSite = (selectedSite.value)?true:false
    //is NewTab
    data.typeTemplate = selectedOption ? selectedOption : data.typeTemplate;

    //image in base64 saved
    data.imageTemplate = imageData ? imageData : data.imageTemplate;

    let id = moduleId;
    let result = {
      id: id,
      config: { data: data },
    };

    Utils.Petition.post(
      "/pages-modules/update",
      result,
      (res) => {
        AlertForm(ICON.SUCCESS, "Guardado Correctamente");
      },
      (err) => {
        console.log(err);
      }
    );
  };

  console.log("elementa del state", data);

  //render for optionLink site link

  //Render  of form is get data render with data and if not get data render without data

  return (
    <CustomFormStyles onSubmit={saveInDB}>
      <div className="form-group">
        <label htmlFor="headerTemplate">Titulo</label>
        <input
          placeholder="Titulo"
          className="form-control mb-2"
          name="headerTemplate"
          onChange={handleInputChange}
          value={data.headerTemplate}
          required
        ></input>
      </div>
      <div className="form-group">
        <label>Descripcion</label>
        <textarea
          className="form-control"
          placeholder="Descripción"
          name="descriptionTemplate"
          rows="3"
          onChange={handleInputChange}
          value={data.descriptionTemplate}
        ></textarea>
      </div>
      <div className="form-group">
        <label className="mt-2">Imagen</label>

        <div className="custom-file mb-2">
          {!data.imageTemplate &&(
            <>
              <input
                type="file"
                className="custom-file-input"
                id="validatedCustomFile"
                name="imageTemplate"
                multer
                required
                accept="image/png,image/jpeg"
                onChange={(event) => base64convert(event.target.files)}
              />
              <label className="custom-file-label" htmlFor="validatedCustomFile">
                {nameImg}
              </label>
            </>
          )}
          {data.imageTemplate && (
            <>
              <input
                type="file"
                className="custom-file-input"
                id="validatedCustomFile"
                name="imageTemplate"
                accept="image/png,image/jpeg"
                multer
                onChange={(event) => base64convert(event.target.files)}
              />
              <label className="custom-file-label" htmlFor="validatedCustomFile">
                {nameImg}
              </label>
              <div className="px-3 alert alert-success mt-2" role="alert">
                Imagen guardada previamente
              </div>
              <div className="text-center">
                <img src={data.imageTemplate} style={{maxWidth:'50%', width:'auto', maxHeight:'80px'}} className="mb-5 mt-2" alt="template"/>
              </div>
            </>
          )}
          <div className="invalid-feedback">
            Imágen inválida
          </div>
        </div>
      </div>
      <div className="form-group mt-5">
        <label>Tipo de Enlace</label>
        <Select
          options={optionLink}
          onChange={handleSelectedLinkType}
          value={selectedLinkType}
          theme={(theme) => styleTheme(theme)}
        />
        {selectedLinkType.value === 1 ? (
          <div className="form-group">
            <label className="mt-2">Enlace</label>
            <div className="mb-2">
              <input
                placeholder="Ingresa una URL"
                className="form-control mb-2"
                name="linkTemplate"
                onChange={handleInputChange}
                value={data.linkTemplate}
              ></input>
            </div>
          </div>
        ) : (
          <div className="form-group">
            <label className="mt-2">Enlace</label>
            <div className="mb-2">
              <Select
                options={url}
                placeholder="Selecciona una opción"
                onChange={handleSelectedSite}
                theme={(theme) => styleTheme(theme)}
                value={selectedSite}
              />
            </div>
          </div>
        )}
      </div>
      <div className="form-group">
        <StyleCheckbox className="custom-checkbox ml-4">
          <input
            className="custom-control-input"
            type="checkbox"
            name="newTabTemplate"
            id="newTabTemplate"
            checked={check}
            onChange={handleCheck}
          />
          <label className="custom-control-label" htmlFor="newTabTemplate">
            Abrir en una nueva ventana
          </label>
        </StyleCheckbox>
      </div>
      <div className="form-group">
        <label>Descripci&oacute;n del Enlace</label>
        <input
          placeholder="Descripci&oacute;n del Enlace"
          className="form-control mb-2"
          name="linkDescription"
          onChange={handleInputChange}
          value={data.linkDescription}
          required
        ></input>
      </div>
      <div className="form-group">
        <label>Seleccionar Plantilla</label>
        <Select
          options={optionTemplate}
          onChange={handleSelectedOption}
          defaultValue={optionTemplate.filter(
            (el) => el.value === dataSaved.data?.typeTemplate
          )}
          theme={(theme) => styleTheme(theme)}
        />
      </div>
      <div className="text-center mt-5">
        <Button type="submit">
          Guardar <FaSave />
        </Button>
      </div>
    </CustomFormStyles>
  );
}

export default CustomForm;
