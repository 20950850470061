import React, { useState, useEffect } from "react";
import Utils from "../../../utils";
import { ButtonGroup } from 'react-bootstrap';

import { FaSave, } from "react-icons/fa";
import { Button, Container } from "../../../styles/CommonStyles";
import { Theme1 } from "../../../styles/Theme1";
import { Loading } from "../../../components/Loading";
import { AlertConfirm, AlertForm, ICON } from "../../../utils/SweetAlert";
import { ButtonLoading } from "../../../components/Loading/ButtonLoading";
import { useConfigRole } from "../../../Hooks/configRole";

function List() {

  const [dataDicc, setDataDicc] = useState("");
  const [dictionary, setDictionary] = useState("");
  const [tableIsLoaded, setTableIsLoaded] = useState(false);
  const [edit, setEdit] = useState(false);
  const [aux, setAux] = useState('');
  const [aux2, setAux2] = useState('');
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const { configRoleByIdModule } = useConfigRole(7);
  const updateView = () => {
    Utils.Petition.get('/dictionary',
    (res) => {
      setDataDicc(res.data);
      setTableIsLoaded(true);
    },
    (err) => {
      console.log(err);
    }
  );
  }
  useEffect(() => {
    updateView();
  }, []);

  const handleSave = (e) => {
    e.preventDefault()
    setIsLoadingSave(true);
    Utils.Petition.post('/dictionary/create', { dictionary }, (res) => {
      AlertForm(ICON.SUCCESS, "Guardado Correctamente");
      updateView();
    },
    (err) => {
      console.log(err);
      AlertForm(ICON.ERROR, "No se guardo la información", "Intentelo nuevamente");
    }, () => {
      setIsLoadingSave(false);
    }
    )
  }

  const handleDelete = (event, id) => {
    console.log(id)
    Utils.Petition.post(`/dictionary/delete/`, {id}, (res) => {
      AlertForm(ICON.SUCCESS, res.message);
      updateView();
    },(err) => {
        console.log(err);
        AlertForm(ICON.ERROR, "No se elimino la información", "Intentelo nuevamente");
      })
  }

  const handleUpdate = (id, key) => {
    setIsLoadingSave(true);
    let data = [...dataDicc];
    data[key].name=data[key].name === aux && aux2;
    setDataDicc(data);
    
    Utils.Petition.post(`/dictionary/update/`, {id, name:aux2}, (res) => {
      AlertForm(ICON.SUCCESS, "Actualizado Correctamente");
      setEdit(!edit);
      updateView();
    }, (err) => {
      console.log(err);
      AlertForm(ICON.ERROR, "No se actualizo la información", "Intentelo nuevamente");
    }, () => {
      setIsLoadingSave(false);
      
    })
  }

  return (
    <Container>
      <div className="d-flex flex-wrap m-4">
        <div className="container-fluid mb-2">
          <div className="card">
            <div className="card-header">
              <b>Administrador de Diccionario</b>
            </div>
            {configRoleByIdModule.create && <div className="card-body">
              <form>
                <div className="form-group">
                  <label>Nombre del Diccionario</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre del Diccionario"
                    onChange={(event)=>setDictionary(event.target.value)}
                    value={dictionary}
                  />
                </div>
                <Button onClick={(e)=>handleSave(e)} className="mr-2" disabled={isLoadingSave}>
                  {isLoadingSave ? <ButtonLoading text="Guardando..." /> : <>Guardar <FaSave /></>} 
                </Button>
              </form>
            </div> }
          </div>
        </div>
        <div className="container-fluid">
          <h2 className="py-5 text-center">Lista de Diccionarios</h2>
          <table className="table table-striped">
          <thead className="bg-dark text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody> 
              {tableIsLoaded &&
                dataDicc.map((value, key) => (
                  <tr key={key}>
                    <td>{value.id}</td>
                    
                    {value.name === aux && edit
                      ?<td> <input
                        type="text"
                        className="form-control"
                        onChange={(event)=>setAux2(event.target.value)}
                        value={aux2}
                      /> </td>
                      : <td>{value.name}</td>
                    }

                    <td>
                      {value.name === aux && edit
                        ? <ButtonGroup aria-label="Basic example">
                          <Button color={Theme1.success} onClick={()=>handleUpdate(value.id, key)} >
                            {isLoadingSave ? <ButtonLoading text="Actualizando..." /> :"Guardar"}
                          </Button>
                          <Button color={Theme1.grayText}  onClick={()=>setEdit(!edit)} >Cancelar</Button>
                        </ButtonGroup>
                        :  <>{
                          configRoleByIdModule.update && 
                          <Button  color={Theme1.grayText} className="mx-2" 
                            onClick={()=>{setEdit(!edit); setAux(value.name); setAux2(value.name)}}> 
                            Editar
                          </Button>
                        }</>
                        }

                      {configRoleByIdModule.delete && 
                        <Button
                          color={Theme1.red}
                          onClick={(e) => 
                            AlertConfirm(ICON.WARNING,
                              "¿Estás seguro que deseas eliminar este item?", "",
                              () => handleDelete(e, value.id)
                            )
                          }>
                          Eliminar 
                        </Button>
                      }
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!tableIsLoaded && <Loading />}
          {tableIsLoaded && !dataDicc.length && "No hay resultados."}
        </div>
      </div>
    </Container>
  );
}

export default List;
