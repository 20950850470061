import { Button } from "react-bootstrap";

function BasicButton({ disabled, formula, setFormula, data, text }) {
    const handleButton = (event) => {
        event.preventDefault();
        let concatenation = (formula) ? formula + '||' : '';
        setFormula(concatenation + data);
    }
    return (
        <Button variant="warning" className="mb-2 mr-2" disabled={disabled} onClick={handleButton}>{text === undefined ? data : text}</Button>
    )
}
export default BasicButton;