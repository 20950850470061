import { ButtonsStyle } from '../styles';
import BasicButton from "./BasicButton";
import Delete from "./Delete";
import Indicator from "./Indicator";
import Inherit from "./Inherit";
import ManualUpdate from "./ManualUpdate";
import Numbers from "./Numbers";
import Operators from './Operators';
import {Compare} from './Compare';
import ValueUnique from './ValueUnique';

function Buttons({ type, btnDisabling, formula, setFormula, isCompare }) {
    return (
        <ButtonsStyle>
            {type === 'indicator' && !isCompare && <Inherit setFormula={setFormula} />}
            {!isCompare && <ManualUpdate setFormula={setFormula} />}
            <div>
                <Indicator disabledBtnIndicator={btnDisabling.disabledBtnIndicator} formula={formula} setFormula={setFormula} />
                {!isCompare && <Compare disabledBtnIndicator={btnDisabling.disabledBtnIndicator} formula={formula} setFormula={setFormula} />}
            </div>
             
            <Operators disabledBtnOperator={btnDisabling.disabledBtnOperator} formula={formula} setFormula={setFormula} />
            <BasicButton disabled={btnDisabling.disabledBtnParentesisOpen} formula={formula} setFormula={setFormula} data='(' />
            <BasicButton disabled={btnDisabling.disabledBtnParentesisClose} formula={formula} setFormula={setFormula} data=')' />
            <Numbers disabledBtnNumber={btnDisabling.disabledBtnNumber} formula={formula} setFormula={setFormula} />
            <div>
                {isCompare && <ValueUnique disabled={btnDisabling.disabledUnique} formula={formula} setFormula={setFormula} data='+' />}
                {isCompare && <ValueUnique disabled={btnDisabling.disabledUnique} formula={formula} setFormula={setFormula} data='-' />}
            </div>
            <BasicButton disabled={btnDisabling.disabledBtnMonth} formula={formula} setFormula={setFormula} data='@M' text="Mes actual" />
            <BasicButton disabled={btnDisabling.disabledBtnMonth} formula={formula} setFormula={setFormula} data='ABS||(' text="Valor Absoluto" />
            
            
            <Delete disabledBtnDelete={btnDisabling.disabledBtnDelete} formula={formula} setFormula={setFormula} />
        </ButtonsStyle>
    );
}
export default Buttons;
