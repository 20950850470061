export const dictionaryRoles = {
    create: "Crear",
    update: "Editar",
    delete: "Eliminar",
    find: "Ver",
    asociar_indicadores: "Asociar Indicadores",
    export: "Exportar",
    import: "Importar",
    create_update: "Crear & Editar",
    upload_my_indicator: "Actualizar mi Indicador",
    upload_value_indicator: "Actualizar valor de cualquier Indicador",
    update_portal: "Editar Portal actual",
    create_update_any: "Crear & Editar cualquier Indicador"
}