import { useEffect, useState } from "react";
import Utils from '../../utils';
import { styleTheme } from "../../styles/SelectTheme";
import { Col, FormGroup, Row, Form, FormControl} from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { useParams } from "react-router";
import { Loading } from "../../components/Loading";
import { FaSave } from "react-icons/fa";
import { AlertForm, ICON } from "../../utils/SweetAlert";
import { Button, StyleCheckbox} from "../../styles/CommonStyles";
import { ButtonLoading } from "../../components/Loading/ButtonLoading";
import { CustomForm } from "./styles";

const type = [
    { label: 'Página del sitio', value: 'sitio' }, 
    { label: 'Enlace externo', value: 'externo' },
    { label: 'Sin enlace', value: null }
];

const optionsView = []
Utils.Petition.get('/view-business', (response) => {

    response.data.forEach(element => {
        let obj = {}
        obj.value = element.code;
        obj.label = element.description;
        optionsView.push(obj);
    });
})


function FormIndicatorRelevant(){
    const { id } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [loadedPre, setLoadedPre] = useState(false);
    const [form, setForm] = useState({
        description:'',
        prefix:'',
        postfix:'',
        idindicator: 0,
        stage:null,
        sign:null,
        view:null,
        link:null,
        type: null,
        newTab: false,
        base: 100
    });
    const [indicator, setIndicator] = useState(null);
    const [view, setView] = useState(null);
    const [stages, setStages] = useState([]);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [url, setUrl] = useState([]);

    useEffect(()=>{
        Utils.Petition.get(
            '/pages-modules/id/' + id,
            ({data}) => {
                if (Object.keys(data.config).length > 0) {
                    setForm({
                        ...form,
                        description:data.config.description,
                        idindicator:data.config.idindicator,
                        stage:data.config.stage,
                        sign:data.config.sign,
                        view:data.config.view, 
                        prefix: data.config.prefix,                       
                        postfix: data.config.postfix,
                        link: data.config.link,
                        type: data.config.type,
                        newTab: data.config.newTab,
                        base: data.config.base ? "0" : data.config.base
                    });

                    for(let i in optionsView){
                        console.log(optionsView[i].value);
                        if(data.config.view===optionsView[i].value){
                            setView(optionsView[i]);
                            break;
                        }
                    }
                    Utils.Petition.get('/indicators/id/'+data.config.idindicator, (response)=>{
                        response.data.label=response.data.code+' '+ response.data.name+' | '+response.data.filter.label;
                        response.data.value=response.data.id;
                        setIndicator(response.data);
                    })
                }
                setLoaded(true);
                setLoadedPre(true);
            }
        );
        Utils.Petition.get(
            '/stages_relation/select',
            (response) => {
                setStages(response.data);
            }
        );
        Utils.Petition.get('/pages/portal/'+ localStorage.getItem('idportal'), (response) => {
            let url = response.data.map(page => ({ label: page.name, value: page.path}));
            setUrl(url);
        });
    }, [id])
    const handleForm = (event)=>{
        setForm({...form, [event.target.name]: event.target.value});
    }
    const selectIndicator = (indicator)=>{
        setIndicator(indicator);
        setForm({...form, idindicator: (indicator)?indicator.id:0 });
    }
    const selectView = (view) => {
        setView(view);
        setForm({...form, view: (view)?view.value:null });
    }
    const selectStage = (stage)=>{
        setForm({...form, stage });
    }
    const selectSign = (stage)=>{
        setForm({...form, sign: stage});
    }
    const loadIndicators = (inputValue) => {

        if (inputValue.length > 2) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    Utils.Petition.get(
                        '/indicators/name-code-indicator/' + inputValue,
                        (response) => {
                            resolve(response.data)
                        }
                    )
                }, 100);
            });
        }

        return new Promise((resolve) => resolve([]));
    };
    const handleSubmit=(event)=>{
        event.preventDefault();
        setIsLoadingSave(true);
        if(validateForm()){
            const data = {
                id: id,
                config: form,
            };
    
            Utils.Petition.post(
                '/pages-modules/update', data,
                (response) => {
                    if (window.parent) {
                        AlertForm(ICON.SUCCESS, response.message);
                        //window.parent.postMessage('cerrar popup', APP_URL);
                    }
                },(err) => {
                    console.log(err);
                },() => {
                    setIsLoadingSave(false);
                }
            );
        }
    }
    const validateForm = ()=>{
        if(form.description === ''){
            AlertForm(ICON.WARNING, 'Ingresa la descripción del indicador');
            return false;
        }
        if(form.idindicator === 0){
            AlertForm(ICON.WARNING, 'Selecciona un indicador');
            return false;
        }
        if(form.stage === null){
            AlertForm(ICON.WARNING, 'Selecciona un escenario');
            return false;
        }
        if(form.sign === null){
            AlertForm(ICON.WARNING, 'Selecciona un escenario para el semáforo');
            return false;
        }
        return true;
    }

    const handleCheckbox = (e) => {
        console.log("check", e);
        setForm({...form, [e.target.name]: e.target.checked});
    }

    return (
        
        !loaded 
        ? <Loading text="Cargando Configuración" />
        : 
        <CustomForm className="container mt-3 border rounded p-3">
            <Form onSubmit={handleSubmit}>

                    <Row>
                        <Col xs={12} lg={6}>
                        <label htmlFor="description">
                            <b>Descripción</b>
                        </label>
                        <Form.Control as="textarea" placeholder="Escriba una descripción Max 60 caracteres" rows={2} maxLength={60} value={form.description} id="description" name="description" onChange={handleForm}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={4} className="mt-3">
                            <label htmlFor="prefijo">
                                <b>Texto antes</b>
                            </label>
                            <FormControl id="prefix" placeholder="Ej: $" name="prefix" onChange={handleForm} value={form.prefix} />
                        </Col>
                        <Col xs={12} lg={4} className="mt-3">
                            <label htmlFor="description">
                                <b>Indicador</b>
                            </label>
                            <AsyncSelect
                                value={indicator}
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={loadIndicators}
                                onChange={selectIndicator}
                                theme={theme => (styleTheme(theme))}
                            />
                        </Col>
                        <Col xs={12} lg={4} className="mt-3">
                            <label htmlFor="postfijo">
                            <b>Texto después</b>
                            </label>
                            <FormControl id="postfix" placeholder="Ej: veces" name="postfix" onChange={handleForm} value={form.postfix} />
                        </Col>
                        <Col xs={12} lg={6} >
                            <label>
                                <b>Escenario</b>
                            </label>
                            <Select
                                value={form.stage}
                                cacheOptions
                                defaultOptions
                                options={stages}
                                onChange={selectStage}
                                theme={theme => (styleTheme(theme))}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <label>
                                <b>Semáforo</b>
                            </label>
                            <Select
                                value={form.sign}
                                cacheOptions
                                defaultOptions
                                options={stages}
                                onChange={selectSign}
                                theme={theme => (styleTheme(theme))}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <label>
                                <b> Vista predeterminada (opcional)</b>
                            </label>
                            {console.log(view)}
                            <Select
                                value={view}
                                cacheOptions
                                isClearable
                                defaultOptions
                                options={optionsView}
                                onChange={selectView}
                                theme={theme => (styleTheme(theme))}
                            />
                        </Col>
                        <Col xs={12} lg={6} className="mt-3">
                            <label htmlFor="base">
                                <b>Base de Cumplimiento (%)</b>
                            </label>
                            <FormControl id="base" name="base" type="number" onChange={handleForm} value={form.base || 0 } />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <label htmlFor="postfijo">
                                <b>Tipo de página</b>
                            </label>
                            <Select
                                onChange={(e) => setForm({ ...form, type: e.value, link: "" })}   
                                options={type}
                                placeholder='Selecciona una opción'
                                theme={theme => (styleTheme(theme))}
                                value={type.filter(el => el.value === form.type )}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            {form.type !== null && 
                                <label htmlFor="postfijo">
                                    <b>URL</b>
                                </label>
                            }
                            {
                                form.type === 'externo' &&
                                <FormControl id="link" placeholder="Escriba enlace" name="link" onChange={handleForm} value={form.link} />
                            }
                            {
                                form.type === 'sitio' &&
                                <Select
                                    name='link'
                                    onChange={(e) => setForm({ ...form, link: e.value })}
                                    options={url}
                                    placeholder='Selecciona una opción'
                                    theme={theme => (styleTheme(theme))}
                                    value={url.filter(el => el.value === form.link )}
                                />
                              }
                        </Col>
                        {form.type !== null && <Col xs={12} lg={6}>
                            <StyleCheckbox className="custom-checkbox ml-3">
                                <input className="custom-control-input" type="checkbox" name="newTab"  id="newTab" onChange={handleCheckbox} checked={form.newTab} />
                                <label className="custom-control-label" htmlFor="newTab">
                                    ¿Abrir en una nueva ventana?
                                </label>
                            </StyleCheckbox>
                        </Col>}
                    </Row>
                        
                <div className="text-center mt-4">
                    <Button type="submit" disabled={!loadedPre || isLoadingSave}>
                        {isLoadingSave ?  <ButtonLoading />:<>Guardar <FaSave /> </>}
                    </Button>
                </div>
                
            </Form>
        </CustomForm>
    )
}
export default FormIndicatorRelevant;