import moment from 'moment';
import 'moment/locale/es';

export const formatDate = (date) => {
    moment.locale('es');
    return moment(date).format('LL');
}

export const relativeTime = (date) => {
    moment.locale('es');
    let dateParse = new Date(date);
    return moment(dateParse.toLocaleString(), "DDMMYYYY, hh:mm").fromNow()
}