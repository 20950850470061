import { Button } from "react-bootstrap";

function Delete({ disabledBtnDelete, formula, setFormula }) {
    const handleDeleteValue = (event) => {
        event.preventDefault();
        let _formula = formula.split('||');
        _formula.pop();
        if(_formula[_formula.length - 1] === "ABS") _formula.pop();
        setFormula(_formula.join('||'));

    }
    return (
        <Button variant="danger" className="mb-2" disabled={disabledBtnDelete} onClick={handleDeleteValue}>Borrar</Button>
    )
}
export default Delete;