import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Loading } from "../components/Loading";
import Utils from "../utils";

function AutoLoginToken({ logged, setLogged }) {
    const { token, year, month, view, site, iframe } = useParams();
    console.log({iframe});
    const history = useHistory();
    useEffect(() => {

        //Este es el token temporal
        localStorage.setItem('token_user', token);
        localStorage.setItem('year', year);
        localStorage.setItem('month', month);
        localStorage.setItem('view', view);
        localStorage.setItem('site', site);
        
        Utils.Petition.get('/users/validate-token',(response)=>{

            //Luego cambio al token normal (vigencia mayor)
            localStorage.setItem('token_user', response.data.token);
            if(!localStorage.getItem('idfilter')){
                localStorage.setItem('idfilter', response.data.idfilter);
                localStorage.setItem('filter', response.data.filter);
            }
            console.log("----user----", response.data.user)
            localStorage.setItem('iddepartment', response.data.user.iddepartment);
            localStorage.setItem('idposition', response.data.user.idposition);
            localStorage.setItem('token_user', response.data.token);
            localStorage.setItem('iduser', response.data.user.id)
            localStorage.setItem('idportal', response.data.idportal);
            localStorage.setItem('portal', response.data.portal);
            localStorage.setItem('idrolAdmin', response.data.idrol_admin);
            localStorage.setItem('rolAdmin', response.data.rol_admin);
            localStorage.setItem('idrolContent', response.data.idrol_content);
            localStorage.setItem('rolContent', response.data.rol_content);
            localStorage.setItem('business', response.data.business);
            localStorage.setItem('idbusiness', response.data.idbusiness);
            localStorage.setItem('config', JSON.stringify(response.data.config));
            localStorage.setItem('admin_permissions',(JSON.stringify(response.data.permissionsAdmin)));
            localStorage.setItem('content_permissions',(JSON.stringify(response.data.permissionsContent)));
            setLogged(true);
            if(iframe!=='true'){
                history.push('/admin')
            }

        })





        
    }, []);
    return (
        <div>En este momento se está iniciando sesión, por favor espere...</div>
    )


}
export default AutoLoginToken;