import { Button } from "react-bootstrap";

function ValueUnique({ disabled, formula, setFormula, data, text }) {
    const handleButton = (event) => {
        event.preventDefault();
        setFormula(` ${data} ` );
    }
    return (
        <Button variant="warning" className="mb-2 mr-2" disabled={disabled} onClick={handleButton}>Valor {data}</Button>
    )
}
export default ValueUnique;