import React, { useState, useEffect } from "react";
import Utils from '../../utils';
import { Container } from 'react-bootstrap';
import CreateTable from './CreateTable';

function GetDataTable({config}) {
    const [data, setData] = useState([]);
    const [idstages, setIdstages] = useState([]);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        setFlag(false);
        const view = config.hasOwnProperty('view') && config.view !== "PorDefecto" ? config.view : localStorage.getItem('view');
        let info_values = [];
        let idstages_temp = [];

        const data = {
            indicators: config.indicators.map(ind => ind.id)
        }

        let contador = 1;
        let bandera = 0
        config.stages.map( stage => {if( stage.othersInd ) contador ++;})
        
        Utils.Petition.post(
            '/indicators/getTypeIndicators', data,
            (response) => {
                const resp = response.data;
                for (let i=0; i < config.indicators.length; i++) {
                    const aux = resp.filter( ind => ind.id === config.indicators[i].id)[0];
                    config.indicators[i].type = aux.type;
                    config.indicators[i].unit = aux.unit;
                }
                bandera ++;
                if (bandera === contador) setFlag(true)
            }
        );

        for(let k=0; k<config.stages.length; k++ ) {
            let dataNewInd = {}
            if( config.stages[k].othersInd ) {
                dataNewInd = {
                    indicators: config.stages[k].newIndicators.map( ind => ind.id)
                }
                Utils.Petition.post(
                    '/indicators/getTypeIndicators', dataNewInd,
                    (response) => {
                        const resp = response.data;
                        for (let i=0; i < config.stages[k].newIndicators.length; i++) {
                            const aux = resp.filter( ind => ind.id === config.stages[k].newIndicators[i].id)[0];
                            config.stages[k].newIndicators[i].type = aux.type;
                            config.stages[k].newIndicators[i].unit = aux.unit;
                        }
                        bandera ++;
                        if (bandera === contador) setFlag(true)
                    }
                );
            }
        };
        
        config.stages.map(stage => {
            let idstage = {id: -1, semaphore: false};
            let month = parseInt(localStorage.getItem('month')) + 1;
            let year = parseInt(localStorage.getItem('year'));
            for(let i=0; i<stage.view.length; i++) {
                if( stage.view[i] === view ) {
                    idstage.id = stage.values[i];
                    idstage.percent = stage.percent;
                    if(stage.semaphore) {
                        idstage.semaphore = stage.semaphore;
                    }
                    break;
                }
            };

            if( stage.lastYear ) {
                year--;
            }   
            if( stage.lastDec ) {
                year--;
                month = 12;
            }

            config.indicators.map((indicator, index) => {
                if(stage.othersInd) {
                    if( stage.lessInd ) {
                        if(index + 1 <= stage.amountLessInd) {
                            info_values.push("'" + stage.newIndicators[index].id + "-" + idstage.id + "-" + year + "-" + month + "'");
                        }
                    } else {
                        info_values.push("'" + stage.newIndicators[index].id + "-" + idstage.id + "-" + year + "-" + month + "'");
                    }
                } else {
                    info_values.push("'" + indicator.id + "-" + idstage.id + "-" + year + "-" + month + "'");
                }
            });
            idstages_temp.push(idstage)
        });            
        
        setIdstages(idstages_temp);

        Utils.Petition.get( '/indicator-value/info_values/' + info_values, 
            (response) => {
                setData(response.data)
                //console.log(response.data)
            }  
        )

    }, []);
    
    return (
        <Container>
            <h3 className="mb-4 text-center">{config.title}</h3>
            {
                flag && data.length > 0 && <CreateTable dataConfig={config} data={data} idstages={idstages} />
            }
        </Container>
    )

}

export default GetDataTable;