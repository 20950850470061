import { useState, useEffect } from "react";
import { Form, FormGroup, Table } from "react-bootstrap";
import Utils from "../../utils";
import Item from "./Item";
import { Button, Container } from "../../styles/CommonStyles";
import { FaSave } from "react-icons/fa";
import {AlertForm, AlertHtml, ICON} from '../../utils/SweetAlert';
import { useConfigRole } from "../../Hooks/configRole";
import { Loading } from "../../components/Loading/";

function List() {

  const [rols, setRols] = useState([]);
  const [rolForm, setRolForm] = useState({
    name: "",
    type: 2
  });
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const { configRoleByIdModule } = useConfigRole(11);
  
  useEffect(() => {
    setIsLoadingTable(true);
    Utils.Petition.get("/rol/listRolsContent", (res) => {
        setRols(res.data);
    },(e) => {
      console.log(e);
    },() => {
      setIsLoadingTable(false);
    });
  }, []);

  const handleRolSaveButton = (event) => {
    event.preventDefault()
    console.log(rolForm);
    Utils.Petition.post('/rol/create', rolForm, (response)=>{
        setRolForm({
        name: "",
        type: 2
      })
      setRols([...rols, response.data]);
      AlertForm(ICON.SUCCESS, 'Rol creado correctamente', '', 'center', null, true);
    },(e) => {
        AlertHtml(ICON.ERROR,'Ocurrió un error inesperado',e.response.data.message)
    });
  };

  const handleRolForm = (event) => {
    if (event && event.target) {
      const value = event.target.value;
      setRolForm({ ...rolForm, [event.target.name]: value });
    } else {
      const value = event;
      setRolForm({ ...rolForm, type: value });
    }

  };

    return (
      <Container>

        <div className="d-flex flex-wrap m-4">
          <div className="container-fluid mb-2">
              <div className="card">
                <div className="card-header">
                  <b>Crear nuevo rol de Contenido</b>
                </div>
                {configRoleByIdModule.create && <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Form.Label>
                        <b>Nombre</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="name"
                        name="name"
                        value={rolForm.name}
                        onChange={handleRolForm}
                      />
                    </FormGroup>
                    <Button className="mr-2" onClick={handleRolSaveButton}> {/* saveDB */}
                      Guardar <FaSave />
                    </Button>
                  </Form>
                </div> }
              </div>
          </div>
                        

          <div className="container-fluid">
            <h2 className="py-5 text-center">Roles de Contenido</h2>
            {!isLoadingTable && <Table hover className="editable-table mt-3">
                <thead className="bg-dark text-white">
                <tr>
                    <th width="5%">#</th>
                    <th width="80%">Nombre</th>
                    <th width="15%">Acciones</th>
                </tr>
                </thead>
                <tbody>
                {rols.map((rol, index) => {
                    return <Item key={rol.id} rols={rols} setRols={setRols} index={index} configRole={configRoleByIdModule}/>
                })}
                </tbody>
            </Table>}
            {isLoadingTable && <Loading />}
        </div>
      </div>
      </Container>
    );
}
export default List;