import { useState } from "react";
import {FaRedo, FaSave } from "react-icons/fa";
import { IconEdit } from "../../../components/iconos/IconEdit";
import { IconSave } from "../../../components/iconos/IconSave";
import { IconCheck } from "../../../components/iconos/IconCheck";
import { IconClose } from "../../../components/iconos/IconClose";
import { IconTrash } from "../../../components/iconos/IconTrash";
import { IconConfig } from '../../../components/iconos/IconConfig';
import Utils from "../../../utils";
import {AlertForm, AlertHtml, AlertConfirm, ICON} from '../../../utils/SweetAlert';
import { StyleInput, StyleSelect } from "../../../styles/CommonStyles";



function Item({permissions, setPermissions, index, pagesOptions, setPagesOptions, filtersOptions, setFiltersOptions}){

    const [editMode, setEditMode] = useState(false);
    //const [permissions, setPermissions] = useState([]);
    const [permissionsForm, setPermissionsForm] = useState({
        id:permissions[index].id,
        idpage:permissions[index].idpage,
        idfilter:permissions[index].idfilter
    });

    const [modalConfigure, setModalConfigure] = useState(false);
    const handleEditMode = ()=>{
        setEditMode(true);
    }
  

    
    const handleChangeForm = (event) => {
        const { name, value } = event.target;
        setPermissionsForm({ ...permissionsForm, [name]: value })
        
    }

    const handleSave = ()=>{
        setEditMode(false);
        console.log(permissionsForm);

        Utils.Petition.post('/permissions-rols-contents/update', permissionsForm, (response) =>{
            setPermissionsForm({
                idpage: null,
                idfilter: null,
                idrol:permissions[index].idrol
            })
        
            Utils.Petition.get(`/permissions-rols-contents/permissionbyrol?idrol=${permissions[index].idrol}`, (res) => {
                setPermissions(res.data);
            });
            AlertForm(ICON.SUCCESS, 'Permiso actualizado correctamente', '', 'center', null, true);
        },(e) => {
            AlertHtml(ICON.ERROR,'Ocurrió un error inesperado',e.response.data.message)
        });
        
    }

    const confirmDelete = ()=>{
        console.log(permissionsForm);

        Utils.Petition.post('/permissions-rols-contents/delete', permissionsForm, (response) =>{
            setPermissionsForm({
                idpage: null,
                idfilter: null,
                idrol:permissions[index].idrol
            })
        
            Utils.Petition.get(`/permissions-rols-contents/permissionbyrol?idrol=${permissions[index].idrol}`, (res) => {
                setPermissions(res.data);
            });
            AlertForm(ICON.SUCCESS, 'Permiso eliminado correctamente', '', 'center', null, true);
        },(e) => {
            AlertHtml(ICON.ERROR,'Ocurrió un error inesperado',e.response.data.message)
        });
    }
    const handleDelete = () => {
        AlertConfirm(ICON.WARNING, "Una vez elimines un permiso, no se puede recuperar", "¿Seguro deseas continuar?", confirmDelete, "Eliminar");
    }

    return (
        <>
            <tr className={'normal'}>
                <td><b>{permissions[index].id}</b></td>
                <td>
                    {!editMode && <label>{permissions[index].name_page}</label>}
                    {
                        editMode &&
                        <StyleSelect value={permissionsForm.idpage} name="idpage" onChange={handleChangeForm}>
                            {
                                pagesOptions.map((pageopt) => {
                                    return <option key={pageopt.value} value={pageopt.value}>{pageopt.label}</option>
                                })
                            }
                        </StyleSelect>
                    }
                </td>
                <td>
                    {!editMode && <label>{permissions[index].filter_name}</label>}
                    {
                        editMode &&
                        <StyleSelect value={permissionsForm.idfilter} name="idfilter" onChange={handleChangeForm}>
                            {
                                filtersOptions.map((filteropt) => {
                                    return <option key={filteropt.value} value={filteropt.value}>{filteropt.label}</option>
                                })
                            }
                        </StyleSelect>
                    }
                </td>
                <td>
                    {!editMode && <IconEdit link onClick={handleEditMode}/> }
                    {editMode && <IconSave  link onClick={handleSave}/> }
                    <IconTrash link color="red" title="Eliminar" onClick={handleDelete} />
 


                </td>
            </tr>
     
        </>
    );

}
export default Item;