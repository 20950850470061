import { Modal } from "react-bootstrap";
import { Button } from '../styles/CommonStyles';
import { Theme1 } from "../styles/Theme1";


function CustomModal({setShow, handleClose, show, size, title, children, hideFooter, handleSaveButton,disabled = false, saveButtonText }) {

    const handleCloseModal = () => {
        setShow(false)
        if (handleClose) {
            handleClose();
        }
    };

    return (
        <Modal show={show} onHide={handleCloseModal} size={size}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>

            {!hideFooter && <Modal.Footer>
                <Button color={Theme1.grayText} onClick={handleCloseModal}>Cerrar</Button>
                {
                    handleSaveButton &&
                    <Button disabled={disabled} onClick={handleSaveButton}>{saveButtonText || "Guardar cambios"}</Button>
                }
            </Modal.Footer>}
        </Modal>
    );
}
export default CustomModal;