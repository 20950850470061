import { useState, useEffect } from "react";
import { Form, FormGroup, Table } from "react-bootstrap";
import Utils from "../../utils";
import Item from "./Item";
import { Button, Container } from "../../styles/CommonStyles";
import Select from "react-select";
import { FaSave } from "react-icons/fa";
import { styleTheme } from "../../styles/SelectTheme";
import { AlertForm, ICON } from "../../utils/SweetAlert";
import { Loading } from "../../components/Loading";
import { useConfigRole } from "../../Hooks/configRole";

function List() {
  const [businesses, setBusinesses] = useState([]);
  const [reloadInterval, setReloadInterval] = useState(false);
  const [intervalVar, setIntervalVar] = useState([]);
  const [businessForm, setBusinessForm] = useState({
    business: "",
    portal: "",
    fromPortal: null,
  });
  const [isLoadingTable, setIsLoadingTable] = useState(false);



  //options
  const [portalOptions, setPortalOptions] = useState([]);
  const [otionsLoaded, setOptionsLoaded] = useState(false);
  const { configRoleByIdModule } = useConfigRole(12);

  useEffect(() => {
    setIsLoadingTable(true);
    Utils.Petition.get("/business", (res) => {
      console.log(res.data);
      setBusinesses(res.data);
      handlerInterval(res.data);
      let options = [];

      for (let i in res.data) {
        options.push({ label: res.data[i].name, value: res.data[i].id });
      }

      setPortalOptions(options);
      setOptionsLoaded(true);
    },
    (err) => {
      console.log(err);
      AlertForm(ICON.ERROR, "Error al traer información de Negocios");
    },
    () => {
      setIsLoadingTable(false);
    }
    );
  }, []);

  const handlerInterval = (businesses)=>{
    let continueLoading = false;
    for(let i in businesses){
      for(let j in businesses[i].portals){
        
        if(businesses[i].portals[j].process_portal && businesses[i].portals[j].process_portal.status === 'En proceso'){continueLoading=true; break;}
      }
      if(continueLoading) break;
    }
    if(!continueLoading) setReloadInterval(false);
    else{
      if(!reloadInterval){
        setReloadInterval(true);
      }
    }
  }
  useEffect(()=>{
    if(reloadInterval){
      setIntervalVar([...intervalVar, 
      
        setInterval(()=>{
          Utils.Petition.get("/business", (res) => {
            setBusinesses(res.data);
            handlerInterval(res.data);
          });
        },3000)
        
      ])

        
    }else{
      for(let i=intervalVar.length-1;i>=0; i--){
        clearInterval(intervalVar[i]);
      }
      if(intervalVar.length) AlertForm(ICON.SUCCESS, 'Terminó correctamente de copiar las páginas');
      setIntervalVar([]);
    }

  },[reloadInterval])

  const handleBusinessSaveButton = (event) => {
    event.preventDefault();
    if(validateForm()){
      Utils.Petition.post('/business/create', businessForm, (response)=>{
        setBusinessForm({
          business: "",
          portal: "",
          fromPortal: null,
        })
        
        if(response.data.portals[0].process_portal && response.data.portals[0].process_portal.status==='En proceso' && !reloadInterval){
          setReloadInterval(true);
          AlertForm(ICON.SUCCESS, 'Se creo el negocio, y se encuentra copiando las páginas');
        }else{
          AlertForm(ICON.SUCCESS, response.message);
        }
        setBusinesses([...businesses, response.data]);
      });
    }
  };

  const handleBusinessForm = (event) => {
    if (event && event.target) {
      const value = event.target.value;
      setBusinessForm({ ...businessForm, [event.target.name]: value });
    } else {
      const value = event;
      setBusinessForm({ ...businessForm, fromPortal: value });
    }

  };
  const validateForm = ()=>{
    if(businessForm.businness===''){
      AlertForm(ICON.WARNING, "Ingresa el nombre del negocio");
      return false;
    }
    if(businessForm.portal===''){
      AlertForm(ICON.WARNING, "Ingresa el nombre del portal");
      return false;
    }
    return true;
  }

  return (
    <Container>
      <div className="d-flex flex-wrap m-4">
        <div className="container-fluid mb-2">
            <div className="card">
              <div className="card-header">
                <b>Crear nuevo negocio</b>
              </div>
              {configRoleByIdModule.create && <div className="card-body">
                <Form>
                  <FormGroup>
                    <Form.Label>
                      <b>Nombre del negocio</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="business"
                      name="business"
                      value={businessForm.business}
                      onChange={handleBusinessForm}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <b>Nombre del portal</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="portal"
                      name="portal"
                      value={businessForm.portal}
                      onChange={handleBusinessForm}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>
                      <b>Copiar desde el portal</b>
                    </label>
                    <Select
                      placeholder="Ninguno"
                      name="fromPortal"
                      isClearable
                      value={businessForm.fromPortal}
                      onChange={handleBusinessForm}
                      options={portalOptions}
                      isLoading={isLoadingTable}
                      theme={theme => (styleTheme(theme))}
                    />
                    <Form.Text className="text-muted">
                      Si selecciona un portal, se duplicaran todas las páginas y contenido del portal
                      seleccionado. En caso de crear un portal vacío, no seleccione ningún portal
                    </Form.Text>
                  </FormGroup>
                  <Button className="mr-2" onClick={handleBusinessSaveButton}> {/* saveDB */}
                    Guardar <FaSave />
                  </Button>
                </Form>
              </div> }
            </div>
        </div>


        <div className="container-fluid">
            <h2 className="py-5 text-center">Negocios y portales</h2>

            <Table hover className="editable-table mt-3">
              <thead className="bg-dark text-white">
                <tr>
                  <th width="5%">#</th>
                  <th width="20%">Nombre</th>
                  <th width="60%">Portales</th>
                  <th width="15%">Acciones</th>
                </tr>
              </thead>
              {!isLoadingTable && 
              <tbody>
                {businesses.map((business, index) => {
                  return <Item key={business.id} configRol={configRoleByIdModule} reloadInterval={reloadInterval} setReloadInterval={setReloadInterval} businesses={businesses} setBusinesses={setBusinesses} index={index} portalOptions={portalOptions} setPortalOptions={setPortalOptions} />
                })}
              </tbody>}
            </Table>
            {isLoadingTable && <Loading />}
            {!isLoadingTable && !businesses.length && "No hay resultados."}
        </div>


      </div>
    </Container>
  );
}
export default List;
