import { Svg } from '../../styles/CommonStyles';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const IconColor = ({ color, link, onClick = () => { }, hover, textTooltip = "Cambiar Color" }) => {
  return (

    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-color`}>
          {textTooltip}
        </Tooltip>
      }
    >
      <Svg color={color} link={link} onClick={onClick} hover={hover} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><rect className="a" width="24" height="24" />
        <path className="b" d="M11,16a7.943,7.943,0,0,1-4.26-1.24L8.2,13.3A5.886,5.886,0,0,0,11,14a6.007,6.007,0,0,0,6-6H14l4-4,4,4H19A8.01,8.01,0,0,1,11,16ZM4,12H4L0,8H3A7.986,7.986,0,0,1,15.26,1.24L13.8,2.7A5.886,5.886,0,0,0,11,2,6.007,6.007,0,0,0,5,8H8L4,12Z" transform="translate(1 4)" />
      </Svg>
    </OverlayTrigger>
  )
}
