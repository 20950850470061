import { useState, useEffect } from 'react';
import Utils from "../../utils";
import { Col, Row, } from "react-bootstrap";
import * as XLSX from "xlsx";
import { Container, Button } from '../../styles/CommonStyles';
import { FormCustom } from '../indicators/styles';
import {AlertForm, AlertHtml, ICON} from '../../utils/SweetAlert';
import * as FileSaver from "file-saver";
import { Link, useHistory } from 'react-router-dom';
import { Theme1 } from '../../styles/Theme1';

function ImportIndicators() {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const [headers, setHeaders] = useState([]);
    const [users, setUsers] = useState([])
    const [filters, setFilters] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [nameFile, setNameFile] = useState('');
    
    const [form, setForm] = useState({
        data: [],
        comments: "",
    });
    const [validations, setValidations] = useState({});
    const [fileStrings, setFileStrings] = useState([]);
    const [numRequires, setNumRequires] = useState(0);
    let history = useHistory();

    useEffect( () => {
        Utils.Petition.get('/indicator-atribute', 
            (response) => {       
                let r = ['Nombre *', 'Responsable *', 'Filtro *'];
                let contador = 3;
                response.data.map( d => {
                    let title = d.name;
                    if(d.isrequired === '1') {title = `${title} *`; contador++;}
                    title = title + ` (${d.object_type })`;
                    r.push(title);
                    d['name_header'] = title
                });
                setHeaders(r);
                setAttributes(response.data);
                setNumRequires(contador);
            }
        );
        Utils.Petition.get(
            '/users/users-client',
            (response) => {
                setUsers(response.data)
            }
        )
        Utils.Petition.get(
            '/filter',
            (response) => {
                setFilters(response.data)
            }
        );
    }, [])

    const exportToCSV = (fileName) => {
        const template = XLSX.utils.json_to_sheet([{}], 
            {header: headers}
        );
        const usersList = XLSX.utils.json_to_sheet(users, 
            {header: []}
        );
        const filterList = XLSX.utils.json_to_sheet(filters, 
            {header: []}
        );

        let Sheets = { data: template, usuarios: usersList, filtros: filterList };
        let SheetNames = ["data", "usuarios", "filtros"];

        attributes.map( attr => {
            if (attr.object_type === "list" || attr.object_type === "list-multiple") {
                Sheets[attr.name] = XLSX.utils.json_to_sheet(attr.lists_options, {header: []});
                SheetNames.push(attr.name)
            };
        });  
        const wb = { Sheets, SheetNames};
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                let ws = [];
                let data = [];
                let strings = [];
                if( wb.Sheets.hasOwnProperty('data') ) {
                    ws =  wb.Sheets.data;
                    for( let key in ws ) {
                        if( ws[key].t === "s" ) strings.push(ws[key].v)
                    }
                    data = XLSX.utils.sheet_to_json(ws);
                    setFileStrings(strings)
                } else {
                    AlertForm(ICON.WARNING, 'No se encuentra la hoja "data" en el archivo seleccionado', '', 'center', null, true);
                }
                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            setForm({
                ...form,
                data: d
            });
        });
    };

    const handleForm = (event) => {
        const value = event.target.value
        setForm({
            ...form,
            [event.target.name]: value
        })
    };

    const saveDatafile = (idfileindicator,info_create) => {
        
        for (let idx = 0; idx < info_create.length; idx++) {
           
            const element = info_create[idx];
            
            Utils.Petition.post('/indicators', element, (response) => {
                
                let datafile = {}
                datafile.code = response.data.code;
                datafile.name = response.data.name;
                datafile.idfileindicator = idfileindicator?.id;
                //agregar los datos del archivo
                Utils.Petition.post(
                    '/indicator-data-files',datafile,
                    (response) => {
                        //console.log("respuestaDatosFile",response.data)
                    },(e) => {
                        AlertHtml(ICON.ERROR,'Ocurrió un error inesperado',e.response.data.message)
                    }
                );
                
            },(e)=>{
                    AlertHtml(ICON.ERROR,'Ocurrió un error inesperado',e.response.data.message)
                }
            )
            
        }
    }

    const handleSubmit = (event) => {   
        event.preventDefault();

        const number_required = 2;
        let contador = 0;

        let temp = {...validations};

        if( nameFile === '' ) temp.file = "danger";
        else { temp.file = ""; contador ++ };

        if ( form.comments === "" ) temp.comments = "danger";
        else { temp.comments = "secondary"; contador ++ };

        setValidations(temp);

        let _errors = [];
        let filas_no_insertadas = [];
        
        if( contador === number_required ) {
            let cont_h = 0;
            for (let k=0; k<headers.length; k++) {
                if( fileStrings.includes( headers[k] )) cont_h++;
            };
            //console.log(form.data)
            if( cont_h === headers.length ) {
                let info_create = [];
                for( let i = 0; i < form.data.length; i++) {
                    let info_ind = {};
                    let contador_req = 0;
                    let info_attr = [];
                    for( let key in form.data[i] ) {
                        let value = form.data[i][key];
                        if(key === "Nombre *") {
                            if( value.trim() !== "" ) {
                                info_ind['name'] = value;
                                contador_req ++;
                            } else {
                                //toca guardar el error 
                                _errors.push({fila:i+1,columna:key,error:`El dato es incorrecto`})
                            }
                        } else if(key === "Responsable *") {
                            if( typeof value === 'number') {
                                const aux = users.filter( u => u.id === value)
                                if( aux.length > 0) {
                                    info_ind['responsable'] = value;
                                    contador_req ++;
                                } else {
                                    //toca guardar el error 
                                    _errors.push({fila:i+1,columna:key,error:`El responsable no existe`})
                                }
                            } else {
                                //toca guardar el error 
                                _errors.push({fila:i+1,columna:key,error:`El tipo de dato no es númerico`})
                            }
                        } else if( key === 'Filtro *') {
                            if( typeof value === 'number' ) {
                                const aux = filters.filter( f => f.id === value)
                                if( aux.length > 0) {
                                    info_ind['idfilter'] = value;
                                    contador_req ++;
                                } else {
                                    //toca guardar el error 
                                    _errors.push({fila:i+1,columna:key,error:`El filtro no existe`})
                                }
                            } else {
                                //toca guardar el error 
                                _errors.push({fila:i+1,columna:key,error:`El tipo de dato no es númerico`})
                            }
                        } else {
                            let attribute = attributes.filter( attr => attr.name_header === key )[0];
                            if( attribute.object_type === "list") {
                                if( typeof value === 'number' ) {
                                    let aux = attribute.lists_options.map( list => list.id === value )
                                    let exist = attribute.lists_options.find( list => list.id === value )
                                    
                                    if(exist !== undefined){
                                        if ( aux.length > 0 ) {
                                            if( attribute.isrequired === "1" ) contador_req ++;
                                            info_attr.push({
                                                idattribute: attribute.id,
                                                value
                                            })
                                        } else {
                                            //toca guardar el error 
                                            _errors.push({fila:i+1,columna:key,error:`El campo no existe o es incorrecto`})
                                        }
                                    }else{
                                        _errors.push({fila:i+1,columna:key,error:`El dato no existe`})
                                    }
                                    
                                } else {
                                    //toca guardar el error 
                                    _errors.push({fila:i+1,columna:key,error:`El tipo de dato no es númerico`})
                                }
                                
                            } else if (attribute.object_type === "list-multiple") {
                                value = value.toString();
                                let values_mult = value.split(',');
                                let temp_values = [];
                                for( let j=0; j<values_mult.length; j++) {
                                    let aux = attribute.lists_options.map( list => list.id === parseInt(values_mult[j]) )
                                    if ( aux.length > 0 ) temp_values.push(values_mult[j]);
                                }
                                if( values_mult.length === temp_values.length ) {
                                    if( attribute.isrequired === "1" ) contador_req ++;
                                    info_attr.push({
                                        idattribute: attribute.id,
                                        value: temp_values
                                    });
                                } else {
                                    //toca guardar el error 
                                    _errors.push({fila:i+1,columna:key,error:`Hay errores en la columna de list-multiple`})
                                }
                            } else {
                                info_attr.push({
                                    idattribute: attribute.id,
                                    value
                                });
                                if( attribute.isrequired === "1" ) contador_req ++;
                            }
                        }
                    }
                    info_ind['attributes'] = info_attr;
                    if( numRequires === contador_req){
                        info_create.push(info_ind)
                    }else{
                        filas_no_insertadas.push(i+1)
                    } 
                }

                if(info_create.length !== 0 && info_create.length === form.data.length){
                    let file = {}
                    file.name = nameFile;
                    file.description = form.comments;
                    Utils.Petition.post(
                        '/indicator-file-imports',file,
                        (response) => {
                            saveDatafile(response.data,info_create);
                        },(e) => {
                            AlertHtml(ICON.ERROR,'Ocurrió un error inesperado',e.response.data.message)
                        }
                    );
                    AlertForm(ICON.SUCCESS,'Indicadores agregados',`Se agrego ${info_create.length} indicador${info_create.length > 1 ? "es": ""}`,'center')
                    history.push("/admin/MassiveImportIndicators");
                }else{
                    let msj = "";
                    let msjerrors = [];
                    if(info_create.length !== 0){
                        let file = {}
                        file.name = nameFile;
                        file.description = form.comments;
                        Utils.Petition.post(
                            '/indicator-file-imports',file,
                            (response) => {
                                saveDatafile(response.data,info_create);
                            },(e) => {
                                AlertHtml(ICON.ERROR,'Ocurrió un error inesperado',e.response.data.message)
                            }
                        );
                    }
                    if(filas_no_insertadas.length > 0 ) msj = `<div><b>No se agregaron los indicadores de las filas: </b> ${filas_no_insertadas.join(",")}</div><br/>`
                    
                    msjerrors = _errors.map((e,index)=>`<div><b>${index+1}.</b> En la columna "${e.columna}" de la fila "${e.fila}" ${e.error}<div>`)
                    
                    if(msj === "" && msjerrors.length <= 0 ){
                        msj = "No se agregaron los indicadores de las filas: "+filas_no_insertadas.join(",")
                    }else{
                        msj += `${msjerrors.join("")}`
                    }
                     
                    AlertHtml(ICON.ERROR,'Errores en el archivo',msj,`Se cargo ${info_create.length} indicador${info_create.length > 1 ? "es": ""}`)
                }
                
            } else {
                AlertForm(ICON.WARNING, 'El archivo tiene headers diferentes', '', 'center', null, true)
            }
        } else {
            AlertForm(ICON.WARNING, 'Los campos en rojo son requeridos', '', 'center', null, true)
        }
    };

    return (
        <Container>
            <h3 className="text-center">Importación Masiva de Indicadores</h3>
            <div className="text-center">
                <Button color={Theme1.success} onClick={(e) => exportToCSV('plantilla_import_indicators')} className="font-weight-bold">
                    Descargar Plantilla
                </Button>
            </div>
            <FormCustom className="text-center my-3" onSubmit={handleSubmit} >  
                <div className="form-group text-center">
                    <Row>
                        <Col><b>Archivos</b></Col>
                        <Col sm={7}>
                            <div className="custom-file">
                                <input 
                                    type="file" 
                                    className="form-control custom-file-input"
                                    id="inputFile"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        setNameFile(file.name);
                                        readExcel(file);
                                    }}
                                />
                                <label className={`custom-file-label text-left border rounded border-${validations.file}`} htmlFor="inputGroupFile01">Selecciona el archivo</label>
                            </div>
                        </Col>                       
                    </Row>  
                </div>
                {
                    nameFile !== '' &&
                    <div className="form-group">
                        <Row>
                            <Col><b>Archivo escogido</b></Col>
                            <Col sm={7}>
                                <label className="font-weight-bold text-left">{nameFile}</label>
                            </Col>
                        </Row>
                    </div>
                } 
                <div className="form-group">
                    <Row>
                        <Col><b>Comentarios</b></Col>
                        <Col sm={7}>
                            <textarea name='comments' className={`form-control border rounded border-${validations.comments}`} id="comentarios" onChange={handleForm} rows="4"></textarea>
                        </Col>
                    </Row>
                </div>
                <Link to="/admin/MassiveImportIndicators">
                    <Button color={Theme1.grayText} type="button">
                        Atras
                    </Button>
                </Link>
                <Button type="submit" className="my-2">Cargar</Button>                
            </FormCustom>
        </Container>    
    )
}

export default ImportIndicators;