import { useState } from 'react';
import { ChromePicker } from 'react-color';
function ColorPickerChrome(props){
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleFocus = (event) => {
        event.preventDefault();
        console.log('focus');
        setDisplayColorPicker(true);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const popover = {
        top:'40px',
        right:0,
        position: 'absolute',
        zIndex: '2',
    }
    const containerPicker ={
        position:'relative'
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    return (
        <div className="container-picker" style={containerPicker}>
            <div className="input-group mb-3">
                <input type="text" name={props.name} onFocus={handleFocus} className="form-control" value={props.color} />
                <div className="input-group-append">
                    <button className="btn" onClick={handleFocus} style={{ backgroundColor: props.color, borderLeftColor: '#ced4da', zIndex: '0' }}></button>
                </div>
            </div>
            {
                displayColorPicker ?
                    <div style={popover}>
                        <div style={cover} onClick={handleClose} />
                        <ChromePicker color={props.color} onChangeComplete={(value)=>props.onChange(value.hex, props.name)} />
                    </div>
                    : null
            }
        </div>
    )
}

export default ColorPickerChrome;