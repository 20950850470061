import React, { useState, useEffect } from "react";
import { Loading } from "../../../components/Loading";
import Utils from "../../../utils";
import { Col, Container, Row, Form, ButtonGroup } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { styleTheme } from '../../../styles/SelectTheme';
import { AlertConfirm, AlertForm, ICON } from '../../../utils/SweetAlert';
import { IconTrash } from '../../../components/iconos/IconTrash';
import { IconSave } from '../../../components/iconos/IconSave';
import { Button } from "../../../styles/CommonStyles";

function IndicatorRelations({data,indicator}){
    const [dataSelect, setDataSelect] = useState([]);
    const [dataIndicators, setDataIndicators] = useState([]);
    const [idIndicatorParent, setIdIndicatorParent] = useState()

    useEffect(() => {
        console.log("data", data);
       setDataIndicators(data);
       setIdIndicatorParent(indicator.id);
    }, [data]);

    const updateInfo = (idIndicator) => {
        Utils.Petition.get(
            '/indicator-relations/id/' + idIndicator,
            (response) => {
                setDataIndicators(response.data);
            }
        );
    }
    const saveRelation = () =>{
        const info={
            idindicator: dataSelect.IDIndicator,
            idparent:idIndicatorParent
        }
        console.log(idIndicatorParent, dataSelect.IDIndicator)
        Utils.Petition.post('/indicator-relations/',
        info,
        (response) => {
            
            updateInfo(idIndicatorParent);
            AlertForm(ICON.SUCCESS, "Información Guardada", '');
        })
    }

    const deleteIndicator = (indicator) => {
        const info={
            idindicator: indicator.idindicator,
            idparent:indicator.idparent,
        }
        Utils.Petition.post('/indicator-relations/delete/',
        info,
        (response) => {
            updateInfo(indicator.idparent);
            AlertForm(ICON.SUCCESS, "Eliminado Correctamente");
        })
    }
    const handleDeleteIndicator = (indicator) => {
        AlertConfirm(ICON.WARNING, `Desea eliminar la union con este indicador?`, 'Estas Seguro', () => deleteIndicator(indicator))
    }

    const loadIndicators = (inputValue) => {
        if (inputValue.length > 3) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    Utils.Petition.get(
                        '/indicators/name-code-indicator/' + inputValue,
                        (response) => {
                            resolve(response.data)
                        }
                    )
                }, 100);
            });
        }
        return new Promise((resolve) => resolve([]));
    }

    const selectIndicators = (data, flag) => {
        let newData = {
            "IDIndicator": data.id,
        };
        setDataSelect(newData);
    }    

    return (
        <div className="container">
            <Row>
                <Col>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={loadIndicators}
                        onChange={e => selectIndicators(e, 0)}
                        theme={theme => (styleTheme(theme))}
                    />
                </Col>
                <Col>
                    <ButtonGroup aria-label="Basic example" className="align-items-center">
                        <Button color="green" onClick={saveRelation}>Guardar </Button>
                    </ButtonGroup>
                    
                </Col>
            </Row>
            <hr />
            {
                dataIndicators.map((indicator, index) => {

                    return (<div key={`indicator-${index}`}>
                        <Row>
                            <Col xs={4}><Form.Control disabled value={indicator.indicator.code} type="text" maxLength="350" /></Col>
                            <Col xs={6}><Form.Control disabled value={indicator.indicator.name} type="text" /></Col>
                            <Col xs={2} >
                                <ButtonGroup aria-label="Basic example">
                                    <IconTrash color="black" link hover="red" onClick={handleDeleteIndicator.bind(this, indicator)} />

                                </ButtonGroup>
                            </Col>
                        </Row><hr /></div>)
                })
            }
        </div>
    )
}

export default IndicatorRelations

