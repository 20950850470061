import { useEffect, useState } from "react";
import { Form, FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import {FaSave, FaTimes} from "react-icons/fa";
import ColorPickerChrome from "../../../components/ColorPickerChrome";
import { ButtonLoading } from "../../../components/Loading/ButtonLoading";
import { Button, ButtonLink, Container } from "../../../styles/CommonStyles";
import Utils from "../../../utils";
import { AlertForm, ICON } from "../../../utils/SweetAlert";
import { CustomMenu, GraphicPalette, PreviewImage } from "./styles";
import { StyleCheckbox } from "../../../styles/CommonStyles";
import Select from "react-select";
import { styleTheme } from "../../../styles/SelectTheme";
import { FaHome, FaNetworkWired, FaChartBar, FaCog, FaUsers, FaBell, FaGraduationCap, FaLifeRing, FaExternalLinkAlt } from "react-icons/fa";
import { IconTrash } from "../../../components/iconos/IconTrash";
import { Theme1 } from "../../../styles/Theme1";
import Swal from "sweetalert2";

function PortalConfig({idportal, portalMethods}){

    const initialForm = ()=> { 
        return {
            idportal,
            color_primary:'#EF8220',
            color_secondary:'#28a745',
            format_number: ',.1f2d',
            format_percent: ',.1f',
            max_upload_indicator_day: '10',
            filter_name:'Región',
            graphic_palette: ['#000000', '#575756','#b3b2b2','#f2821c','#95bd3c','#d22027','#1a4582', '#ffffff','#ffffff', '#ffffff', '#204f79', '#3074b6', '#8597b0', '#8eaadc', '#bdd7ee', '#aad18e', '#71ad47', '#548234', '#395723', '#e1f0d8'],
            menu:{data:[]},
            logo: ''
        }
    }
    const typelinkOptions = [
        {label:"Sin enlace",value:"1"},
        {label:"Página del sitio",value:"2"},
        {label:"Enlace externo",value:"3"}
    ]

    const optionsIcons = [
        {label:<>Home <FaHome/></>,value:"FaHome"}, 
        {label:<>NetworkWired <FaNetworkWired/></>,value:"FaNetworkWired"}, 
        {label:<>ChartBar <FaChartBar/></>,value:"FaChartBar"}, 
        {label:<>Cog <FaCog/></>,value:"FaCog"}, 
        {label:<>Users <FaUsers/></>,value:"FaUsers"}, 
        {label:<>Bell <FaBell/></>,value:"FaBell"}, 
        {label:<>GraduationCap <FaGraduationCap/></>,value:"FaGraduationCap"}, 
        {label:<>LifeRing <FaLifeRing/></>,value:"FaLifeRing"}, 
        {label:<>ExternalLinkAlt <FaExternalLinkAlt/></>,value:"FaExternalLinkAlt"}
    ]

    const DataMenu = Utils.getConfigPortal('menu') === null ? [] : Utils.getConfigPortal('menu').data === undefined ? [] : Utils.getConfigPortal('menu').data;

    const [indexPaletteSelected, setIndexPaletteSelected] = useState(-1);
    const [selectColorPalette, setSelectColorPalette] = useState('#000000');
    const [isLoadingConfig, setIsLoadingConfig] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [portalConfigForm, setPortalConfigForm] = useState(initialForm());
    const [extensionAprobacion, setExtensionAprobacion] = useState(false);
    const [namemenu, setNamemenu] = useState("");
    const [typelink, setTypelink] = useState({label:"",value:""});
    const [showListPageMenu, setShowListPageMenu] = useState(false);
    const [pageMenu, setPageMenu] = useState({label:"",value:""});
    const [allPagesSite, setAllPagesSite] = useState([]);
    const [pageMenuOptions, setPageMenuOptions] = useState([]);
    const [showLinkExternalMenu, setShowLinkExternalMenu] = useState(false);
    const [linkExternalMenu, setLinkExternalMenu] = useState("");
    const [nameSubItem, setNameSubItem] = useState("");
    const [typelinkSubItem, setTypelinkSubItem] = useState({label:"",value:""});
    const [showListPageSubItem, setShowListPageSubItem] = useState(false);
    const [pageSubItem, setPageSubItem] = useState({label:"",value:""});
    const [showLinkExternalSubItem, setShowLinkExternalSubItem] = useState(false);
    const [linkExternalSubItem, setLinkExternalSubItem] = useState("");
    const [jsonMenu, setJsonMenu] = useState(DataMenu);
    const [index, setIndex] = useState(-1);
    const [parentIndex, setParentIndex] = useState(-2);
    const [AddEditButton, setAddEditButton] = useState("Agregar");
    const [AddEditButtonSubItem, setAddEditButtonSubItem] = useState("Agregar");
    const [showItemMenu, setShowItemMenu] = useState(true);
    const [iconItem, setIconItem] = useState({label:<>Home <FaHome/></>,value:"FaHome"})
    const [nameImg, setNameImg] = useState({logo: "", splash: ""});
    const [centrifyURL, setCentrifyURL] = useState({url: "" , validation: false});
    //Se crea esta variable para evitar que fallen los useEffect
    if(!portalMethods){
        portalMethods={portalConfigSaveControl:false, loadIdPortalToConfig:0};
    }

    useEffect(()=>{
        if(idportal){
            Swal.fire({
                title: 'Cargando...',
                allowOutsideClick: false,
            });
            Swal.showLoading()
            Utils.Petition.get('/portal-config/idportal/'+idportal, (response)=>{
                setPortalConfigForm(response.data);
                setCentrifyURL({url: response.data.centrify_login, validation: response.data.centrify_login ? true:false} );
            }, (error) => console.log(error),
            () => Swal.close()
            );
        }
        Utils.Petition.get('/extension_portal/isApproval/', (response)=>{
            setExtensionAprobacion(response.isApproval);
        });
        
        Utils.Petition.get('/pages/portal/'+localStorage.getItem('idportal'),(response) => {
            if (response.data && response.data.length > 0){
                setAllPagesSite(response.data)
            }
            
        })
        setShowItemMenu(true);
    }, []);
    useEffect(()=>{
        if(portalMethods.portalConfigSaveControl){
            handleConfigSave();
            portalMethods.setPortalConfigSaveControl(false);
        }
    }, [portalMethods.portalConfigSaveControl]);

    useEffect(()=>{
        if(portalMethods.loadIdPortalToConfig){
            
            let _portalConfig = portalMethods.businesses[portalMethods.index].portals[portalMethods.indexPortal].portal_config;
            setPortalConfigForm({
                idportal: _portalConfig.idportal,
                color_primary: _portalConfig.color_primary,
                color_secondary: _portalConfig.color_secondary,
                format_number: _portalConfig.format_number,
                format_percent: _portalConfig.format_percent,
                max_upload_indicator_day: _portalConfig.max_upload_indicator_day,
                graphic_palette: _portalConfig.graphic_palette?_portalConfig.graphic_palette:initialForm().graphic_palette
            });
            portalMethods.setLoadIdPortalToConfig(0);
        }
    }, [portalMethods.loadIdPortalToConfig]);

    const handleConfigSave = ()=>{

        if(validateForm()){
            setIsLoadingSave(true);
            Utils.Petition.post('/extension_portal/update',{idextension:extensionAprobacion === true ? 1 : null}, );
            if(portalMethods.portalForm){ //Si es un formulario que depende del portal
                
                if(portalMethods.portalForm.id){
                    
                    //actualización
                    if(portalMethods.portalEditTitle===portalMethods.portalForm.name){//No cambia el nombre
                        Utils.Petition.post('/portal-config/update', portalConfigForm, (response)=>{
                            AlertForm(ICON.SUCCESS, 'Portal y '+response.message);
                            setPortalConfigForm(initialForm());
                            portalMethods.setIsLoadingSave(false);
                        }, (error) => console.log(error),
                        () => setIsLoadingSave(false)
                        );
                        portalMethods.setPortalForm(portalMethods.clearPortalForm());
                    }else{ // Cambia el nombre
                        
                        Utils.Petition.post('/portals/update', portalMethods.portalForm, (response)=>{
                        
                            let _businesses = JSON.parse(JSON.stringify(portalMethods.businesses));
                            _businesses[portalMethods.index].portals[portalMethods.indexPortal].name = portalMethods.portalForm.portal;
                            portalMethods.setBusinesses(_businesses);
                            
                            
                            Utils.Petition.post('/portal-config/update', { ...portalConfigForm}, (response)=>{
                                AlertForm(ICON.SUCCESS, 'Portal y '+response.message);
                                setPortalConfigForm(initialForm());
                                portalMethods.setIsLoadingSave(false);
                            }, (error) => console.log(error),
                            () => setIsLoadingSave(false));
                            portalMethods.setPortalForm(portalMethods.clearPortalForm());
    
                        });
                    }


                }else{
                    //Creación
                    Utils.Petition.post('/portals/create', portalMethods.portalForm, (response)=>{
                        portalMethods.setPortalForm(portalMethods.clearPortalForm());
                        if(response.data.process_portal && response.data.process_portal.status==='En proceso' && !portalMethods.reloadInterval){
                            portalMethods.setReloadInterval(true);
                        }
                        let _businesses = JSON.parse(JSON.stringify(portalMethods.businesses));
                        _businesses[portalMethods.index].portals.push(response.data);
                        portalMethods.setBusinesses(_businesses);

                        Utils.Petition.post('/portal-config/create', { ...portalConfigForm, idportal:response.data.id }, (response)=>{
                            AlertForm(ICON.SUCCESS, 'Portal y '+response.message);
                            setPortalConfigForm(initialForm());
                        });

                        portalMethods.setPortalForm(portalMethods.clearPortalForm());

                    }, (error) => console.log(error),
                    () => setIsLoadingSave(false));
                }

            }else{ // Si hace parte del formulario independiente
                if(portalConfigForm.idportal){
                    Utils.Petition.post('/portal-config/update', portalConfigForm, (response)=>{
                        AlertForm(ICON.SUCCESS, response.message);
                    }, (error) => console.log(error),
                    () => setIsLoadingSave(false));
                }
            }
        }
        
    }
    const validateForm = ()=>{
        
        if(portalMethods.portalForm){
            if(portalMethods.portalForm.portal===''){
                AlertForm(ICON.WARNING, "Ingresa el nombre del portal");
                return false;
            }
        }

        if(portalConfigForm.color_primary===''){
            AlertForm(ICON.WARNING, "Ingresa el color primario");
            return false;
        }
        if(portalConfigForm.color_secondary===''){
            AlertForm(ICON.WARNING, "Ingresa el color secundario");
            return false;
        }
        if(portalConfigForm.format_number===''){
            AlertForm(ICON.WARNING, "Ingresa el formato de número");
            return false;
        }
        if(portalConfigForm.format_percent===''){
            AlertForm(ICON.WARNING, "Ingresa el formato de porcentaje");
            return false;
        }
        if(portalConfigForm.max_upload_indicator_day===''){
            AlertForm(ICON.WARNING, "Ingresa un día máximo de actualización del mes (válido para la extensión aprobadores de indicadores)");
            return false;
        }
        if(portalConfigForm.graphic_palette.length===0){
            AlertForm(ICON.WARNING, "Ingresa por lo menos un color en la paleta de colores");
            return false;
        }
        return true;
    }
    const handleEditColorPalette = (i) => {
        setIndexPaletteSelected(i);
        setSelectColorPalette(portalConfigForm.graphic_palette[i]);
    }
    const handleChangeSelectedColor = ()=>{
        let _portalConfigForm = {...portalConfigForm};
        _portalConfigForm.graphic_palette[indexPaletteSelected] = selectColorPalette;
        setPortalConfigForm(_portalConfigForm); 
    }
    const handleDeleteSelectedColor = ()=>{
        let _portalConfigForm = {...portalConfigForm};
        _portalConfigForm.graphic_palette.splice(indexPaletteSelected, 1);
        setIndexPaletteSelected(-1);
        setPortalConfigForm(_portalConfigForm); 
    }
    const handleAddSelectedColor = ()=>{
        let _portalConfigForm = {...portalConfigForm};
        _portalConfigForm.graphic_palette.push(selectColorPalette);
        setIndexPaletteSelected(_portalConfigForm.graphic_palette.length-1);
        setPortalConfigForm(_portalConfigForm); 
    }
    const handleConfigPortalForm = (event) =>{
        setPortalConfigForm({...portalConfigForm, [event.target.name]: event.target.value});
    }

    const handleColorsForm =(value, name) => {
        setPortalConfigForm({ ...portalConfigForm, [name]: value });
    }
    const handlePaletteColorsForm=(value)=>{
        setSelectColorPalette(value);
    }
    const handleExtensionIndicadores = (event) => {
        setExtensionAprobacion(event.target.checked);
    }

    const setNamemenuForm = (event) =>{
        if (event && event.target) {
            setNamemenu(event.target.value)
        }
    }
    const handleConfigPortalFormTypeLink = (event) => {
        setTypelink(event);
        if(event && event.value !== undefined && event.value === "2"){
            setShowListPageMenu(true);
            setShowLinkExternalMenu(false);
            setLinkExternalMenu("");
        } else if(event && event.value !== undefined && event.value === "3"){
                setShowLinkExternalMenu(true);
                setShowListPageMenu(false);
                setPageMenu({label:"",value:""})
        }else {
            setShowLinkExternalMenu(false);
            setShowListPageMenu(false);
            setLinkExternalMenu("");
            setPageMenu({label:"",value:""})
        }
    }

    const handleConfigPortalFormPageMenu = (event) => {
        setPageMenu(event);
    }

    useEffect(() => {
        const pageOptions = []
        for (let index = 0; index < allPagesSite.length; index++) {
            const element = allPagesSite[index];
            pageOptions.push({label:element.name, value: element.id})
        }
        setPageMenuOptions(pageOptions);
    }, [allPagesSite])

    const setLinkExternalMenuForm = (event) =>{
        if (event && event.target) {
            setLinkExternalMenu(event.target.value)
        }
    }

    const setNameSubItemForm = (event) =>{
        if (event && event.target) {
            setNameSubItem(event.target.value)
        }
    }

    const handleConfigPortalFormTypeLinkSubItem = (event) => {
        setTypelinkSubItem(event)
        if(event && event.value !== undefined && event.value === "2"){
            setShowListPageSubItem(true);
            setShowLinkExternalSubItem(false);
            setLinkExternalSubItem("");
        }else if(event && event.value !== undefined && event.value === "3"){
                setShowLinkExternalSubItem(true);
                setShowListPageSubItem(false);
                setPageSubItem({label:"",value:""})
        }else{
                setShowLinkExternalSubItem(false);
                setShowListPageSubItem(false);
                setLinkExternalSubItem("");
                setPageSubItem({label:"",value:""})
        }
    }

    const handleConfigPortalFormPageSubItem = (event) => {
        setPageSubItem(event);
    }

    const setLinkExternalSubItemForm = (event) =>{
        if (event && event.target) {
            setLinkExternalSubItem(event.target.value)
        }
    }

    const handleAddEditItem = () => {
        const newJson = [...jsonMenu];
        if(typelink == null){
            AlertForm(ICON.WARNING, "Seleccione un tipo de enlace");
        }else{
            if(AddEditButton === "Agregar"){
                const newItem = {
                    label: namemenu,
                    child:[],
                    icon:iconItem.value,
                    path:""
                };
                if(typelink.value === "1"){
                    newItem.path = "#"
                }
                if(typelink.value === "2"){
                    newItem.path = allPagesSite.filter((e) => e.id === pageMenu.value)[0].path;
                }
                if(typelink.value === "3"){
                    newItem.external = true;
                    newItem.path = linkExternalMenu;
                }
                
                newJson.push(newItem);
                setJsonMenu(newJson);
                setPortalConfigForm({...portalConfigForm,menu:{data:newJson}})
            }else{
                let _jsonMenu = [...jsonMenu]
                const indexItem = parentIndex === -1 ? index : parentIndex;
                
                delete _jsonMenu[indexItem].external;
    
                _jsonMenu[indexItem].label = namemenu;
                if(typelink.value === "1"){
                    _jsonMenu[indexItem].path = "#"
                }
                if(typelink.value === "2"){
                    _jsonMenu[indexItem].path = allPagesSite.filter((e) => e.id === pageMenu.value)[0].path
                }
                if(typelink.value === "3"){
                    _jsonMenu[indexItem].external = true;
                    _jsonMenu[indexItem].path = linkExternalMenu
                }
                _jsonMenu[indexItem].icon = iconItem.value;
                setJsonMenu(_jsonMenu);
                setAddEditButton("Agregar");
                setParentIndex(-2);
                setPortalConfigForm({...portalConfigForm,menu:{data:_jsonMenu}})
            }
            setNamemenu("");
            setIconItem({label:<>Home <FaHome/></>,value:"FaHome"});
            setTypelink(null);
            setShowLinkExternalMenu(false);
            setShowListPageMenu(false);
            setLinkExternalMenu("");
            setPageMenu({label:"",value:""})
        }        
        
    }

    const handleAddEditSubItem = () => {
        const newJson = [...jsonMenu];
        if(typelinkSubItem == null){
            AlertForm(ICON.WARNING, "Seleccione un tipo de enlace para el subitem");
        }else{
            if(AddEditButtonSubItem === "Agregar"){
                const indexParameter = parentIndex === -1 ? index : parentIndex;
                const newItem = {
                    label: nameSubItem,
                    path:""
                };
                if(typelinkSubItem.value === "1"){
                    newItem.path = "#"
                }
                if(typelinkSubItem.value === "2"){
                    newItem.path = allPagesSite.filter((e) => e.id === pageSubItem.value)[0].path
                }
                if(typelinkSubItem.value === "3"){
                    newItem.external = true;
                    newItem.path = linkExternalSubItem
                }
                
                newJson[indexParameter].child.push(newItem);
                setJsonMenu(newJson);

                if(parentIndex === -1){
                    setIndex(newJson.length - 1);
                }else{
                    setParentIndex(indexParameter);
                    setIndex(newJson[indexParameter].child.length - 1); 
                }
                setPortalConfigForm({...portalConfigForm,menu:{data:newJson}})
                
            }else{
                let _jsonMenu = [...jsonMenu];
                if(_jsonMenu[parentIndex].child[index].external !== undefined ) delete _jsonMenu[parentIndex].child[index].external;
    
                _jsonMenu[parentIndex].child[index].label = nameSubItem;
                if(typelinkSubItem.value === "1"){
                    _jsonMenu[parentIndex].child[index].path = "#"
                }
                if(typelinkSubItem.value === "2"){
                    _jsonMenu[parentIndex].child[index].path = allPagesSite.filter((e) => e.id === pageSubItem.value)[0].path
                }
                if(typelinkSubItem.value === "3"){
                    _jsonMenu[parentIndex].child[index].external = true;
                    _jsonMenu[parentIndex].child[index].path = linkExternalSubItem
                }
                setJsonMenu(_jsonMenu);
                if(parentIndex === -1) {
                    setAddEditButtonSubItem("Agregar");
                }
                setPortalConfigForm({...portalConfigForm,menu:{data:_jsonMenu}})
            }
            if(parentIndex === -1 || AddEditButtonSubItem === "Agregar") {
                setNameSubItem("");
                setTypelinkSubItem(null);
                setPageSubItem({label:"",value:""});
                setLinkExternalSubItem("");
                setShowLinkExternalSubItem(false);
                setShowListPageSubItem(false);
            }
            
        }
    }

    const handleCancelItem = () => {
        setAddEditButton("Agregar");
        setNamemenu("");
        setTypelink({label:"",value:""});
        setPageMenu({label:"",value:""});
        setLinkExternalMenu("");
        setIndex(-1);
        setParentIndex(-2);
        setIconItem(optionsIcons[0]);
        
    }
    const handleCancelSubItem = () => {
        setAddEditButtonSubItem("Agregar");
        setNameSubItem("");
        setTypelinkSubItem(null);
        setPageSubItem({label:"",value:""});
        setLinkExternalSubItem("");
        setShowLinkExternalSubItem(false);
        setShowListPageSubItem(false);
    }

    const handleSelect = (indexParameter, parentIndexParameter) => {
        
        setIndex(indexParameter);
        setParentIndex(parentIndexParameter);

        setAddEditButtonSubItem("Agregar");
        setAddEditButton("Editar");
        if(parentIndexParameter === -1){//es por que es un item
            setShowItemMenu(true);
            handleCancelSubItem();
            setNamemenu(jsonMenu[indexParameter].label)
            
            if(jsonMenu[indexParameter].path[0] === "/"){
                setShowListPageMenu(true);
                setShowLinkExternalMenu(false);
                setTypelink(typelinkOptions[1]);
                setPageMenu(pageMenuOptions.filter(e => e.label === allPagesSite.filter(e => e.path == jsonMenu[indexParameter].path)[0].name)[0])
                
            }
            if(jsonMenu[indexParameter].path === "#"){
                setShowListPageMenu(false);
                setShowLinkExternalMenu(false);
                setTypelink(typelinkOptions[0]);
                setLinkExternalMenu(jsonMenu[indexParameter].path);
               
            }
            if(jsonMenu[indexParameter].external !== undefined){
                setShowListPageMenu(false);
                setShowLinkExternalMenu(true);
                setTypelink(typelinkOptions[2]);
                setLinkExternalMenu(jsonMenu[indexParameter].path);
            }
            setIconItem(optionsIcons.filter(e => e.value === jsonMenu[indexParameter].icon))
        }else{//es un sub item
            setShowItemMenu(false);
            setAddEditButtonSubItem("Editar");
            //datos del item
            setNamemenu(jsonMenu[parentIndexParameter].label)            
            if(jsonMenu[parentIndexParameter].path[0] === "/"){
                setShowListPageMenu(true);
                setShowLinkExternalMenu(false);
                setTypelink(typelinkOptions[1]);
                setPageMenu(pageMenuOptions.filter(e => e.label === allPagesSite.filter(e => e.path == jsonMenu[parentIndexParameter].path)[0].name)[0])
                
            }
            if(jsonMenu[parentIndexParameter].path === "#"){
                setShowListPageMenu(false);
                setShowLinkExternalMenu(false);
                setTypelink(typelinkOptions[0]);
                setLinkExternalMenu(jsonMenu[parentIndexParameter].path);
               
            }
            if(jsonMenu[parentIndexParameter].external !== undefined){
                setShowListPageMenu(false);
                setShowLinkExternalMenu(true);
                setTypelink(typelinkOptions[2]);
                setLinkExternalMenu(jsonMenu[parentIndexParameter].path);
            }

            //Datos del subitem
            
            setNameSubItem(jsonMenu[parentIndexParameter].child[indexParameter].label)
            if(jsonMenu[parentIndexParameter].child[indexParameter].path[0] === "/"){
                setShowListPageSubItem(true);
                setShowLinkExternalSubItem(false);
                setTypelinkSubItem(typelinkOptions[1])
                setPageSubItem(pageMenuOptions.filter(e => e.label === allPagesSite.filter(e => e.path == jsonMenu[parentIndexParameter].child[indexParameter].path)[0].name)[0])
                
            }
            if(jsonMenu[parentIndexParameter].child[indexParameter].external !== undefined ){
                setShowListPageSubItem(false);
                setShowLinkExternalSubItem(true);
                setTypelinkSubItem(typelinkOptions[2]);
                setLinkExternalSubItem(jsonMenu[parentIndexParameter].child[indexParameter].path);
               
            }
            if(jsonMenu[parentIndexParameter].child[indexParameter].path[0] == "#"){
                setShowListPageSubItem(false);
                setShowLinkExternalSubItem(false);
                setTypelinkSubItem(typelinkOptions[0]);
                setLinkExternalSubItem(jsonMenu[parentIndexParameter].child[indexParameter].path);
            }
        }
        
    }

    const handleDeleteItem = (indexParameter, parentIndexParameter) => {
        setIndex(indexParameter);
        setParentIndex(parentIndexParameter);
        handleCancelItem();
        handleCancelSubItem();
        let newjson = [...jsonMenu];
        if(parentIndexParameter === -1){//es por que es un item
            newjson.splice(indexParameter,1);
            setJsonMenu(newjson)
        }else{// es un sub item
            const parent = newjson[parentIndexParameter]
            parent.child.splice(indexParameter,1);
            setJsonMenu(newjson)
        }
        setPortalConfigForm({...portalConfigForm,menu:{data:newjson}})
    }

    const handleIcons = (event) => {
        setIconItem(event);
        
    }
    const handleFile = (e, name) => {
        const file = e.target.files[0];
        if (file.type.slice(0, 5) === "image") {
            if(file.size > 2000000) return AlertForm(ICON.WARNING, "El tamaño maximo de la imagen es de 2MB", "Por favor seleccionar otra imagen o redimensionar la imagen");
            setNameImg({...nameImg, [name]:file.name});
            const reader = new FileReader();
            let binary, base_64_file;
            reader.readAsBinaryString(file);
            reader.addEventListener(
            "loadend",
            function () {
                binary = reader.result; 
                base_64_file = btoa(binary); 
                setPortalConfigForm({...portalConfigForm, [name]: base_64_file})
            },
            false
            );
        } else {
            AlertForm(ICON.WARNING, "No haz seleccionado una imagen");
        }
    }

    const deleteImage = (name) => {
        setPortalConfigForm({...portalConfigForm, [name]: ""}); 
        setNameImg({...nameImg, [name]: ""});
    }

    const handleCheckUrlCentrify = () => {
        
        if(centrifyURL.validation) {
            setPortalConfigForm({...portalConfigForm, centrify_login: null});
        }else {
            setPortalConfigForm({...portalConfigForm, centrify_login: centrifyURL.url || "https://"});
        }
        setCentrifyURL({...centrifyURL, validation: !centrifyURL.validation});
    }
    return (
        <Container>
            <Row className="align-items-center">
                <Col md={6}>
                    <FormGroup>
                        <FormLabel>
                            <b>Color primario</b>
                        </FormLabel>
                        <ColorPickerChrome name="color_primary" color={portalConfigForm.color_primary} onChange={handleColorsForm}/>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <FormLabel>
                            <b>Color secundario</b>
                        </FormLabel>
                        <ColorPickerChrome name="color_secondary" color={portalConfigForm.color_secondary} onChange={handleColorsForm}/>
                    </FormGroup>
                </Col>
            </Row>
            <hr />
            <Row className="align-items-center mb-3">  
                <Col md={6}>
                    <FormGroup>
                        <FormLabel>
                            <b>Imagen Login</b>
                        </FormLabel>
                        <div className="custom-file">
                                <input 
                                    type="file" 
                                    className="form-control custom-file-input"
                                    id="inputFile"
                                    onChange={(e) =>handleFile(e, "splash")}
                                    accept="image/*"
                                />
                                <label className={`custom-file-label text-left border rounded`} htmlFor="inputGroupFile01">{nameImg.splash || "Selecciona una imagen"}</label>
                            </div>
                            <small>Tamaño Maximo de la Imagen 2MB</small>
                    </FormGroup>
                </Col>
                <Col md={4}>
                {portalConfigForm.splash &&
                    <PreviewImage image={`data:image/png;base64,${portalConfigForm.splash}`}>
                        <div className="img-preview"></div>
                    </PreviewImage>
                }
                {
                    !portalConfigForm.splash && <p style={{margin: "0"}}>No hay previsualización de imagen</p>
                }
                   
                </Col>
                <Col md={2}>
                    {portalConfigForm.splash &&  <IconTrash link onClick={() => deleteImage("splash")}/>}
                </Col>
            </Row>
            <Row className="align-items-center">  
                <Col md={6}>
                    <FormGroup>
                        <FormLabel>
                            <b>Logo</b>
                        </FormLabel>
                        <div className="custom-file">
                                <input 
                                    type="file" 
                                    className="form-control custom-file-input"
                                    id="inputFile"
                                    onChange={(e) =>handleFile(e, "logo")}
                                    accept="image/*"
                                />
                                <label className={`custom-file-label text-left border rounded`} htmlFor="inputGroupFile01">{nameImg.logo || "Selecciona una imagen"}</label>
                            </div>
                            <small>Tamaño Maximo de la Imagen 2MB</small>
                    </FormGroup>
                </Col>
                <Col md={4}>
                {portalConfigForm.logo &&
                    <PreviewImage image={`data:image/png;base64,${portalConfigForm.logo}`}>
                        <div className="img-preview"></div>
                    </PreviewImage>
                }
                {
                    !portalConfigForm.logo && <p style={{margin: "0"}}>No hay previsualización de imagen</p>
                }
                   
                </Col>
                <Col md={2}>
                    {portalConfigForm.logo &&  <IconTrash link onClick={() => deleteImage("logo")}/>}
                </Col>
            </Row>
            <hr />
            <FormGroup>
                <Row>
                    <Col md={6}>
                        <FormLabel>
                            <b>Paleta de colores de las gráficas</b>
                        </FormLabel>
                        <ColorPickerChrome name="select_color" color={selectColorPalette} onChange={handlePaletteColorsForm}/>
                        <Button color="green" onClick={handleAddSelectedColor}>Agregar nuevo</Button>
                        {
                            indexPaletteSelected>=0 &&
                            <>
                                <Button color="gray" onClick={handleChangeSelectedColor}>Editar seleccionado</Button>
                                <Button color="red" onClick={handleDeleteSelectedColor}>Eliminar</Button>
                            </>
                        }
                    </Col>
                    <Col md={6}>
                        <b>Previsualización</b> <i>(Selecciona el color que quieres editar)</i>
                        <div style={{width:'200px'}}>
                            {portalConfigForm.graphic_palette.map((color, i)=>{
                                return <GraphicPalette key={i} borderColor={(indexPaletteSelected===i)} color={color} onClick={()=>handleEditColorPalette(i)}>
                                    <div className="styled-background" />
                                </GraphicPalette>
                            })}
                        </div>
                    </Col>
                </Row>
            </FormGroup>
            <hr />
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <FormLabel>
                            <b>Formato de número</b>
                        </FormLabel>
                        <Form.Control type="text" id="format_number" name="format_number" value={portalConfigForm.format_number} onChange={handleConfigPortalForm} />
                        <Form.Text className="text-muted">
                            Resultado (43200.3): <b>{Utils.formatNumber(portalConfigForm.format_number,43200.3)}</b>
                        </Form.Text>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>
                            <b>Formato de porcentaje</b>
                        </FormLabel>
                        <Form.Control type="text" id="format_percent" name="format_percent" value={portalConfigForm.format_percent} onChange={handleConfigPortalForm} />
                        <Form.Text className="text-muted">
                            Resultado (120.38%): <b>{Utils.formatNumber(portalConfigForm.format_percent,120.38)}%</b>
                        </Form.Text>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <p>Explicación general (ejemplo: ,.1f3d):</p>
                    <ul>
                        <li><b>1er. digito ','</b>: Separación de miles</li>
                        <li><b>2do. digito '.'</b>: Separación de decimales</li>
                        <li><b>3er y 4to digito'1f'</b>:Cantidad de decimales (1)</li>
                        <li><b>5to y 6to digito '3d' (opcional)</b>:Eliminar decimales dinámicamente (despues de 3 dígitos) </li>
                    </ul>
                    <p>Ejemplos practicos:</p>
                    <ul>
                        <li><b>.,2f</b> - 3233: 3.233,00</li>
                        <li><b>,.0f</b> - 1643242.12: 1,643,242</li>
                        <li><b>,.3f3d</b> - 242.123: 242.123</li>
                        <li><b>,.3f3d</b> - 1242.123: 1,242.12</li>
                        <li><b>,.3f3d</b> - 11242.123: 11,242.1</li>
                        <li><b>,.3f3d</b> - 111242.123: 111,242</li>
                        <li><b>,.3f3d</b> - 1111242.123: 1,111,242</li>
                    </ul>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <FormLabel>
                        <b> Día máximo para subir valores de indicadores del mes anterior</b>
                        </FormLabel>
                        <Form.Control type="number" id="max_upload_indicator_day" name="max_upload_indicator_day" readOnly={!extensionAprobacion} value={portalConfigForm.max_upload_indicator_day} onChange={handleConfigPortalForm} />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <FormLabel>
                            <b>Nombre del filtro</b>
                        </FormLabel>
                        <Form.Control type="text" id="filter_name" name="filter_name" value={portalConfigForm.filter_name} onChange={handleConfigPortalForm} />
                    </FormGroup>
                </Col>
            </Row>

                <FormGroup>
                    <StyleCheckbox className="custom-control custom-checkbox">
                    <input className="custom-control-input" type="checkbox" name="extensionAprobacion" id="extensionAprobacion" checked={extensionAprobacion} onChange={handleExtensionIndicadores}/>
                    <label className="custom-control-label" htmlFor="extensionAprobacion">
                        Extensión de aprobación de indicadores
                    </label>
                    </StyleCheckbox>
                </FormGroup>
                <hr />
                <Row>
                    <Col md={6}>
                        <StyleCheckbox className="custom-control custom-checkbox">
                        <input className="custom-control-input" type="checkbox" id="checkURL" checked={centrifyURL.validation} onChange={handleCheckUrlCentrify}/>
                        <label className="custom-control-label" htmlFor="checkURL">
                            Implementar Centrify URL
                        </label>
                        </StyleCheckbox>
                    </Col>
                    <Col md={6}>
                    {
                        centrifyURL.validation &&
                        <FormGroup>
                            <FormLabel>
                                <b>Centrify URL</b>
                            </FormLabel>
                            <Form.Control type="text" id="centrify_login" name="centrify_login" value={portalConfigForm.centrify_login} onChange={handleConfigPortalForm} />
                        </FormGroup>
                    }
                    </Col>
                </Row>
               <hr />
               <div>
                   <h5>Performance y Analitica</h5>
               <Row>
                    
                    <Col md={3}>
                        <FormGroup>
                            <FormLabel>
                                <b>Tiempo De Rebote (sg)</b>
                            </FormLabel>
                            <Form.Control type="number" id="tiempo_rebote" name="tiempo_rebote" value={portalConfigForm.tiempo_rebote} onChange={handleConfigPortalForm} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <FormLabel>
                                <b>Tiempo De inactividad Pagina (min)</b>
                            </FormLabel>
                            <Form.Control type="number" id="tiempo_inactividad" name="tiempo_inactividad" value={portalConfigForm.tiempo_inactividad} onChange={handleConfigPortalForm} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <FormLabel>
                                <b>Tiempo De inactividad Usuarios(meses)</b>
                            </FormLabel>
                            <Form.Control type="number" id="deactivate_user_months" name="deactivate_user_months" value={portalConfigForm.deactivate_user_months} onChange={handleConfigPortalForm} />
                        </FormGroup>
                    </Col>
                </Row>
               </div>
               
                <hr />
                {/* MENU */}
                <FormGroup>
                    <CustomMenu>
                    <Row>
                        <Col md={6}>
                        { showItemMenu && 
                        <>
                            <h5>Menu</h5>
                            <FormGroup>
                                <FormLabel>
                                    Nombre de menú
                                </FormLabel>
                                <Form.Control type="text" id="namemenu" name="namemenu" value={namemenu} onChange={setNamemenuForm} />
                            </FormGroup>
                            <FormGroup>
                                <label>Icono</label>
                                <Select
                                    placeholder="Icono"
                                    name="IconItem"
                                    isClearable
                                    value={iconItem}
                                    onChange={handleIcons}
                                    options={optionsIcons}
                                    theme={theme => (styleTheme(theme))}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Tipo de enlace</label>
                                <Select
                                    placeholder="Tipo de enlace"
                                    name="typelink"
                                    isClearable
                                    value={typelink}
                                    onChange={handleConfigPortalFormTypeLink}
                                    options={typelinkOptions}
                                    theme={theme => (styleTheme(theme))}
                                />
                            </FormGroup>
                            {
                                showListPageMenu &&
                                <FormGroup>
                                    <label>Página</label>
                                    <Select
                                        placeholder="Página"
                                        name="pageMenu"
                                        isClearable
                                        value={pageMenu}
                                        onChange={handleConfigPortalFormPageMenu}
                                        options={pageMenuOptions}
                                        theme={theme => (styleTheme(theme))}
                                    />
                                </FormGroup>
                            }
                            {
                                showLinkExternalMenu &&
                                <FormGroup>
                                    <FormLabel>
                                        Enlace externo del menu
                                    </FormLabel>
                                    <Form.Control type="text" name="linkExternalMenu" value={linkExternalMenu} onChange={setLinkExternalMenuForm} />
                                </FormGroup>
                            }
                            <Button color="green" onClick={handleAddEditItem}>{AddEditButton}</Button>
                            <Button color="red" onClick={handleCancelItem}>Cancelar</Button>
                        </>
                        }
                        { parentIndex !== -2 && 
                        <>
                            <h5 className="mt-4">{AddEditButtonSubItem} subitem</h5>
                            <FormGroup>
                                <FormLabel>
                                    Nombre
                                </FormLabel>
                                <Form.Control type="text" id="nameSubMenu" name="nameSubMenu" value={nameSubItem} onChange={setNameSubItemForm}/>
                            </FormGroup>
                            <FormGroup>
                                <label>Tipo de enlace</label>
                                <Select
                                    placeholder="Tipo de enlace"
                                    name="typelinkSubItem"
                                    isClearable
                                    value={typelinkSubItem}
                                    onChange={handleConfigPortalFormTypeLinkSubItem}
                                    options={typelinkOptions}
                                    theme={theme => (styleTheme(theme))}
                                />
                            </FormGroup>
                            {
                                showListPageSubItem &&
                                <FormGroup>
                                    <label>Página</label>
                                    <Select
                                        placeholder="Página"
                                        name="pageSubItem"
                                        isClearable
                                        value={pageSubItem}
                                        onChange={handleConfigPortalFormPageSubItem}
                                        options={pageMenuOptions}
                                        theme={theme => (styleTheme(theme))}
                                    />
                                </FormGroup>
                            }
                            {
                                showLinkExternalSubItem &&
                                <FormGroup>
                                    <FormLabel>
                                        Enlace externo
                                    </FormLabel>
                                    <Form.Control type="text" name="linkExternalSubItem" value={linkExternalSubItem} onChange={setLinkExternalSubItemForm} />
                                </FormGroup>
                            }
                            <Button color="green" onClick={handleAddEditSubItem}>{AddEditButtonSubItem}</Button>
                            <Button color="red" onClick={handleCancelSubItem}>Cancelar</Button>
                        </>}
                            
                            </Col>
                            <Col md={6}>
                                <h3>Menú</h3>
                                {
                                    jsonMenu.map((item, i) => {
                                        return(
                                        <FormGroup>
                                            <div className="mb-3">                         
                                                <ButtonLink key={i} onClick={()=>handleSelect(i, -1)}  color={index===i && parentIndex === -1 && Theme1.orange } ><b>{item.label}</b></ButtonLink>
                                                <IconTrash link color="red" title="Eliminar" onClick={()=>handleDeleteItem(i,-1)}/>
                                            </div>
                                            {
                                                item.child !== undefined &&
                                                <ul>
                                                    {
                                                        item.child.map((e, key) => (
                                                            <li>
                                                            <ButtonLink color={index===key && parentIndex === i  && Theme1.orange } key={key} onClick={()=>handleSelect(key, i)} >{e.label}</ButtonLink> 
                                                            <IconTrash link color="red" title="Eliminar" onClick={()=>handleDeleteItem(key,i)} />
                                                            </li>
                                                            
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </FormGroup>
                                        )
                                    })
                            }
                        </Col>
                    </Row>
                    </CustomMenu>
                    
                </FormGroup>
            <div className="text-center mt-3">              
                {


                    portalMethods.portalForm === undefined &&
                    (
                        !isLoadingSave ? 
                        <Button onClick={handleConfigSave}>Guardar <FaSave className="ml-2"/></Button> :
                        <Button disabled><ButtonLoading text="Guardando..." /></Button>
                    )
                }
            </div>
        </Container>
    )
}
export default PortalConfig;