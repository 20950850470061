import PortalConfig from "../business/portals/PortalConfig";

function Form(){
    return (
        <div className="d-flex flex-wrap m-4">
            <div className="container-fluid mb-2">
                <div className="card">
                    <div className="card-header">
                        <b>Configuración del portal: {localStorage.getItem('portal')}</b>
                    </div>
                    <div className="card-body">
                        <PortalConfig idportal={localStorage.getItem('idportal')}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Form;