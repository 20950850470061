import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Utils from '../../utils'
import { Col, Container, Row } from 'react-bootstrap';
import ColorPicker from "../../components/ColorPicker";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import Graphic from "./Graphic";
import { APP_URL } from "../../config";
import { Loading } from "../../components/Loading";
import { styleTheme } from "../../styles/SelectTheme";
import { DivWrapper, StyleProperty } from '../style';
import { StyleCheckbox, Button } from '../../styles/CommonStyles';
import { AlertForm, ICON } from "../../utils/SweetAlert";
import { FaSave } from "react-icons/fa";
function Form() {
    const { id } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [loadedPre, setLoadedPre] = useState(false);
    const [msj, setMsj] = useState('');
    const [stages, setStages] = useState([]);
    const [configUsers, setConfigUsers] = useState({});

    const [form, setForm] = useState({
        indicator: {},
        stages: [],
        title: '',
        legend: false,
        height: 600
    })

    useEffect(() => {
        Utils.Petition.get(
            '/pages-modules/id/' + id,
            (response) => {
                if (Object.keys(response.data.config).length > 0) {
                    setForm({
                        ...form,
                        indicator: response.data.config.default.indicator,
                        stages: response.data.config.default.stages,
                        title: response.data.config.default.title,
                        hideLegend: response.data.config.default.hideLegend,
                        legendPosition: response.data.config.default.legendPosition,
                        height: response.data.config.default.height,
                        minYL: response.data.config.default.minYL,
                        yLeft: response.data.config.default.yLeft
                        
                    });
                    setConfigUsers(response.data.config.users);
                    setLoadedPre(true)
                }
                setLoaded(true)
            }
        );

        Utils.Petition.get(
            '/stages_relation/select',
            (response) => {
                setStages(response.data);
            }
        );

    }, []);

    useEffect(() => {
        let stages_temp = []

        if (form.stages.length === 0) {
            if (stages.length > 0) {
                stages_temp.push((stages.filter(stage => stage.label === 'Real'))[0])
                stages_temp.push((stages.filter(stage => stage.label === 'Presupuesto'))[0])
            }
            setForm({
                ...form,
                stages: stages_temp
            })
        }        
        
    }, [stages])

    useEffect(() => {
        const bootstrap = async () => {
            await setLoadedPre(false);

            if (Object.keys(form.indicator).length > 0) {
                await setLoadedPre(true);
            } else {
                await setMsj('Debe escoger un indicador')
            }
        };
        bootstrap();
        console.log('hola')
    }, [form])

    const handleForm = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        });
    }

    const loadIndicators = (inputValue) => {

        if (inputValue.length > 2) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    Utils.Petition.get(
                        '/indicators/name-code-indicator/' + inputValue,
                        (response) => {
                            resolve(response.data)
                        }
                    )
                }, 100);
            });
        }

        return new Promise((resolve) => resolve([]));
    };

    const selectIndicator = data => {

        setLoadedPre(false)
        setForm({
            ...form,
            indicator: data
        })
    };

    const handleStage = (event) => {
        let temp = [...form.stages];
        const type = (event.target.name.split('-'))[0]
        const index = (event.target.name.split('-'))[1]
        temp[index][type] = event.target.value

        setForm({
            ...form,
            stages: temp
        })
    };

    const selectStage = (data, index) => {

        setLoadedPre(false)
        
        let temp = [...form.stages];
        data.color = temp[index].color
        temp[index] = data;
        
        setForm({
            ...form,
            stages: temp
        })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            id: id,
            config: {
                default: form,
                users: configUsers
            }
        };

        Utils.Petition.post(
            '/pages-modules/update', data,
            (response) => {
                if (window.parent) {
                    AlertForm(ICON.SUCCESS, response.message);
                    //window.parent.postMessage('cerrar popup', APP_URL);
                }
            }
        );
    }

    const handleLegend = (event) => {
        let temp = false;
        if (event.target.checked) temp = true;

        setForm({
            ...form,
            hideLegend: temp
        });
    }

    console.log(form)
    return (
        <DivWrapper>
        <Container fluid >
            {
                !loaded && <Loading text="Cargando Configuración" />
            }
            {
                loaded &&
                <Row>
                    <Col sm={5}>
                        <h3 className="mb-3">Configuración</h3>
                        <form onSubmit={handleSubmit} className="mb-3">
                            <div className="form-group">
                                <Row>
                                    <Col><b>Título</b></Col>
                                    <Col>
                                        <input type="text" className="form-control" id="title" name="title" onChange={handleForm} value={form.title} />
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-group">
                                <Row>
                                    <Col><b>Alto de la gráfica</b></Col>
                                    <Col>
                                        <input type="number" className="form-control" id="height" name="height" onChange={handleForm} defaultValue={400} value={form.height} />
                                    </Col>
                                </Row>
                            </div>

                            <div className="form-group">
                                <Row>
                                    <Col><b>Posición de la leyenda</b></Col>
                                    <Col>
                                        <select disabled={form.hideLegend} className="form-control" id="legendPosition" name="legendPosition" value={form.legendPosition} onChange={handleForm}>
                                            <option key="0" value="PorDefecto">Seleccionar ...</option>
                                            <option key="1" value="top">Arriba</option>
                                            <option key="2" value="bottom">Abajo</option>
                                            <option key="3" value="left">Izquierda</option>
                                            <option key="4" value="right">Derecha</option>
                                        </select>
                                    </Col>
                                </Row>
                            </div>

                            <div className="form-group">
                                <Row>
                                    <Col><b>Signo eje</b></Col>
                                    <Col>
                                        <select className="form-control" id="yLeft" name="yLeft" value={form.yLeft} onChange={handleForm}>
                                            <option key="0" value="PorDefecto">Seleccionar ...</option>
                                            <option key="1" value="%">Porcentaje %</option>
                                            <option key="2" value="$">Pesos/USD $</option>
                                        </select>
                                    </Col>
                                </Row>
                            </div>

                            <div className="form-group">
                                <Row>
                                    <Col><b>Mínimo Eje Y</b></Col>
                                    <Col>
                                        <input type="number" className="form-control" id="minYL" name="minYL" onChange={handleForm} value={form.minYL} />
                                    </Col>
                                </Row>
                            </div>

                            <div className="form-group ml-4 mb-3">
                                <StyleCheckbox className="custom-checkbox">
                                    <input className="custom-control-input" type="checkbox" checked={form.hideLegend} name="hideLegend" id="hideLegend" onChange={handleLegend} />
                                    <label className="custom-control-label" htmlFor="hideLegend">
                                        Ocultar leyenda
                                    </label>
                                </StyleCheckbox>
                            </div>

                            <div className="form-group my-3">
                                <Row>
                                    <Col><b>Indicador</b></Col>
                                    <Col>
                                        <AsyncSelect
                                            value={form.indicator}
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={loadIndicators}
                                            onChange={selectIndicator}
                                            theme={theme => (styleTheme(theme))}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col><b>Escenario 1</b></Col>
                                    <Col>
                                        <Select
                                            value={form.stages[0]}
                                            cacheOptions
                                            defaultOptions
                                            options={stages}
                                            onChange={e => selectStage(e, 0)}
                                            theme={theme => (styleTheme(theme))}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col><b>Escenario 2</b></Col>
                                    <Col>
                                        <Select
                                            value={form.stages[1]}
                                            cacheOptions
                                            defaultOptions
                                            options={stages}
                                            onChange={e => selectStage(e, 1)}
                                            theme={theme => (styleTheme(theme))}
                                        />
                                    </Col>
                                </Row>    
                                {
                                    form.stages.length > 0 &&
                                    form.stages.map((stage, index) => {
                                        return (
                                            <StyleProperty>
                                                <h4>{"Propiedades " + stage.label}</h4>
                                                <div className="form-group">
                                                    <Row>
                                                        <Col><b>Label del Escenario</b></Col>
                                                        <Col>
                                                            <input type="text" className="form-control" id={"labelStage-" + index} name={"labelStage-" + index} onChange={handleStage} value={stage.labelStage ? stage.labelStage : ""} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="form-group my-1">
                                                    <Row>
                                                        <Col><b>Color del Indicador</b></Col>
                                                        <Col>
                                                            <ColorPicker name={"colorStageUser-" + index} value={stage.color} form={form} setForm={setForm} />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="form-group">
                                                    <Row>
                                                        <Col><b>Tipo de BarChart</b></Col>
                                                        <Col>
                                                            <select className="form-control" id={"type-" + index} name={"type-" + index} value={stage.type ? stage.type : "PorDefecto"} onChange={handleStage}>
                                                                <option key="0" value="PorDefecto">Seleccionar ...</option>
                                                                <option key="1" value="bar">Columna</option>
                                                                <option key="2" value="line">Línea</option>
                                                            </select>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </StyleProperty>
                                        )  
                                    })
                                }
                            </div>
                            <Button type="submit" disabled={!loadedPre}>Guardar <FaSave /></Button>
                        </form>
                    </Col>
                    <Col style={{maxHeight: 500, overflow: 'auto'}} sm={7}>
                        <div className="fixed">
                            <h3 className="mb-3">Previsualización</h3>
                            {
                                loadedPre &&
                                <Graphic config={JSON.parse(JSON.stringify(form))} />
                            }
                            {
                                !loadedPre && msj !== '' &&
                                <div className="container alert alert-danger my-2 mx-0 my-2 text-center" role="alert">
                                    {msj}
                                </div>
                            }
                        </div>
                        
                    </Col>
                </Row>
            }
        </Container>
        </DivWrapper>
    )
}

export default Form;