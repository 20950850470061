import { useState, useEffect } from 'react';
import Utils from "../../utils";
import { useParams } from "react-router";
import CustomModal from "../../components/CustomModal"
import Calculator from '../../components/calculator/Calculator';
import { Container, Button, ButtonMin } from '../../styles/CommonStyles';
import { StyleInfo, StyleData, TableStyle } from './styles';
import { AlertConfirm, AlertForm, ICON } from '../../utils/SweetAlert';
import { Table } from 'react-bootstrap';
import { useConfigRole } from '../../Hooks/configRole';


function Indic() {
    let { id } = useParams();
    localStorage.setItem('id', id);

    const [values, setValues] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [showCalcModal, setShowCalcModal] = useState(false)
    const [showObservModal, setShowObservModal] = useState(false)
    const [observaciones, setObservaciones] = useState('')
    const [indicador, setIndicador] = useState(null)
    const [idStge, setIdStge] = useState(null)
    const [table, setTable] = useState([])
    const [years, setYears] = useState([])
    const [stages, setStages] = useState([])
    const [auxValue, setAuxValue] = useState('')
    const [year, setYear] = useState(new Date().getFullYear());
    const { configRoleByIdModule } = useConfigRole(2);

    useEffect(() => {
        let currentYear = new Date().getFullYear()
        let _years = [];
        for (let i = currentYear - 5; i < currentYear + 2; i++) {
            _years.push(i)
        }
        setYears(_years)

        Utils.Petition.get(`/indicators/findAllRelation/id/${id}`, function (response) {
            setIndicador(response.data)
        });

        Utils.Petition.get(`/stages/stages`, function (response) {
            setStages(response.data)
        });
    }, [])

    useEffect(() => {
        Utils.Petition.get(`/indicator-value/idindicator/${id}/year/${year}`, function (response) {
            setTable(response.data[0])
        });
    }, [year])

    const onChangeInputIndicator = (event) => {
        let { value } = event.target
        // setDataDefaultChange(true)
        let newData = [...table]
        const { montht, aux } = event.target.dataset
        newData[aux][montht] = value
        newData !== table && setTable(newData)
    }

    const onBlurChangeData = (event) => {
        let { value } = event.target
        const { stage, monthn, montht, aux, type } = event.target.dataset
        let valuesAux = { value, stage, monthn, montht, aux, indicador: indicador.id, year, type, observaciones }
        setAuxValue(values.value)
        if (value !== values.value) {
            setShowObservModal(true)
            setValues(valuesAux)
        }
    }

    const _focus = (event) => {
        let { value } = event.target
        const { stage, monthn, montht, aux, type } = event.target.dataset
        setValues({ value, stage, monthn, montht, aux, idindicator: indicador.id, year, type })
    }

    const handleCustomCalc = (event) => {
        setIdStge(event.target.dataset.stage)
        setShowCalcModal(true)
    }

    const handleObservModal = () => {
        let auxValues = { ...values, observaciones }
        //consultar si existe un registro 
        Utils.Petition.post('/indicator-value/values', auxValues, function (response) {
            if(response.exist !== undefined){
                AlertConfirm(ICON.QUESTION,response.message,'¿Desea cancelar la solicitud antigua y mandar una nueva?',()=>confirmNewRequest(auxValues),'Si',()=>{setObservaciones('');setShowObservModal(false);})
                setObservaciones('')
                setShowObservModal(false)
            }else{
                AlertForm(ICON.SUCCESS, response.message, '', 'center', 6000, true);
                setObservaciones('')
                setShowObservModal(false)
            }
        })      
    }

    const hableObservClose = () => {
        let newData = [...table]
        const { montht, aux } = values
        newData[aux][montht] = auxValue
        newData !== table && setTable(newData)
        setValues({ ...values, value: auxValue })
        console.log(values)
    }

    const handleAfterSave = () => {
        setShowCalcModal(false)
        Utils.Petition.get(`/indicator-value/idindicator/${id}/year/${year}`, function (response) {
            setTable(response.data[0])
        })
    }

    const confirmNewRequest = (values) => {
        values.newRequest = true;
        Utils.Petition.post('/indicator-value/values', values, function (response) {
            AlertForm(ICON.SUCCESS, response.message, '', 'center', 6000, true);
            setObservaciones('')
            setShowObservModal(false)
            
        })
    } 
    const validateInput = (r,j) => {
        let validateResponsible = Number(localStorage.getItem("iduser")) === indicador.responsable;
        return  (configRoleByIdModule.create_update_any || (configRoleByIdModule.create_update && validateResponsible))  && !(table[r].formula !== '' && (stages[j].formula !== null || (table[r].formula !== null && table[r].formula !== '')))
    }

    if (indicador === null && table === '') {
        return (
            <div>Cargando...</div>
        )
    } else {
        return (
            <Container>
                <div className="mb-2">
                    <div className="row">
                        <div className="col">
                            <h3>Atributos</h3>
                        </div>
                        <div className="col col-lg-2">
                            <Button onClick={() => setShowModal(true)}>Configuración</Button>
                        </div>
                    </div>
                </div>
                <StyleInfo>
                    <div className="row">
                        <div className="col-sm">
                            {indicador !== null && <p><span className='font-weight-bold'>Nombre:</span> {indicador.name} </p>}
                        </div>
                        <div className="col-sm">
                            {indicador !== null && <p><span className='font-weight-bold'> Código:</span> {indicador.code}</p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            {indicador !== null && <p><span className='font-weight-bold'>Región:</span> {indicador.filter.name}</p>}
                        </div>
                        <div className="col-sm">
                            {indicador !== null && <p><span className='font-weight-bold'>Responsable:</span> {indicador.user.full_name} </p>}
                        </div>
                    </div>
                </StyleInfo>
                <StyleData>
                    <div className="btn-group">
                        {years.map(e =>
                            year === e
                                ? <button type="button" key={e} className="btn btn-success"> {e} </button>
                                : <button type="button" key={e} onClick={() => setYear(e)} className="btn btn-secondary"> {e} </button>
                        )}
                    </div><p />
                    <div className='table-responsive'>
                        <TableStyle className="table table-striped table-bordered" >
                            <thead className="bg-dark text-white">
                                <tr>
                                    <th className='small bg-dark' scope="col"> Mes</th>
                                    {table.map(e => {
                                        return <th className='small' key={e.name} scope="col"><b>{e.name}</b></th>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {table[0] != null && Object.keys(table[0]).map((e, i) => {
                                    if (e !== 'name' && e !== 'formula') {
                                        return (
                                            <tr key={e} >
                                                <th className='small' key={e} scope="row">{e}</th>
                                                {Object.keys(table).map((r, j) => {
                                                    return (
                                                        <td key={`${e}-${r}`} style={{ padding: 6, margin: 6, backgroundColor: 'white' }} >
                                                            {stages !== null && <input type={stages[j].type.toLowerCase()}
                                                                className='form-control'
                                                                style={{ minWidth: '6em', border: 'none' }}
                                                                onChange={onChangeInputIndicator}
                                                                onBlur={onBlurChangeData}
                                                                onFocus={_focus}
                                                                
                                                                data-stage={stages[j].id}
                                                                data-monthn={i}
                                                                data-montht={e}
                                                                data-aux={j}
                                                                data-type={stages[j].type}
                                                                value={table[r][e] || ''}
                                                                disabled={!validateInput(r,j)}
                                                            />}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </TableStyle>
                    </div>
                </StyleData>
                <CustomModal title={"Escenarios"} show={showModal} setShow={setShowModal}>
                    <div className="container-fluid">
                        <Table striped>
                            <tbody>
                                {stages.map((e) => {
                                    return (
                                        <tr key={e.id}>
                                            {/* <th className='small' scope="row"> */}
                                            <td style={{ padding: 6, margin: 6 }}><b>{e.name}</b></td>
                                            {/* </th> */}
                                            {/* <th className='small' scope="row"> */}
                                            <td style={{ padding: 6, margin: 6 }}>
                                                {e.type === 'Number' && <ButtonMin style={{ marginLeft: 120 }} data-stage={e.id} onClick={handleCustomCalc}>calculadora</ButtonMin>}
                                            </td>
                                            {/* </th> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </CustomModal>
                <CustomModal title={"Calculadora para la formula"} show={showCalcModal} size="lg" setShow={setShowCalcModal} hideFooter >
                    {showCalcModal === true && <Calculator type="indicator" idstage={idStge} idindicator={indicador.id} handleAfterSave={handleAfterSave} />}
                </CustomModal>
                <CustomModal title={"Agregar observaciones para la modificación del indicador"}
                    show={showObservModal} setShow={setShowObservModal}
                    handleSaveButton={handleObservModal} handleClose={hableObservClose}
                >
                    <div>
                        <p>Observaciones:</p>
                        <input type='text' className='form-control' onChange={(e) => setObservaciones(e.target.value)} value={observaciones} /><br />
                    </div>
                </CustomModal>
            </Container>
        );
    }
}

export default Indic;