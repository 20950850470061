import { useEffect, useState } from "react";
import Utils from "../../utils";
import { StyleExpression } from "./styles";

function Expresion({expresion}){
    const [indicator, setIndicator] = useState('');
    const [stage,setStage] = useState('');
    const [acumulate, setAcumulate] = useState(false);
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');

    const data = expresion.split(',');

    useEffect(()=>{

        if(data[0].includes('@I-')){
           
            Utils.Petition.get('/indicators/id/'+data[0].split('-')[1], (response)=>{
                if(response.data){
                    setIndicator(response.data.name);
                }else{
                    setIndicator('[Desconocido]');
                }
            })

        }
        let idstage = data[1];
        if(parseInt(idstage)<0){
            setAcumulate(true);
            idstage *= -1;
        }
        Utils.Petition.get('/stages/id/'+idstage, (response)=>{
            if(response.data){
                setStage(response.data.name);
            }else{
                setStage('[Desconocido]');
            }
        });
        if(data[2].includes('a-')){
            let operatorYear = data[2].split('-')[1];
            if(operatorYear==='1'){
                setYear('Año pasado');
            }else{
                setYear(`Hace ${operatorYear} años`);
            }
        }
        if(data[3].includes('m-')){
            let operatorMonth = data[3].split('-')[1];
            if(operatorMonth==='1'){
                setMonth('Mes pasado');
            }else{
                setMonth(`Hace ${operatorMonth} meses`);
            }
        }

        
    }, []);
    return (
            <StyleExpression>
                {acumulate && <div >Indicador acumulado</div>}
                {indicator && <div >{indicator}</div>}
                {stage &&<div >{stage}</div>}
                {year && <div >{year}</div>}
                {month && <div >{month}</div>}
            </StyleExpression>
    );
}

export default Expresion;