import React, { useState, useEffect } from "react";
import { Col, Container, Row, Table } from 'react-bootstrap';
import Utils from "../../utils";
import Sign from "./Sign";
import { TableWrapper } from "./styles";

function CreateTable({dataConfig, data, idstages}) {

    const [tableData, setTableData] = useState([]);
    const [headersTable, setHeadersTable] = useState([])
    const format_number = Utils.getConfigPortal('format_number');
  const format_percent = Utils.getConfigPortal('format_percent');
    
    useEffect( () => {
        let htmlTr = [];
        let headers = [];
         
        if( dataConfig.reverse ) {

            headers.push(dataConfig.title_column !== "" ? <th key={0} scope="col" style={ dataConfig.widthFirstCol && dataConfig.widthFirstCol > 0 ? {width:`${dataConfig.widthFirstCol}px`} : {width:''} }>{dataConfig.title_column}</th>: <th key={0} scope="col" style={dataConfig.widthFirstCol && dataConfig.widthFirstCol > 0 ? {width:`${dataConfig.widthFirstCol}px`} : {width:''}}>Escenarios</th>)

            headers.push(dataConfig.indicators.map((ind, index) => {
                let color_ind = "";
                if( ind.url || ind.popup) {
                    color_ind = 'text-primary';
                }
                if( ind.hasOwnProperty('labelIndicator') && ind.labelIndicator.trim() !== "") {
                    return <th key={index + 1} className={color_ind} scope="col">{ind.labelIndicator}</th>;
                } else {
                    return <th key={index + 1} className={color_ind} scope="col">{ind.name}</th>;
                }
            }));
            
            idstages.map((idstage, index) => {
                let month = parseInt(localStorage.getItem('month')) + 1;
                let year = parseInt(localStorage.getItem('year'));
                if( dataConfig.stages[index].lastYear ) {
                    year--;
                }   
                if( dataConfig.stages[index].lastDec ) {
                    year--;
                    month = 12;
                }
                let info = '';
                let stage_label = dataConfig.stages[index].label;
                
                if( dataConfig.stages[index].hasOwnProperty('labelStage') && dataConfig.stages[index].labelStage.trim() !== "") stage_label = dataConfig.stages[index].labelStage;
                if( dataConfig.stages[index].spaceAbove ) {
                    htmlTr.push(
                        <tr className="tr-empty">
                            {
                                dataConfig.stages[index].hasOwnProperty('labelSpaceAbove') && dataConfig.stages[index].labelSpaceAbove !== "" ? 
                                <th scope="row">{dataConfig.stages[index].labelSpaceAbove}</th>
                                :
                                <th scope="row">  </th>
                            }
                            {
                                dataConfig.indicators.map( (indicator, indexI) => {
                                    return <td key={indexI}></td>
                                })
                            }
                            
                        </tr>
                    )
                };
                
                htmlTr.push(
                    <tr key={index}>
                        <th scope="row">{stage_label}</th>
                        {
                            dataConfig.indicators.map( (indicator, indexI) => {
                                if (dataConfig.stages[index].othersInd) {
                                    if( dataConfig.stages[index].lessInd ) {
                                        if( indexI + 1 >= parseInt(dataConfig.stages[index].posLessInd) && indexI + 1 < parseInt(dataConfig.stages[index].posLessInd) + parseInt(dataConfig.stages[index].amountLessInd)) {
                                            info = data.filter( aux => dataConfig.stages[index].newIndicators[indexI - parseInt(dataConfig.stages[index].posLessInd) + 1].id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                                            
                                        } else {
                                            return <td key={indexI}></td>
                                        }
                                    } else {
                                        info = data.filter( aux => dataConfig.stages[index].newIndicators[indexI].id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                                        
                                    }   
                                } else {
                                    info = data.filter( aux => indicator.id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                                }
                                
                                let value = 0;
                                if (info.value !== null) {
                                    value  = Utils.formatNumber(format_number, info.value);
                                }
                                if (indicator.percent || idstage.percent) {
                                    value = info.value !== null ? Utils.formatNumber(format_percent, info.value) + '%' : 0.0 + '%';
                                }
                                if( idstage.semaphore === true ) {
                                    if( dataConfig.stages[index].othersInd ) {
                                        if(dataConfig.stages[index].lessInd ) {
                                            if( indexI + 1 >= parseInt(dataConfig.stages[index].posLessInd) && indexI + 1 < parseInt(dataConfig.stages[index].posLessInd) + parseInt(dataConfig.stages[index].amountLessInd)) {
                                                return <td key={indexI}><Sign type={dataConfig.stages[index].newIndicators[indexI - parseInt(dataConfig.stages[index].posLessInd) + 1].type} value={info.value} base={dataConfig.stages[index].newIndicators[indexI - parseInt(dataConfig.stages[index].posLessInd) + 1].unit === "%" ? 0 : 100} isDescendingIndicator={!dataConfig.disableDesendingSign} /><span> {value}</span></td>
                                            }
                                        } else {
                                            return <td key={indexI}><Sign type={dataConfig.stages[index].newIndicators[indexI].type} value={info.value} base={dataConfig.stages[index].newIndicators[indexI].unit === "%" ? 0 : 100} isDescendingIndicator={!dataConfig.disableDesendingSign}/><span> {value}</span></td>
                                        }
                                    } else {
                                        return <td key={indexI}><Sign type={indicator.type} value={info.value} base={indicator.unit === "%" ? 0 : 100} isDescendingIndicator={!dataConfig.disableDesendingSign}/><span> {value}</span></td>
                                    }   
                                } else {
                                    return <td key={indexI}>{value}</td>
                                }
                            })
                        }
                    </tr>
                );
                
                if( dataConfig.stages[index].spaceBelow ) {
                    htmlTr.push(
                        <tr className="tr-empty">
                            {
                                dataConfig.stages[index].hasOwnProperty('labelSpaceBelow') && dataConfig.stages[index].labelSpaceBelow !== "" ? 
                                <th scope="row">{dataConfig.stages[index].labelSpaceBelow}</th>
                                :
                                <th scope="row">  </th>
                            }
                            {
                                dataConfig.indicators.map( (indicator, indexI) => {
                                    return <td key={indexI}></td>
                                })
                            }
                            
                        </tr>
                    )
                };

            })

        } else {
            
            headers.push(dataConfig.title_column !== "" ? <th key={0} scope="col" style={dataConfig.widthFirstCol && dataConfig.widthFirstCol > 0 ? {width:`${dataConfig.widthFirstCol}px`} : {width:''}}>{dataConfig.title_column}</th>: <th key={0} style={dataConfig.widthFirstCol && dataConfig.widthFirstCol > 0 ? {width:`${dataConfig.widthFirstCol}px`} : {width:''}} scope="col">Indicadores</th>)

            headers.push(dataConfig.stages.map((stage, index) => {
                if( stage.hasOwnProperty('labelStage') && stage.labelStage.trim() !== "") {
                    return <th key={index + 1} scope="col">{stage.labelStage}</th>;
                } else {
                    return <th key={index + 1} scope="col">{stage.label}</th>;
                }
            }));

            dataConfig.indicators.map( (indicator, index) => {
                let info = '';
                let color_ind = '';
                if( indicator.url || indicator.popup) {
                    color_ind = 'text-primary'
                }
                let ind_label = indicator.name;
                if( indicator.hasOwnProperty('labelIndicator') && indicator.labelIndicator.trim() !== "") ind_label = indicator.labelIndicator;

                if( indicator.spaceAbove ) {
                    htmlTr.push(
                        <tr className="tr-empty">
                            {
                                indicator.hasOwnProperty('labelSpaceAbove') && indicator.labelSpaceAbove !== "" ? 
                                <th scope="row">{indicator.labelSpaceAbove}</th>
                                :
                                <th scope="row">  </th>
                            }
                            {
                                idstages.map( (idstage, indexS) => {        
                                    return <td key={indexS}></td>
                                })
                            }
                            
                        </tr>
                    )
                };
                htmlTr.push(
                    <tr key={index} className={indicator.highlight ? "table-secondary": ""}>                    
                        <th className={color_ind} scope="row">{ind_label}</th>
                        {
                            idstages.map( (idstage, indexS) => {
                                let month = parseInt(localStorage.getItem('month')) + 1;
                                let year = parseInt(localStorage.getItem('year'));
                                if( dataConfig.stages[indexS].lastYear ) {
                                    year--;
                                }   
                                if( dataConfig.stages[indexS].lastDec ) {
                                    year--;
                                    month = 12;
                                }
                                let value = 0;
                                if( dataConfig.stages[indexS].othersInd ) {
                                    if(  dataConfig.stages[indexS].lessInd ) {
                                        if( index + 1 >= parseInt(dataConfig.stages[indexS].posLessInd) && index + 1 < parseInt(dataConfig.stages[indexS].posLessInd) + parseInt(dataConfig.stages[indexS].amountLessInd)) {
                                            info = data.filter( aux => dataConfig.stages[indexS].newIndicators[index - parseInt(dataConfig.stages[indexS].posLessInd) + 1].id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                                        } else {
                                            return <td key={indexS}></td>
                                        }
                                    } else {
                                        info = data.filter( aux => dataConfig.stages[indexS].newIndicators[index].id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                                    }   
                                } else {
                                    info = data.filter( aux => indicator.id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                                }
                                
                                if (info.value !== null) {
                                    value  = Utils.formatNumber(format_number, info.value);
                                }
                                if (indicator.percent || idstage.percent) {
                                    value = info.value !== null ? Utils.formatNumber(format_percent, info.value) + '%' : 0.0 + '%';
                                }

                                if( idstage.semaphore === true ) {
                                    if( dataConfig.stages[indexS].othersInd ) {
                                        if(  dataConfig.stages[indexS].lessInd ) {
                                            if( index + 1 >= parseInt(dataConfig.stages[indexS].posLessInd) && index + 1 < parseInt(dataConfig.stages[indexS].posLessInd) + parseInt(dataConfig.stages[indexS].amountLessInd)) {
                                                return <td key={indexS}><Sign type={dataConfig.stages[indexS].newIndicators[index - parseInt(dataConfig.stages[indexS].posLessInd) + 1].type} value={info.value} base={dataConfig.stages[indexS].newIndicators[index - parseInt(dataConfig.stages[indexS].posLessInd) + 1].unit === "%" ? 0 : 100} isDescendingIndicator={!dataConfig.disableDesendingSign}/><span> {value}</span></td>
                                            }
                                        } else {
                                            return <td key={indexS}><Sign type={dataConfig.stages[indexS].newIndicators[index].type} value={info.value} base={dataConfig.stages[indexS].newIndicators[index].unit === "%" ? 0 : 100} isDescendingIndicator={!dataConfig.disableDesendingSign}/><span> {value}</span></td>
                                        }
                                    } else {
                                        return <td key={indexS}><Sign type={indicator.type} value={info.value} base={indicator.unit === "%" ? 0 : 100} isDescendingIndicator={!dataConfig.disableDesendingSign}/><span> {value}</span></td>
                                    }
                                } else {
                                    return <td key={indexS}>{value}</td>
                                }

                            })
                        }
                    </tr>
                );
                if( indicator.spaceBelow ) {
                    htmlTr.push(
                        <tr className="tr-empty">
                            {
                                indicator.hasOwnProperty('labelSpaceBelow') && indicator.labelSpaceBelow !== "" ? 
                                <th scope="row">{indicator.labelSpaceBelow}</th>
                                :
                                <th scope="row">  </th>
                            }
                            {
                                idstages.map( (idstage, indexS) => {        
                                    return <td key={indexS}></td>
                                })
                            }
                            
                        </tr>
                    )
                };
            });
        }
            
        setHeadersTable(headers)
        setTableData(htmlTr)
    }, [])

    return(
        <div className="text-center">
            <TableWrapper>
            <Table style={{marginTop:`${dataConfig.marginTop !== undefined && dataConfig.marginTop !== "" ? dataConfig.marginTop : 0 }px`}} className={dataConfig.otherStyle ? "table-sec" : ""}>
                <thead>
                    <tr>
                        {headersTable}
                    </tr>
                </thead>
                <tbody>
                    {tableData}
                </tbody>
            </Table>
            </TableWrapper>
            
        </div>
    )
}

export default CreateTable