import styled from 'styled-components';
import { Container as Cont } from 'react-bootstrap';
import { Theme1 } from './Theme1';

export const Container = styled.div`
padding: ${(props => props.padding ? props.padding : '30px 0')};
width: ${(props => props.width ? props.width : '100%')};
min-height: calc(100vh - 133px);
margin: auto;
& > h2, & > h3 {
    text-align: center;
    margin-bottom: 1.5rem;
}
`;
export const ContainerForm = styled(Container)`
    min-height: 0;
`;

export const Svg = styled.svg`
    margin-right: 0.5rem;
    cursor:  ${(props => props.link ? 'pointer' : 'default')};
    & .a {
        fill:none;
    }
    & .b {
        fill: ${(props => props.color ? props.color : 'var( --c-icon)')};
    }
    &:hover {
        opacity: 0.6;
        & .b {
            fill: ${(props => props.hover)};
        }
    }
`;

export const SvgStroke = styled.svg`
    margin-right: 0.5rem;
    cursor:  ${(props => props.link ? 'pointer' : 'default')};
    & .a {
        fill:#555;
        stroke:#555;
        stroke-width:0.5px;
    }
    & .b {
        clip-path:url(#a);
    }
    &:hover {
        opacity: 0.6;
    }
`;

export const Header = styled.header`
    height: 83px;
    background-color: var(--c-headerAdmin);
`;

export const Footer = styled.footer`
    height: 50px;
    background-color: var(--c-headerAdmin);
    color: var(--c-white);
    padding: 0.6rem;

    & .text-footer {
        text-align: center;
    }
`;

export const ContainerNavbar = styled(Cont)`
    height: 100%;
    & > a {
        font-size: 30px;
        font-weight: 600;
    }

    & > div {
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0px;
    }
    & img {
        width: 26px;
        margin-right: 0.8rem;
    }

    & .icon {
        font-size: 1.3rem;
    }
`;
export const StyleCheckbox = styled.div`
    
/*** CHECK AND RADIO INPUT CUSTOM **/
& .custom-control-input:checked ~ .custom-control-label::before {
  color: var(--c-white) !important;
  border: var(--c-orange) !important;
  background-color: var(--c-orange) !important;
}

& .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem var(--c-orange-opacity);
}
& .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}
& .custom-control-input:active ~ .custom-control-label::before {
  background-color: var(--c-lightorange); 
}

& label {
    font-weight: bold;
}
`; 

export const Button = styled.button`
    color: var( --c-white);
    background-color: ${props => props.color ? props.color : Theme1.orange};
    border-radius: 0.5rem;
    padding: 0.5rem 1.2rem;
    font-weight: 600;
    border: 2px solid  ${props => props.color ? props.color : Theme1.orange};
    font-size: 12px;
    color:white;
    
    padding: 0.375rem 0.75rem;
    border-radius: 4px;
    margin-right: 5px;
    &:hover{
        color: ${props => props.color ? props.color : Theme1.orange};
        background-color: white !important;
    }

    &:disabled {
        color: ${props => props.color ? props.color : Theme1.orange};
        cursor: not-allowed;
        color: ${props => props.color ? props.color : Theme1.orange};
        background-color: var(--c-white);
    }
`;

export const ButtonMin = styled(Button)`
    padding: 0.2rem 0.9rem;
    font-weight: 400;
    border: 1px solid ${props => props.color ? props.color : 'var(--c-orange)'};
`;

export const ButtonLink = styled.span`
    background-color: ${props => props.color ?  `${props.color}10`: "transparent"};
    padding: 0.2rem 0.4rem;
    border: 2px solid  ${props => props.color ?  props.color: "transparent"};
    border-radius: 0.2rem;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0 rgb(0 0 0 / 20%);
    &:hover {
        text-decoration: underline;
        color: ${Theme1.orange};
    }
`;

export const ButtonLinkAccordeon = styled(ButtonLink)`
    color: ${Theme1.orange};
    box-shadow:none;
    font-weight: bold;
`;

export const StyleSelect = styled.select`
    margin: ${(props => props.margin ? props.margin : 0)};
    border: 2px solid var(--c-orange);
    border-radius: 0.4rem;
    padding: 0.65rem 0.8rem;
    color: var(--c-black);

    &::placeholder {
        color: var(--c-darkgray);
    }
`;

export const StyleInput = styled.input`
    margin: ${(props => props.margin ? props.margin : 0)};
    border: 2px solid var(--c-orange);
    border-radius: 0.4rem;
    padding: 0.5rem 0.8rem;
    color: var(--c-black);
    &::placeholder {
        color: var(--c-darkgray);
    }
`;

export const CardCustom = styled.div`
    
    border: 2px solid orange;
    padding: 1rem;
    margin-bottom: 1.6rem;
    cursor: pointer;
    border-radius: 0.4rem;
    height: 12rem;
    overflow: auto;

    &:hover {
        background-color: var(--c-lightorange-opacity);
    }

    & a {
        display: flex;
        color: black;
        text-decoration: none;
        height: 100%;
    }
    & img {
        width: 4rem;
    }

    & p.card-text {
        font-size: 0.8rem;
    }
`;


