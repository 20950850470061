import { Form } from "react-bootstrap";
import styled from "styled-components";

export const CustomFormStyles = styled(Form)`
    & .form-group > label {
        font-weight: bold;
    }

    & .custom-file-label::after {
        background-color: var(--c-orange);
        color: white;
        font-weight: bold;
    }

    & .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Seleccionar";
}
`;