import React from "react";
import { useState } from "react";
import { Row, Col, Form, FormControl } from 'react-bootstrap';
import { FaAngleUp, FaAngleDown, FaEdit, FaSave, FaTrash, FaUndo } from "react-icons/fa";
import { IconDown } from "../../components/iconos/IconDown";
import { IconEdit } from "../../components/iconos/IconEdit";
import { IconSave } from "../../components/iconos/IconSave";
import { IconTrash } from "../../components/iconos/IconTrash";
import { IconUndo } from "../../components/iconos/IconUndo";
import { IconUp } from "../../components/iconos/IconUp";
import { Button, StyleCheckbox } from "../../styles/CommonStyles";
import { AlertConfirm, AlertForm, ICON } from "../../utils/SweetAlert";
import Indicators from "./Indicators";
import { AddFormStyle, DivActions } from "./style";

function Objectives({ form, setForm, indicatorData, indexPerspective, setIndicatorData }) {
    const [showAddForm, setShowAddForm] = useState(false);
    const [formObjective, setFormObjective] = useState({ objective: '' })
    const [editIndex, setEditIndex] = useState(false);

    const handleChangeForm = (event) => {
        const name = event.target.name.split('-')[0];
        setFormObjective({ ...formObjective, [name]: event.target.value })
    }

    const handleSave = () => {
        let _form = { ...form };
        _form.data[indexPerspective].data[editIndex].name = formObjective.objective;
        setForm(_form);
        setEditIndex(-1);
    }
    const handleEdit = (index) => {
        setShowAddForm(false);
        setEditIndex(index);
        setFormObjective({ objective: form.data[indexPerspective].data[index].name });
    }

    const handleShowAddForm = () => {
        setEditIndex(-1);
        setShowAddForm(!showAddForm);
        setFormObjective({ objective: '' });

    }
    const handleBack = () => {
        setEditIndex(-1);
    }
    const deleteConfirm = (index) => {
        let _form = { ...form };
        _form.data[indexPerspective].data.splice(index, 1);
        setForm(_form);
    }

    const handleDelete = (index) => {
        AlertConfirm(ICON.WARNING, '¿Seguro que desea eliminar esta perspectiva?', "",
        () => deleteConfirm(index));
    }

    const handleAdd = (event) => {
        event.preventDefault();
        if (formObjective.objective === '') {
            AlertForm(ICON.WARNING, 'Ingrese un nombre por favor');
        } else {
            let _form = { ...form };
            _form.data[indexPerspective].data.push({ name: formObjective.objective, data: [] });
            setForm(_form);
            setFormObjective({ objective: '' })
        }
    }

    const handleOrderUp = (index) => {
        let _form = JSON.parse(JSON.stringify(form));
        _form.data[indexPerspective].data[index - 1] = _form.data[indexPerspective].data[index];
        _form.data[indexPerspective].data[index] = form.data[indexPerspective].data[index - 1];
        setForm(_form);
    }
    const handleOrderDown = (index) => {
        let _form = JSON.parse(JSON.stringify(form));
        _form.data[indexPerspective].data[index + 1] = _form.data[indexPerspective].data[index];
        _form.data[indexPerspective].data[index] = form.data[indexPerspective].data[index + 1];
        setForm(_form);
    }
    const handleObjectiveActions = (event) => {
        const name = event.target.name.split('-');
        let _form = JSON.parse(JSON.stringify(form));
        _form.data[indexPerspective].data[name[3]][name[1]] = event.target.checked;
        setForm(_form);
    }


    return (
        <>
            <Button onClick={handleShowAddForm} className="mb-3">Nuevo objetivo</Button>
            <AddFormStyle>
                {
                    showAddForm &&
                    <Form className="p-3">
                        <Button className="close" onClick={() => { setShowAddForm(false) }}>×</Button>
                        <Form.Group>
                            <Form.Label>
                                <b>Nombre</b>
                            </Form.Label>
                            <FormControl type="text" onChange={handleChangeForm} value={formObjective.objective} id="objective" name="objective" />
                        </Form.Group>
                        <Form.Group>
                            <Button onClick={handleAdd}>Agregar</Button>
                        </Form.Group>
                    </Form>
                }
            </AddFormStyle>
            <div className="container-group">
                <Row className="justify-content-md-center m-0">
                    {
                        form.data[indexPerspective].data.map((objective, index) => {
                            return (
                                <React.Fragment key={index}>

                                    <Col sm={4} className={((objective.transversal || objective.unique) && 'col-sm-12') + ' p-3'}>
                                        <Row>
                                            <Col className={(!objective.transversal && objective.unique && 'col-sm-4 offset-md-4')}>
                                                <StyleCheckbox className="custom-checkbox ml-4">
                                                    <input className="custom-control-input" type="checkbox" name={"objective-unique-" +indexPerspective+'-'+ index} id={"objective-unique-" +indexPerspective+'-'+ index} onChange={handleObjectiveActions} checked={objective.unique} />
                                                    <label className="custom-control-label" htmlFor={"objective-unique-" +indexPerspective+'-'+ index}>
                                                        Mostrar como elemento único superior
                                                    </label>
                                                </StyleCheckbox>
                                                <StyleCheckbox className="custom-checkbox ml-4 mb-4">
                                                    <input className="custom-control-input" type="checkbox" name={"objective-transversal-" +indexPerspective+'-'+ index} id={"objective-transversal-" +indexPerspective+'-'+ index} onChange={handleObjectiveActions} checked={objective.transversal} />
                                                    <label className="custom-control-label" htmlFor={"objective-transversal-" +indexPerspective+'-'+ index}>
                                                        Mostrar como transversal
                                                    </label>
                                                </StyleCheckbox>
                                                <DivActions>
                                                    {
                                                        index === editIndex ?
                                                            <>
                                                                <FormControl type="text" onChange={handleChangeForm} value={formObjective.objective} id={"objective-" + index} name={'objective-' + index} />
                                                                <IconSave color="black" link onClick={handleSave} />
                                                                <IconUndo color="black" link onClick={handleBack} />
                                                            </>
                                                            :
                                                            <>
                                                                <span>{objective.name}</span>
                                                                <IconEdit color="black" link onClick={() => handleEdit(index)} />
                                                            </>
                                                    }
                                                    {index !== 0 && <IconUp color="black" link onClick={() => handleOrderUp(index)} />}
                                                    {form.data[indexPerspective].data.length - 1 !== index && <IconDown color="black" link onClick={() => handleOrderDown(index)} />}
                                                    <IconTrash link onClick={() => handleDelete(index)} />
                                                </DivActions>
                                                <Indicators form={form} setForm={setForm} indexPerspective={indexPerspective} indexObjective={index} setIndicatorData={setIndicatorData} indicatorData={indicatorData} />
                                            </Col>
                                        </Row>

                                    </Col>

                                </React.Fragment>
                            );
                        })
                    }
                </Row>
            </div>
        </>
    );
}
export default Objectives;