import PersonAdmin from '../assets/images/personAdmin.svg';
import Buscar from '../assets/images/Buscar.svg';
import Crear from '../assets/images/Crear.svg';
import Exportar from '../assets/images/Exportar.svg';
import Importar from '../assets/images/Importar.svg';
import GestionPaginas from '../assets/images/GestionPaginas.svg';
import AdminUsuarios from '../assets/images/admin-usuarios.svg';
import Atributos from '../assets/images/atributos.svg';
import Diccionario from '../assets/images/diccionario.svg';
import Escenarios from '../assets/images/escenarios.svg';
import Listas from '../assets/images/listas.svg';
import Revisar from '../assets/images/revisar-indicadores.svg';




export const dataMenu = [
    {
        id: 9,
        img: AdminUsuarios,
        title: 'Administrar Usuarios',
        text: 'Crea, edita y elimina usuarios del portal',
        role: 'admin',
        url: '/admin/users',
        idModule: 8,
        permission: 'find'
    }, 
    {
        id: 1,
        img: Buscar,
        title: 'Buscar Indicadores',
        text: 'Busca y edita los valores de los indicadores creados',
        role: 'viewIndicator',
        url: '/admin/indicators',
        idModule: 1,
        permission: 'find'
    },
    {
        id: 2,
        img: Crear,
        title: 'Crear Indicadores',
        text: 'Crea indicadores individualmente con su respectiva configuración',
        role: 'adminIndicator',
        url: '/admin/indicators/create',
        idModule: 1,
        permission: 'create'
    },
    {
        id: 3,
        img: Importar,
        title: 'Importación Masiva',
        text: 'Importa masivamente valores de los indicadores',
        role: 'updaterIndicator',
        url: '/admin/MassiveImport',
        idModule: 3,
        permission: 'upload_my_indicator'
    },
    {
        id: 4,
        img: Exportar,
        title: 'Exportación Masiva',
        text: 'Exporta una lista de indicadores a través de filtros avanzados',
        role: 'viewIndicator',
        url: '/admin/indicators/massiveDownload',
        idModule: 1,
        permission: 'export'
    },
    {
        id: 5,
        img: GestionPaginas,
        title: 'Gestión de Paginas',
        text: 'Crea, edita y elimina páginas del portal',
        role: 'adminSite',
        url: '/admin/pages',
        idModule: 4,
        permission: 'find'
    },
    {
        id: 6,
        img: Atributos,
        title: 'Configurar atributos',
        text: 'Configura los atributos de los indicadores del portal',
        role: 'admin',
        url: '/admin/indicators/atributes',
        idModule: 5,
        permission: 'find'
    },
    {
        id: 7,
        img: Escenarios,
        title: 'Configurar escenarios',
        text: 'Configura los escenarios de los indicadores del portal',
        role: 'admin',
        url: '/admin/indicators/stages',
        idModule: 6,
        permission: 'find'
    }, 
    {
        id: 8,
        img: Diccionario,
        title: 'Configurar diccionario',
        text: 'Configura los diccionarios de los indicadores del portal',
        role: 'admin',
        url: '/admin/indicators/dictionary',
        idModule: 7,
        permission: 'find'
    }, 
    {
        id: 10,
        img: Listas,
        title: 'Administrar Listas',
        text: 'Crea, edita y elimina Listas del portal',
        role: 'admin',
        url: '/admin/list',
        idModule: 9,
        permission: 'find'
    },
    {
        id: 11,
        img: Revisar,
        title: 'Auditoría',
        text: 'Revisar cambios que se han hecho a los indicadores',
        role: 'admin',
        url: '/admin/audit',
        idModule: 13,
        permission: 'find'
    },
    {
        id: 13,
        img: Atributos,
        title: 'Configurar portal',
        text: 'Configura los colores del sitio, el formato y la paleta de colores',
        role: 'admin',
        url: '/admin/portal',
        idModule: 12,
        permission: 'update_portal'
    },
    {
        id: 0,
        img: PersonAdmin,
        title: 'Administración de negocios',
        text: 'Crea negocios a partir de otros negocios creados',
        role: 'multiBusinessAdmin',
        url: '/admin/business',
        idModule: 12,
        permission: 'find'
    },
    
];