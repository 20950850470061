import { useState, useEffect } from 'react';
import Utils from "../../utils";
import { Container, Table } from "react-bootstrap";
import { Link } from 'react-router-dom';
import CustomModal from '../../components/CustomModal';
import {Button, Container as Cont} from '../../styles/CommonStyles';
import { formatDate } from '../../utils/formatDate';
import {IconView} from '../../components/iconos/iconView';
import { BsDownload } from "react-icons/bs";
import { CustomTd } from '../massiveImport/styles';

import ViewDataFile from './viewDataFile';
import { AlertForm, ICON } from '../../utils/SweetAlert';
import { Loading } from '../../components/Loading';
import { PaginationMain } from '../../components/pagination';



function HistoricalImport() {
    const [files, setFiles] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [id, setId] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [dataPagination, setDataPagination] = useState([]);

    useEffect(() => {
        setLoading(true);
        Utils.Petition.get(
            '/indicator-file-imports',
            (response) => {
                setFiles(response.data);
            },(e) => { 
                AlertForm(ICON.ERROR,'Ocurrió un error inesperado.',e.response.data?.message)
            },() => {
                setLoading(false);
            }
        );
    }, [])

    const handlePopup = (id) => {
        setId(id)
        setShowModal(true);
    }
    return (
        <Cont>
            <h3>Cargues</h3>
            <Container className="text-center mb-3">
                <Link to='/admin/MassiveImportIndicators/upload' className="text-decoration-none text-success font-weight-bold"><Button color="green">Nuevo Cargue</Button></Link>
            </Container>
            <Table hover bordered className="text-center">
                <thead className="bg-dark text-white">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Archivo</th>
                        <th scope="col">Observación</th>
                        <th scope="col">Usuario</th>
                        {/* <th scope="col">Archivo</th> */}
                        <th scope="col">Resumen</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataPagination.length > 0 && 
                        dataPagination.map((value,key)=>(
                            <tr key={key}>
                                <td>{value.id}</td>
                                <td>{formatDate(value.createdAt)}</td>
                                <td>{value.name}</td>
                                <td>{value.description}</td>
                                <td>{value.user?.full_name}</td>
                                {/* <CustomTd> <BsDownload /> </CustomTd> */}
                                <td><IconView link onClick={() => {handlePopup(value.id)}} color="black" /> </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            {loading && <Loading />}
            {!loading && !files.length && "No hay resultados."}
            <PaginationMain data={files} setDataPagination={setDataPagination} />
            <CustomModal title={"Información del cargue"} show={showModal} setShow={setShowModal} >
                    {
                        showModal &&
                        <ViewDataFile id={id}/>
                    }
            </CustomModal>
        </Cont>
    )

}

export default HistoricalImport;