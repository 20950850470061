export const styleTheme = (theme) => {
    return {
      ...theme,
      borderRadius: 6,
      colors: {
        ...theme.colors,
        primary25: 'var(--c-orange-opacity)',
        primary: 'var(--c-orange)',
      },
    }
  }