import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Utils from '../../utils';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { APP_URL } from "../../config";
import { StyleProperty, DivWrapper } from '../style';
import { StyleCheckbox, Button } from '../../styles/CommonStyles';
import GetDataTable from './GetDataTable';

function Form(){
    const { id } = useParams();
    const { idpage } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [loadedPre, setLoadedPre] = useState(false);
    const [msj, setMsj] = useState('');

    const [form, setForm] = useState({
        title: "",
        indicators: [],
        view: undefined,
        hideLastYear: false 
    });

    useEffect(( )=> {
        Utils.Petition.get(
            '/pages-modules/id/' + id,
            (response) => {
                if (Object.keys(response.data.config).length > 0 ) {
                    setForm({
                        ...form,
                        title: response.data.config.title,
                        indicators: response.data.config.indicators,
                        view: response.data.config.view,
                        hideLastYear: response.data.config.hideLastYear
                    });
                    setLoadedPre(true)
                }   
                setLoaded(true);
            }
        );
    }, []);

    const loadIndicators = (inputValue) => {
        
        if( inputValue.length > 2 ) {
            return new Promise((resolve) => {
                setTimeout( () => {
                    Utils.Petition.get(
                        '/indicators/name-code-indicator/' + inputValue,
                        (response) => { 
                            resolve(response.data)
                        }   
                    )
                }, 100);
            });
        }

        return new Promise((resolve) => resolve([]));
    };

    const handleForm = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        });
    };

    const selectData = (data) => {
        setForm({
            ...form,
            indicators: data
        })
    };

    const handleInfo = (event) => {
        const index = (event.target.name.split("-"))[2];
        const variable = (event.target.name.split("-"))[0];
        const type = (event.target.name.split("-"))[1];
        let temp = JSON.parse(JSON.stringify(form[type]));
        
        temp[index][variable] = event.target.value;
            
        setForm({
            ...form,
            [type]: temp
        });
    };

    const verifyIndicators = () => {
        let contador = 0
        form.indicators.map( ind => {
            if (ind.hasOwnProperty('labelCanal') && ind.labelCanal.trim() !== "") contador++;
        })

        return contador === form.indicators.length ? true : false
    }

    useEffect( () => {
        const bootstrap = async () => {
            await setLoadedPre(false);

            if( form.indicators.length === 0 ) {
                await setMsj('Debe escoger por lo menos un indicador')
            } 
            else {
                await setLoadedPre(true);
            }   
        };
        bootstrap();
    }, [form])

    const handleSubmit = (event) => {
        event.preventDefault();

        if( loadedPre ) {
            const data = {
                id: id,
                config: form
            };
   
            Utils.Petition.post(
                '/pages-modules/update', data,
                (response)=>{
                    if(window.parent){
                        //window.parent.postMessage('cerrar popup', APP_URL);
                    }
                }
            )
        } 
    }

    const handleChecks = (event) => {
        const variable = event.target.name
        setForm({
            ...form,
            [variable]: event.target.checked
        })
    }

    console.log('form', form)
    return (
        <DivWrapper>
        <Container fluid>
            <Row>
                <Col sm={5}>
                    <h3>Configuración</h3>
                    <form onSubmit={handleSubmit}>
                        <Container fluid className="mb-1">
                            <div className="form-group">
                                <Row>
                                    <Col sm={3}><b>Título</b></Col>
                                    <Col sm={9}>
                                        <input type="text" className="form-control" id="title" name="title" onChange={handleForm} value={form.title}/>
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-group">
                                <Row>
                                    <Col sm={3}><b>Vista</b></Col>
                                    <Col sm={9}>
                                        <select className="form-control" id="view" name="view" value={form.view} onChange={handleForm}>
                                            <option key="0" value="PorDefecto">Seleccionar...</option>
                                            <option key="1" value="M">Mensual</option>
                                            <option key="2" value="A">Acumulado</option>
                                        </select>
                                    </Col>
                                </Row>
                            </div>
                            
                            <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                <input className="custom-control-input" name="hideLastYear" type="checkbox" checked={form.hideLastYear} id="hideLastYear" onChange={handleChecks} />
                                <label className="custom-control-label" htmlFor="hideLastYear">
                                    Ocultar Año Anterior
                                </label>
                            </StyleCheckbox>
                                
                            <div className="form-group my-3">
                                <Row>
                                    <Col sm={3}><b>Indicadores</b></Col>
                                    <Col sm={9}>
                                        <AsyncSelect
                                            isMulti
                                            value={form.indicators}
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={loadIndicators}
                                            onChange={selectData}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {
                                form.indicators.length > 0 &&
                                form.indicators.map( (ind, index) => {
                                    return (
                                        <StyleProperty>
                                            <h4>{"Propiedades " + ind.name}</h4>
                                            <div className="form-group">
                                                <Row>
                                                    <Col><b>Nombre del Cliente</b></Col>
                                                    <Col>
                                                        <input type="text" className="form-control" id={"labelIndicator-indicators-" + index} name={"labelIndicator-indicators-" + index} onChange={handleInfo} value={ind.labelIndicator} />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="form-group">
                                                <Row>
                                                    <Col><b>Nombre del Canal</b></Col>
                                                    <Col>
                                                        <input type="text" className="form-control" id={"labelCanal-indicators-" + index} name={"labelCanal-indicators-" + index} onChange={handleInfo} value={ind.labelCanal} />
                                                    </Col>
                                                </Row>
                                            </div>

                                        </StyleProperty>
                                    )
                                })
                            }
                        </Container>
                        <Container className="p-2" style={{maxHeight: 60, overflow: 'auto'}}>
                            <button type="submit" className="btn btn-primary" disabled={!loadedPre}>Guardar</button>
                        </Container>
                    </form>
                </Col>
                <Col style={{maxHeight: 500, overflow: 'auto'}}  sm={7}>
                    <h3>Previsualización</h3>
                    {
                        loadedPre && 
                        <GetDataTable config={JSON.parse(JSON.stringify(form))} />
                    }
                    {
                        !loadedPre && msj !== '' && 
                        <div className="container alert alert-danger my-2 mx-0 my-2 text-center" role="alert">
                            {msj}
                        </div>
                    }
                </Col>
            </Row>
        </Container>
        </DivWrapper>
    )
}

export default Form;