import { useState, useEffect } from 'react';
import Utils from "../../utils";
import { Container,  Table } from "react-bootstrap";
import { AlertForm, ICON } from '../../utils/SweetAlert';


function ViewDataFile ({id}) {

    const [dataFile, setDataFile] = useState([]);

    useEffect(() => {
        Utils.Petition.get(
            '/indicator-data-files/idfileindicator/'+id,
            (response) => {
                setDataFile(response.data)
            },(e) => {
                AlertForm(ICON.ERROR,'Ocurrió un error inesperado.',e.response.data.message)
            }
        );
    }, [id])

    return (
        <Container fluid>
            {
                <Container fluid>
                    <Table hover bordered>
                        <thead className="bg-dark text-white">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Código</th>
                                <th scope="col">Nombre del Indicador</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataFile.length > 0 &&
                                dataFile.map( (data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{data.code?data.code:""}</td>
                                            <td>{data.name?data.name:""}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    {
                        dataFile.length === 0  && 
                        <div className="alert alert-warning text-center" role="alert">
                            No hay datos 
                        </div>
                    }
                </Container>
            }
        </Container>
        
    )

}

export default ViewDataFile;