import { useState, useEffect } from 'react';
import Utils from "../../utils";
import { Col, Row, } from "react-bootstrap";
import Select from 'react-select';
import * as XLSX from "xlsx";
import { Container, StyleCheckbox, Button } from '../../styles/CommonStyles';
import { FormCustom } from '../indicators/styles';
import { styleTheme } from '../../styles/SelectTheme';
import {AlertForm, ICON} from '../../utils/SweetAlert';
import { useConfigRole } from '../../Hooks/configRole';
import { useHistory } from "react-router-dom";
import { ButtonLoading } from '../../components/Loading/ButtonLoading';
function ImportIndicators() {

    const headers = ['Código', 'Nombre del Indicador', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    const [years, setYears] = useState([
        {value: 2012, label: '2012'},
        {value: 2013, label: '2013'},
        {value: 2014, label: '2014'},
        {value: 2015, label: '2015'},
        {value: 2016, label: '2016'},
        {value: 2017, label: '2017'},
        {value: 2018, label: '2018'},
        {value: 2019, label: '2019'},
        {value: 2020, label: '2020'},
        {value: 2021, label: '2021'},
        {value: 2022, label: '2022'},
        {value: 2023, label: '2023'},
        {value: 2024, label: '2024'}
    ]);
    const [stages, setStages] = useState([]);
    const [nameFile, setNameFile] = useState('');
    const [validations, setValidations] = useState({});
    const [fileStrings, setFileStrings] = useState([]);
    const [form, setForm] = useState({
        data: [],
        comments: "",
        stage: 0,
        year: 0
    });
    const [UpdateRestriction, setUpdateRestriction] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const { configRoleByIdModule } = useConfigRole(3);
    let history = useHistory();

    useEffect( () => {
        Utils.Petition.get(
            '/stages',
            (response) => { 
                setStages(response.data)
            }   
        );
    }, []);

    const handleForm = (event) => {
        const value = event.target.value
        setForm({
            ...form,
            [event.target.name]: value
        })
    };

    const selectYear = (data) => {
        const value = data.value;
        setForm({
            ...form,
            year: value
        })
    }

    const selectStage = (data) => {
        setForm({
            ...form,
            stage: data.id
        })
    };

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                let _headers = wb.Strings.map( str => str.t );
                _headers = _headers.filter(i=> i!=='');
                setFileStrings(_headers);
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            setForm({
                ...form,
                data: d
            });
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const number_required = 4;
        let contador = 0;

        let temp = {...validations};

        if( nameFile === '' ) temp.file = "danger";
        else { temp.file = ""; contador ++ };

        if ( form.comments === "" ) temp.comments = "danger";
        else { temp.comments  = "secondary"; contador ++ };

        if ( form.stage === 0 ) temp.stage = "danger";
        else { temp.stage = ""; contador ++ };

        if ( form.year === 0 ) temp.year = "danger";
        else { temp.year  = ""; contador ++ };

        setValidations(temp);
        
        if( contador === number_required ) {
            let cont_h = 0;
            for (let k=0; k<headers.length; k++) {
                if( fileStrings.includes( headers[k] )) cont_h++;
            };
            if( cont_h === headers.length ) {
                setisLoading(true);
                let codes_errors = [];
                for (let i=0; i<form.data.length; i++) {
                    if (!form.data[i]['Código']) {
                        codes_errors.push(i+2);
                    } 
                }
                if(codes_errors.length === 0) {
                    form.nameFile = nameFile;
                    form.restriction = UpdateRestriction;
                    
                    Utils.Petition.post('/indicator-value/import_data', form,
                        (response) => {
                            AlertForm(ICON.SUCCESS, response.message);
                            history.push("/admin/MassiveImport");
                            setisLoading(false);
                        }
                    )
                } else {
                    let msg = ''
                    if( codes_errors.length === 1 ) msg = `La fila ${codes_errors} del excel adjunto tiene código vacío`;
                    else msg = `Las filas ${codes_errors} del excel adjunto tienen código vacío`;
                    AlertForm(ICON.WARNING, msg, '', 'center', null, true)
                }

            } else {
                AlertForm(ICON.WARNING, 'El archivo tiene headers diferentes', '', 'center', null, true)
            } 
        } else {
            AlertForm(ICON.WARNING, 'Los campos en rojo son requeridos', '', 'center', null, true)
        }
    };

    const handleChangeUpdateRestiction = () => {
        setUpdateRestriction(!UpdateRestriction);
    }

    return (
        <Container>
            <h3 className="text-center">Importación Masiva de Valores</h3>
            <FormCustom className="text-center my-3" onSubmit={handleSubmit}>
                <div className="form-group text-center">
                    <Row>
                        <Col><b>Archivos</b></Col>
                        <Col sm={7}>
                            <div className=" custom-file">
                                <input 
                                    type="file" 
                                    className="form-control custom-file-input"
                                    id="inputFile"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        setNameFile(file.name);
                                        readExcel(file);
                                    }}
                                />
                                <label className={`custom-file-label text-left border rounded border-${validations.file}`} htmlFor="inputGroupFile01">Selecciona el archivo</label>
                            </div>
                        </Col>                       
                    </Row>  
                </div>
                {
                    nameFile !== '' &&
                    <div className="form-group">
                        <Row>
                            <Col><b>Archivo escogido</b></Col>
                            <Col sm={7}>
                                <label className="font-weight-bold text-left">{nameFile}</label>
                            </Col>
                        </Row>
                    </div>
                }   
                
                <div className="form-group">
                    <Row>
                        <Col><b>Comentarios</b></Col>
                        <Col sm={7}>
                            <textarea name='comments' className={`form-control border rounded border-${validations.comments}`} id="comentarios" onChange={handleForm} rows="4"></textarea>
                        </Col>
                    </Row>
                </div>
                <div className="form-group">
                    <Row>
                        <Col><b>Escenario</b></Col>
                        <Col sm={7}>
                            <Select onChange={selectStage} className={`text-left border rounded border-${validations.stage}`} options={stages} theme={theme => (styleTheme(theme))}/>
                        </Col>
                    </Row>
                </div>
                <div className="form-group">
                    <Row>
                        <Col><b>Año de carga</b></Col>
                        <Col sm={7}>
                            <Select name="year" onChange={selectYear} className={`text-center border rounded border-${validations.year}`} options={years} theme={theme => (styleTheme(theme))}/>
                        </Col>
                    </Row>
                </div>
                {configRoleByIdModule.upload_value_indicator && <div className="form-group">
                    <StyleCheckbox className="custom-checkbox">
                        <input className="custom-control-input" type="checkbox" name="stubCode" id="stubCode" checked={UpdateRestriction} onChange={handleChangeUpdateRestiction}/>
                        <label className="custom-control-label" htmlFor="stubCode">
                            Actualización Masiva sin restricciones
                        </label>
                    </StyleCheckbox>
 
                </div>}
                <Button type="submit" disabled={isLoading} className="my-2">{isLoading ? <ButtonLoading text="Guardando...." /> : "Cargar"}</Button>
            </FormCustom>

        </Container>
    )
}

export default ImportIndicators;
