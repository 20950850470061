import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SvgStroke } from '../../styles/CommonStyles';

export const IconGraph = ({ color, link, onClick = () => { }, textTooltip = "Ver Grafica" }) => {
  return (

    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-graph`}>
          {textTooltip}
        </Tooltip>
      }
    >
      <SvgStroke color={color} link={link} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 50 50">
        <defs><clipPath id="a"><rect className="a" width="24" height="24" /></clipPath></defs>
        <g transform="translate(0.203 0.163)"><g transform="translate(0 0)"><g className="b">
          <path className="a" d="M2087.116,658.323a.646.646,0,0,1-.344-.1l-6.136-3.875a.644.644,0,0,1-.241-.814,12.656,12.656,0,0,0,1.149-5.292,12.807,12.807,0,0,0-11.051-12.659.646.646,0,0,1-.559-.639v-7.26a.643.643,0,0,1,.21-.473.653.653,0,0,1,.491-.167,21.269,21.269,0,0,1,17.053,30.932.647.647,0,0,1-.4.326.658.658,0,0,1-.168.022Zm-5.328-4.771,5.068,3.2a19.98,19.98,0,0,0-15.632-28.351v5.991a14.1,14.1,0,0,1,11.607,13.851,13.94,13.94,0,0,1-1.042,5.31Zm0,0" transform="translate(-2047.434 -626.948)" /></g></g>
          <path className="a" d="M1965.865,800.5a21.284,21.284,0,0,1-19.555-12.856.643.643,0,0,1,.39-.867l6.906-2.286a.646.646,0,0,1,.781.329,12.764,12.764,0,0,0,21.262,2.6.647.647,0,0,1,.839-.132l6.148,3.876a.644.644,0,0,1,.172.937,21.14,21.14,0,0,1-16.943,8.4Zm-18.076-12.729a20,20,0,0,0,33.554,4.1l-5.074-3.2a14.055,14.055,0,0,1-22.782-2.789Zm0,0" transform="translate(-1944.532 -757.924)" /><path className="a" d="M1937.467,653.6a.645.645,0,0,1-.628-.5,21.156,21.156,0,0,1-.566-4.885,21.4,21.4,0,0,1,19.426-21.2.646.646,0,0,1,.7.642v7.26a.644.644,0,0,1-.559.639,12.808,12.808,0,0,0-11.054,12.662,12.527,12.527,0,0,0,.22,2.331.644.644,0,0,1-.43.732l-6.906,2.285a.65.65,0,0,1-.2.033Zm17.642-25.224a20.007,20.007,0,0,0-17.166,23.741l5.7-1.886a13.852,13.852,0,0,1-.148-2.012,14.106,14.106,0,0,1,11.611-13.847Zm0,0" transform="translate(-1936.226 -626.925)" />
          <path className="a" d="M2070.582,638.017a.645.645,0,0,1-.436-1.118l4.541-4.195a.645.645,0,0,1,.875.946l-4.541,4.193a.641.641,0,0,1-.438.173Zm0,0" transform="translate(-2047.438 -631.524)" /><path className="a" d="M2084.227,648.263a.645.645,0,0,1-.437-1.118l5.785-5.332a.645.645,0,0,1,.875.946l-5.785,5.332a.642.642,0,0,1-.438.172Zm0,0" transform="translate(-2058.791 -639.103)" /><path className="a" d="M2104.416,659.715a.645.645,0,0,1-.437-1.118l5.395-4.982a.646.646,0,0,1,.878.947l-5.394,4.982a.647.647,0,0,1-.441.171Zm0,0" transform="translate(-2075.588 -648.906)" /><path className="a" d="M2120.124,674.154a.645.645,0,0,1-.438-1.118l5.315-4.9a.645.645,0,0,1,.876.946l-5.315,4.9a.642.642,0,0,1-.437.172Zm0,0" transform="translate(-2088.657 -661.003)" /><path className="a" d="M2131.791,691.171a.645.645,0,0,1-.437-1.118L2136.82,685a.645.645,0,0,1,.875.946L2132.229,691a.643.643,0,0,1-.437.171Zm0,0" transform="translate(-2098.365 -675.039)" />
          <path className="a" d="M2138.678,710.956a.645.645,0,0,1-.438-1.118l5.951-5.5a.645.645,0,1,1,.876.946l-5.952,5.5a.643.643,0,0,1-.437.172Zm0,0" transform="translate(-2104.095 -691.107)" /><g transform="translate(0 0)">
            <g className="b"><path className="a" d="M2137.912,734.72a.646.646,0,0,1-.437-1.118l7.166-6.616a.645.645,0,0,1,.875.946l-7.165,6.616a.646.646,0,0,1-.438.172Zm0,0" transform="translate(-2103.458 -709.968)" /><path className="a" d="M2143.5,760.643a.645.645,0,0,1-.437-1.118l6.572-6.073a.645.645,0,1,1,.876.947l-6.573,6.073a.643.643,0,0,1-.437.171Zm0,0" transform="translate(-2108.109 -731.973)" /></g></g><path className="a" d="M2161.842,790.807a.645.645,0,0,1-.438-1.118l2.494-2.306a.645.645,0,1,1,.875.946l-2.494,2.306a.637.637,0,0,1-.437.172Zm0,0" transform="translate(-2123.368 -760.199)" /></g></SvgStroke>

    </OverlayTrigger>

  )
}
