import { useState } from "react";
import {FaRedo, FaSave } from "react-icons/fa";
import { IconEdit } from "../../components/iconos/IconEdit";
import { IconSave } from "../../components/iconos/IconSave";
import { IconCheck } from "../../components/iconos/IconCheck";
import { IconClose } from "../../components/iconos/IconClose";
import { IconTrash } from "../../components/iconos/IconTrash";
import { IconConfig } from '../../components/iconos/IconConfig';
import Utils from "../../utils";
import { AlertConfirm, ICON} from '../../utils/SweetAlert';
import CustomModal from "../../components/CustomModal";
import ListPortal from "./portals/ListPortal";
import { ButtonLoading } from "../../components/Loading/ButtonLoading";
import { Theme1 } from "../../styles/Theme1";
import { IconUndo } from "../../components/iconos/IconUndo";

function Item({reloadInterval, setReloadInterval, businesses, setBusinesses, index, portalOptions, setPortalOption, configRol}){
    const [editMode, setEditMode] = useState(false);
    const [businessForm, setBusinessForm] = useState({name:businesses[index].name})
    const [portalForm, setPortalForm] = useState({
        portal:"",
        fromPortal:null
    })
    const [modalConfigure, setModalConfigure] = useState(false);
    const handleEditMode = ()=>{
        setEditMode(true);
    }
    
    const handleDelete = ()=>{
        AlertConfirm(ICON.WARNING,"Una vez actualices la página no podrás recuperar el contenido", "¿Seguro deseas continuar?", ()=>deleteFunction(index) )
    }
    const deleteFunction = () => {
        let _delete = '1';
        Utils.Petition.post('/business/update', {id:businesses[index].id, delete:_delete});
        let _businesses = [...businesses];
        _businesses[index].delete=_delete;
        setBusinesses(_businesses);
    }
    const handleRecover = () => {
        let _delete = '0';
        Utils.Petition.post('/business/update', {id:businesses[index].id, delete:_delete});
        let _businesses = [...businesses];
        _businesses[index].delete=_delete;
        setBusinesses(_businesses);
    }
    const handleDisabled=()=>{
        let disable = '1';
        Utils.Petition.post('/business/update', {id:businesses[index].id, disable});
        let _businesses = [...businesses];
        _businesses[index].disable=disable;
        setBusinesses(_businesses);
    }
    const handleEnabled=()=>{
        let disable = '0';
        Utils.Petition.post('/business/update', {id:businesses[index].id, disable});
        let _businesses = [...businesses];
        _businesses[index].disable=disable;
        setBusinesses(_businesses);
    }
    const handleChangeForm= (event)=>{
        setBusinessForm({[event.target.name]: event.target.value});
    }
    const handleSave = ()=>{
        setEditMode(false);
        let name = businessForm.name;
        Utils.Petition.post('/business/update', {id:businesses[index].id, name});
        let _businesses = [...businesses];
        _businesses[index].name=name;
        setBusinesses(_businesses);
    }
    return (
        <>
            <tr className={businesses[index].delete ==='1' ? 'deleted':'normal'}>
                <td><b>{businesses[index].id}</b></td>
                <td>
                    {!editMode && <label>{businesses[index].name}</label>}
                    {editMode && <input type="text" className="form-control" name="name" value={businessForm.name} onChange={handleChangeForm} />}
                </td>
                <td>
                    <ul style={{margin: 0}}>
                    {
                        businesses[index].portals.map(portal=>{
                            return (
                            <li key={portal.id} className={portal.delete ==='1' ? 'deleted':'normal'}>
                                <label>{portal.name}</label>
                                &nbsp;
                                {
                                    portal.process_portal  && portal.process_portal.status==='En proceso' &&  <span className="badge badge-secondary"><ButtonLoading text='Copiando contenido...' /></span>
                                }
                                {
                                    portal.process_portal  && portal.process_portal.status==='Con errores' &&  <span className="badge badge-danger">{portal.process_portal.status}</span>
                                }
                            </li>
                            )
                        })
                    }  
                    </ul>           
                </td>
                <td>
                    {!editMode && configRol.update && <IconEdit link onClick={handleEditMode}/> }
                    {editMode && <IconSave color={Theme1.success}  link onClick={handleSave}/> }
                    {configRol.update && <IconConfig textTooltip="Configuración" link onClick={()=>setModalConfigure(true)}/>}
                    {businesses[index].disable==='0' && configRol.update && <IconClose link color="red" onClick={handleDisabled} />}
                    {businesses[index].disable==='1' && configRol.update && <IconCheck link color="green" onClick={handleEnabled} />}
                    {businesses[index].delete==='0' && configRol.delete && <IconTrash link color="red" title="Eliminar" onClick={handleDelete} />}
                    {businesses[index].delete==='1' && <IconUndo link textTooltip="Recuperar" onClick={handleRecover} />}
                    
                </td>
            </tr>
            {
                modalConfigure &&
                <CustomModal title={"Configuración "+businesses[index].name} show={modalConfigure} setShow={setModalConfigure} size="xl">
                    {
                        
                        modalConfigure &&
                        <ListPortal reloadInterval={reloadInterval} setReloadInterval={setReloadInterval} businesses={businesses} setBusinesses={setBusinesses} portalOptions={portalOptions} index={index} />
                        
                    }
                </CustomModal>
            }
        </>
    );

}
export default Item;