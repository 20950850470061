import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Svg } from '../../styles/CommonStyles';

export const IconUndo = ({ color, link, onClick = () => { }, textTooltip = "Regresar" }) => {
    return (

        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-editar`}>
                    {textTooltip}
                </Tooltip>
            }
        >
            <Svg color={color} link={link} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><rect className="a" width="24" height="24" />
                <path className="b" d="M10,0C4.48,0,0,2.24,0,5,0,7.24,2.94,9.13,7,9.77V13l4-4L7,5V7.73C3.85,7.17,2,5.83,2,5c0-1.06,3.04-3,8-3s8,1.94,8,3c0,.73-1.46,1.89-4,2.53V9.58c3.53-.77,6-2.53,6-4.58C20,2.24,15.52,0,10,0Z" transform="translate(2.5 6)" />
            </Svg>
        </OverlayTrigger>

    )
}
