import { useState, useEffect } from 'react';
import Utils from "../../utils";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useParams } from 'react-router';
import { Container, Button } from '../../styles/CommonStyles';
import { styleTheme } from '../../styles/SelectTheme';
import { AlertForm, ICON } from "../../utils/SweetAlert";
import { FormCustom } from './styles';
import { ButtonLoading } from '../../components/Loading/ButtonLoading';
import { Theme1 } from '../../styles/Theme1';

const END_POINTS = {
    dictionary: '/dictionary',
    filter: '/filter',
    measure: '/measure',
    atribute: '/indicator-atribute',
    option: '/list-options',
}

const Create = () => {

    const [validDicc, setValidDicc] = useState(false)
    const [diccNew, setDiccNew] = useState(false)
    const [dictionary, setDictionary] = useState('Diccionario...')
    const [dictionarySelec, setDictionarySelec] = useState([])
    const [nameS, setNameS] = useState('')
    const [name, setName] = useState('')
    const [users, setUsers] = useState([])
    const [responsable, setResponsable] = useState('')
    const [filters, setFilters] = useState([])
    const [idfilter, setIdFilter] = useState('')
    const [listO, setListO] = useState([])
    const [dataInsert, setDataInsert] = useState([])
    const { id } = useParams();
    const [validations, setValidations] = useState({})
    const [msj, setMsj] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const filter_name = Utils.getConfigPortal('filter_name');

    useEffect(() => {

        if (id) {
            Utils.Petition.get(
                '/indicators/id/' + id,
                (responseId) => {
                    console.log(responseId)
                    setValidDicc(true);
                    setName(responseId.data.name)
                    setResponsable(responseId.data.user)
                    setIdFilter(responseId.data.filter)

                    let temp = responseId.data.indicators_atributes_values;
                    for(let i=0; i<temp.length; i++) {
                        if( temp[i].indicators_atribute.object_type === "list-multiple" ) {
                            temp[i].value = temp[i].value.split(',');
                        }
                    }
                    setDataInsert(temp)
                }
            )
        }

        Utils.Petition.get(END_POINTS['atribute'],
            (response) => {
                let orderedList = [];
                for (let i = 0; i < response.data.length; i++) {
                    const element = response.data[i];
                    for (let j = 0; j < element.lists_options.length; j++) {
                        response.data[i].lists_options[j].idattribute = response.data[i].id;
                        response.data[i].lists_options[j].required = response.data[i].isrequired;
                    }
                }
                orderedList = response.data.sort((a, b) => a.idattribute - b.idattribute)
                setListO(orderedList);
            }
        )

        Utils.Petition.get('/users/select_name',
            (response) => {
                setUsers(response.data)
            }
        )

        Utils.Petition.get(END_POINTS['filter'] + '/select_name',
            (response) => {
                setFilters(response.data)
            }
        )

        Utils.Petition.get('/dictionary', async function (response) {
            let dic = []
            for (let i = 0; i < response.data.length; i++) {
                dic.push({ value: response.data[i].id, label: response.data[i].name })
            }
            await setDictionarySelec(dic)

        })

    }, []);

    const handleChangeAttribute = (data) => {
        // if(data.length){
            let newDataInsert = [...dataInsert]
            console.log(newDataInsert)

            let fabi = findAttributeById(Array.isArray(data) ? data[0].idattribute : data.idattribute)
            if (Array.isArray(data)) {
                if (data.length) {
                    let values = []
                    for (const key in data) {
                        values.push(`${data[key].id}`)
                    }
                    if (fabi === null) {
                        newDataInsert.push({ idattribute: data[0].idattribute, value: values, label: data[0].label })
                    } else {
                        fabi.value = values
                        fabi.label = data.label
                    }
        // let newDataInsert = [...dataInsert]
        // let fabi = findAttributeById(Array.isArray(data) ? data[0].idattribute : data.idattribute)
        // console.log(fabi)
        // if (Array.isArray(data)) {
        //     if (data.length) {
        //         let values = []
        //         for (const key in data) {
        //             values.push(`${data[key].id}`)
                }
            } else {
                if (fabi === null) {
                    newDataInsert.push({ idattribute: data.idattribute, value: `${data.id}`, label: data.label })
                } else {
                    fabi.value = `${data.id}`
                    fabi.label = data.label
                }
            }

            // console.log(newDataInsert)
            setDataInsert(newDataInsert)

            function findAttributeById(id) {
                for (let i = 0; i < newDataInsert.length; i++) {
                    if (newDataInsert[i].idattribute === id) return newDataInsert[i]
                }
                return null
            }
        // }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        let number_required = 2;
        let contador = 0;
        listO.map(list => { if (list.isrequired === "1") number_required++; })

        let temp = { ...validations };

        if (responsable === '') temp.responsable = "danger";
        else { temp.responsable = ""; contador++ }

        if (idfilter === '') temp.filter = "danger";
        else { temp.filter = ""; contador++ }

        for (let i = 0; i < listO.length; i++) {
            if (listO[i].isrequired === "1") {
                let bandera = 0;
                for (let j = 0; j < dataInsert.length; j++) {
                    if (listO[i].id === dataInsert[j].idattribute) {
                        bandera = 1;
                        temp = {
                            ...temp,
                            [listO[i].id]: ''
                        }
                        contador++;
                        break;
                    }
                }
                if (bandera === 0) {
                    temp = {
                        ...temp,
                        [listO[i].id]: 'danger'
                    }
                }
            }
        }

        setValidations(temp);
        setIsLoading(false);
        if (contador === number_required) {
            setIsLoading(true);
            setMsj('');
            if (id) {
                const data = {
                    id: parseInt(id),
                    responsable: responsable.id,
                    filter: idfilter.id,
                    values: dataInsert,
                    name: name
                };
                console.log(data);
                Utils.Petition.post('/indicators/updateIndicator', data, (response) => {
                    AlertForm(ICON.SUCCESS, response.message, '', 'center', null, true)
                    setIsLoading(false);
                });

            } else {
                if (diccNew === true) {
                    Utils.Petition.post('/dictionary/create', { dictionary }, function (response) {
                        AlertForm(ICON.SUCCESS,'el diccionario se creó con exito');
                    })
                }

                const data = {
                    name,
                    responsable: responsable.id,
                    idfilter: idfilter.id,
                    attributes: dataInsert
                };

                Utils.Petition.post('/indicators', data, (response) => {
                    AlertForm(ICON.SUCCESS, response.message + ' con el código: ' + response.data.code, '', 'center', null, true);
                    setIsLoading(false);
                    setValidDicc(false);
                    setDiccNew(false);
                    setDictionary('Diccionario...');
                    setNameS('');
                    setName('');
                    setResponsable('');
                    setIdFilter('');
                    setDataInsert([]);
                })
            }
        } else {
            AlertForm(ICON.WARNING, 'Debe completar los campos obligatorios', '', 'center', null, true);
        }
    }

    const backToView = (event) => {
        event.preventDefault();
        setValidDicc(false)
    }

    const handleConfirmDcry = (event) => {
        event.preventDefault();
        // data.__isNew__==true&&
        if (dictionary !== 'Diccionario...' && nameS !== '') {
            setName(`${dictionary} ${nameS}`)
            setValidDicc(dictionary, nameS)
        } else {
            AlertForm(ICON.WARNING, "Campos Incompletos");
        }
    }

    const handleChangeDcry = (data) => {
        if (data !== null) {
            data.__isNew__ === true && setDiccNew(true)
            setDictionary(data.label)
        }
    }

    const handleOtherInfo = (event) => {

        let temp = [...dataInsert];
        const id = parseInt(event.target.name.split('-')[1]);
        let flag = 0;
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].idattribute === id) {
                temp[i].value = event.target.value;
                flag = 1;
                break;
            }
        }
        if (!flag) {
            temp.push({
                idattribute: id,
                value: event.target.value
            })
        }
        setDataInsert(temp);
    }

    const findValueAttribute = (id) => {

        for (let i = 0; i < dataInsert.length; i++) {
            if (dataInsert[i].idattribute === id) {
                const attribute = listO.filter(list => list.id === id);
                if (attribute[0].object_type === 'list') {
                    const options = attribute[0].lists_options;
                    const value = options.filter(option => option.id === parseInt(dataInsert[i].value));
                    return value[0];
                } else if (attribute[0].object_type === 'text') {
                    return dataInsert[i].value;
                } else if (attribute[0].object_type === 'list-multiple') {
                    const options = attribute[0].lists_options;
                    const values_attr = dataInsert[i].value;
                    let values = [];
                    let aux = [];
                    for(let j=0; j<values_attr.length; j++) {
                        aux = options.filter(option => option.id === parseInt(values_attr[j]));
                        values.push(aux[0]);
                    };
                    return values;
                }
            }
        }
        return undefined;
    }


    if (!validDicc) {
        return (
            <Container width="600px">
                <div className="card">
                <div className="card-header">
                <b> {id ? "Actualizar " : "Creación de "} indicadores</b>
            </div>
            <div className="card-body">
                
                <form className="form-group needs-validation" onSubmit={handleConfirmDcry}>
                    <div className="form-group w-50 m-auto text-center">
                        <label className='pr-3'> Nombre indicador: </label>
                        <CreatableSelect
                            className="pr-1"
                            isClearable
                            onChange={handleChangeDcry}
                            options={dictionarySelec}
                            placeholder={dictionary}
                            theme={theme => (styleTheme(theme))}
                        />
                        <br />
                        <input className="form-control" placeholder='Completa nombre del indicador' value={nameS} type="text" onChange={e => { setNameS(e.target.value) }} />
                    </div>
                    <div className='text-center mt-3'>
                        <Button type="submit"> Siguiente </Button>
                    </div>
                </form>
                </div>
                </div>
            </Container>
        )
    } else {
        return (
            <Container width="700px">
                <div className="card">
                <div className="card-header">
                <b> {id ? "Actualizar " : "Creación de "} indicadores</b>
            </div>
            <div className="card-body">
                
                <FormCustom className="form-group" onSubmit={handleSubmit}>
                    <div className="form-group row">
                        <label className='pr-3'> Nombre: </label>
                        <input className="form-control" value={name} placeholder={name} disabled={!id} onChange={e => setName(e.target.value)}/>
                    </div>
                    <div className="form-group row">
                        <label className='pr-3'> Responsable *: </label>
                        <Select theme={theme => (styleTheme(theme))} className={`border rounded border-${validations.responsable}`} value={responsable} onChange={e => setResponsable(e)} options={users} />
                    </div>
                    <div className="form-group">
                        <label className='pr-3'> {filter_name} *: </label>
                        <Select theme={theme => (styleTheme(theme))} className={`border rounded border-${validations.filter}`} value={idfilter} onChange={e => setIdFilter(e)} options={filters} />
                    </div>
                    {
                        listO.map((attribute) => {
                            return (
                                <div key={attribute.id} className="form-group">
                                    <label className='pr-3'>{attribute.name + ' ' + (attribute.isrequired === "1" ? '*' : '')} : </label>
                                    {attribute.object_type === 'list' && <Select theme={theme => (styleTheme(theme))} className={`border rounded border-${validations[attribute.id]}`} value={findValueAttribute(attribute.id)} onChange={handleChangeAttribute} name={`list-${attribute.idlist}`} options={attribute.lists_options} />}
                                    {attribute.object_type === 'list-multiple' && <Select theme={theme => (styleTheme(theme))} className={`border rounded border-${validations[attribute.id]}`}  onChange={handleChangeAttribute} name={`list-${attribute.idlist}`} isMulti options={attribute.lists_options} closeMenuOnSelect={false} />}
                                    {attribute.object_type === 'text' && <input type="text" className={`form-control border rounded border-${validations[attribute.id]}`} value={findValueAttribute(attribute.id)} id={attribute.name + "-" + attribute.id} name={attribute.name + "-" + attribute.id} onChange={e => handleOtherInfo(e)} />}
                                </div>
                            )
                        })
                    }
                    <div className="text-center">
                        <span ><b>Campos obligatorios (*)</b></span>
                    </div>

                    <div className='col-5 text-center'>
                         {!id && <Button color={Theme1.grayText} type="button" onClick={backToView} >Atras </Button>}
                        <Button type="submit" className="ml-1" disabled={isLoading}>{isLoading ? <ButtonLoading text="Enviando..." /> : 'Enviar'}</Button>
                    </div>
                    {
                        msj !== '' &&
                        <div className="alert alert-danger my-2" role="alert">
                            {msj}
                        </div>
                    }
                </FormCustom>
                </div>
                </div>
            </Container>
        )
    }
}
export default Create;