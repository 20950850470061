import { Svg } from '../../styles/CommonStyles';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const IconConfig = ({ color, link, onClick = () => { }, hover, textTooltip = "Indicadores" }) => {
  return (

    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-indicadores`}>
          {textTooltip}
        </Tooltip>
      }
    >
      <Svg color={color} link={link} onClick={onClick} hover={hover} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><rect className="a" width="24" height="24" />
        <g transform="translate(0.136 0.141)"><rect className="a" width="24" height="24" /><path className="b" d="M11.139,1.9A6.547,6.547,0,0,0,3.665.657L8.025,5l-3.014,3L.652,3.661a6.511,6.511,0,0,0,8.177,8.923l9.151,9.123a1,1,0,0,0,1.416,0l2.31-2.3a1,1,0,0,0,0-1.412L12.6,8.9A6.5,6.5,0,0,0,11.139,1.9Z" transform="translate(1 1)" /></g>
      </Svg>
    </OverlayTrigger>
  )
}
