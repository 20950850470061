import { useEffect, useState } from "react";
import { Form, Table, Col, Row, Container } from "react-bootstrap";
import { useParams } from "react-router";
import Select from 'react-select'
import Utils from '../../utils'
import { APP_URL } from "../../config";
// import { Link } from 'react-router-dom'
import { FaSave } from "react-icons/fa";
import { styleTheme } from "../../styles/SelectTheme";
import { StyleCheckbox, Button } from '../../styles/CommonStyles';
import { AlertForm, ICON } from "../../utils/SweetAlert";
import { ButtonLoading } from '../../components/Loading/ButtonLoading';
import { IconEdit } from '../../components/iconos/IconEdit';
import { IconTrash } from '../../components/iconos/IconTrash';

function Forms() {
    const { id } = useParams();

    const [url, setUrl] = useState([])
    const [index, setIndex] = useState(-1)
    const [form, setForm] = useState({ url: "" })
    const [config, setConfig] = useState({ data: [] })
    const [type, setType] = useState([{ label: 'Página del sitio', value: 'sitio' }, { label: 'Enlace externo', value: 'externo' }])
    const [typeMenu, setTypeMenu] = useState([{ label: 'Horizontal', value: 'horizontal' }, { label: 'Vertical', value: 'vertical' }])
    const [typeMenuA, setTypeMenuA] = useState([{ label: 'Pestaña', value: 'tab' }, { label: 'Botón', value: 'btn' }, { label: 'Enlace', value: 'link' }])
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setForm({ ...form, newTab: false })

        Utils.Petition.get('/pages-modules/id/' + id, (response) => {
            if (!response.data.config.menu) {
                response.data.config.tMenu = 'link'
                response.data.config.menu = 'horizontal'
            }
            if (!response.data.config.data) {
                response.data.config.data = []
            }
            setConfig(response.data.config)
        });

        Utils.Petition.get('/pages/portal/'+ localStorage.getItem('idportal'), (response) => {
            const data = response.data
            let url = []
            for (const key in data) {
                let page = data[key]
                url.push({ label: page.name, value: page.path })
            }
            setUrl(url)
        });
    }, []);

    const handleChangeForm = () => {
        let _config = { ...config }

        if (index === -1) {
            _config.data.push(form)
        } else {
            _config.data[index] = form
        }

        setConfig(_config)
        setIndex(-1)
        setForm({ url: "", newTab: "" })
    }

    const handleSave = (e) => {
        e.preventDefault();
        setIsLoading(true);
        Utils.Petition.post('/pages-modules/update', { id: id, config }, () => {
            if (window.parent) {
                AlertForm(ICON.SUCCESS, 'Informacion Guardada', '');
                //window.parent.postMessage('cerrar popup', APP_URL);
                setIsLoading(false);
            }
        });
    }

    const handleCheckBox = () => {
        if (form.newTab !== undefined)
            setForm({ ...form, newTab: !form.newTab })
        else
            setForm({ ...form, newTab: false })

    }

    const handleForm = (event) => {
        let _form = { ...form, [event.target.name]: event.target.value }
        setForm(_form)
    }

    const handleEdit = (i) => {
        setIndex(i)
        setForm(config.data[i])
    }

    const handleDelete = (i) => {
        let _config = { ...config }
        _config.data.splice(i, 1)
        setConfig(_config)
    }

    const hanldeCancel = () => {
        setIndex(-1)
        setForm({ url: "", newTab: "" })
    }

    const handleChangeOrder = (event,index) => {
        
        let position = index;
        let position_new = event.target.value;
        let _data = config.data
        let el = _data[position]
        let el_new = _data[position_new]

        for (let index = 0; index < _data.length; index++) {
            if(index == position){
                _data[position] = el_new
            }
            if(index == position_new){
                _data[position_new] = el
            }
        }
        setConfig({...config,data:_data})
    }


    const Tabla = () => {
        return (
            <Table striped bordered hover responsive size="sm">
                <thead className="bg-dark text-white">
                    <tr>
                        <th>Orden</th>
                        <th>Nombre</th>
                        <th>Nueva página</th>
                        <th>Url</th>
                        <th>Tipo de página</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {config.data.map((e, i) =>
                        <tr key={i}>
                            <td>
                                <select onChange={(e)=>handleChangeOrder(e,i)}>
                                    {
                                        config.data.map((el,idx) =>
                                            <option selected={idx==i} value={idx}>{idx+1}</option>
                                        )
                                    }
                                </select>
                            </td>
                            <td><b>{e.title}</b></td>
                            <td>{e.newTab ? 'Si' : 'No'}</td>
                            <td>{e.url}</td>
                            <td>{e.type}</td>
                            <td>
                                <IconEdit link onClick={() => handleEdit(i)} /> <IconTrash link onClick={() => handleDelete(i)} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        )
    }

    return (
        <Container fluid>
            <Form>
                <Row>
                    <Col sm={4}>
                        <Form.Group className="mb-3">
                            <Form.Label><b>Titulo</b></Form.Label>
                            <Form.Control
                                type="text"
                                value={form.title ? form.title : ""}
                                onChange={(e) => setForm({ ...form, title: e.target.value })}
                                placeholder="Ingresa el titulo del sitio"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label><b>Tipo de página</b></Form.Label>
                            <Select
                                onChange={(e) => { e !== null && setForm({ ...form, type: e.value }) }}
                                isClearable
                                value={type.filter(aux => aux.value === form.type)}
                                options={type}
                                placeholder='Selecciona una opción'
                                theme={theme => (styleTheme(theme))}
                            />
                        </Form.Group>

                        {
                            form.type === 'externo' &&
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='url'
                                    value={form.url}
                                    onChange={handleForm}
                                    placeholder="Ingresa la URL del sitio"
                                    theme={theme => (styleTheme(theme))}
                                />
                            </Form.Group>
                        }
                        {
                            form.type === 'sitio' &&
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>URL</Form.Label>
                                <Select
                                    name='url'
                                    onChange={(e) => { e !== null && setForm({ ...form, url: e.value }) }}
                                    isClearable
                                    value={url.filter(aux => aux.value === form.url)}
                                    options={url}
                                    placeholder='Selecciona una opción'
                                    theme={theme => (styleTheme(theme))}
                                />
                            </Form.Group>
                        }

                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <StyleCheckbox className="custom-checkbox ml-3">
                                <input className="custom-control-input" type="checkbox" name="newTab" checked={form.newTab ? form.newTab : false} id="newTab" onChange={handleCheckBox} />
                                <label className="custom-control-label" htmlFor="newTab">
                                    ¿Abrir en una nueva ventana?
                                </label>
                            </StyleCheckbox>
                        </Form.Group>
                        <Form.Group>
                            {index === -1//+1>config.data.length
                                ? <Button onClick={handleChangeForm} disabled={!form.title || !form.url} variant="primary">
                                    Agregar
                                </Button>
                                : <>
                                    <Button onClick={handleChangeForm} className="mr-3">
                                        Editar
                                    </Button>
                                    <Button onClick={hanldeCancel} color="gray">
                                        Cancelar
                                    </Button>
                                </>
                            }
                        </Form.Group>
                    </Col>
                    <Col sm={8}>
                        {config && <Tabla />}
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Tipo de menu</Form.Label>
                            <Select
                                name='typeMenu'
                                onChange={(e) => { setConfig({ ...config, menu: e.value }) }}
                                value={typeMenu.filter(e => e.value === config.menu)}
                                options={typeMenu}
                                placeholder={config.menu}
                                theme={theme => (styleTheme(theme))}
                            // placeholder="Horizontal"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Tipo de menus disponibles</Form.Label>
                            {console.log(config)}
                            <Select
                                name='tMenuA'
                                onChange={(e) => { setConfig({ ...config, tMenu: e.value }) }}
                                value={typeMenuA.filter(e => e.value === config.tMenu)}
                                options={typeMenuA}
                                placeholder={config.tMenu}
                                theme={theme => (styleTheme(theme))}
                            />
                        </Form.Group>
                        <Button onClick={handleSave} disabled={isLoading} variant="primary">
                            {isLoading ? <ButtonLoading text="Guardando..." /> : <>Guardar Cambios <FaSave /></>}
                        </Button>
                    </Col>
                </Row>

            </Form>
        </Container>
    )
}

export default Forms;