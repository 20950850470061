import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
/* CUSTOM PROPERTIES - VARIABLES
  ============================= */
:root {
  --c-white: #fff;
  --c-black: #2C2C2B;
  --c-light: #FAFAFA;
  --c-dark: #283449;
  --c-icon: #7C7C80;
  --c-lightgray: #E3E3E3;
  --c-darkgray: #B3B1B2;
  --c-red: #DB191A;
  --c-orange: #EF8220;
  --c-orange-opacity: #EF822040;
  --c-lightorange-opacity: #EF822020;
  --c-lightorange: #F6A421;
  --c-yellow: #F7E82C;
  --c-green: #8EC046;
  --c-darkblue: #034990;
  --c-headerAdmin: #54565B;
  --font-family: 'Montserrat', sans-serif;
}

img {
  width: 100%;
  height: auto;
}

/* GLOBALS
  ============================= */
  html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: var(--c-white);
  color: var(--c-dark);
  font-family: var(---font-family);
}

img {
  width: 100%;
  height: auto;
}

// style SCROLL
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

// focus input 
input.form-control:focus, textarea.form-control:focus, select.form-control:focus {
  border-color: var(--c-orange);
  box-shadow: 0 0 0 0.2rem var(--c-orange-opacity);
}


.deleted label {
  text-decoration: line-through;
}


/* MEDIA QUERIES OPCIONAL
  ============================= */
@media screen and (min-width: 576px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 992px) {}
@media screen and (min-width: 1200px) {
  .modal-dialog {
    
    margin: 1.75rem auto;
  }
}
@media screen and (min-width: 1400px) {}
`;


