import { useState, useEffect, useRef } from 'react';
import Utils from "../../utils";
import { Container, Pagination, Table } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import ViewSummarize from "./viewSummarize";
import CustomModal from '../../components/CustomModal';
import { Button, Container as Cont} from '../../styles/CommonStyles';
import { formatDate } from '../../utils/formatDate';
import {IconView} from '../../components/iconos/iconView';
import { BsDownload } from "react-icons/bs";
import { CustomTd } from './styles';
import { Loading } from '../../components/Loading';
import { AlertForm, ICON } from '../../utils/SweetAlert';
import { PaginationMain } from '../../components/pagination';


function HistoricalImport() {
    
    const [historical, setHistorical] = useState([]);
    const [dataDownload, setDataDownload] = useState([]);
    const [nameFile, setNameFile] = useState('');
    const [msg, setMsg] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [id, setId] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [dataPagination, setDataPagination] = useState([]);
    const csvButton = useRef();

    const TYPE_FILE = {
        data: '/data-files/id',
        error: '/import-errors/id'
    };
    const NAME_FILE = {
        data: 'dataFile.csv',
        error: 'errorsDataFile.csv'
    };
    const ERROR_FILE = {
        data: 'No hay información para descargar. Revisar los erores de cargue',
        error: 'No hubo ningun error en el cargue de la información'
    }
    const headersData = [
        {label: "Código", key: "code"},
        {label: "Nombre del Indicador", key: "name"},
        {label: "Ene", key: "Ene"},
        {label: "Feb", key: "Feb"},
        {label: "Mar", key: "Mar"},
        {label: "Abr", key: "Abr"},
        {label: "May", key: "May"},
        {label: "Jun", key: "Jun"},
        {label: "Jul", key: "Jul"},
        {label: "Ago", key: "Ago"},
        {label: "Sep", key: "Sep"},
        {label: "Oct", key: "Oct"},
        {label: "Nov", key: "Nov"},
        {label: "Dic", key: "Dic"}
    ]

    const headersError = [
        {label: "Código", key: "code"},
        {label: "Nombre", key: "name"},
        {label: "Fila", key: "row"},
        {label: "Observación", key: "comment"}
    ]

    const [headers, setHeaders] = useState([])

    useEffect( () => {
        setLoading(true);
        Utils.Petition.get('/file-imports', 
            (response) => {
                setHistorical(response.data);
                setLoading(false);
            }
        );
    }, []);

    useEffect(()=>{
        if (dataDownload.length) {
            setTimeout(()=>{
                csvButton.current.link.click()
            })
        }
    },[dataDownload]);

    const handleBtnCSV = ({id, type}) => {

        setNameFile(NAME_FILE[type]);

        if( type === 'data') {
            setHeaders(headersData);
        } else {
            setHeaders(headersError);
        }

        Utils.Petition.get(`${TYPE_FILE[type]}/${id}`, 
            (response) => {
                console.log(response.data)
                if(response.data.length) {
                    setDataDownload(response.data);
                } else {
                    AlertForm(ICON.INFO, ERROR_FILE[type], '', 'center', 7000, true)
                }
            }
        )
    }

    const handlePopup = (id) => {
        setId(id)
        setShowModal(true);
    }

    return (
        <Cont>
            <h3>Cargues</h3>
            <Container className="text-center mb-3">
                <Link to='/admin/MassiveImport/upload' className="text-decoration-none text-success font-weight-bold"><Button color="green">Nuevo Cargue</Button></Link>
            </Container>
            <Table hover bordered className="text-center">
                <thead className="bg-dark text-white">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Archivo</th>
                        <th scope="col">Escenario</th>
                        <th scope="col">Año</th>
                        <th scope="col">Observación</th>
                        <th scope="col">Usuario</th>
                        <th scope="col">Archivo</th>
                        <th scope="col">Errores</th>
                        <th scope="col">Resumen</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataPagination.length > 0 &&
                        dataPagination.map( (hist, index) => {
                            return (
                                <tr key={index}>
                                    <td><b>{index + 1}</b></td>
                                    <td>{formatDate(hist.createdAt)}</td>
                                    <td>{hist.name}</td>
                                    <td>{hist.stage.name}</td>
                                    <td>{hist.year}</td>
                                    <td>{hist.comment}</td>
                                    <td>{hist.user.full_name}</td>
                                    <CustomTd><BsDownload onClick={() => {handleBtnCSV({id: hist.id, type: 'data'})}} /></CustomTd>
                                    <CustomTd><BsDownload onClick={() => {handleBtnCSV({id: hist.id, type: 'error'})}} /></CustomTd>
                                    <td><IconView link onClick={() => {handlePopup(hist.id)}} color="black" /></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            {loading && <Loading />}
            {!loading && !historical.length && "No hay resultados."}
            <PaginationMain data={historical} dataPagination={dataPagination} setDataPagination={setDataPagination} />
            <CSVLink ref={csvButton} filename={nameFile} data={dataDownload} headers={headers} />
            <CustomModal title={"Información del cargue"} show={showModal} setShow={setShowModal} size="xl">
                {
                    showModal && 
                    <ViewSummarize id={id}/>
                }
            </CustomModal>
        </Cont>
    )
}

export default HistoricalImport;