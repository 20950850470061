import styled from "styled-components";
import {Theme1} from "./../../styles/Theme1";
export const TableWrapper = styled.div`
    width: 100%;
    table{
            border-top-left-radius: ${Theme1.radius};
            border-top-right-radius: ${Theme1.radius};
            overflow: hidden;
            thead{
                background-color: ${Theme1.orange};
                color: white;
                font-size: 14px;
                th{
                    &:not(:first-child){
                        text-align: center !important;
                    }
                }
                a{
                    color: white;
                    text-decoration: underline;
                }
            }
            tbody{
                font-size: 14px;
                tr{
                    background-color: #f2f2f2;
                    &.tr-empty{
                        th{
                            height: 20px;
                        }
                    }
                    th{
                        font-weight: normal;
                        font-size: 16px;
                        padding: 0.3rem 0.5rem;
                        a{
                            color: ${Theme1.orange};
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                    td{
                        font-size: 16px;
                        padding: 0.3rem 0.5rem;
                        a{
                            color: ${Theme1.orange}
                        }
                    }
                    &.table-secondary{
                        th{
                            font-weight: bold;
                        }
                        td{
                            font-weight: bold;
                        }
                    }
                    &.tr-empty th{
                        font-weight: bold;
                    }
                }
            }
            &.table-sec{
                td, th{
                    padding: .3rem;
                }
                thead{
                    background-color: #f2f2f2;
                    color: #a2a2a2;
                    font-weight: 300;
                    th{
                        font-weight: bold;
                    }
                    a{
                        color:${Theme1.orange};
                    }
                }
            }
        }
        
    
`;