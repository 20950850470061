import React, { useState, useEffect } from "react";
import { Bar } from 'react-chartjs-2';
import Utils from "../../utils";

function BarChartUser ({dataConfig, data, stages}) {

    const [chartData, setChartData] = useState({});
    const meses = [ "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];   

    const chart = () => {
        const xLabels = meses.slice(0, data[0].data.length);
        let dataSetInfo = [];

        stages.map((id, index) => {
            let info = {}
            const data_stage = (data.filter( d => d.indicator === dataConfig.indicator.id && d.stage === id))[0];
            info.data = data_stage.data.map( temp => temp.value );
            info.backgroundColor = dataConfig.stages[index].color;
            info.type = dataConfig.stages[index].type === 'PorDefecto'? 'bar' : dataConfig.stages[index].type || 'bar';
            info.fill = "false";
            info.borderColor = dataConfig.stages[index].color;
            if ( dataConfig.stages[index].hasOwnProperty('labelStage') && dataConfig.stages[index].labelStage !== "" ) {
                info.label = dataConfig.stages[index].labelStage;
            } else {
                info.label = dataConfig.stages[index].label;
            }
            info.borderRadius = 10;  
            dataSetInfo.push(info)
        })

        setChartData({
            labels: xLabels,
            datasets: dataSetInfo
        })
    }
    
    useEffect(() => {
        chart();
    }, []);

    console.log(dataConfig)
    
    return (
        <div>
            <Bar
                data={chartData}
                height={dataConfig.hasOwnProperty('height') && dataConfig.height !== undefined && dataConfig.height !== "" ? dataConfig.height: 400}
                width={600}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {  
                        legend: {
                            display: !dataConfig.hideLegend,
                            position: dataConfig.hasOwnProperty('legendPosition') && dataConfig.legendPosition !== "PorDefecto" ?
                                        dataConfig.legendPosition : "top",
                            labels: {
                                usePointStyle: true,
                                padding: 12,
                                boxWidth: 20,
                                pointStyle: 'circle'
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: function (ctx) {
                                    let label = ctx.dataset.label || '';
                                    let valuePre =  ctx.dataset.data[ctx.dataIndex];
                                    let value = valuePre !== undefined ? valuePre : 0;
                                    let formatT = Math.abs(value) < 100 ? ',.1f' : ',.0f'
                                    value = dataConfig.hasOwnProperty('yLeft') && dataConfig.yLeft !== undefined && dataConfig.yLeft.trim() !== "PorDefecto" ? dataConfig.yLeft  === "$" ?  "$" +  Utils.formatNumber(formatT,valuePre) : Utils.formatNumber(',.1f', valuePre)  + "%"  :  Utils.formatNumber(formatT,valuePre)
                                    return `${label}: ${value}`
                                }
                            }
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: false,
                            grid: {
                                display: false
                            },
                            ticks: {
                                callback: function(value) {
                                    let formatAxes = Math.abs(value) < 100 ? ',.1f' : ',.0f';
                                    return dataConfig.hasOwnProperty('yLeft') && dataConfig.yLeft !== undefined && dataConfig.yLeft.trim() !== "PorDefecto" ? dataConfig.yLeft  === "$" ?  "$" +  Utils.formatNumber(formatAxes,value) : Utils.formatNumber(',.1f', value)  + "%"  :  Utils.formatNumber(formatAxes,value)
                                }
                            },
                            suggestedMin: dataConfig.hasOwnProperty("minYL") && dataConfig.minYL !== undefined && dataConfig.minYL !== "" ? dataConfig.minYL : undefined
                        },
                        x: {
                            grid: {
                                display: false
                            }
                        }
                    }
                }}
            />
        </div>
    )
}
export default BarChartUser