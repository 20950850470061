import { useState, useEffect } from 'react';
import { Pagination  } from "react-bootstrap";
import { Container } from '../../styles/CommonStyles';
/**
 * PaginationMain: Componente que permite la paginación de los datos
 *   @data = es la data o informacion que vamos a paginar
 *   @setDataPagination = es el seteo de los datos que se va a ver en la paginacion.
 *   @numberItems = permite saber cuantos items o datos se van a ver por pagina por defecto 20 datos   
 *  
 * */ 
export const PaginationMain = ({data = [], setDataPagination, numberItems = 20 }) => {

    const [pageActive, setPageActive] = useState(null)

    
      useEffect(() => {
        if (!data) return;
        getData(1);
      }, [data])

    const getPages = () => {
        if (data) {
          const last = Math.ceil(data.length / numberItems);
          let active = parseInt(pageActive);
    
          let pages = [];
          let numberPage = active < 11 ? 1 : active - 10;
          for (let i = 1; i <= 20; i++) {
            if (numberPage <= last) {
              pages.push(
                <Pagination key={numberPage}>
                  <Pagination.Item
                    active={numberPage === active}
                    id={numberPage}
                    onClick={(e) => handlePagination("number", e)}
                  >
                    {numberPage}
                  </Pagination.Item>
                </Pagination>
              );
              numberPage++;
            }
          }
          return pages;
        }
      };
    
      const handlePagination = (name, e = null) => {
        console.log("handle", name);
        if (name === "number") {
          getData(Number(e.target.id));
        } 
          if (name === 'first') {
            getData(1);
          } else if (name === 'prev') {
            if (pageActive - 1 >= 1) {
              getData(pageActive - 1);
            }
          } else if (name === 'next') {
            if (pageActive + 1 <= Math.ceil(data.length / numberItems)) {
              getData(pageActive + 1);
            }
          } else if (name === 'last') {
            getData(Math.ceil(data.length / numberItems));
          }
      };
    
      const getData = (numberPage) => {
        const arr = [];
        const start = (numberPage - 1) * numberItems;
        const end = start + numberItems;
        for (let idx = start; idx < end; idx++) {
          arr.push(data[idx]);
        }
        const filterArr = arr.filter(el => el !== undefined);
        if (data.length === 0) {
          setDataPagination([]);
        } else {
          setDataPagination(filterArr);
        }
    
        setPageActive(numberPage);
      };


    return (
        <Container>
            {
                data?.length > 0 &&
                <Container padding="0px">

                    <Pagination className='justify-content-center'>
                        <Pagination.First
                        onClick={() => handlePagination("first")}
                        />
                        <Pagination.Prev
                        onClick={() => handlePagination("prev")}
                        />
                        {getPages()}
                        <Pagination.Next
                        onClick={() => handlePagination("next")}
                        />
                        <Pagination.Last
                        onClick={() => handlePagination("last")}
                        />
                    </Pagination>
                    
                </Container>
            }
        </Container>
    )
}
