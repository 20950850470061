import { useState, useEffect } from "react";
import { FaRedo, FaSave } from "react-icons/fa";
import { IconEdit } from "../../components/iconos/IconEdit";
import { IconSave } from "../../components/iconos/IconSave";
import { IconCheck } from "../../components/iconos/IconCheck";
import { IconClose } from "../../components/iconos/IconClose";
import { IconTrash } from "../../components/iconos/IconTrash";
import { IconConfig } from '../../components/iconos/IconConfig';
import Utils from "../../utils";
import { AlertForm, AlertHtml, AlertConfirm, ICON } from '../../utils/SweetAlert';
import CustomModal from "../../components/CustomModal";
import { ButtonLoading } from "../../components/Loading/ButtonLoading";
import { Accordion, Card, Button, Row, InputGroup, FormControl } from "react-bootstrap";
import { AccordionStyle } from "./styles";
import { StyleCheckbox } from "../../styles/CommonStyles";
import { dictionaryRoles } from "./dictionary";


function Item({ rols, setRols, index, portalOptions, setPortalOption, infoModules, configRole }) {
  const [editMode, setEditMode] = useState(false);
  const [rolsForm, setRolsForm] = useState({ name: rols[index].name })
  const [modalConfigure, setModalConfigure] = useState(false);
  const [configPermissions, setConfigPermissions] = useState([]);
  const [filterInfoModules, setFilterInfoModules] = useState([]);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  
  useEffect(() => {
    Utils.Petition.get(`/permissions-rols-admin/permissionbyrol/${rols[index].id}`, (response) => {
      console.log(response);
      for (const el of response.data) {
        delete el.id;
        delete el.module;
      }
      setConfigPermissions(response.data);
    }, (e) => {
      console.log(e )
      AlertHtml(ICON.ERROR, 'Ocurrió un error inesperado en configuración de Permisos', e.response)
    });
  }, [rols, index])
  
  useEffect(() => {
    setFilterInfoModules(infoModules);
  }, [infoModules])
  

  const handleEditMode = () => {
    setEditMode(true);
  }


  const handleChangeForm = (event) => {
    setRolsForm({ [event.target.name]: event.target.value });
  }
  const handleSave = () => {
    setEditMode(false);
    let name = rolsForm.name;
    Utils.Petition.post('/rol/update', { id: rols[index].id, name }, (response) => {
      let _rols = [...rols];
      _rols[index].name = name;
      setRols(_rols);
      AlertForm(ICON.SUCCESS, 'Rol actualizado correctamente', '', 'center', null, true);
    }, (e) => {
      AlertHtml(ICON.ERROR, 'Ocurrió un error inesperado al guardar', e.response.data.message)
    });

  }
  
  const handleSaveConfigRol = () => {
    setIsLoadingPermissions(true);
    
      Utils.Petition.post('/permissions-rols-admin/create', configPermissions, (response) => {
        AlertForm(ICON.SUCCESS, 'Permisos Rol creado correctamente', '', 'center', null, true);
        setModalConfigure(false);
      }, (e) => {
        AlertHtml(ICON.ERROR, 'Ocurrió un error inesperado cuando se actualiza permisos rol', e.response.data.message);
      }, () => {
        setIsLoadingPermissions(false);
      })
  }

  const handleCheckbox = (infoModule, element) => {
    console.log("infoModule", infoModule)
    let findPermissionsByRolAndModule = configPermissions.find(el => el.idmodule === infoModule.id);
    let objActions = {};
    if(findPermissionsByRolAndModule) {
      objActions = findPermissionsByRolAndModule.permissions;
      objActions[element] = !objActions[element];
    } else {
      for (const el of infoModule.actions) {
        objActions[el] = element === el ? true:false;
      }
    }
    let filterPermissionsByRolAndModule = configPermissions.filter(el => el.idmodule !== infoModule.id);
    let validate = Object.values(objActions);
    if(validate.every(el => el === false)) {
      setConfigPermissions(filterPermissionsByRolAndModule);
    }else{
      setConfigPermissions([...filterPermissionsByRolAndModule, { idmodule: infoModule.id, idrol: rols[index].id, permissions: objActions}]);
    } 
  }

  const validateCheck = (infoModule, element) => {
    return configPermissions.find(el => el.idmodule === infoModule.id)?.permissions[element] ? true: false;
  }

  const handleCheckAllItems = (infoModule) => {
    console.log(infoModule, validateAllItems(infoModule));
    let validate = validateAllItems(infoModule);
    let filterPermissionsByRolAndModule = configPermissions.filter(el => el.idmodule !== infoModule.id);
    let objActions = {};
    for (const el of infoModule.actions) {
      objActions[el] = !validate;
    }
    let validateValues = Object.values(objActions);
    if(validateValues.every(el => el === false)) {
      setConfigPermissions(filterPermissionsByRolAndModule);
    }else{
      setConfigPermissions([...filterPermissionsByRolAndModule, { idmodule: infoModule.id, idrol: rols[index].id, permissions: objActions}]);
    }
  }
  const validateAllItems = (infoModule) => {
    let filterPermissionsByModule = configPermissions.find(el => el.idmodule === infoModule.id);
    if(!filterPermissionsByModule) return false;
    console.log(filterPermissionsByModule);
    let validate = Object.values(filterPermissionsByModule.permissions);
    return validate.every(el => el === true);
  }
  const handleInput = (e) => {
    console.log(e.target.name, e.target.value);
    let filterSearch = infoModules.filter(el => el.module.toLowerCase().includes(e.target.value));
    setFilterInfoModules(filterSearch);

  }
  return (
    <>
      <tr className={'normal'}>
        <td><b>{rols[index].id}</b></td>  {/* id del rol */}
        <td>
          {!editMode && <label>{rols[index].name}</label>}
          {editMode && <input type="text" className="form-control" name="name" value={rolsForm.name} onChange={handleChangeForm} />}
        </td>
        <td>
          {!editMode && configRole.update && <IconEdit link onClick={handleEditMode} />}
          {editMode && <IconSave link onClick={handleSave} />}
          {configRole.update && <IconConfig textTooltip='Permisos' link onClick={() => setModalConfigure(true)} />}
        </td>
      </tr>

      <CustomModal title={"Configurar Permisos"} size="md" show={modalConfigure} setShow={setModalConfigure} handleSaveButton={handleSaveConfigRol} saveButtonText={isLoadingPermissions ? "Guardando...": "Guardar"} disabled={isLoadingPermissions}>
      <InputGroup className="mb-3 w-75">
        <FormControl
          placeholder="Buscar Modulo"
          aria-label="Buscar Modulo"
          aria-describedby="basic-module"
          type="search"
          size="sm"
          onChange={handleInput}
          name="inputModule"
        />
      </InputGroup>
        
        <AccordionStyle>
          {
            filterInfoModules.map((item, idx) => {
              return (
                <Card key={`module-rol-${item.id}`}>
                  <Card.Header>
                    <Row className="justify-content-between align-items-center">
                      <Accordion.Toggle as={Button} variant="link" eventKey={idx + 1}> {/* item.id ID module */}
                          <b>{item.module}</b>
                      </Accordion.Toggle>
                      <StyleCheckbox className="custom-checkbox ml-4 mt-2">
                        <input className="custom-control-input" type="checkbox" checked={validateAllItems(item)} id={`${item.module}-checkbox`} onChange={() => handleCheckAllItems(item)} />
                        <label className="custom-control-label" htmlFor={`${item.module}-checkbox`}>
                          Todos
                        </label>
                      </StyleCheckbox>
                    </Row>
                  </Card.Header>
                  <Accordion.Collapse eventKey={idx + 1}>
                    <Card.Body>
                      {
                        item.actions.map((el) => {
                          return (
                            <StyleCheckbox key={el} className="custom-checkbox ml-4 mt-2">
                              <input className="custom-control-input" type="checkbox" checked={validateCheck(item,el)} id={`${item.module}-${el}`} onChange={() => handleCheckbox(item, el)} />
                              <label className="custom-control-label" htmlFor={`${item.module}-${el}`}>
                                {dictionaryRoles[el]}
                              </label>
                            </StyleCheckbox>
                          )
                        })
                      }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )
            })
          }
        </AccordionStyle>
        {filterInfoModules.length === 0 && `Ningun resultado`}
      </CustomModal>

    </>
  );

}
export default Item;