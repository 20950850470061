import React, { useState, useEffect } from "react";
import { Doughnut } from 'react-chartjs-2';
import Utils from "../../../utils";

function DoughnutChart({dataConfig, data}) {

    const [chartData, setChartData] = useState({});
    const format_number = Utils.getConfigPortal('format_number');
    const format_percent = Utils.getConfigPortal('format_percent');

    const chart = () => {
        let xlabels = [];
       
        let dataY = dataConfig.indicators.map(ind => {
            let label = ""
            if( ind.hasOwnProperty('labelIndicator') && ind.labelIndicator.trim() !== "" ) label = ind.labelIndicator;
            else label = ind.name;

            let total = 0;
            data.map( d => {
                if(d.value !== null) total = total + Math.abs(parseInt(d.value))
            })

            let temp = 0;
            let value = 0;
            for (let i = 0; i < data.length; i ++) {
                if (data[i].indicator === ind.id && data[i].value !== null) {
                    temp = data[i].value;
                    value  = Utils.formatNumber(format_percent,(Math.abs(temp)/total)*100);
                    label = `${label} ${value}%` 
                    break;
                }
            }
            xlabels.push(label)
            return temp;
        });

        let backgroundColors = dataConfig.indicators.map(indicator => indicator.color);

        setChartData({
            labels:xlabels,
            datasets: [{
                data: dataY,
                backgroundColor: backgroundColors
            }]
        })
    }
    
    useEffect(() => {
        chart();
    }, []);

    return (
        <div>
            <Doughnut
                height={dataConfig.height !== undefined && dataConfig.height !== "" ? dataConfig.height: 400}
                style={{margin:'0 auto'}}
                width={dataConfig.width !== undefined && dataConfig.width !== "" ? dataConfig.width : 400}
                data={chartData}
                options={{
                    responsive: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: 0
                    },
                    plugins: {
                        legend: {
                            display: !dataConfig.hideLegend, 
                            position: dataConfig.hasOwnProperty('legendPosition') && dataConfig.legendPosition !== "PorDefecto" ?
                                        dataConfig.legendPosition : "top",
                            labels: {
                                boxWidth: 10,
                                padding: 5,
                                usePointStyle: true,
                                pointerStyle: 'circle'
                            }
                        },
                        tooltip: {
                            enabled: !dataConfig.hideTooltip,
                            callbacks: {
                                label: function (ctx) {
                                    console.log(ctx)
                                    let label = ctx.label || '';
                                    let valuePre =  ctx.dataset.data[ctx.dataIndex];
                                    let value = valuePre !== undefined ? valuePre : 0;
                                    value = dataConfig.hasOwnProperty('yLeft') && dataConfig.yLeft !== undefined && dataConfig.yLeft.trim() !== "PorDefecto" ? dataConfig.yLeft  === "$" ?  "$" +  Utils.formatNumber(format_number,value) : Utils.formatNumber(format_percent, value)  + "%"  :  Utils.formatNumber(format_number,value)
                                    return `${label}: ${value}`
                                }
                            }
                        },
                    }
                }}
            />
        </div>
    )
}

export default DoughnutChart;