import styled from 'styled-components';
import { Theme1 } from '../styles/Theme1';

export const StyleProperty = styled.div`
    border: 2px solid var(--c-orange);
    border-radius: 0.5rem;
    padding: ${props => props.p ? props.p : '1rem'};
    margin: 2rem 0;
    & h4 {
        padding-bottom: 1rem;
    }
    &> button {
        width: 100%;
        padding: 0;
    }
`;

export const DivWrapper = styled.div`
    width: 100%;
    height: 100vh;
    .container-fluid{
        height: 100%;
        .row{
            height: 99%;
            & > div{
                &:nth-child(2){
                    &>div.fixed{
                        position: fixed;
                    }
                    h3{
                    font-size: 18px;
                    font-weight: bold;
                    color: ${Theme1.orange};
                    }
                }
                height: 100%;
                & > h3{
                    font-size: 18px;
                    font-weight: bold;
                    color: ${Theme1.orange};
                }
                & > form{
                    margin-bottom: 0px !important;

                    &> div:first-child{
                        overflow: auto;
                    }
                }
            }
        }
    }
`;