import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Svg } from '../../styles/CommonStyles';

export const IconDown = ({ color, link, onClick = () => { }, textTooltip = "Bajar" }) => {
    return (

        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-editar`}>
                    {textTooltip}
                </Tooltip>
            }
        >
            <Svg color={color} link={link} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><rect className="a" width="24" height="24" />
                <path className="b" d="M10.59,0,6,4.574,1.41,0,0,1.408,6,7.4l6-5.992Z" transform="translate(6 8.6)" />
            </Svg>
        </OverlayTrigger>

    )
}
