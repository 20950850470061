import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Utils from "../../utils";
import CustomForm from "./functions/CustomForm";

function Form() {
  const [config, setConfig] = useState({});
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    Utils.Petition.get("/pages-modules/id/" + id, (response) => {
      setConfig(response.data.config);
      setIsLoaded(true)
    });
  }, []);

  return (

      <div className="container mt-3 border rounded p-3">
        {isLoaded && <CustomForm dataSaved={config} moduleId={id}></CustomForm>}
      </div>
  );
}

export default Form;
