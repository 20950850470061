import {Fragment} from 'react';
import { Link, NavLink } from 'react-router-dom'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Indicator from './pages/indicators/indicatorData';
import Pages from './pages/pages';
import Indicators from './pages/indicators';
import Business from './pages/business';
import Home from './pages/Home';
import MassiveImport from './pages/massiveImport';
import MassiveImportIndicators from './pages/massiveImportIndicators'
import { Route } from 'react-router';
import Calculator from './components/calculator/Calculator';
import { Header,ContainerNavbar, Footer } from './styles/CommonStyles';
import adminIcon from './assets/images/adminIcon.svg';
import { BsBoxArrowRight } from "react-icons/bs";

import Atributes from './pages/indicators/atributes';
import Stages from './pages/indicators/stages'
import Dictionary from './pages/indicators/dictionary'
import AdminUsers from './pages/adminUsers';
import AdminList from './pages/adminList';
import Audit from './pages/audit';
import RolsContent from './pages/rolsContent';
import RolsAdmin from './pages/rolsAdmin';
import Portal from './pages/portal';
import { useConfigRole } from './Hooks/configRole';

function MasterPage(){
  const { validateView } = useConfigRole();

    return (
      <Fragment> 
        <Header>
         <Navbar variant="dark">
          <ContainerNavbar>
            <Navbar.Brand as={Link} to="/admin"><img src={adminIcon} alt="admin"></img> Administrador</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav>
                {validateView(4) && <Nav.Link as={NavLink} to="/admin/pages">Páginas</Nav.Link> }
                <NavDropdown title="Indicadores" id="basic-nav-dropdown">
                  {validateView(1) && <NavDropdown.Item as={Link} to="/admin/indicators">Buscar</NavDropdown.Item>}
                  {validateView(1, "create") && <NavDropdown.Item as={Link} to="/admin/indicators/create">Crear nuevo</NavDropdown.Item>}
                  <NavDropdown.Divider />
                  {(validateView(1, "import") || validateView(3, "upload_my_indicator")) && <NavDropdown.ItemText>Importación</NavDropdown.ItemText>}
                  {validateView(1, "import") && <NavDropdown.Item as={Link} to="/admin/MassiveImportIndicators">Indicadores</NavDropdown.Item>}
                  {validateView(3, "upload_my_indicator") && <NavDropdown.Item as={Link} to="/admin/MassiveImport">Valores</NavDropdown.Item>}
                  <NavDropdown.Divider />
                  {validateView(1, "export") && <NavDropdown.Item as={Link} to="/admin/indicators/massiveDownload">Exportación</NavDropdown.Item>}
                  <NavDropdown.Divider />
                  {(validateView(5) || validateView(6) || validateView(7)) && <NavDropdown.ItemText>Configuración adicional</NavDropdown.ItemText>}
                  {validateView(5) && <NavDropdown.Item as={Link} to="/admin/indicators/atributes">Atributos</NavDropdown.Item>}
                  {validateView(6) && <NavDropdown.Item as={Link} to="/admin/indicators/stages">Escenarios</NavDropdown.Item>}
                  {validateView(7) && <NavDropdown.Item as={Link} to="/admin/indicators/dictionary">Diccionario</NavDropdown.Item>}
                </NavDropdown>
                <NavDropdown title="Configuraciones" id="basic-nav-dropdown">
                  {(validateView(8) || validateView(10) || validateView(11)) && <NavDropdown.ItemText>Usuarios</NavDropdown.ItemText>}
                  {validateView(8) && <NavDropdown.Item as={Link} to="/admin/users">Administrar</NavDropdown.Item>}
                  {validateView(10) && <NavDropdown.Item as={Link} to="/admin/rols-admin">Roles de administración</NavDropdown.Item>}
                  {validateView(11) && <NavDropdown.Item as={Link} to="/admin/rols-content">Roles de contenido</NavDropdown.Item>}
                  
                  {
                    (validateView(9) || validateView(12) || validateView(12, "update_portal")) && 
                    <>
                      <NavDropdown.Divider />
                      <NavDropdown.ItemText>Configuración adicional</NavDropdown.ItemText>
                    </>
                  }
                  {validateView(12) && <NavDropdown.Item as={Link} to="/admin/business">Negocios</NavDropdown.Item>}
                  {(validateView(12) || validateView(12, "update_portal")) && <NavDropdown.Item as={Link} to="/admin/portal">Portal actual</NavDropdown.Item>}
                  {validateView(9) &&  <NavDropdown.Item as={Link} to="/admin/list">Listas</NavDropdown.Item>}
                </NavDropdown>
                <a className="nav-link" href={decodeURIComponent(localStorage.getItem('site'))}>Volver al sitio</a>
                <a className="nav-link" href={decodeURIComponent(localStorage.getItem('site'))+'/login/logout'}>Salir <BsBoxArrowRight className="ml-2 icon" /></a>
              </Nav>
            </Navbar.Collapse>
            </ContainerNavbar>
          </Navbar>
        </Header>
        <main>
          <Container>
            <Route path="/admin" exact component={() => <Home />} />
            <Route exact path="/admin/indicators" component={validateView(1) ? ()=><Indicators.Search />: () => <Home />} />
            <Route path="/admin/indicators/create" component={validateView(1, "create") ? ()=><Indicators.Create /> : () => <Home />} />
            <Route exact path="/admin/indicator/id/:id" component={()=><Indicator />} />
            <Route path="/admin/business" component={ validateView(12) ? ()=><Business.List /> : ()=> <Home />} />
            <Route path="/admin/indicators/update/:id" component={()=><Indicators.Create />} />
            <Route path="/admin/indicators/massiveDownload" component={validateView(1, "export") ? ()=><Indicators.MassiveDownload /> : () => <Home />} />
            <Route path="/admin/pages" component={validateView(4) ? ()=> <Pages.List />: ()=> <Home /> } />
            <Route exact path="/admin/MassiveImport" component={validateView(3, "upload_my_indicator") ? ()=> <MassiveImport.HistoricalImport />:() => <Home /> } />
            <Route path="/admin/MassiveImport/upload" component={()=> <MassiveImport.ImportData />} />
            <Route exact path="/admin/MassiveImportIndicators" component={validateView(1, "import") ? ()=> <MassiveImportIndicators.HistoricalImport /> : () => <Home />} />
            <Route path="/admin/MassiveImportIndicators/upload" component={()=> <MassiveImportIndicators.ImportData />} />
            <Route path="/admin/indicators/atributes" exact component={validateView(5) ? ()=> <Atributes.List /> : () => <Home />} />
            <Route path="/admin/indicators/stages" exact component={validateView(6) ?()=> <Stages.List />  : () => <Home />} />
            <Route path="/admin/indicators/dictionary" exact component={validateView(7) ? ()=> <Dictionary.List />  : () => <Home />} />
            <Route path="/admin/users" exact component={validateView(8) ? ()=> <AdminUsers.Form /> : () => <Home />} />
            <Route path="/admin/list" exact component={validateView(9) ? ()=> <AdminList.Form />: ()=> <Home />} />
            <Route path="/admin/audit" exact component={validateView(13) ? ()=> <Audit.Form /> : ()=> <Home />} />
            <Route path="/admin/rols-content" exact component={validateView(11) ? ()=> <RolsContent.List /> : () => <Home />} />
            <Route path="/admin/rols-admin" exact component={validateView(10) ? ()=> <RolsAdmin.List /> : () => <Home />} />
            <Route path="/admin/portal" exact component={validateView(12, "update_portal") ? ()=> <Portal.Form /> : () => <Home />} />
          </Container>
        </main>
        <Footer>
          <Container>
            <div className="text-footer">Pie de Pagina</div>
          </Container>
        </Footer>
      </Fragment>
    )
}
export default MasterPage;