import { assertObjectTypeCallProperty } from "@babel/types";
import { useState, useEffect } from "react";
import { Form, FormGroup, Table } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import Select from "react-select";
import { Button } from "../../../styles/CommonStyles";
import { styleTheme } from "../../../styles/SelectTheme";
import Utils from "../../../utils";
import Item from "./ItemPermiso";
import {AlertForm, AlertHtml, ICON} from '../../../utils/SweetAlert';


function ListPermiso({rols, setRols, index}){

    const [permissions, setPermissions] = useState([]);
    const [pagesOptions, setPagesOptions] = useState([]);
    const [filtersOptions, serFiltersOptions] = useState([]);
    const [pages, setPages] = useState({
        id:"",
        name:""
    });

    const [filters, setFilters] = useState({
        id:"",
        name:""
    });

    const [permissionForm, setPermissionForm] = useState({
        idpage:null,
        idfilter:null,
        idrol:rols[index].id,
    });
    
   

   useEffect(() => {

        Utils.Petition.get(`/permissions-rols-contents/permissionbyrol/${rols[index].id}`, (res) => {
            setPermissions(res.data);
            
        });

        Utils.Petition.get("/pages/pagesactives", (res) => {
            setPages(res.data);
            let options = [];
            options.push({ label: 'Todas', value: 0});
            for (let i in res.data) {
                options.push({ label: res.data[i].name, value: res.data[i].id });
            }
            
            setPagesOptions(options);
        });

        Utils.Petition.get("/filter/", (res) => {
            setFilters(res.data);
            let optionsF = [];
            optionsF.push({ label: 'Todas', value: 0});
            for (let i in res.data) {
                optionsF.push({ label: res.data[i].name, value: res.data[i].id });
            }
            
            serFiltersOptions(optionsF);
        });

      }, []);

   
    const handlePagePermisoForm = (event)=>{
        const value = event;
        setPermissionForm({ ...permissionForm, idpage: value });      
    }

    const handleFilterPermisoForm = (event)=>{
        const value = event;
        setPermissionForm({ ...permissionForm, idfilter: value });      
    }
    const handlePortalSave = (event)=>{
        event.preventDefault();
        Utils.Petition.post('/permissions-rols-contents/create', {idpage:permissionForm.idpage.value,idfilter:permissionForm.idfilter.value,idrol:rols[index].id}, (response)=>{
            setPermissionForm({
                idpage: null,
                idfilter: null,
                idrol:rols[index].id
            })

            Utils.Petition.get(`/permissions-rols-contents/permissionbyrol/${rols[index].id}`, (res) => {
                setPermissions(res.data);
                console.log(res.data);
            });
            AlertForm(ICON.SUCCESS, 'Permiso creado correctamente', '', 'center', null, true);

        },(e) => {
            AlertHtml(ICON.ERROR,'Ocurrió un error inesperado',e.response.data.message)
        });
    }

    return (
        <div className="d-flex flex-wrap m-4">
            <div className="container-fluid mb-2">
                <div className="card">
                    <div className="card-header">
                        <b>Crear nuevo permiso</b>
                    </div>
                    <div className="card-body">
                        <Form>
                            <FormGroup>
                                <label>
                                <b>Pagina</b>
                                </label>
                                <Select
                                    placeholder="Ninguno"
                                    name="page"
                                    isClearable
                                    value={permissionForm.idpage}
                                    onChange={handlePagePermisoForm}
                                    options={pagesOptions}
                                    theme={theme => (styleTheme(theme))}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>
                                <b>Regiones</b>
                                </label>
                                <Select
                                    placeholder="Ninguno"
                                    name="filter"
                                    isClearable
                                    value={permissionForm.idfilter}
                                    onChange={handleFilterPermisoForm}
                                    options={filtersOptions}
                                    theme={theme => (styleTheme(theme))}
                                />
                            </FormGroup>
                            
                            <Button className="mr-2" onClick={handlePortalSave}> {/* saveDB */}
                                Guardar <FaSave />
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
           
            <div className="container-fluid">
            <h2 className="py-5 text-center">Permisos</h2>
            <Table hover className="editable-table mt-3">
                <thead className="bg-dark text-white">
                <tr>
                    <th width="5%">#</th>
                    <th width="80%">Pagina</th>
                    <th width="80%">Región</th>
                    <th width="15%">Acciones</th>
                </tr>
                </thead>
                <tbody>
                {permissions.map((permission, index) => {
                    return <Item key={permission.id} permissions={permissions} setPermissions={setPermissions} index={index} pagesOptions={pagesOptions} setPagesOptions = {setPagesOptions} filtersOptions={filtersOptions} serFiltersOptions={serFiltersOptions}  />
                })}
                </tbody>
            </Table>
        </div>





        </div>
    )
}
export default ListPermiso;