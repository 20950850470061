import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Svg } from '../../styles/CommonStyles';

export const IconCheck = ({ color, link, onClick = () => { }, textTooltip = "Habilitar" }) => {
  return (

    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-check`}>
          {textTooltip}
        </Tooltip>
      }
    >
      <Svg color={color} link={link} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><rect className="a" width="24" height="24" />
        <path className="b" d="M16,18H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H16a2,2,0,0,1,2,2V16A2,2,0,0,1,16,18ZM3.41,7.59h0L2,9l5,5,9-9L14.59,3.58,7,11.17,3.41,7.59Z" transform="translate(3 3)" />
      </Svg>
    </OverlayTrigger>

  )
}
