import { useEffect, useState } from "react";
import { Button, Col, Form, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import CustomModal from "../../CustomModal";
import Utils from '../../../utils';
import { styleTheme } from "../../../styles/SelectTheme";
import { StyleCheckbox } from "../../../styles/CommonStyles";
import { AlertForm, ICON } from "../../../utils/SweetAlert";

function Indicator({ disabledBtnIndicator, formula, setFormula ,isCompare=false}) {
    const [showModal, setShowModal] = useState(false);
    const [valid, setValid] = useState(false)
    const [indicator, setIndicator] = useState({
        acumulated: false,
        searchIndicator: false,
        indicator: null,
        stage: null,
        year: 'a',
        month: 'm',

    });
    useEffect(() => {
        if (indicator.stage !== null && (!indicator.searchIndicator || indicator.indicator)) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [indicator]);
    const handleModal = () => {
        setShowModal(true)
    }
    const handleSearchIndicator = () => {
        const isSearch = !indicator.searchIndicator;
        setIndicator({ ...indicator, searchIndicator: isSearch, indicator: null })
    }
    const handleAcumulated = (event) => {
        setIndicator({ ...indicator, acumulated: event.target.checked });
    }
    const handleFormIndicador = (event) => {
        setIndicator({ ...indicator, [event.target.name]: event.target.value });
    }
    const loadIndicators = (inputValue) => {

        if (inputValue.length > 3) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    Utils.Petition.get(
                        '/indicators/name-code-indicator/' + inputValue,
                        (response) => {
                            resolve(response.data)
                        }
                    )
                }, 100);
            });
        }

        return new Promise((resolve) => resolve([]));
    }

    const loadStages = (inputValue) => {

        if (inputValue.length > 2) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    Utils.Petition.get(
                        '/stages/name-stage/' + inputValue,
                        (response) => {
                            resolve(response.data)
                        }
                    )
                }, 100);
            });
        }

        return new Promise((resolve) => resolve([]));
    }

    const selectIndicator = (data) => {
        setIndicator({ ...indicator, indicator: data });
    }

    const selectStage = (data) => {
        setIndicator({ ...indicator, stage: data });
    }
    const handleSaveButton = (event) => {
        event.preventDefault();
        if (valid) {
            let idindicator = (indicator.indicator) ? '-' + indicator.indicator.id : '';
            let concatenation = (formula) ? formula + '||' : '';
            let indicator_formula = '@I' + idindicator + ',' + ((indicator.acumulated) ? indicator.stage.id * -1 : indicator.stage.id) + ',' + indicator.year + ',' + indicator.month;
            setFormula((!isCompare ? concatenation: "") + indicator_formula);
            setShowModal(false);
        } else {
            AlertForm(ICON.WARNING, 'Ingresa un escenario y un indicador en caso de que sea necesario');
        }
    }

    return (
        <span>
            <Button variant="warning" className="mb-2" disabled={disabledBtnIndicator} onClick={handleModal}>Agregar un indicador</Button>
            <CustomModal title={"Agregar indicador"} show={showModal} setShow={setShowModal} handleSaveButton={handleSaveButton} saveButtonText="Agregar">
                <Form.Group>
                    <StyleCheckbox className="custom-checkbox ml-3">
                        <input className="custom-control-input" type="checkbox" name="acumulated" id="acumulated" checked={indicator.acumulated} value="acumulated" onChange={handleAcumulated} />
                        <label className="custom-control-label" htmlFor="acumulated">
                            <b>Indicador acumulado</b>, suma automáticamente los valores hasta el mes actual
                        </label>
                    </StyleCheckbox>
                </Form.Group>
                <Form.Group>
                    <ToggleButtonGroup type="radio" name="search-indicators" onChange={handleSearchIndicator} value={indicator.searchIndicator ? 2 : 1}>
                        <ToggleButton variant="outline-primary" value={1}><b>Usar indicador actual</b></ToggleButton>
                        <ToggleButton variant="outline-primary" value={2}><b>Buscar indicador</b></ToggleButton>
                    </ToggleButtonGroup>
                </Form.Group>
                {
                    indicator.searchIndicator &&
                    <Form.Group as={Row} controlId="searchIndicator">
                        <Form.Label column sm="3">
                            Indicador
                        </Form.Label>
                        <Col sm="9">
                            <AsyncSelect
                                cacheOptions
                                isClearable
                                loadOptions={loadIndicators}
                                onChange={selectIndicator}
                                placeholder="Buscar indicador"
                                theme={theme => (styleTheme(theme))}
                            />
                        </Col>
                    </Form.Group>
                }
                <Form.Group as={Row} controlId="searchStage">
                    <Form.Label column sm="3">Escenario</Form.Label>
                    <Col sm="9">
                        <AsyncSelect
                            cacheOptions
                            isClearable
                            loadOptions={loadStages}
                            onChange={selectStage}
                            placeholder="Buscar escenario"
                            theme={theme => (styleTheme(theme))}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="searchStage">
                    <Form.Label column sm="3">Año</Form.Label>
                    <Col sm="9">
                        <select onChange={handleFormIndicador} name="year" value={indicator.year} className="form-control">
                            <option value="a">Año actual</option>
                            <option value="a-1">Año pasado</option>
                            <option value="a-2">Hace 2 años</option>
                            <option value="a-3">Hace 3 años</option>
                            <option value="a-4">Hace 4 años</option>
                            <option value="a-5">Hace 5 años</option>
                            <option value="a-6">Hace 6 años</option>
                        </select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="searchStage">
                    <Form.Label column sm="3">Mes</Form.Label>
                    <Col sm="9">
                        <select onChange={handleFormIndicador} name="month" value={indicator.month} className="form-control">
                            <option value="m">Mes actual</option>
                            <option value="m-1">Mes pasado</option>
                            <option value="m-2">Hace 2 meses</option>
                            <option value="m-3">Hace 3 meses</option>
                            <option value="m-4">Hace 4 meses</option>
                            <option value="m-5">Hace 5 meses</option>
                            <option value="m-6">Hace 6 meses</option>
                        </select>
                    </Col>
                </Form.Group>

            </CustomModal>
        </span>
    );
}

export default Indicator;