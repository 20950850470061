import { useState } from "react";
import {FaRedo, FaSave } from "react-icons/fa";
import { IconEdit } from "../../components/iconos/IconEdit";
import { IconSave } from "../../components/iconos/IconSave";
import { IconCheck } from "../../components/iconos/IconCheck";
import { IconClose } from "../../components/iconos/IconClose";
import { IconTrash } from "../../components/iconos/IconTrash";
import { IconConfig } from '../../components/iconos/IconConfig';
import Utils from "../../utils";
import {AlertForm, AlertHtml, AlertConfirm, ICON} from '../../utils/SweetAlert';
import CustomModal from "../../components/CustomModal";
import { ButtonLoading } from "../../components/Loading/ButtonLoading";
import ListPermiso from "./permisos/ListPermiso";


function Item({rols, setRols, index, portalOptions, setPortalOption, configRole}){
    const [editMode, setEditMode] = useState(false);
    const [rolsForm, setRolsForm] = useState({name:rols[index].name})

    const [modalConfigure, setModalConfigure] = useState(false);
    const handleEditMode = ()=>{
        setEditMode(true);
    }
  

    const handleChangeForm= (event)=>{
        setRolsForm({[event.target.name]: event.target.value});
    }
    const handleSave = ()=>{
        setEditMode(false);
        let name = rolsForm.name;
        Utils.Petition.post('/rol/update', {id:rols[index].id, name}, (response) =>{
            let _rols = [...rols];
            _rols[index].name=name;
            setRols(_rols);
            AlertForm(ICON.SUCCESS, 'Rol actualizado correctamente', '', 'center', null, true);
        },(e) => {
            AlertHtml(ICON.ERROR,'Ocurrió un error inesperado',e.response.data.message)
        });
        
    }

    return (
        <>
            <tr className={'normal'}>
                <td><b>{rols[index].id}</b></td>
                <td>
                    {!editMode && <label>{rols[index].name}</label>}
                    {editMode && <input type="text" className="form-control" name="name" value={rolsForm.name} onChange={handleChangeForm} />}
                </td>
                <td>
                    {!editMode && configRole.update && <IconEdit link onClick={handleEditMode}/> }
                    {editMode && <IconSave  link onClick={handleSave}/> }
                    {configRole.update && <IconConfig textTooltip = 'Permisos' link onClick={()=>setModalConfigure(true)}/>} 
                </td>
            </tr>

            {
                modalConfigure &&
                <CustomModal title={"Permisos para "+rols[index].name} show={modalConfigure} setShow={setModalConfigure} size="xl">
                    {
                        modalConfigure && <ListPermiso rols={rols} setRols={setRols} index={index} />
                    }
                </CustomModal>
            }
        </>
    );

}
export default Item;