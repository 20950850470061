import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { AlertForm, ICON } from "../../utils/SweetAlert";
import Utils from "../../utils";
import Item from "./Item";
import { Container, StyleInput, StyleSelect } from "../../styles/CommonStyles";
import { IconSave } from "../../components/iconos/IconSave";
import { Loading } from "../../components/Loading";
import { useConfigRole } from "../../Hooks/configRole";

function List(){
    const [portals, setPortals] = useState([]);
    const [portal, setPortal] = useState(0);
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState({
        template:"Default",
        name:"",
        path:""
    })
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [idportal, setIdportal] = useState(localStorage.getItem('idportal'));
    const { configRoleByIdModule } = useConfigRole(5);

    useEffect(()=>{
        
        setLoading(true);
        Utils.Petition.get('/portals', function(response){
            setPortals(response.data);
            let _portal = response.data[0];

            setPortal(response.data[0]);
            setIdportal(response.data[0].id);
            Utils.Petition.get('/pages/portal/'+_portal.id, function(response){
                setPages(response.data);
            })
        });
        Utils.Petition.get('/templates', function(response){
            setTemplates(response.data);
            setLoading(false);
        });

    }, []);

    const handleChangeForm = (event)=>{
        setPage({...page, [event.target.name]:event.target.value});
    }
    const handleSave = ()=>{
        let newPage = {...page};
        let newPages = [...pages];
        if(!newPage.name || !newPage.template){
            return AlertForm(ICON.WARNING, "Campos Incompletos");
        }
        newPage.idportal = idportal;
        newPage.deleted='0';
        setPage({
            template:"Default",
            name:"",
            path:""
        });

        Utils.Petition.post('/pages', newPage, function(response){
            newPages.push(response.data);
            setPages(newPages);
        });
        
    }
    const handleChangePortal = (event) => {
        setIdportal(event.target.value)
        Utils.Petition.get('/pages/portal/'+event.target.value, function(response){
            setPages(response.data);
        })
    }
    return (
    <Container>
        <h2>Gestión de páginas</h2>
        {loading && <Loading height="80vh"/>}
        {!loading && portals.length > 1 && 
            <StyleSelect margin="1rem" onChange={handleChangePortal} value ={idportal}>
                {
                    portals.map((e,i)=>{
                        return(
                            <option key={i} value={e.id}>{e.name}</option>
                        )
                    })
                }
            </StyleSelect>
        }
        {!loading && <Table hover>
            <thead className="bg-dark text-white">
                <tr>
                    <th width="5%">#</th>
                    <th width="22%">Nombre</th>
                    <th width="28%">Plantilla</th>
                    <th width="40%">Enlace</th>
                    <th width="5%">Acciones</th>
                </tr>
            </thead>
            <tbody>
                {
                    pages.map((page)=>{
                        return (
                            <Item key={page.id} templates={templates} data={page} configRol={configRoleByIdModule} />
                        )
                    })
                }
                {configRoleByIdModule.create && <tr>
                    <td></td>
                    <td>
                        <StyleInput type="text" name="name" placeholder="Nueva página" value={page.name} onChange={handleChangeForm} />
                    </td>
                    <td>
                        <StyleSelect value={page.template} name="template" onChange={handleChangeForm}>
                          {
                            templates.map((template)=>{
                              return <option key={template.code} value={template.code}>{template.name}</option>
                            })
                          }
                        </StyleSelect>
                    </td>
                    <td>
                        <StyleInput type="text" name="path" placeholder="/" value={page.path}  onChange={handleChangeForm} />
                    </td>
                    <td>
                        <IconSave color="black" link onClick={handleSave}/>
                    </td>
                </tr> }
            </tbody>
        </Table>  }
        
    
    </Container>
    )
}
export default List;