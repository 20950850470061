import { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import Utils from '../../utils';
import Select from 'react-select';
import { Container, StyleCheckbox, Button } from '../../styles/CommonStyles';
import { CustomAttributes, CustomCard } from './styles';
import { styleTheme } from '../../styles/SelectTheme';
import { AlertForm, ICON } from '../../utils/SweetAlert';
import { ButtonLoading } from '../../components/Loading/ButtonLoading';
import { FaDownload, FaSave } from 'react-icons/fa';
import { Col, Form, Row } from 'react-bootstrap';

const MassiveDownload = () => {
  const filter_name = Utils.getConfigPortal('filter_name');
  const [nombre, setNombre] = useState({ id: '1', name: 'Nombre', check: false, value: '', options: [] });
  const [filter, setFilter] = useState({ id: '2', name: filter_name, check: false, value: '', options: [] });
  const [responsable, setResponsable] = useState({
    id: '3',
    name: 'Responsable',
    check: false,
    value: '',
    options: [],
  });
  const [atributos, setAtributos] = useState([]);
  const [stages, setStages] = useState([]);
  const [years, setYears] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [nameTemplate, setNameTemplate] = useState('');
  const [optionsTemplate, setOptionsTemplate] = useState([]);

  const csvButton = useRef();

  useEffect(() => {
    dataTemplate(null);
    getTemplate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data.length) {
      setTimeout(() => {
        csvButton.current.link.click();
        setLoading(false);
      });
    }
  }, [data]);

  const dataTemplate = (data) => {
    let currentYear = new Date().getFullYear();
    let _years = [];
    for (let i = currentYear - 5; i < currentYear + 2; i++) {
      if (data) {
        let find = data.years.find((el) => el.label === i);
        if (find) {
          _years.push({ label: find.label, check: find.check });
          continue;
        }
      }
      _years.push({ label: i, check: false });
    }
    setYears(_years);

    Utils.Petition.get(`/dictionary`, function (response) {
      let options = [];
      for (const key in response.data) {
        options.push({ id: response.data[key].id, value: response.data[key].name, label: response.data[key].name });
      }
      setNombre({
        ...nombre,
        check: data ? data.attributes.name.check : false,
        value: data ? data.attributes.name.value : '',
        options,
      });
    });

    Utils.Petition.get(`/filter`, function (response) {
      let options = [];
      for (const key in response.data) {
        options.push({ id: response.data[key].id, value: response.data[key].name, label: response.data[key].name });
      }
      setFilter({
        ...filter,
        check: data ? data.attributes.filter.check : false,
        value: data ? data.attributes.filter.value : '',
        options,
      });
    });

    Utils.Petition.get(`/users/indicator-updaters`, function (response) {
      let options = [];
      for (const key in response.data) {
        options.push({
          id: response.data[key].id,
          value: response.data[key].full_name,
          label: response.data[key].full_name,
        });
      }
      setResponsable({
        ...responsable,
        check: data ? data.attributes.responsable.check : false,
        value: data ? data.attributes.responsable.value : '',
        options,
      });
    });

    Utils.Petition.get(`/indicator-atribute`, function (response) {
      let filter = [];
      response.data.forEach((e) => {
        let options = [];
        for (const key in e.lists_options) {
          options.push({
            id: e.lists_options[key].id,
            value: e.lists_options[key].label,
            label: e.lists_options[key].label,
          });
        }
        let find = data ? data.attributes.others.find((el) => Number(el.id) === e.id) : null;
        if (options.length > 0) {
          filter.push({
            id: `${e.id}`,
            name: e.name,
            check: find ? find.check : false,
            value: find ? find.value || '' : '',
            options,
            type: e.object_type,
          });
        } else {
          filter.push({ id: `${e.id}`, name: e.name, check: find ? find.check : false, type: e.object_type });
        }
      });
      setAtributos(filter);
    });

    Utils.Petition.get(`/stages/stages`, function (response) {
      setStages(
        response.data.map((e) => {
          let find = data ? data.stages.find((el) => Number(el.id) === e.id) : null;
          return { id: e.id, label: e.name, check: find ? find.check : false, type: e.type };
        })
      );
    });
  };

  const getTemplate = () => {
    Utils.Petition.get(
      '/templates-massive-exports/',
      (response) => {
        setOptionsTemplate(response.data.map((el) => ({ label: el.name, value: el.id })));
      },
      (err) => {
        console.log(err, err.response);
        AlertForm(ICON.ERROR, 'No se pudo obtener plantillas de Importación Masiva');
      }
    );
  };
  const handleBtnCsvLink = () => {
    setLoading(true);
    let headers = [];
    const { nombre, filter, responsable, atributes, stages, years } = getFilterData();
    let dNombre = 0,
      dFilter = 0,
      dResponsable = 0,
      dAtributes = '',
      dStages = 0,
      dYear = 0;

    nombre.forEach((e) => {
      if (e) {
        if (e.value === '') dNombre = '*';
        else if (dNombre !== 0 || dNombre !== '*') dNombre = `${e.value}`;
      }
    });
    filter.forEach((e) => {
      if (e) {
        if (e.value === '') dFilter = '*';
        else if (dFilter !== 0 || dFilter !== '*') dFilter = `${e.value}`;
      }
    });
    responsable.forEach((e) => {
      if (e) {
        if (e.value === '') dResponsable = '*';
        else if (dResponsable !== 0 || dResponsable !== '*') dResponsable = `${e.value}`;
      }
    });
    atributes.forEach((e) => {
      if (e != null) {
        if (e.type === 'text') e.value = '';

        if (dAtributes === '') dAtributes = `${e.id}-${e.value}-${e.name}`;
        else dAtributes += `,${e.id}-${e.value}-${e.name}`;
      }
    });
    if (dAtributes === '') {
      dAtributes = 0;
    }

    stages.map((e) =>
      e != null && dStages === 0
        ? (dStages = `${e.id}-${encodeURIComponent(e.label)}-${e.type}`)
        : (dStages += `,${e.id}-${encodeURIComponent(e.label)}-${e.type}`)
    );
    years.map((e) => (e != null && dYear === 0 ? (dYear = `${e.label}`) : (dYear += `,${e.label}`)));

    Utils.Petition.get(
      `/indicators/nombre/${dNombre}/filter/${dFilter}/responsable/${dResponsable}/atributes/${dAtributes}/stages/${dStages}/years/${dYear}`,
      function (response) {
        if (response.data.length === 0)
          AlertForm(
            ICON.WARNING,
            'No se encontraron datos en la consulta',
            'Por favor realiza una consulta diferente o revisa tu consulta actual',
            'center',
            null,
            true
          );
        else {
          headers.push({ label: 'Código', key: 'code' });

          if (dNombre !== 0) {
            headers.push({ label: 'Nombre', key: 'indicador' });
          }

          if (dFilter !== 0) {
            headers.push({ label: filter_name, key: 'region' });
          }

          if (dResponsable !== 0) {
            headers.push({ label: 'Responsable', key: 'responsable' });
          }

          if (dAtributes !== 0) {
            atributes.forEach((e) => {
              headers.push({ label: e.name, key: e.name });
            });
          }

          const currentHeaders = headers.length;

          if (dStages !== 0 && dYear !== 0) {
            const months = [
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ];
            let { data } = response;
            for (let j = 0; j < data.length; j++) {
              if (data[j].stage) {
                for (let i = 0; i < months.length; i++) {
                  headers.push({
                    label: data[j].stage + ' ' + months[i] + ' ' + data[j].year,
                    key: data[j].stage + ' ' + months[i] + ' ' + data[j].year,
                  });
                }
              }
            }

            let aux = data.shift();
            for (const prim in data) {
              let basico = data[prim];
              let i = 0;
              for (const key in basico.data) {
                let _currentHeaders = currentHeaders + prim * (Object.keys(basico.data[key]).length - 1);
                let sec = basico.data[key];
                for (const ke in sec) {
                  if (ke !== 'id') {
                    aux[i][headers[_currentHeaders++].key] = sec[ke];
                  }
                }
                i++;
              }
            }
            setHeaders(headers);
            setData(aux);
          } else {
            setHeaders(headers);
            setData(response.data);
          }
        }
      },
      (err) =>
        AlertForm(
          ICON.WARNING,
          'Hubo un error al hacer la petición',
          'Por favor intentalo nuevamente',
          'center',
          null,
          true
        ),
      () => setLoading(false)
    );
  };

  const getFilterData = () => {
    let data = {
      nombre: [],
      filter: [],
      responsable: [],
      atributes: [],
      stages: [],
      years: [],
    };
    nombre.check === true && data.nombre.push(nombre);
    filter.check === true && data.filter.push(filter);
    responsable.check === true && data.responsable.push(responsable);
    data.atributes = atributos.filter((e) => e.check);
    data.stages = stages.filter((e) => e.check);
    data.years = years.filter((e) => e.check);

    return data;
  };

  const handleInputChange = (event) => {
    const { name } = event.target;
    setYears(
      years.map((i) => {
        if (i.label === parseInt(name)) {
          return { ...i, check: !i.check };
        }
        return i;
      })
    );
  };

  const handleInputChangeStage = (event) => {
    const { name } = event.target;
    setStages(
      stages.map((i) => {
        if (i.label === name) {
          return { ...i, check: !i.check };
        }
        return i;
      })
    );
  };

  const handleInputChangeNombre = (event) => {
    setNombre({ ...nombre, check: !nombre.check });
  };

  const handleInputChangeFilter = (event) => {
    setFilter({ ...filter, check: !filter.check });
  };

  const handleInputChangeResponsable = (event) => {
    setResponsable({ ...responsable, check: !responsable.check });
  };

  const handleInputChangeAtributos = (event) => {
    const { name } = event.target;
    setAtributos(
      atributos.map((i) => {
        if (i.name === name) {
          return { ...i, check: !i.check };
        }
        return i;
      })
    );
  };

  const handleChangeSelectNombre = (event) => {
    if (event) {
      let { label } = event;
      setNombre({ ...nombre, check: true, value: label });
    } else setNombre({ ...nombre, check: false, value: '' });
  };

  const handleChangeSelectFilter = (event) => {
    if (event) {
      let { id } = event;
      setFilter({ ...filter, check: true, value: id });
    } else setFilter({ ...filter, check: false, value: '' });
  };

  const handleChangeSelectResponsable = (event) => {
    if (event) {
      let { id } = event;
      setResponsable({ ...responsable, check: true, value: id });
    } else setResponsable({ ...responsable, check: false, value: '' });
  };

  const handleChangeSelect = (event) => {
    if (event) {
      let { label } = event;
      setAtributos(
        atributos.map((e) => {
          for (const key in e.options) {
            if (e.options[key].label === label) {
              return { ...e, value: e.options[key].id, check: true };
            }
          }
          return e;
        })
      );
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!nameTemplate) return AlertForm(ICON.WARNING, 'El nombre esta vacio');
    let findName = optionsTemplate.find((el) => el.label.toLowerCase() === nameTemplate.toLowerCase());
    if (findName) return AlertForm(ICON.WARNING, 'Existe una plantilla guardado con este nombre');
    setIsLoadingSave(true);
    let _form = {
      attributes: {
        name: { check: nombre.check, value: nombre.value },
        filter: { check: filter.check, value: filter.value },
        responsable: { check: responsable.check, value: responsable.value },
        others: atributos.map((el) => ({ check: el.check, value: el.value || '', id: el.id })),
      },
      stages,
      years,
    };
    Utils.Petition.post(
      '/templates-massive-exports/',
      {
        name: nameTemplate,
        config: _form,
      },
      (response) => {
        AlertForm(ICON.SUCCESS, response.message);
        setNameTemplate('');
        getTemplate();
      },
      (err) => {
        console.log(err, err.response);
        AlertForm(ICON.ERROR, 'Error al Guardar Importación Masiva');
      },
      () => {
        setIsLoadingSave(false);
      }
    );
  };
  const handleSelectTemplate = (e) => {
    Utils.Petition.get(
      `/templates-massive-exports/id/${e.value}`,
      (response) => {
        dataTemplate(response.data[0].config);
      },
      (err) => {
        console.log(err, err.response);
        AlertForm(ICON.ERROR, `Error al consultar plantilla ${e.label} Importación Masiva`);
      }
    );
  };
  return (
    <Container>
      <Row>
        <Col cols={12} lg={6}>
          <CustomCard className="card">
            <div className="card-header">
              <b>Guardar Plantilla</b>
            </div>
            <div className="card-body">
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <Row className="align-items-center">
                    <Col md={3}>
                      <b>Nombre</b>
                    </Col>
                    <Col md={5}>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={nameTemplate}
                        onChange={(e) => setNameTemplate(e.target.value)}
                      />
                    </Col>
                    <Col md={4}>
                      <Button type="submit" disabled={isLoadingSave}>
                        {isLoadingSave ? (
                          <ButtonLoading text="Guardando..." />
                        ) : (
                          <>
                            Guardar <FaSave />
                          </>
                        )}{' '}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          </CustomCard>
        </Col>
        <Col cols={12} lg={6}>
          <CustomCard className="card">
            <div className="card-header">
              <b>Ver Plantilla</b>
            </div>
            <div className="card-body">
              <Row className="align-items-center">
                <Col md={4}>
                  <b>Plantilla</b>
                </Col>
                <Col md={8}>
                  <Select
                    options={optionsTemplate}
                    onChange={handleSelectTemplate}
                    placeholder="Seleccione una Opción"
                    theme={(theme) => styleTheme(theme)}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 999 }),
                    }}
                  />
                </Col>
              </Row>
            </div>
          </CustomCard>
        </Col>
      </Row>
      <Row>
        <Col cols={12} lg={5}>
          <CustomAttributes>
            <h3 className="text-muted text-center py-3">Atributos</h3>
            <div>
              <div className="content">
                <div className="checkbox">
                  <div className="input-group-prepend">
                    <StyleCheckbox className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        name="Código"
                        id="codigo"
                        disabled
                        checked={true}
                      />
                      <label className="custom-control-label" htmlFor="codigo">
                        Código
                      </label>
                    </StyleCheckbox>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="checkbox">
                  <div className="input-group-prepend">
                    <StyleCheckbox className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        name={nombre.name}
                        checked={nombre.check}
                        onChange={handleInputChangeNombre}
                        id={'nombre-' + nombre.id}
                      />
                      <label className="custom-control-label" htmlFor={'nombre-' + nombre.id}>
                        {nombre.name}
                      </label>
                    </StyleCheckbox>
                  </div>
                </div>
                <div className="select">
                  <Select
                    onChange={handleChangeSelectNombre}
                    isClearable={true}
                    options={nombre.options}
                    placeholder="Selecciona una opción"
                    theme={(theme) => styleTheme(theme)}
                    value={nombre.options.find((el) => el.value === nombre.value)}
                  />
                </div>
              </div>
              <div className="content">
                <div className="checkbox">
                  <div className="input-group-prepend">
                    <StyleCheckbox className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={'filter-' + filter.id}
                        name={filter.name}
                        checked={filter.check}
                        onChange={handleInputChangeFilter}
                      />
                      <label className="custom-control-label" htmlFor={'filter-' + filter.id}>
                        {filter.name}
                      </label>
                    </StyleCheckbox>
                  </div>
                </div>
                <div className="select">
                  <Select
                    onChange={handleChangeSelectFilter}
                    isClearable={true}
                    options={filter.options}
                    placeholder="Selecciona una opción"
                    theme={(theme) => styleTheme(theme)}
                    value={filter.options.find((el) => el.id === filter.value)}
                  />
                </div>
              </div>
              <div className="content">
                <div className="checkbox">
                  <div className="input-group-prepend">
                    <StyleCheckbox className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={'responsable-' + responsable.id}
                        name={responsable.name}
                        checked={responsable.check}
                        onChange={handleInputChangeResponsable}
                      />
                      <label className="custom-control-label" htmlFor={'responsable-' + responsable.id}>
                        {responsable.name}
                      </label>
                    </StyleCheckbox>
                  </div>
                </div>
                <div className="select">
                  <Select
                    onChange={handleChangeSelectResponsable}
                    isClearable={true}
                    options={responsable.options}
                    placeholder="Selecciona una opción"
                    theme={(theme) => styleTheme(theme)}
                    value={responsable.options.find((el) => el.id === responsable.value)}
                  />
                </div>
              </div>
              {atributos.map((e, i) => (
                <div className="content" key={e.id}>
                  <div className="checkbox">
                    <div className="input-group-prepend">
                      <StyleCheckbox className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={'atribute-' + e.id}
                          name={e.name}
                          checked={e.check}
                          onChange={handleInputChangeAtributos}
                        />
                        <label className="custom-control-label" htmlFor={'atribute-' + e.id}>
                          {e.name}
                        </label>
                      </StyleCheckbox>
                    </div>
                  </div>
                  {e.options && (
                    <div className="select">
                      <Select
                        onChange={handleChangeSelect}
                        isClearable={true}
                        options={e.options}
                        placeholder="Selecciona una opción"
                        theme={(theme) => styleTheme(theme)}
                        value={e.options.find((el) => el.id === e.value)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </CustomAttributes>
        </Col>
        <Col cols={12} lg={5}>
          <CustomAttributes>
            <h3 className="text-muted text-center">Escenarios</h3>

            {stages.map((e) => (
              <div className="input-group px-3 py-1" key={e.id}>
                <div className="input-group-prepend">
                  <StyleCheckbox className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={e.id}
                      name={e.label}
                      checked={e.check}
                      onChange={handleInputChangeStage}
                    />
                    <label className="custom-control-label" htmlFor={e.id}>
                      {e.label}
                    </label>
                  </StyleCheckbox>
                </div>
              </div>
            ))}
          </CustomAttributes>
        </Col>
        <Col cols={6} lg={2}>
          <CustomAttributes>
            <h3 className="text-muted text-center">Períodos</h3>
            {years.map((e) => (
              <div className="input-group px-3 py-1" key={e.label}>
                <div className="input-group-prepend">
                  <StyleCheckbox className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={e.label}
                      name={e.label}
                      checked={e.check}
                      onChange={handleInputChange}
                    />
                    <label className="custom-control-label" htmlFor={e.label}>
                      {e.label}
                    </label>
                  </StyleCheckbox>
                </div>
              </div>
            ))}
          </CustomAttributes>
        </Col>
      </Row>
      <div className="text-center">
        <Button className="m-4" disabled={loading} onClick={handleBtnCsvLink}>
          {loading ? (
            <ButtonLoading text="Preparando Archivos..." />
          ) : (
            <>
              Descargar <FaDownload />
            </>
          )}
        </Button>
      </div>

      <CSVLink ref={csvButton} data={data} headers={headers} />
    </Container>
  );
};

export default MassiveDownload;
