import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Utils from '../../utils'
import { Col, Container, Row, Accordion, Card } from 'react-bootstrap';
import ColorPicker from "../../components/ColorPicker";
import IndicatorForm from "../../components/IndicatorForm"
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import Graphic from "./Graphic";

import ColorSquare from "./ColorSquare";
import { APP_URL } from "../../config";
import { Loading } from "../../components/Loading";
import { StyleCheckbox, Button } from '../../styles/CommonStyles';
import { styleTheme } from "../../styles/SelectTheme";
import { DivWrapper, StyleProperty } from '../style';
import { AccordionStyle } from './style';
import { IconTrash } from '../../components/iconos/IconTrash';
import { AlertForm, ICON } from "../../utils/SweetAlert";


function Form() {
    const { id } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [loadedPre, setLoadedPre] = useState(false);
    const [config, setConfig] = useState({});
    const [columnLabel, setColumnLabel] = useState("");
    const [serieLabel, setSerieLabel] = useState("");
    const [serieColor, setSerieColor] = useState("");
    const [multipleColors, setMultipleColors] = useState(false);
    const [msjView, setmsjView] = useState("");
    const [stages, setStages] = useState([]);
    const currentMonth = useState(parseInt(localStorage.getItem('month')));
    const currentYear = useState(parseInt(localStorage.getItem('year')));
    const [years, setYears] = useState([
        {value: 2012, label: '2012'},
        {value: 2013, label: '2013'},
        {value: 2014, label: '2014'},
        {value: 2015, label: '2015'},
        {value: 2016, label: '2016'},
        {value: 2017, label: '2017'},
        {value: 2018, label: '2018'},
        {value: 2019, label: '2019'},
        {value: 2020, label: '2020'},
        {value: 2021, label: '2021'},
        {value: 2022, label: '2022'},
        {value: 2023, label: '2023'},
        {value: 2024, label: '2024'}
    ]);

    const [form, setForm] = useState({
        type: "",
        title: "",
        height: "",
        width: "",
        indicators: [],
        completeYear: false,
        indicatorsComplete: [],
        stages: [],
        series: [],
        columns: [],
        indicatorsStages: [],
        stagesSerie: false,
        infoTotal: false,
        stagesSerieSelected: [],
        indicatorTotalSelected: [],
        stagesTotalSelected: [],
        othersIndForLabels: {},
        othersIndForTotal: {}
    })

    useEffect(() => {
        Utils.Petition.get(
            '/pages-modules/id/' + id,
            (response) => {
                if (Object.keys(response.data.config).length > 0) {
                    setConfig(response.data.config);
                    setForm({
                        ...form,
                        type: response.data.config.type,
                        title: response.data.config.title,
                        height: response.data.config.height,
                        labelTotal: response.data.config.labelTotal,
                        option: response.data.config.option,
                        months: response.data.config.months,
                        years: response.data.config.years,
                        backgroundTotalLabel: response.data.config.backgroundTotalLabel,
                        indicators: response.data.config.indicators,
                        indicatorsComplete: response.data.config.indicatorsComplete,
                        stages: response.data.config.stages,
                        series: response.data.config.series,
                        columns: response.data.config.columns,
                        indicatorsStages: response.data.config.indicatorsStages,
                        completeYear: response.data.config.completeYear,
                        beginningYear: response.data.config.beginningYear,
                        stagesSerie: response.data.config.stagesSerie,
                        infoTotal: response.data.config.infoTotal,
                        stagesSerieSelected: response.data.config.stagesSerieSelected,
                        indicatorTotalSelected: response.data.config.indicatorTotalSelected,
                        stagesTotalSelected: response.data.config.stagesTotalSelected,
                        colorTotalLabel: response.data.config.colorTotalLabel,
                        hideLegend: response.data.config.hideLegend,
                        legendPosition: response.data.config.legendPosition,
                        hideY: response.data.config.hideY,
                        percentWaterFall: response.data.config.percentWaterFall, 
                        addTotalColumn: response.data.config.addTotalColumn,
                        colorPositive: response.data.config.colorPositive,
                        colorNegative: response.data.config.colorNegative,
                        posTitle: response.data.config.posTitle,
                        otherIndSerie: response.data.config.otherIndSerie,
                        othersIndForLabels: response.data.config.othersIndForLabels,
                        otherIndTotal: response.data.config.otherIndTotal,
                        othersIndForTotal: response.data.config.othersIndForTotal,
                        hideTooltip: response.data.config.hideTooltip,
                        yLeft: response.data.config.yLeft,
                        yRight: response.data.config.yRight,
                        minYL: response.data.config.minYL,
                        minYR: response.data.config.minYR,
                        width: response.data.config.width
                    });
                    setLoadedPre(true)
                }
                setLoaded(true)
            }
        );
        Utils.Petition.get(
            '/stages',
            (response) => {
                setStages(response.data)
            }
        )
    }, []);


    const handleForm = (event) => {
        const value = event.target.value;
        let temp = JSON.parse(JSON.stringify(form.indicators));
        let flag = form.completeYear;
        if(event.target.name === "type" && value === "BarChart") {
            temp = [];
            flag = false
        }
        setForm({
            ...form,
            completeYear: flag,
            indicators: temp,
            [event.target.name]: value
        });
    }

    const loadIndicators = (inputValue) => {

        if (inputValue.length > 3) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    Utils.Petition.get(
                        '/indicators/name-code-indicator/' + inputValue,
                        (response) => {
                            resolve(response.data)
                        }
                    )
                }, 100);
            });
        }

        return new Promise((resolve) => resolve([]));
    }

    const loadStages = (inputValue) => {

        return new Promise((resolve) => {
            setTimeout(() => {
                Utils.Petition.get(
                    '/stages',
                    (response) => {
                        resolve(response.data)
                    }
                )
            }, 100);
        });

        return new Promise((resolve) => resolve([]));
    }

    const selectInfoInd = (temp, aux) => {
        let i = 0;
        while (i < temp.length) {
            const found = aux.filter(data => data.id === temp[i].id)
            if (found.length === 0) {
                temp.splice(i, 1);
                i--;
            };
            i++;
        }

        while (i < aux.length) {
            const found = temp.filter(data => data.id === aux[i].id)
            if (found.length === 0) {
                temp.push(aux[i]);
            };
            i++;
        }
        return temp;
    }

    const selectIndicators = (data, flag) => {

        setLoadedPre(false)
        let typeInd = "";

        if (flag === 0) {
            typeInd = "indicators";
        } else if (flag === 1) {
            typeInd = "indicatorsComplete";
            for (let i = 0; i < data.length; i++) data[i].complete = 1;
        }
        let temp = JSON.parse(JSON.stringify(data));
        
        if( data.length > 0) {
            temp[temp.length-1].value = (data[data.length-1].value + "").split("-")[0] + "-" + Date.now()
        };

        setForm({
            ...form,
            [typeInd]: temp
        })
    }

    const selectStages = (data) => {

        setLoadedPre(false)

        setForm({
            ...form,
            stages: data
        })
    }

    const handleOptions = (event) => {
        const id = (event.target.name.split("-"))[2];
        const type = (event.target.name.split("-"))[1];
        const variable = (event.target.name.split("-"))[0];
        let temp = JSON.parse(JSON.stringify(form[type]));
        temp[id][variable] = event.target.checked;

        setForm({
            ...form,
            [type]: temp
        })
    }

    const handleOptionsOthersInd = (event) => {
        const id = (event.target.name.split("-"))[5];
        const indexS = (event.target.name.split("-"))[3];
        const indexC = (event.target.name.split("-"))[4];
        const type = (event.target.name.split("-"))[1];
        const typeData = (event.target.name.split("-"))[2];
        const variable = (event.target.name.split("-"))[0];
        let temp = JSON.parse(JSON.stringify(form[type]));
        temp[`${indexS}-${indexC}`][typeData][id][variable] = event.target.checked

        setForm({
            ...form,
            [type]: temp
        })
    }

    const handleOthersInd = (event) => {
        const id = (event.target.name.split("-"))[5];
        const indexS = (event.target.name.split("-"))[3];
        const indexC = (event.target.name.split("-"))[4];
        const type = (event.target.name.split("-"))[1];
        const typeData = (event.target.name.split("-"))[2];
        const variable = (event.target.name.split("-"))[0];
        let temp = JSON.parse(JSON.stringify(form[type]));
        temp[`${indexS}-${indexC}`][typeData][id][variable] = event.target.value

        setForm({
            ...form,
            [type]: temp
        })
    }

    const handleOptionsOthersIndTotal = (event) => {
        const id = (event.target.name.split("-"))[4];
        const index = (event.target.name.split("-"))[3];
        const type = (event.target.name.split("-"))[1];
        const typeData = (event.target.name.split("-"))[2];
        const variable = (event.target.name.split("-"))[0];
        let temp = JSON.parse(JSON.stringify(form[type]));
        temp[`${index}`][typeData][id][variable] = event.target.checked

        setForm({
            ...form,
            [type]: temp
        })
    }


    const selectStageLabelBar = (data, index, type) => {
        let temp = JSON.parse(JSON.stringify(form[type]));

        temp[index].stageBarSelected = data;

        setForm({
            ...form,
            [type]: temp
        })
    }

    const handleCompleteYear = (event) => {
        let temp = false;
        let indicators = JSON.parse(JSON.stringify(form.indicatorsComplete));
        if (event.target.checked) temp = true;
        else indicators = [];

        setForm({
            ...form,
            completeYear: temp,
            indicatorsComplete: indicators
        })
    }

    const handleBeginningYear = (event) => {
        let temp = 0;
        let month = Number(localStorage.getItem('month'));
        if (event.target.checked) temp = 1;

        setForm({
            ...form,
            beginningYear: temp,
            months: month + 1
        })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (loadedPre) {
            const data = {
                id: id,
                config: form
            };

            Utils.Petition.post(
                '/pages-modules/update', data,
                (response) => {
                    if (window.parent) {
                        AlertForm(ICON.SUCCESS, response.message);
                        //window.parent.postMessage('cerrar popup', APP_URL);
                    }
                }
            )
        }
    }

    const handleIndicator = (event) => {
        const id = (event.target.name.split("-"))[2];
        const type = (event.target.name.split("-"))[1];
        const variable = (event.target.name.split("-"))[0];
        let temp = JSON.parse(JSON.stringify(form[type]));
        let indstages = JSON.parse(JSON.stringify(form.indicatorsStages));
        if( variable === "serieLabel" ) {
            for (let i = 0; i<indstages.length; i++) {
                indstages[i].serieLabel = indstages[i].serieLabel === temp[id][variable] ? event.target.value : indstages[i].serieLabel;                
            }
        }
        if( variable === "columnLabel" ) {
            for (let i = 0; i<indstages.length; i++) {
                indstages[i].columnLabel = indstages[i].columnLabel === temp[id][variable] ? event.target.value : indstages[i].columnLabel;                
            }
        }
        temp[id][variable] = event.target.value;
        if(variable === "numYears") {
            temp[id].infoYears = [];
            for(let i=1; i <= parseInt(event.target.value); i++) {
                temp[id].infoYears.push({
                    labelYear: "",
                    colorYear: '#777273'
                }) 
            }
        };
        
        setForm({            
            ...form,
            [type]: temp,
            indicatorsStages: indstages
        })
    }

    const addSerie = (event) => {

        event.preventDefault();

        let seriesNow = JSON.parse(JSON.stringify(form.series));

        seriesNow.push({
            serieLabel,
            serieColor,
            multipleColors
        })

        setForm({
            ...form,
            series: seriesNow
        })

        setSerieLabel("")
    }

    const addColumn = (event) => {
        event.preventDefault()

        let seriesNow = JSON.parse(JSON.stringify(form.columns));

        seriesNow.push({
            columnLabel
        });

        setForm({
            ...form,
            columns: seriesNow
        })

        setColumnLabel("");
    }

    const removeSerie = (index, event) => {

        let temp = form.series;
        let tempIndStage = JSON.parse(JSON.stringify(form.indicatorsStages));
        let temp_labels = form.othersIndForLabels !== undefined ? JSON.parse(JSON.stringify(form.othersIndForLabels)) : undefined;

        if (tempIndStage.length > 0) {
            let i = 0
            while (i < tempIndStage.length) {
                if (temp[index].serieLabel === tempIndStage[i].serieLabel) {
                    tempIndStage.splice(i, 1)
                    i--;
                }
                i++;
            }
        }

        if( temp_labels !== undefined) {
            for ( let clave in temp_labels) {
                let num = clave.split("-")[0];
                let col = clave.split("-")[1];
                if( num === `${index}`) {
                    delete temp_labels[clave];
                }
                if( num > `${index}`) {
                    temp_labels[`${num - 1}-${col}`] = temp_labels[`${num}-${col}`]
                    delete temp_labels[`${num}-${col}`]
                }
            }
        }

        temp.splice(index, 1)
        setForm({
            ...form,
            series: temp,
            indicatorsStages: tempIndStage,
            othersIndForLabels: temp_labels
        })
    }

    const removeColumn = (index, event) => {

        let temp = form.columns;
        let tempIndStage = JSON.parse(JSON.stringify(form.indicatorsStages));
        let temp_labels = form.othersIndForLabels !== undefined ? JSON.parse(JSON.stringify(form.othersIndForLabels)) : undefined;
        let temp_labelsTotal = form.othersIndForTotal !== undefined ? JSON.parse(JSON.stringify(form.othersIndForTotal)) : undefined;
    
        if (tempIndStage.length > 0) {
            let i = 0;
            while (i < tempIndStage.length) {
                if (temp[index].columnLabel === tempIndStage[i].columnLabel) {
                    tempIndStage.splice(i, 1);
                    i--;
                }
                i++;
            }
        }
        temp.splice(index, 1);

        if( temp_labels !== undefined) {
            for ( let clave in temp_labels) {
                let row = parseInt(clave.split("-")[0]);
                let num = parseInt(clave.split("-")[1]);
                if( num === `${index}`) {
                    delete temp_labels[clave];
                }
                if( num > `${index}`) {
                    temp_labels[`${row}-${num - 1}`] = temp_labels[`${row}-${num}`]
                    delete temp_labels[`${row}-${num}`]
                }
            }
        }

        if( temp_labelsTotal !== undefined) {
            for ( let clave in temp_labelsTotal) {
                if( clave === `${index}`) {
                    delete temp_labelsTotal[clave];
                }
                if( clave > `${index}`) {
                    temp_labelsTotal[`${parseInt(clave) - 1}`] = temp_labelsTotal[clave]
                    delete temp_labelsTotal[clave]
                }
            }
        }

        setForm({
            ...form,
            columns: temp,
            indicatorsStages: tempIndStage,
            othersIndForLabels: temp_labels,
            othersIndForTotal: temp_labelsTotal
        })

    }

    const handleColumnLabel = (event) => {
        setColumnLabel(event.target.value)
    }

    const handleSerieLabel = (event) => {
        setSerieLabel(event.target.value)
    }

    const handleMultipleColors = (event) => {
        setMultipleColors(event.target.checked)
    }

    const selectStackedBar = (dataInd, dataStage, serieLabel, columnLabel) => {

        setLoadedPre(false);
    
        let temp = JSON.parse(JSON.stringify(form.indicatorsStages));
        let bandera = 0;

        if (temp.length > 0) {

            for (let i = 0; i < temp.length; i++) {
                if (temp[i].serieLabel === serieLabel && temp[i].columnLabel === columnLabel) {
                    if (Object.keys(dataInd).length > 0) {
                        temp[i].indicator = dataInd;

                    } else {
                        temp[i].stage = dataStage;
                    }
                    bandera = 1
                    break;
                }
            }

            if (bandera === 0) {
                if (Object.keys(dataInd).length > 0) {
                    temp.push({
                        serieLabel,
                        columnLabel,
                        indicator: dataInd,
                    })
                } else {
                    temp.push({
                        serieLabel,
                        columnLabel,
                        stage: dataStage,
                    })
                }
            }

        } else {
            if (Object.keys(dataInd).length > 0) {
                temp.push({
                    serieLabel,
                    columnLabel,
                    indicator: dataInd,
                })
            } else {
                temp.push({
                    serieLabel,
                    columnLabel,
                    stage: dataStage,
                })
            }
        }
            
        
        setForm({
            ...form,
            indicatorsStages: temp
        })
    }

    const colorStackedBar = (e, serieLabel, columnLabel) => {

        let findIndicatorStage = form.indicatorsStages.find(stage => stage.serieLabel === serieLabel && stage.columnLabel === columnLabel);
        if(findIndicatorStage) {
            let tempIS = {...findIndicatorStage};
            tempIS.checked = e.target.checked;
            let filterIS = form.indicatorsStages.filter(stage => !(stage.serieLabel === serieLabel && stage.columnLabel === columnLabel));
            setForm({
                ...form,
                indicatorsStages: [...filterIS, tempIS]
            })
        }
    }
    const validateIndicatorStage = (serieLabel,columnLabel,action ) => {

        return form.indicatorsStages.find(stage => stage.serieLabel === serieLabel  && stage.columnLabel === columnLabel)?.[action] || false;

    }
    const findValue = (serieLabel, columnLabel, type) => {
        let temp = JSON.parse(JSON.stringify(form.indicatorsStages));

        if (temp.length > 0) {
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].serieLabel === serieLabel && temp[i].columnLabel === columnLabel) {
                    return temp[i][type]
                }
            }
        }
    }

    const checkIndicatorsStages = (temp) => {
        let contador = 0

        for (let i = 0; i < temp.length; i++) {
            if (temp[i].indicator && temp[i].stage) {
                contador++;
            }
        }

        if (contador === temp.length) {
            return true;
        } else {
            return false;
        }
    }

    const removeInfo = (event, serieLabel, columnLabel) => {
        setLoadedPre(false)
        const typePri = (event.target.name.split('-'))[0]
        const typeSec = (event.target.name.split('-'))[1]

        let temp = JSON.parse(JSON.stringify(form.indicatorsStages));

        if (temp.length > 0) {
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].serieLabel === serieLabel && temp[i].columnLabel === columnLabel) {
                    if ( temp[i].hasOwnProperty(typePri) && temp[i].hasOwnProperty(typeSec) ) {
                        delete temp[i][typePri]
                    } else {
                        temp.splice(i,1);
                    }
                }
            }
        }
        setForm({
            ...form,
            indicatorsStages: temp
        })
    }

    const checkIndicators = (temp) => {
        
        if (temp.length === 0) {
            return false;
        }

        let contador = 0
        
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].stage) {
                contador++;
            }
        }

        if (contador === temp.length) {
            return true;
        } else {
            return false;
        }
    };

    const orderFunction = (temp, value, id) => {

        let aux = new Array(temp.length);
        
        if( value < id ) {
            for(let i=0; i<temp.length; i ++) {
                if(i < value || i > id) {
                    aux[i] = temp[i];
                } else if (i === value) {
                    aux[i] = temp[id]
                } else {
                    aux[i] = temp[i-1]
                }
            }
        } else if (value > id) {
            for(let i=temp.length-1; i>=0; i--) {
                if(i > value || i < id) {
                    aux[i] = temp[i];
                } else if (i === value) {
                    aux[i] = temp[id]
                } else {
                    aux[i] = temp[i+1]
                }
            }
        }

        return aux;
    }

    const orderArray = ({event, type = ''}) => {
        const value = event.target.value - 1;
        const typeArray = event.target.name.split('-')[1];
        const id = event.target.name.split('-')[2];
        let temp = JSON.parse(JSON.stringify(form[typeArray]));;
        let aux = [];

        if( type !== '') {
            const indexS = event.target.name.split('-')[3];
            let arr = orderFunction(JSON.parse(JSON.stringify(temp[indexS][type])), value, id);
            temp[indexS][type] = arr;
            aux = temp;
        } else {
            aux = orderFunction(temp, value, id);
        }
    
        setForm({
            ...form,
            [typeArray]: aux
        })

    }

    const handleCheckOptions = (event) => {
        setLoadedPre(false)
        const variable = event.target.name    
        setForm({
            ...form,
            [variable]: event.target.checked
        });
    }

    const selectStagesLabel = (data, type) => {

        setForm({
            ...form,
            [type]: data
        })
    }

    const selectOtherIndsForLabel = ({data, type, index, typeData }) => {
        setLoadedPre(false)
        let temp = form[type] !== undefined ? JSON.parse(JSON.stringify(form[type])) : {};
        if ( temp.hasOwnProperty(`${index}`) ) {
            temp[`${index}`][typeData] = data
        } else {
            temp[`${index}`] = {
                [typeData]: data
            }
        }
        setForm({
            ...form,
            [type]: temp
        })
        
    }

    const selectStagesForOthersInd = ({data, type, indexT, index, typeData }) => {
        setLoadedPre(false)
        let temp = JSON.parse(JSON.stringify(form[type]));
        temp[`${indexT}`].indicator[index][typeData] = data
        
        setForm({
            ...form,
            [type]: temp
        })

    }

    const handleInfoYear = (event) => {
        const variable = event.target.name.split('-')[0];
        const type = event.target.name.split('-')[1];
        const index = event.target.name.split('-')[2];
        const arr = event.target.name.split('-')[3];
        const indexY = event.target.name.split('-')[4];

        let temp = JSON.parse(JSON.stringify(form[type]));
        temp[index][arr][indexY][variable] = event.target.value;

        setForm({
            ...form,
            [type]: temp
        })
    }

    const selectWatefallStage = (event, type, index) => {

        let temp = JSON.parse(JSON.stringify(form[type]));

        temp[index].stage = event;

        setForm({
            ...form,
            [type]: temp
        })
    }

    const verifyOtherInds = (temp) => {
        let contador = 0;
        if( temp === undefined) return false;
        
        for( let i = 0; i < form.series.length; i++) {
            for( let j = 0; j < form.columns.length; j++ ) {
                if( temp.hasOwnProperty(i + "-" + j) && temp[i + "-" + j].hasOwnProperty('indicator') && temp[i + "-" + j].indicator.length > 0 ) {
                    for ( let k = 0; k < temp[i + "-" + j].indicator.length; k++ ){
                        if(!temp[i + "-" + j].indicator[k].hasOwnProperty('stage')) {
                            return false
                        }
                    }
                } else {
                    return false
                }
            }
        };
        return true
    };

    const verifyOtherIndsTotal = (temp) => {
        let contador = 0;
        if( temp === undefined) return false;
        
        for( let i = 0; i < form.columns.length; i++ ) {
            if( temp.hasOwnProperty(`${i}`) && temp[`${i}`].hasOwnProperty('indicator') && temp[`${i}`].indicator.length > 0 ) {
                for ( let k = 0; k < temp[`${i}`].indicator.length; k++ ){
                    if(!temp[`${i}`].indicator[k].hasOwnProperty('stage')) {
                        return false
                    }
                }
            } else {
                return false
            }
        }
        return true
    }

    const verifyAmountOtherInds = (temp) => {
        let contador = 0;
        let amountInd = temp['0'].indicator.length;
        if( temp === undefined) return false;
        for( let i = 1; i < form.columns.length; i++ ) {
            if( temp[`${i}`].indicator.length !== amountInd  ) {
                return false
            } 
        }
        return true
    }

    useEffect(() => {
        let mounted = true;
        const bootstrap = async () => {

            await setLoadedPre(false);

            if (form.type === "StackedBarChart") {
                if (form.stagesSerie && !form.otherIndSerie && form.stagesSerieSelected.length === 0 ) {
                    await setmsjView("Debe escoger al menos un escenario en la parte de escenarios por serie")
                } else if (form.infoTotal && !form.otherIndTotal && (form.stagesTotalSelected.length === 0 || form.indicatorTotalSelected.length === 0)) {
                    await setmsjView("Debe seleccionar por lo menos un indicador y un escenario en la parte de escenarios totales")
                } else if (form.columns.length === 0 && form.series.length === 0) {
                    await setmsjView("Debe escoger una columna y una serie")
                } else if (form.columns.length === 0) {
                    await setmsjView("Debe escoger una columna")
                } else if (form.series.length === 0) {
                    await setmsjView("Debe escoger una serie")
                } else if (form.indicatorsStages.length === 0) {
                    await setmsjView("Debe elegir por lo menos un par indicador - escenario")
                } else if (form.infoTotal && !form.otherIndTotal && form.indicatorTotalSelected.length !== form.columns.length) {
                    await setmsjView(`Debe elegir ${form.columns.length} indicadores en la parte de escenarios totales`)
                } else if(form.stagesSerie && form.otherIndSerie && !verifyOtherInds(form.othersIndForLabels)) {
                    await setmsjView(`Debe elegir todas las parejas indicador-escenario en labels por serie`)
                } else if(form.infoTotal && form.otherIndTotal && !verifyOtherIndsTotal(form.othersIndForTotal)) {
                    await setmsjView(`Debe elegir todas las parejas indicador-escenario en labels Total`)
                } else if(form.infoTotal && form.otherIndTotal && verifyOtherIndsTotal(form.othersIndForTotal) && !verifyAmountOtherInds(form.othersIndForTotal)) {
                    await setmsjView(`Todas las columnas de labels Total deben tener la misma cantidad de indicadores`) 
                } else {
                    if (checkIndicatorsStages(form.indicatorsStages)) {
                        await setLoadedPre(true);
                    } else {
                        await setmsjView("A cada pareja de Serie - Columna debe seleccionar el indicador y el escenario")
                    }
                }
            }

            if (form.type === "BarChart") {
                if (form.option && form.option === "PorDefecto") {
                    await setmsjView("Debe colocar una opción de gráfica")
                } else {
                    if (form.option === "lastMonths") {
                        if (form.months && form.months !== "" && parseInt(form.months) > 0) {
                            if (checkIndicators(form.indicators)) {
                                await setLoadedPre(true);
                            } else {
                                await setmsjView("Debe haber por lo menos un indicador con su escenario");
                            }
                        } else {
                            await setmsjView("Debe colocar una cantidad de meses mayor a 0");
                        }
                    };

                    if (form.option === "lastYears") {
                        if (form.years && form.years !== "" && parseInt(form.years) > 0) {
                            if (checkIndicators(form.indicators)) {
                                await setLoadedPre(true);
                            } else {
                                await setmsjView("Debe haber por lo menos un indicador con su escenario");
                            }
                        } else {
                            await setmsjView("Debe colocar una cantidad de años mayor a 0");
                        }
                    };

                    if (form.option === "currentYear") {
                        if (form.indicators.length === 0 && (form.indicatorsComplete === undefined || form.indicatorsComplete.length === 0)) {
                            await setmsjView("Debe elegir un indicador con su escenario");
                        } else if (form.indicators.length > 0 && !checkIndicators(form.indicators) && (form.indicatorsComplete === undefined || form.indicatorsComplete.length === 0)) {
                            await setmsjView("Cada indicador debe tener su escenario");
                        } else if (form.indicators.length === 0 && (form.indicatorsComplete !== undefined && form.indicatorsComplete.length > 0 && !checkIndicators(form.indicatorsComplete))) {
                            await setmsjView("Cada indicador de pronóstico debe tener su escenario");
                        } else if (form.indicators.length > 0 && !checkIndicators(form.indicators) && (form.indicatorsComplete !== undefined && form.indicatorsComplete.length > 0 && !checkIndicators(form.indicatorsComplete))) {
                            await setmsjView("Cada indicador e indicador de pronóstico debe tener un escenario");
                        } else if (form.indicators.length > 0 && !checkIndicators(form.indicators) && (form.indicatorsComplete !== undefined && form.indicatorsComplete.length > 0 && checkIndicators(form.indicatorsComplete))) {
                            await setmsjView("Cada indicador debe tener su escenario");
                        } else if (form.indicators.length > 0 && checkIndicators(form.indicators) && (form.indicatorsComplete !== undefined && form.indicatorsComplete.length > 0 && !checkIndicators(form.indicatorsComplete))) {
                            await setmsjView("Cada indicador de pronóstico debe tener su escenario");
                        } else {
                            await setLoadedPre(true);
                        }
                    }
                };
            }

            if (form.type === "DoughnutChart") {
                if ( form.height <= 0 && form.height !== "") {
                    await setmsjView("La altura de la gráfica debe ser mayor a 0")
                } else if (form.indicators.length > 0 && form.stages.length > 0) {
                    await setLoadedPre(true);
                } else {
                    await setmsjView("Debe haber al menos un escenario y un indicador seleccionado")
                }
            }
 
            if (form.type === "WaterfallChart" ) {
                if (form.indicators.length > 0 && checkIndicators(form.indicators)) {
                    await setLoadedPre(true);
                } else if (form.indicators.length === 0) {
                    await setmsjView("Debe escoger por lo menos un indicador")
                } else if(!checkIndicators(form.indicators)) {
                    await setmsjView("Cada indicador debe tener un escenario")
                }
            }            
        }
        if(mounted){
            bootstrap();
        }        
        return () => mounted = false;

    }, [form]);

    //console.log(form)

    return (
        <DivWrapper className="divx">
            {
                !loaded && <Loading text="Cargando Configuración" />
            }
            {
                loaded &&
                <Container fluid>
                    <Row>
                        <Col sm={5}>
                            <h3>Configuración</h3>
                            <form onSubmit={handleSubmit} className="my-3 ml-2">
                                <Container fluid className="mb-1">
                                <div className="form-group">
                                    <Row>
                                        <Col><b>Tipo de gráfica</b></Col>
                                        <Col>
                                            <select className="form-control" id="type" name="type" value={form.type} onChange={handleForm}>
                                                <option key="0" value="PorDefecto">Seleccionar ...</option>
                                                <option key="1" value="StackedBarChart"> Barras apiladas</option>
                                                <option key="2" value="BarChart">Barras/Línea básico</option>
                                                <option key="3" value="WaterfallChart">Cascada</option>
                                                <option key="4" value="DoughnutChart">Anillos / Torta</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="form-group">
                                    <Row>
                                        <Col><b>Título</b></Col>
                                        <Col>
                                            <input type="text" className="form-control" id="title" name="title" onChange={handleForm} value={form.title} />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row>
                                        <Col><b>Posición del Título</b></Col>
                                        <Col>
                                            <select className="form-control" id="posTitle" name="posTitle" value={form.posTitle} onChange={handleForm}>
                                                <option key="0" value="PorDefecto">Seleccionar ...</option>
                                                <option key="1" value="left"> Izquierda</option>
                                                <option key="2" value="center">Centro</option>
                                                <option key="3" value="right">Derecha</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </div>
                                
                                <div className="form-group">
                                    <Row>
                                        <Col><b>Alto de la gráfica</b></Col>
                                        <Col>
                                            <input type="number" className="form-control" id="height" name="height" onChange={handleForm} value={form.height} />
                                        </Col>
                                    </Row>
                                </div>
                                {
                                    form.type === "DoughnutChart" && 
                                    <div className="form-group">
                                        <Row>
                                            <Col><b>Ancho de la gráfica</b></Col>
                                            <Col>
                                                <input type="number" className="form-control" id="width" name="width" onChange={handleForm} value={form.width} />
                                            </Col>
                                        </Row>
                                    </div>
                                }

                                <div className="form-group">
                                    <Row>
                                        <Col><b>Posición de la leyenda</b></Col>
                                        <Col>
                                            <select disabled={form.hideLegend} className="form-control" id="legendPosition" name="legendPosition" value={form.legendPosition} onChange={handleForm}>
                                                <option key="0" value="PorDefecto">Seleccionar ...</option>
                                                <option key="1" value="top">Arriba</option>
                                                <option key="2" value="bottom">Abajo</option>
                                                <option key="3" value="left">Izquierda</option>
                                                <option key="4" value="right">Derecha</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="form-group mb-0">
                                    <Row>
                                        <Col>
                                            <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                <input className="custom-control-input" type="checkbox" checked={form.hideLegend} name="hideLegend" id="hideLegend" onChange={handleCheckOptions} />
                                                <label className="custom-control-label" htmlFor="hideLegend">
                                                    Ocultar leyenda
                                                </label>
                                            </StyleCheckbox>
                                        </Col>
                                        <Col>
                                            {
                                                form.type !== "DoughnutChart" &&
                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                    <input className="custom-control-input" type="checkbox" checked={form.hideY} name="hideY" id="hideY" onChange={handleCheckOptions} />
                                                    <label className="custom-control-label" htmlFor="hideY">
                                                        Ocultar Eje Y
                                                    </label>
                                                </StyleCheckbox>
                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div className="form-group">
                                    <Row>
                                        <Col><b>Signo eje Y Izquierdo</b></Col>
                                        <Col>
                                            <select className="form-control" id="yLeft" name="yLeft" value={form.yLeft} onChange={handleForm}>
                                                <option key="0" value="PorDefecto">Seleccionar ...</option>
                                                <option key="1" value="%">Porcentaje %</option>
                                                <option key="2" value="$">Pesos/USD $</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </div>
                                {
                                    form.type === "WaterfallChart" &&
                                    <div className="form-group">
                                        <Row>
                                            <Col><b>Mínimo Eje Izquierdo</b></Col>
                                            <Col>
                                                <input type="number" className="form-control" id="minYL" name="minYL" onChange={handleForm} value={form.minYL} />
                                            </Col>
                                        </Row>
                                    </div>
                                }

                                {   
                                    form.type === "BarChart" &&
                                    <>
                                    <div className="form-group">
                                        <Row>
                                            <Col><b>Signo eje Y Derecho</b></Col>
                                            <Col>
                                                <select className="form-control" id="yRight" name="yRight" value={form.yRight} onChange={handleForm}>
                                                    <option key="0" value="PorDefecto">Seleccionar ...</option>
                                                    <option key="1" value="%">Porcentaje %</option>
                                                    <option key="2" value="$">Pesos/USD $</option>
                                                </select>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="form-group">
                                        <Row>
                                            <Col><b>Mínimo Eje Izquierdo</b></Col>
                                            <Col>
                                                <input type="number" className="form-control" id="minYL" name="minYL" onChange={handleForm} value={form.minYL} />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="form-group">
                                        <Row>
                                            <Col><b>Mínimo Eje Derecho</b></Col>
                                            <Col>
                                                <input type="number" className="form-control" id="minYR" name="minYR" onChange={handleForm} value={form.minYR} />
                                            </Col>
                                        </Row>
                                    </div>
                                    </>
                                }
                                {
                                    form.type === 'BarChart' &&
                                    <div className="container px-0">
                                        <div className="form-group">
                                            <Row>
                                                <Col><b>Opciones de gráfica</b></Col>
                                                <Col>
                                                    <select className="form-control" id="option" name="option" value={form.option} onChange={handleForm}>
                                                        <option key="0" value="PorDefecto">Seleccionar ...</option>
                                                        <option key="1" value="lastMonths"> Últimos meses</option>
                                                        <option key="2" value="lastYears">Mes de cada año</option>
                                                        <option key="3" value="currentYear">Año actual</option>
                                                    </select>
                                                </Col>
                                            </Row>
                                        </div>
                                        {
                                            form.option === 'lastMonths' &&
                                            <div className="form-group">
                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                    <input className="custom-control-input" type="checkbox" checked={form.beginningYear === 1 ? true : false} name="beginningYear" id="beginningYear" onChange={handleBeginningYear} />
                                                    <label className="custom-control-label" htmlFor="beginningYear">
                                                        ¿Desde comienzo de año actual?
                                                    </label>
                                                </StyleCheckbox>

                                                <Row>
                                                    <Col><b>Cantidad de meses</b></Col>
                                                    <Col>
                                                        <input type="number" className="form-control" id="months" name="months" onChange={handleForm} value={form.months} disabled={form.beginningYear === 1 ? true : false} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                        {
                                            form.option === 'lastYears' &&
                                            <div className="form-group">
                                                <Row>
                                                    <Col><b>Cantidad de años</b></Col>
                                                    <Col>
                                                        <input type="number" className="form-control" id="years" name="years" onChange={handleForm} value={form.years} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </div>
                                }
                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                        <input className="custom-control-input" type="checkbox" checked={form.hideTooltip} name="hideTooltip" id="hideTooltip" onChange={handleCheckOptions} />
                                        <label className="custom-control-label" htmlFor="hideTooltip">
                                            Ocultar tooltip
                                        </label>
                                    </StyleCheckbox>
                                {
                                    form.type === "WaterfallChart" && form.type !== "" && form.type !== "PorDefecto" &&
                                    <>
                                    {/*<StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                            <input className="custom-control-input" type="checkbox" checked={form.addTotalColumn} name="addTotalColumn" id="addTotalColumn" onChange={handleCheckOptions} />
                                            <label className="custom-control-label" htmlFor="addTotalColumn">
                                                Agregar Columna Total
                                            </label>
                                    </StyleCheckbox>*/}

                                    <div className="form-group mb-0">
                                        <Row>
                                            <Col><b>Color Positivo</b></Col>
                                            <Col>
                                                <ColorPicker name="waterfall-colorPositive" value={form.colorPositive} form={form} setForm={setForm} />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="form-group mb-0">
                                        <Row>
                                            <Col><b>Color Negativo</b></Col>
                                            <Col>
                                                <ColorPicker name="waterfall-colorNegative" value={form.colorNegative} form={form} setForm={setForm} />
                                            </Col>
                                        </Row>
                                    </div>

                                    {/*   
                                        form.addTotalColumn &&
                                        <div className="container px-0">
                                            <div>
                                                <div className="form-group">
                                                    <Row>
                                                        <Col><b>Label Columna Total</b></Col>
                                                        <Col>
                                                            <input type="text" className="form-control" id="labelTotal" name="labelTotal" onChange={handleForm} value={form.labelTotal} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="form-group mb-0">
                                                    <Row>
                                                        <Col><b>Color</b></Col>
                                                        <Col>
                                                            <ColorPicker name="backgroundTotalLabel" value={form.backgroundTotalLabel} form={form} setForm={setForm} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    */}
                                    {/*<StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                        <input className="custom-control-input" type="checkbox" checked={form.percentWaterFall} name="percentWaterFall" id="percentWaterFall" onChange={handleCheckOptions} />
                                        <label className="custom-control-label" htmlFor="percentWaterFall">
                                            Agregar Porcentaje
                                        </label>
                                    </StyleCheckbox>*/}
                                    </>
                                    
                                }
                                {
                                    form.type !== "StackedBarChart" &&
                                    <div>
                                        <div className="form-group my-3">
                                            <Row>
                                                <Col><b>Indicadores</b></Col>
                                                <Col>
                                                    <AsyncSelect
                                                        value={form.indicators}
                                                        isMulti
                                                        cacheOptions
                                                        defaultOptions
                                                        loadOptions={loadIndicators}
                                                        onChange={e => selectIndicators(e, 0)}
                                                        theme={theme => (styleTheme(theme))}
                                                    />
                                                </Col>
                                            </Row>
                                            <AccordionStyle>
                                                {/* acordeones*/
                                                    form.indicators.length > 0 &&
                                                    form.indicators.map((ind, index) => {
                                                        return (
                                                            <StyleProperty p="0" key={index}>
                                                                
                                                                <Accordion.Toggle eventKey={index + 1}>
                                                                    <Card.Header>
                                                                        <b>{"Propiedades " + ind.name}</b>
                                                                        </Card.Header>
                                                                    </Accordion.Toggle>
                                                                
                                                                <Accordion.Collapse eventKey={index + 1}>
                                                                    <Card.Body>
                                                                        {
                                                                            form.type === 'BarChart' &&
                                                                            <IndicatorForm name={"indForm-" + index} index={index} ind={ind} nameTypeInd="indicators" typeInd={form.indicators} form={form} setForm={setForm} stages={stages} index={index} setLoadedPre={setLoadedPre} />
                                                                        }
                                                                        {
                                                                            form.type !== "BarChart" &&
                                                                            <div className="form-group">
                                                                                <Row>
                                                                                    <Col><b>Label del Indicador</b></Col>
                                                                                    <Col>
                                                                                        <input type="text" className="form-control" id={"labelIndicator-indicators-" + index} name={"labelIndicator-indicators-" + index} onChange={handleIndicator} value={ind.labelIndicator} />
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        }
                                                                        <div className="form-group">
                                                                            {
                                                                                form.type === "BarChart" && !ind.multiStages && 
                                                                                <>
                                                                                    <Row>
                                                                                        <Col><b>Color del Indicador</b></Col>   
                                                                                        <Col>
                                                                                            <ColorPicker name={"labelInd-color-" + index} nameTypeInd="indicators" typeInd={form.indicators} value={ind.color} form={form} setForm={setForm} />
                                                                                        </Col>
                                                                                    </Row>
                                                                                    
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                                <input className="custom-control-input" name={"dataLabelTop-indicators-" + index} type="checkbox" checked={ind.dataLabelTop} id={"dataLabelTop-indicators-" + index} onChange={handleOptions} />
                                                                                                <label className="custom-control-label" htmlFor={"dataLabelTop-indicators-" + index}>
                                                                                                    Añadir escenario (Top)
                                                                                                </label>
                                                                                            </StyleCheckbox>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            {
                                                                                                form.type === 'BarChart' && form.option === 'currentYear' && currentMonth[0] !== 11 &&
                                                                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                                    <input className="custom-control-input" name={"completeYearInd-indicators-" + index} type="checkbox" checked={ind.completeYearInd} id={"completeYearInd-indicators-" + index} onChange={handleOptions} />
                                                                                                    <label className="custom-control-label" htmlFor={"completeYearInd-indicators-" + index}>
                                                                                                        Año completo
                                                                                                    </label>
                                                                                                </StyleCheckbox>
                                                                                            }
                                                                                            {
                                                                                                form.option === 'currentYear' && currentMonth[0] === 11 &&
                                                                                                <div className="container alert alert-danger my-2 mx-0 my-2 text-center" role="alert">
                                                                                                    La opción de completar año se activa cuando el mes actual no es Diciembre
                                                                                                </div>
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>
                                                                                    
                                                                                </>
                                                                            }             
                                                                            {
                                                                                ind.dataLabelTop && !ind.multiStages &&
                                                                                <>
                                                                                <div className="form-group">
                                                                                    <Row>
                                                                                        <Col sm={3}><b>Escenario</b></Col>
                                                                                        <Col sm={9}>
                                                                                            <Select
                                                                                                value={ind.stageBarSelected}
                                                                                                options={stages}
                                                                                                onChange={e => selectStageLabelBar(e, index, 'indicators')}
                                                                                                theme={theme => (styleTheme(theme))}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <Row>
                                                                                        <Col><b>Nombre del Label</b></Col>
                                                                                        <Col>
                                                                                            <input type="text" className="form-control" id={"nameLabel-indicators-" + index} name={"nameLabel-indicators-" + index} onChange={handleIndicator} value={ind.nameLabel} />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                                <div className="form-group my-0">
                                                                                    <Row>
                                                                                        <Col><b>Color del Label</b></Col>
                                                                                        <Col>
                                                                                            <ColorPicker name={"labelInd-colorLabel-" + index} nameTypeInd="indicators" typeInd={form.indicators} value={ind.colorLabel} form={form} setForm={setForm} />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                    <input className="custom-control-input" name={"percent-indicators-" + index} type="checkbox" checked={ind.percent} id={"percent-indicators-" + index} onChange={handleOptions} />
                                                                                    <label className="custom-control-label" htmlFor={"percent-indicators-" + index}>
                                                                                        Agregar porcentaje
                                                                                    </label>
                                                                                </StyleCheckbox>
                                                                                </>
                                                                            }
                                                                            {   
                                                                                form.type === 'BarChart' && form.option === 'currentYear' && !ind.multiStages &&
                                                                                <> 
                                                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                    <input className="custom-control-input" name={"otherYears-indicators-" + index} type="checkbox" checked={ind.otherYears} id={"otherYears-indicators-" + index} onChange={handleOptions} />
                                                                                    <label className="custom-control-label" htmlFor={"otherYears-indicators-" + index}>
                                                                                        Incluir años anteriores
                                                                                    </label>
                                                                                </StyleCheckbox>
                                                                                {
                                                                                    ind.otherYears &&
                                                                                    <div className="form-group">
                                                                                        <Row>
                                                                                            <Col><b>Cantidad de años</b></Col>
                                                                                            <Col>
                                                                                                <input type="number" className="form-control" name={"numYears-indicators-" + index} id={"numYears-indicators-" + index} onChange={handleIndicator} value={ind.numYears} />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                }     
                                                                                {
                                                                                    ind.otherYears && parseInt(ind.numYears) > 0 &&
                                                                                    ind.infoYears.map( (year, indexY) => {
                                                                                        return(
                                                                                            <StyleProperty>
                                                                                                <h5>{currentYear[0] - ind.numYears + indexY}</h5>
                                                                                                <div className="form-group">
                                                                                                <Row>
                                                                                                    <Col><b>Label del año</b></Col>
                                                                                                    <Col>
                                                                                                        <input type="text" className="form-control" id={"labelYear-indicators-" + index + "-infoYears-" + indexY} name={"labelYear-indicators-" + index + "-infoYears-" + indexY} onChange={handleInfoYear} value={year.labelYear} />
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                </div>
                                                                                                <div className="form-group">
                                                                                                    <Row>
                                                                                                        <Col><b>Color del año</b></Col>
                                                                                                        <Col>
                                                                                                            <ColorPicker name={"colorYear-" + index + "-infoYears-" + indexY} nameTypeInd="indicators" typeInd={form.indicators} value={year.colorYear} form={form} setForm={setForm} />
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            </StyleProperty>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                </>
                                                                            }  
                                                                            {
                                                                                form.type !== "BarChart" &&
                                                                                <Row>
                                                                                    <Col><b>Color del Indicador</b></Col>
                                                                                    <Col>
                                                                                        <ColorPicker name={"labelInd-color-" + index} nameTypeInd="indicators" typeInd={form.indicators} value={ind.color} form={form} setForm={setForm} />
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                            {
                                                                                form.type === 'WaterfallChart' &&
                                                                                <>
                                                                                <div className="container px-0 my-2">
                                                                                    <div className="form-group">
                                                                                        <Row>
                                                                                            <Col><b>Escenario</b></Col>
                                                                                            <Col>
                                                                                                <Select
                                                                                                    value={ind.stage}
                                                                                                    cacheOptions
                                                                                                    defaultOptions
                                                                                                    options={stages}
                                                                                                    onChange={e => selectWatefallStage(e, 'indicators', index)}
                                                                                                    theme={theme => (styleTheme(theme))}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <Row>
                                                                                        <Col sm={6}>
                                                                                            <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                                <input className="custom-control-input" name={"negative-indicators-" + index} type="checkbox" checked={ind.negative} id={"negative-indicators-" + index} onChange={handleOptions} />
                                                                                                <label className="custom-control-label" htmlFor={"negative-indicators-" + index}>
                                                                                                    Valor negativo
                                                                                                </label>
                                                                                            </StyleCheckbox>
                                                                                        </Col>
                                                                                        <Col className="text-left"><b>Orden</b></Col>
                                                                                        <Col>
                                                                                            <select name={"order-indicators-"+index} value={index+1} onChange={event => orderArray({event})}>
                                                                                                {
                                                                                                    form.indicators.map( (ind, indexO) => {
                                                                                                        return(
                                                                                                            <option key={indexO} value={indexO + 1}>{indexO + 1}</option>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </select>
                                                                                        </Col> 
                                                                                    </Row>
                                                                                    
                                                                                </div>
                                                                                </>
                                                                                
                                                                            }
                                                                            {
                                                                                form.type === 'DoughnutChart' && 
                                                                                <div className="form-group">
                                                                                    <Row>
                                                                                        <Col className="text-right"><b>Orden</b></Col>
                                                                                        <Col>
                                                                                            <select name={"order-indicators-"+index} value={index+1} onChange={event => orderArray({event})}>
                                                                                                {
                                                                                                    form.indicators.map( (ind, indexO) => {
                                                                                                        return(
                                                                                                            <option key={indexO} value={indexO + 1}>{indexO + 1}</option>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </select>
                                                                                        </Col> 
                                                                                    </Row>
                                                                                </div>
                                                                            }
                                                                            
                                                                        </div>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </StyleProperty>
                                                        )
                                                    })
                                                }
                                            </AccordionStyle>
                                            {
                                                form.type !== "BarChart" && form.type !== "WaterfallChart" && form.type !== "" && form.type !== "PorDefecto" &&
                                                <div className="container px-0 my-2">
                                                    <div className="form-group">
                                                        <Row>
                                                            <Col><b>Escenarios</b></Col>
                                                            <Col>
                                                                <Select
                                                                    value={form.stages}
                                                                    isMulti
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    options={stages}
                                                                    onChange={e => selectStages(e)}
                                                                    theme={theme => (styleTheme(theme))}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            }                                            
                                            {
                                                form.type === 'BarChart' &&
                                                <Container className="p-0" fluid >
                                                    {
                                                        form.option === 'currentYear' && currentMonth[0] !== 11 &&
                                                        <div className="container my-1 px-0">
                                                            <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                <input className="custom-control-input" type="checkbox" checked={form.completeYear} name="completeYear" id="completeYear" onChange={handleCompleteYear} />
                                                                <label className="custom-control-label" htmlFor="completeYear">
                                                                    ¿Incluir pronóstico?
                                                                </label>
                                                            </StyleCheckbox>
                                                        </div>
                                                    }
                                                    {
                                                        form.completeYear &&
                                                        <div className="container-fluid mx-0 my-3 p-0">
                                                            <Row>
                                                                <Col><b>Indicadores pronóstico</b></Col>
                                                                <Col>
                                                                    <AsyncSelect
                                                                        value={form.indicatorsComplete}
                                                                        isMulti
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={loadIndicators}
                                                                        onChange={e => selectIndicators(e, 1)}
                                                                        theme={theme => (styleTheme(theme))}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                   
                                                </Container>
                                            }
                                            {
                                                form.type === "BarChart" && form.option === "currentYear" && form.completeYear && form.indicatorsComplete.length > 0 &&
                                                form.indicatorsComplete.map((ind, index) => {
                                                    return (
                                                        <StyleProperty>
                                                            {/* implementar acordeon*/}
                                                            <h5>{"Propiedades " + ind.name}</h5>
                                                            <IndicatorForm name={"ind-form-" + index} ind={ind} nameTypeInd="indicatorsComplete" typeInd={form.indicatorsComplete} form={form} setForm={setForm} stages={stages} index={index} setLoadedPre={setLoadedPre} />
                                                            {
                                                                !ind.multiStages &&
                                                                <div className="form-group">
                                                                    <Row>
                                                                        <Col><b>Color del Indicador</b></Col>
                                                                        <Col>
                                                                            <ColorPicker name={"labelInd-color-" + index} nameTypeInd="indicatorsComplete" typeInd={form.indicatorsComplete} value={ind.color} form={form} setForm={setForm} />
                                                                        </Col>
                                                                    </Row>
                                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                        <input className="custom-control-input" name={"dataLabelTop-indicatorsComplete-" + index} type="checkbox" checked={ind.dataLabelTop} id={"dataLabelTop-indicatorsComplete-" + index} onChange={handleOptions} />
                                                                        <label className="custom-control-label" htmlFor={"dataLabelTop-indicatorsComplete-" + index}>
                                                                            Añadir escenario (Top)
                                                                        </label>
                                                                    </StyleCheckbox>
                                                                </div>
                                                            }
                                                            {
                                                                ind.dataLabelTop && !ind.multiStages &&
                                                                <>
                                                                <div className="form-group">
                                                                    <Row>
                                                                        <Col sm={3}><b>Escenario</b></Col>
                                                                        <Col sm={9}>
                                                                            <Select
                                                                                value={ind.stageBarSelected}
                                                                                options={stages}
                                                                                onChange={e => selectStageLabelBar(e, index, 'indicatorsComplete')}
                                                                                theme={theme => (styleTheme(theme))}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div className="form-group">
                                                                    <Row>
                                                                        <Col><b>Nombre del Label</b></Col>
                                                                        <Col>
                                                                            <input type="text" className="form-control" id={"nameLabel-indicatorsComplete-" + index} name={"nameLabel-indicatorsComplete-" + index} onChange={handleIndicator} value={ind.nameLabel} />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div className="form-group my-0">
                                                                    <Row>
                                                                        <Col><b>Color del Label</b></Col>
                                                                        <Col>
                                                                            <ColorPicker name={"labelInd-colorLabel-" + index} nameTypeInd="indicatorsComplete" typeInd={form.indicatorsComplete} value={ind.colorLabel} form={form} setForm={setForm} />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                    <input className="custom-control-input" name={"percent-indicatorsComplete-" + index} type="checkbox" checked={ind.percent} id={"percent-indicatorsComplete-" + index} onChange={handleOptions} />
                                                                    <label className="custom-control-label" htmlFor={"percent-indicatorsComplete-" + index}>
                                                                        Agregar porcentaje
                                                                    </label>
                                                                </StyleCheckbox>
                                                                
                                                                </>
                                                            }    
                                                            
                                                        </StyleProperty>
                                                    )
                                                })
                                            }

                                        </div>

                                    </div>
                                }

                                {
                                    form.type === "StackedBarChart" &&
                                    <div className="mt-5">
                                        <StyleProperty>
                                            <h3>Series</h3>
                                            <div className="form-group mt-3">
                                                <Row>
                                                    <Col><b>Nombre de la Serie</b></Col>
                                                    <Col>
                                                        <input className="form-control" type="text" onChange={handleSerieLabel} value={serieLabel} />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="form-group">
                                                <Row>
                                                    <Col><b>Color del Indicador</b></Col>
                                                    <Col>
                                                        <ColorPicker key='colorSerie' name={"colorSerie"} colorSerie={serieColor} value={serieColor} setSerieColor={setSerieColor} />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                <input className="custom-control-input" name="multipleColors" type="checkbox" id="multipleColors" onChange={handleMultipleColors} />
                                                <label className="custom-control-label" htmlFor={"multipleColors"}>
                                                    Incluir multiples Colores
                                                </label>
                                            </StyleCheckbox>
                                            <Button type="submit" onClick={addSerie}>Agregar Serie</Button>

                                            {
                                                form.series.map((serie, index) => {
                                                    return (
                                                        <Row className="pt-2 px-3">
                                                            <Col><h5> {serie.multipleColors ? 'Multiples Colores - ': <ColorSquare key={index} color={serie.serieColor} />  } <input type="text" className="ml-3" id={"serieLabel-series-" + index} name={"serieLabel-series-" + index} onChange={handleIndicator} value={serie.serieLabel}/> <IconTrash link onClick={e => removeSerie(index, e)} /> </h5> </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </StyleProperty>

                                        <StyleProperty>
                                            <h3>Columnas</h3>
                                            <div className="form-group mt-3">
                                                <Row>
                                                    <Col><b>Nombre de la Columna</b></Col>
                                                    <Col>
                                                        <input type="text" className="form-control" onChange={handleColumnLabel} value={columnLabel} />
                                                    </Col>
                                                </Row>
                                            </div>

                                            <Button type="submit" onClick={addColumn}>Agregar Columna</Button>
                                            {
                                                form.columns.map((column, index) => {
                                                    return (
                                                        <Row className="pt-2 px-3">
                                                            <Col><h5> <input type="text" className="ml-3" id={"columnLabel-columns-" + index} name={"columnLabel-columns-" + index} onChange={handleIndicator} value={column.columnLabel}/> <IconTrash link onClick={e => removeColumn(index, e)} /> </h5> </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </StyleProperty>
                                        {
                                            form.series.length > 0 && form.columns.length > 0 &&
                                            <h2>Indicadores</h2>
                                        }

                                        <div className="form-group">
                                            {
                                                form.series.map((serie) => {
                                                    return (
                                                        form.columns.map((column, idx) => {
                                                            return (
                                                                <StyleProperty key={`IS-${serie.serieLabel}-${column.columnLabel}`}>
                                                                    <h3>{serie.serieLabel + ' - ' + column.columnLabel}</h3>
                                                                    <div className="form-group">
                                                                        <Row>
                                                                            <Col sm={4}><b>Indicador</b></Col>
                                                                            <Col sm={8}>
                                                                                <AsyncSelect
                                                                                    value={findValue(serie.serieLabel, column.columnLabel, 'indicator')}
                                                                                    cacheOptions
                                                                                    defaultOptions
                                                                                    loadOptions={loadIndicators}
                                                                                    onChange={e => selectStackedBar(e, {}, serie.serieLabel, column.columnLabel)}
                                                                                    theme={theme => (styleTheme(theme))}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <Row>
                                                                            <Col sm={4}><b>Escenario</b></Col>
                                                                            <Col sm={8}>
                                                                                <Select
                                                                                    value={findValue(serie.serieLabel, column.columnLabel, 'stage')}
                                                                                    cacheOptions
                                                                                    isClearable
                                                                                    defaultOptions
                                                                                    options={stages}
                                                                                    onChange={e => selectStackedBar({}, e, serie.serieLabel, column.columnLabel)}
                                                                                    theme={theme => (styleTheme(theme))}
                                                                                />
                                                                            </Col>
                                                                            
                                                                        </Row>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <Row>
                                                                            <Col sm={4}>
                                                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                    <input className="custom-control-input" type="checkbox" checked={validateIndicatorStage(serie.serieLabel, column.columnLabel, "checked")}id={`changeColor${serie.serieLabel}-${column.columnLabel}`} onChange={(e) => colorStackedBar(e, serie.serieLabel, column.columnLabel)} />
                                                                                    <label className="custom-control-label" htmlFor={`changeColor${serie.serieLabel}-${column.columnLabel}`}>
                                                                                        Cambiar Color
                                                                                    </label>
                                                                                </StyleCheckbox>    
                                                                            </Col>
                                                                            {validateIndicatorStage(serie.serieLabel, column.columnLabel, "checked") && <Col sm={8} >
                                                                                <ColorPicker name={`IndicatorStage`} serie={serie.serieLabel} columm={column.columnLabel} value={validateIndicatorStage(serie.serieLabel, column.columnLabel, "background") || "#777273"} form={form} setForm={setForm} />
                                                                            </Col>}
                                                                        </Row>
                                                                    </div>
                                                                </StyleProperty>
                                                            )
                                                        }) 
                                                    )
                                                })
                                            }

                                        </div>
                                        {
                                            form.type === 'StackedBarChart' &&
                                            <div className="form-group">
                                                <Row>
                                                    <Col>
                                                        <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                            <input className="custom-control-input" type="checkbox" checked={form.stagesSerie} name="stagesSerie" id="stagesSerie" onChange={handleCheckOptions} />
                                                            <label className="custom-control-label" htmlFor="stagesSerie">
                                                                Agregar escenarios por serie
                                                            </label>
                                                        </StyleCheckbox>
                                                    </Col>
                                                    {
                                                        form.stagesSerie &&
                                                        <Col>
                                                            <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                <input className="custom-control-input" type="checkbox" checked={form.otherIndSerie} name="otherIndSerie" id="otherIndSerie" onChange={handleCheckOptions} />
                                                                <label className="custom-control-label" htmlFor="otherIndSerie">
                                                                    Indicadores diferentes
                                                                </label>
                                                            </StyleCheckbox>
                                                        </Col>
                                                    }
                                                </Row>
                                                {   
                                                    form.stagesSerie &&
                                                    <>
                                                    {
                                                        form.series.length > 0 &&
                                                        form.series.map( (serie, index) => {
                                                            return(
                                                                <StyleProperty key={serie.serieLabel}>
                                                                    <Row>
                                                                        <Col sm={4}><h5>{serie.serieLabel}</h5></Col>
                                                                        <Col sm={2}><b>Color</b></Col>
                                                                        <Col sm={6}>
                                                                            <ColorPicker name={"otherOptionColor-serieLabelColor-" + index} nameTypeInd="series" typeInd={form.series} value={serie.serieLabelColor} form={form} setForm={setForm} />
                                                                        </Col>
                                                                    </Row>
                                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                        <input className="custom-control-input" name={"hideLabel-series-" + index} type="checkbox" checked={serie.hideLabel} id={"hideLabel-series-" + index} onChange={handleOptions} />
                                                                        <label className="custom-control-label" htmlFor={"hideLabel-series-" + index}>
                                                                            Ocultar Label
                                                                        </label>
                                                                    </StyleCheckbox>
                                                                    {
                                                                        form.otherIndSerie && form.columns.length > 0 &&
                                                                        form.columns.map((column, indexC) => {
                                                                            return(
                                                                                <StyleProperty key={`SS-${serie.serieLabel}-${column.columnLabel}`}>
                                                                                    <h5>{column.columnLabel}</h5>
                                                                                    <div className="form-group">
                                                                                        <Row>
                                                                                            <Col sm={4}><b>Indicadores</b></Col>
                                                                                            <Col sm={8}>
                                                                                                <AsyncSelect
                                                                                                    value={form.othersIndForLabels !== undefined ? form.othersIndForLabels.hasOwnProperty(`${index}-${indexC}`) ? form.othersIndForLabels[`${index}-${indexC}`].indicator : undefined : undefined}
                                                                                                    isMulti
                                                                                                    cacheOptions
                                                                                                    defaultOptions
                                                                                                    loadOptions={loadIndicators}
                                                                                                    onChange={e => selectOtherIndsForLabel({data: e, type: 'othersIndForLabels', index: index + "-" + indexC, typeData: 'indicator'})}
                                                                                                    theme={theme => (styleTheme(theme))}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                    {
                                                                                        form.othersIndForLabels !== undefined && form.othersIndForLabels.hasOwnProperty(`${index}-${indexC}`) && form.othersIndForLabels[`${index}-${indexC}`].indicator.length > 0 &&
                                                                                        form.othersIndForLabels[`${index}-${indexC}`].indicator.map( (ind, indexK) => {
                                                                                            return (
                                                                                                <StyleProperty>
                                                                                                    <h5>{ind.name}</h5>
                                                                                                    <div className="form-group">
                                                                                                        <Row>
                                                                                                            <Col sm={4}><b>Label</b></Col>
                                                                                                            <Col sm={8}>
                                                                                                                <input type="text" className="form-control" id={"serieLabel-othersIndForLabels-indicator-" + index + "-" + indexC + "-" + indexK} name={"serieLabel-othersIndForLabels-indicator-" + index + "-" + indexC + "-" + indexK} onChange={handleOthersInd} value={ind.serieLabel} />
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <Row>
                                                                                                            <Col sm={4}><b>Escenario</b></Col>
                                                                                                            <Col sm={8}>
                                                                                                                <Select
                                                                                                                    value={form.othersIndForLabels !== undefined ? form.othersIndForLabels.hasOwnProperty(`${index}-${indexC}`) ? form.othersIndForLabels[`${index}-${indexC}`].indicator[indexK].hasOwnProperty('stage') ? form.othersIndForLabels[`${index}-${indexC}`].indicator[indexK].stage : undefined : undefined : undefined}
                                                                                                                    options={stages}
                                                                                                                    onChange={e => selectStagesForOthersInd({data: e, type: 'othersIndForLabels', indexT: index + "-" + indexC, typeData: 'stage', index: indexK})}
                                                                                                                    theme={theme => (styleTheme(theme))}
                                                                                                                />
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                                                    <input disabled={ind.peso} className="custom-control-input" name={"percent-othersIndForLabels-indicator-" + index + "-" + indexC + "-" + indexK} type="checkbox" checked={ind.percent} id={"percent-othersIndForLabels-indicator-" + index + "-" + indexC + "-" + indexK} onChange={handleOptionsOthersInd} />
                                                                                                                    <label className="custom-control-label" htmlFor={"percent-othersIndForLabels-indicator-"+ index + "-" + indexC + "-" + indexK}>
                                                                                                                        Agregar porcentaje
                                                                                                                    </label>
                                                                                                                </StyleCheckbox>
                                                                                                            </Col>
                                                                                                            <Col>
                                                                                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                                                    <input disabled={ind.percent} className="custom-control-input" name={"peso-othersIndForLabels-indicator-" + index + "-" + indexC + "-" + indexK} type="checkbox" checked={ind.peso} id={"peso-othersIndForLabels-indicator-" + index + "-" + indexC + "-" + indexK} onChange={handleOptionsOthersInd} />
                                                                                                                    <label className="custom-control-label" htmlFor={"peso-othersIndForLabels-indicator-"+ index + "-" + indexC + "-" + indexK}>
                                                                                                                        Agregar signo peso
                                                                                                                    </label>
                                                                                                                </StyleCheckbox>
                                                                                                            </Col>
                                                                                                        </Row>

                                                                                                    </div>
                                                                                                    
                                                                                                </StyleProperty>
                                                                                            )   
                                                                                        })
                                                                                    }
                                                                                </StyleProperty>
                                                                            )   
                                                                        })
                                                                    }

                                                                </StyleProperty>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        !form.otherIndSerie && 
                                                        <>
                                                        <div className="form-group">
                                                            <Row>
                                                                <Col sm={3}><b>Escenarios</b></Col>
                                                                <Col sm={9}>
                                                                    <Select
                                                                        value={form.stagesSerieSelected}
                                                                        isMulti
                                                                        options={stages}
                                                                        onChange={e => selectStagesLabel(e, 'stagesSerieSelected')}
                                                                        theme={theme => (styleTheme(theme))}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {
                                                            form.stagesSerie && form.stagesSerieSelected.length > 0 &&
                                                            form.stagesSerieSelected.map((stage, index) => {
                                                                return (
                                                                    <StyleProperty>
                                                                        <h4>{"Propiedades " + stage.name}</h4>
                                                                        <div className="form-group">
                                                                            <Row>
                                                                                <Col><b>Nombre del Label</b></Col>
                                                                                <Col>
                                                                                    <input type="text" className="form-control" id={"serieLabel-stagesSerieSelected-" + index} name={"serieLabel-stagesSerieSelected-" + index} onChange={handleIndicator} value={stage.serieLabel} />
                                                                                </Col>
                                                                            </Row>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <Row>
                                                                                <Col>
                                                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                        <input disabled={stage.peso} className="custom-control-input" name={"percent-stagesSerieSelected-" + index} type="checkbox" checked={stage.percent} id={"percent-stagesSerieSelected-" + index} onChange={handleOptions} />
                                                                                        <label className="custom-control-label" htmlFor={"percent-stagesSerieSelected-" + index}>
                                                                                            Agregar porcentaje
                                                                                        </label>
                                                                                    </StyleCheckbox>
                                                                                </Col>
                                                                                <Col>
                                                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                        <input disabled={stage.percent} className="custom-control-input" name={"peso-stagesSerieSelected-" + index} type="checkbox" checked={stage.peso} id={"peso-stagesSerieSelected-" + index} onChange={handleOptions} />
                                                                                        <label className="custom-control-label" htmlFor={"peso-stagesSerieSelected-" + index}>
                                                                                            Agregar signo peso
                                                                                        </label>
                                                                                    </StyleCheckbox>
                                                                                </Col>
                                                                                
                                                                            </Row>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <Row>
                                                                                <Col className="text-center"><b>Orden</b></Col>
                                                                                <Col>
                                                                                    <select name={"order-stagesSerieSelected-"+index} value={index+1} onChange={event => orderArray({event})}>
                                                                                        {
                                                                                            form.stagesSerieSelected.map( (ind, indexO) => {
                                                                                                return(
                                                                                                    <option key={indexO} value={indexO + 1}>{indexO + 1}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </Col>  
                                                                            </Row>
                                                                        </div>
                                                                    </StyleProperty>
                                                                )
                                                            })
                                                        } 
                                                        </>
                                                    }
                                                    
                                                    </>
                                                }
                                                <div className="form-group">
                                                    <Row>
                                                        <Col>
                                                            <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                <input className="custom-control-input" type="checkbox" checked={form.infoTotal} name="infoTotal" id="infoTotal" onChange={handleCheckOptions} />
                                                                <label className="custom-control-label" htmlFor="infoTotal">
                                                                    Agregar escenarios totales
                                                                </label>
                                                            </StyleCheckbox>
                                                        </Col>
                                                        {
                                                            form.infoTotal &&
                                                            <Col>
                                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                    <input className="custom-control-input" type="checkbox" checked={form.otherIndTotal} name="otherIndTotal" id="otherIndTotal" onChange={handleCheckOptions} />
                                                                    <label className="custom-control-label" htmlFor="otherIndTotal">
                                                                        Indicadores diferentes
                                                                    </label>
                                                                </StyleCheckbox>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </div>
                                                {   
                                                    form.infoTotal &&
                                                    <>
                                                        {
                                                            form.otherIndTotal && form.columns.length > 0 && 
                                                            form.columns.map( (col, index) => {
                                                                return (
                                                                    <StyleProperty>
                                                                        <h4>{col.columnLabel}</h4>
                                                                        <div className="form-group">
                                                                            <Row>
                                                                                <Col sm={4}><b>Indicadores</b></Col>
                                                                                <Col sm={8}>
                                                                                    <AsyncSelect
                                                                                        value={form.othersIndForTotal !== undefined ? form.othersIndForTotal.hasOwnProperty(`${index}`) ? form.othersIndForTotal[`${index}`].indicator : undefined : undefined}
                                                                                        isMulti
                                                                                        cacheOptions
                                                                                        defaultOptions
                                                                                        loadOptions={loadIndicators}
                                                                                        onChange={e => selectOtherIndsForLabel({data: e, type: 'othersIndForTotal', index, typeData: 'indicator'})}
                                                                                        theme={theme => (styleTheme(theme))}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        {
                                                                            form.othersIndForTotal !== undefined && form.othersIndForTotal.hasOwnProperty(`${index}`) && form.othersIndForTotal[`${index}`].indicator.length > 0 &&
                                                                            form.othersIndForTotal[`${index}`].indicator.map( (ind, indexI) => {
                                                                                return (
                                                                                    <StyleProperty>
                                                                                        <h4>{ind.name}</h4>
                                                                                        <div className="form-group">
                                                                                            <Row>
                                                                                                <Col sm={4}><b>Escenario</b></Col>
                                                                                                <Col sm={8}>
                                                                                                    <Select
                                                                                                        value={form.othersIndForTotal !== undefined ? form.othersIndForTotal.hasOwnProperty(`${index}`) ? form.othersIndForTotal[`${index}`].indicator[indexI].hasOwnProperty('stage') ? form.othersIndForTotal[`${index}`].indicator[indexI].stage : undefined : undefined : undefined}
                                                                                                        options={stages}
                                                                                                        onChange={e => selectStagesForOthersInd({data: e, type: 'othersIndForTotal', indexT: index , typeData: 'stage', index: indexI})}
                                                                                                        theme={theme => (styleTheme(theme))}
                                                                                                    />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div> 
                                                                                        <div className="form-group">
                                                                                            <Row>
                                                                                                <Col sm={4}><b>Color</b></Col>
                                                                                                <Col sm={8}>
                                                                                                    <ColorPicker name={"otherIndOptionColor-totalColor-" + index + "-" + indexI} nameTypeInd="othersIndForTotal" typeInd={form.othersIndForTotal} value={ind.totalColor} form={form} setForm={setForm} />
                                                                                                </Col>
                                                                                            </Row>  
                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                                        <input disabled={ind.peso} className="custom-control-input" name={"percent-othersIndForTotal-indicator-" + index + "-" + indexI} type="checkbox" checked={ind.percent} id={"percent-othersIndForTotal-indicator-" + index + "-" + indexI} onChange={handleOptionsOthersIndTotal} />
                                                                                                        <label className="custom-control-label" htmlFor={"percent-othersIndForTotal-indicator-"+ index + "-" + indexI}>
                                                                                                            Agregar porcentaje
                                                                                                        </label>
                                                                                                    </StyleCheckbox>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                                        <input disable={ind.percent} className="custom-control-input" name={"peso-othersIndForTotal-indicator-" + index + "-" + indexI} type="checkbox" checked={ind.peso} id={"peso-othersIndForTotal-indicator-" + index + "-" + indexI} onChange={handleOptionsOthersIndTotal} />
                                                                                                        <label className="custom-control-label" htmlFor={"peso-othersIndForTotal-indicator-"+ index + "-" + indexI}>
                                                                                                            Agregar signo peso
                                                                                                        </label>
                                                                                                    </StyleCheckbox>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                        
                                                                                    </StyleProperty>   
                                                                                )
                                                                            })
                                                                        }
                                                                    </StyleProperty>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            !form.otherIndTotal &&
                                                            <>
                                                            {
                                                                form.indicatorTotalSelected.length <= form.columns.length ? 
                                                                <div className="container alert alert-success my-2 mx-0 my-2 text-center" role="alert">
                                                                    {`Debe escoger ${form.columns.length} indicadores`} 
                                                                </div> :
                                                                <div className="container alert alert-danger my-2 mx-0 my-2 text-center" role="alert">
                                                                    {`No debe escoger más de ${form.columns.length} indicadores`}
                                                                </div> 
                                                            }
                                                            <div className="form-group">
                                                                <Row>
                                                                    <Col sm={3}><b>Indicadores</b></Col>
                                                                    <Col sm={9}>
                                                                        <AsyncSelect
                                                                            value={form.indicatorTotalSelected}
                                                                            isMulti
                                                                            cacheOptions
                                                                            defaultOptions
                                                                            loadOptions={loadIndicators}
                                                                            onChange={e => selectStagesLabel(e, 'indicatorTotalSelected')}
                                                                            theme={theme => (styleTheme(theme))}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            {
                                                                form.indicatorTotalSelected.length > 0 &&
                                                                form.indicatorTotalSelected.map( (ind, index) => {
                                                                    return(
                                                                        <StyleProperty>
                                                                            <Row>
                                                                                <Col sm={6}><h5>{"Propiedades " + ind.name}</h5></Col>
                                                                                <Col className="text-right"><b>Orden</b></Col>
                                                                                    <Col>
                                                                                        <select name={"order-indicatorTotalSelected-"+index} value={index+1} onChange={event => orderArray({event})}>
                                                                                            {
                                                                                                form.indicatorTotalSelected.map( (ind, indexO) => {
                                                                                                    return(
                                                                                                        <option key={indexO} value={indexO + 1}>{indexO + 1}</option>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </Col>  
                                                                            </Row>
                                                                        </StyleProperty>
                                                                    )
                                                                })
                                                            }
                                                            <div className="form-group">
                                                                <Row>
                                                                    <Col sm={3}><b>Escenarios</b></Col>
                                                                    <Col sm={9}>
                                                                        <Select
                                                                            value={form.stagesTotalSelected}
                                                                            isMulti
                                                                            options={stages}
                                                                            onChange={e => selectStagesLabel(e, 'stagesTotalSelected')}
                                                                            theme={theme => (styleTheme(theme))}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            {
                                                                form.stagesTotalSelected.length > 0 && 
                                                                form.stagesTotalSelected.map( (stage, index) => {
                                                                    return (
                                                                        <StyleProperty>
                                                                            <h4>{"Propiedades " + stage.name}</h4>
                                                                            <div className="form-group">
                                                                                <Row>
                                                                                    <Col>
                                                                                        <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                            <input disabled={stage.peso} className="custom-control-input" name={"percent-stagesTotalSelected-" + index} type="checkbox" checked={stage.percent} id={"percent-stagesTotalSelected-" + index} onChange={handleOptions} />
                                                                                            <label className="custom-control-label" htmlFor={"percent-stagesTotalSelected-" + index}>
                                                                                                Agregar porcentaje
                                                                                            </label>
                                                                                        </StyleCheckbox>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                                                            <input disabled={stage.percent} className="custom-control-input" name={"peso-stagesTotalSelected-" + index} type="checkbox" checked={stage.peso} id={"peso-stagesTotalSelected-" + index} onChange={handleOptions} />
                                                                                            <label className="custom-control-label" htmlFor={"peso-stagesTotalSelected-" + index}>
                                                                                                Agregar signo peso
                                                                                            </label>
                                                                                        </StyleCheckbox>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <Row>
                                                                                    <Col className="text-right"><b>Orden</b></Col>
                                                                                    <Col>
                                                                                        <select name={"order-stagesTotalSelected-"+index} value={index+1} onChange={event => orderArray({event})}>
                                                                                            {
                                                                                                form.stagesSerieSelected.map( (ind, indexO) => {
                                                                                                    return(
                                                                                                        <option key={indexO} value={indexO + 1}>{indexO + 1}</option>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <Row>
                                                                                    <Col><b>Color</b></Col>
                                                                                    <Col>
                                                                                        <ColorPicker name={"otherOptionColor-totalColor-" + index} nameTypeInd="stagesTotalSelected" typeInd={form.stagesTotalSelected} value={stage.totalColor} form={form} setForm={setForm} />
                                                                                    </Col>
                                                                                </Row>  
                                                                            </div>
                                                                        </StyleProperty>
                                                                    )
                                                                })
                                                            }
                                                            </>
                                                        }      
                                                    </>
                                                }
                                            </div>
                                        }

                                    </div>
                                }
                                </Container>
                                <Container className="p-0">
                                    <Button type="submit" disabled={!loadedPre}>Guardar</Button>
                                </Container>
                            </form>
                        </Col>
                        {console.log({form})}
                        <Col style={{maxHeight: 500, overflow: 'auto'}} sm={7}>
                            <div className="fixed">
                                <h3>Previsualización</h3>
                                {
                                    loadedPre &&
                                    <Graphic config={JSON.parse(JSON.stringify(form))} />
                                }
                                {
                                    !loadedPre && msjView !== '' &&
                                    <div className="container alert alert-danger my-2 mx-0 my-2 text-center" role="alert">
                                        {msjView}
                                    </div>
                                }
                            </div>
                            
                        </Col>
                    </Row>
                </Container>
            }
        </DivWrapper >
    );
}
export default Form;