import styled from "styled-components";

export const CustomForm = styled.div`
    & textarea {
        resize: none;
    }

    & form div[class^="col-"] {
        margin-top: 1rem;
    }

`;