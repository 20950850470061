import React, { useState, useEffect } from "react";
import Select from "react-select";
import Utils from "../../utils";
import { Pagination, InputGroup, FormControl } from 'react-bootstrap';
import { FaSave, FaEraser } from "react-icons/fa";
//Buttons
import { IconEdit } from "../../components/iconos/IconEdit";

//popUps
import CustomModal from "../../components/CustomModal";
import { Button } from "../../styles/CommonStyles";
import { Theme1 } from "../../styles/Theme1";
import { IconConfig } from "../../components/iconos/IconConfig";
import { styleTheme } from "../../styles/SelectTheme";
import { AlertForm, ICON } from "../../utils/SweetAlert";
import { useConfigRole } from "../../Hooks/configRole";
import { Loading } from "../../components/Loading";
import { IconQuestion } from "../../components/iconos/IconQuestion";
import { IconSuccess } from "../../components/iconos/IconSuccess";
import { IconError } from "../../components/iconos/IconError";
import { relativeTime } from "../../utils/formatDate";

function Forms() {
  //data table
  const [dataUsers, setDataUsers] = useState("");

  //Loaders States
  const [tableIsLoaded, setTableIsLoaded] = useState(false);
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const [isApproval, setIsApproval] = useState(false);

  //dataConfig
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRoleContent, setUserRoleContent] = useState("");
  const [userRoleAdmin, setUserRoleAdmin] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //states popUps
  const [showModal, setShowModal] = useState(false);

  //stateLists
  const [rolesOptions, setRolesOptions] = useState([]);
  const [rolesAdminOptions, setAdminOptions] = useState([]);
  const [rolesContentOptions, setContentOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState({});
  const [selectedApproval, setSelectedApproval] = useState({})

  //states to change passwd
  const [userSend, setUserSend] = useState("");
  const [idSend, setidSend] = useState("");
  const [passwdChanger, setPasswdChanger] = useState("")


  // states to pagination
  const [searchPagination, setSearchPagination] = useState(null);
  const [pageActive, setPageActive] = useState(null);

  //Search Word
  const [searchWord, setSearchWord] = useState();
  // Rol Configuration
  const { configRoleByIdModule } = useConfigRole(8);

  //Select business
  const [business, setBusiness] = useState([]);
  const [userBusiness, setUserBusiness] = useState({});

  //Select department
  const [departments, setDepartments] = useState([]);
  const [userDepartment, setUserDepartment] = useState(null);

  //Select position
  const [positions, setPositions] = useState([]);
  const [userPosition, setUserPosition] = useState(null);

  useEffect(() => {

    let urlApproval = `/extension_portal/isApproval`;
    Utils.Petition.get(
      urlApproval,
      (res) => {
        setIsApproval(res.isApproval)
      },
      (err) => {
        console.log(err);
      }
    );

    //table users
    let urlUsers = `/users/users-client`;
    Utils.Petition.get(
      urlUsers,
      (res) => {
        setDataUsers(res.data);
        setTableIsLoaded(true);
      },
      (err) => {
        console.log(err);
      }
    );

    let usersList = `/users/users-client`;
    Utils.Petition.get(
      usersList,
      (res) => {
        let options = [];
        for (let i in res.data) {
          let obj = { label: res.data[i].username, value: res.data[i].id };
          options.push(obj);
        }
        setUsersOptions(options);
        setRolesLoaded(true);
      },
      (err) => {
        console.log(err);
      }
    );

    //Role options
    let urlList = `/rol/listRole`;
    Utils.Petition.get(
      urlList,
      (res) => {
        let options = [];
        for (let i in res.data) {
          let obj = { label: res.data[i].name, value: res.data[i].id };
          options.push(obj);
        }
        setRolesOptions(options);
        setRolesLoaded(true);
      },
      (err) => {
        console.log(err);
      }
    );
  
  let urlListRolesAdmin = `/rol/listRolsAdmin`;
    Utils.Petition.get(
      urlListRolesAdmin,
      (res) => {
        let options = [];
        for (let i in res.data) {
          let obj = { label: res.data[i].name, value: res.data[i].id };
          options.push(obj);
        }
        setAdminOptions(options);
        setRolesLoaded(true);
      },
      (err) => {
        console.log(err);
      }
    );

    let urlListRolesContent = `/rol/listRolsContent`;
    Utils.Petition.get(
      urlListRolesContent,
      (res) => {
        let options = [];
        for (let i in res.data) {
          let obj = { label: res.data[i].name, value: res.data[i].id };
          options.push(obj);
        }
        setContentOptions(options);
        setRolesLoaded(true);
      },
      (err) => {
        console.log(err);
      }
    );

    Utils.Petition.get(
      "/business",
      (res) => {
        let options = [];
        for (let i in res.data) {
          let obj = { label: res.data[i].name, value: res.data[i].id };
          options.push(obj);
        }
        setBusiness(options);
        setUserBusiness(options.filter(e => e.value == localStorage.getItem('idbusiness'))[0])
      },
      (err) => {
        console.log(err);
      }
    );

    Utils.Petition.get(
      "/department",
      (res) => {
        let options = [];
        for (let i in res.data) {
          let obj = { label: res.data[i].name, value: res.data[i].id };
          options.push(obj);
        }
        setDepartments(options);
      },
      (err) => {
        console.log(err);
      }
    );

    Utils.Petition.get(
      "/position",
      (res) => {
        let options = [];
        for (let i in res.data) {
          let obj = { label: res.data[i].name, value: res.data[i].id };
          options.push(obj);
        }
        setPositions(options);
      },
      (err) => {
        console.log(err);
      }
    );

  },
  []);


  //UseEfect Pagination
  useEffect(() => {
    if (searchPagination === null && dataUsers) {
      getSearch(1);
    }

  }, [searchPagination, dataUsers]);

  useEffect(() => {
    if (!dataUsers) return;
    getSearch(1);
  }, [dataUsers])
  //



  const handleUserName = (event) => {
    setUserName(event.target.value);
  };

  const handleUserFullName = (event) => {
    setUserFullName(event.target.value);
  };

  const handleUserEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const handleUserPassword = (event) => {
    setUserPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleRoleContentOptions = (event) => {
    setUserRoleContent(event);
  };

  const handleRoleAdminOptions = (event) => {
    setUserRoleAdmin(event);
  };
  

  const handleUserOptions = (event) => {
    setSelectedApproval(event);
    console.log(selectedApproval)
  };

  const handleShowModal = (id, username) => {
    setidSend(id)
    setUserSend(username)
    setShowModal(true);
  };

  const handlePasswdChanger = (event) => {
    setPasswdChanger(event.target.value);
  };

  const updateView = () => {
    let url = `/users/users-client`;
    setTableIsLoaded(false);
    Utils.Petition.get(
      url,
      (res) => {
        setDataUsers(res.data);
        setTableIsLoaded(true);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const saveDB = (event) => {
    event.preventDefault();

    //congigurates the body that will be send to de endpoint
    let config = {
      username: userName,
      full_name: userFullName,
      email: userEmail,
      idrol_content: userRoleContent.value,
      idrol_admin: userRoleAdmin.value,
      idapprovator: selectedApproval.value,
      idbusiness:userBusiness.value,
      idposition: userPosition.value,
      iddepartment: userDepartment.value,
    };
    if (userId) {
      config.id = userId;
      Utils.Petition.post(
        `/users/update-user`,
        config,
        (res) => {
          AlertForm(ICON.SUCCESS, "Actualizado Correctamente");
          cleanFields();
        },
        (err) => {
          console.log(err);
          AlertForm(ICON.ERROR, "No se Actualizo", "Intentalo nuevamente");
        }
      );
    } else {
      if (userPassword === confirmPassword) {
        config.password = userPassword;
        Utils.Petition.post(
          "/users/create-user",
          config,
          (res) => {
            AlertForm(ICON.SUCCESS, "Usuario Creado");
            cleanFields();
          },
          (err) => {
            console.log(err);
            AlertForm(ICON.ERROR, "No se creo usuario", "Intentalo nuevamente");
          }
        );
      } else {
        AlertForm(ICON.WARNING, "Las contraseñas no coinciden");
      }
    }
    updateView();
  };

  const savePassword = () => {
    
    if(idSend && passwdChanger){
      let config = {
        id: idSend,
        password: passwdChanger
      }
      Utils.Petition.post(
        `/users/update-user`,
        config,
        (res) => {
          AlertForm(ICON.SUCCESS, "Contraseña Actualizada");
        },
        (err) => {
          console.log(err);
          AlertForm(ICON.ERROR, "No se actualizo contraseña", "Intentalo nuevamente");
        }
      );
    } else {
      AlertForm(ICON.WARNING, "Debes llenar este campo");
    }

  }

  const cleanFields = (e) => {
    if (e) e.preventDefault();
    //dataConfig restart
    setUserId("");
    setUserName("");
    setUserFullName("");
    setUserEmail("");
    setUserRoleAdmin("");
    setUserRoleContent("");
    setConfirmPassword("");
    setUserPassword("");
    setUserDepartment(null);
    setUserPosition(null);
  };

  const fillFields = (e, id, username, full_name, email, idrol_admin, idrol_content,idbusiness, iddepartment, idposition) => {
    e.preventDefault();
    //dataConfig fill with id
    setUserId(id);
    setUserName(username);
    setUserFullName(full_name);
    setUserEmail(email);
    setUserRoleAdmin(rolesAdminOptions.find(el=> el.value === idrol_admin));
    setUserRoleContent(rolesContentOptions.find(el=> el.value === idrol_content));
    setUserBusiness(business.filter(e => e.value == idbusiness));
    setUserDepartment(departments.filter(e => e.value == iddepartment))
    setUserPosition(positions.filter(e => e.value == idposition))
  };

  // Paginación

  const getPages = () => {
    if (dataUsers) {
      const last = dataUsers.length / 20;
      let active = parseInt(pageActive);

      let pages = [];
      let numberPage = active < 11 ? 1 : active - 10;
      for (let i = 1; i <= 20; i++) {
        if (numberPage < last) {
          pages.push(
            <Pagination key={numberPage}>
              <Pagination.Item
                id={numberPage}
                active={numberPage === active}
                onClick={handlePagination}
              >
                {numberPage}
              </Pagination.Item>
            </Pagination>
          );
          numberPage++;
        }
      }
      return pages;
    }
  };

  const handlePagination = (e) => {
    if (e.target.id) {
      getSearch(e.target.id);
    } else if (e.target.dataset.name) {
      if (e.target.dataset.name === 'first') {
        getSearch(1);
      } else if (e.target.dataset.name === 'prev') {
        if (pageActive - 1 >= 1) {
          getSearch(pageActive - 1);
        }
      } else if (e.target.dataset.name === 'next') {
        if (pageActive + 1 <= dataUsers.length / 20) {
          getSearch(pageActive + 1);
        }
      } else if (e.target.dataset.name === 'last') {
        getSearch(dataUsers.length / 20);
      }
    }
  };

  const getSearch = (numberPage) => {
    const arr = [];
    const start = (numberPage - 1) * 20;
    const end = start + 20;
    for (let idx = start; idx < end; idx++) {
      arr.push(dataUsers[idx]);
    }
    const filterArr = arr.filter(el => el !== undefined);
    if (dataUsers.length === 0) {
      setSearchPagination([]);
    } else {
      setSearchPagination(filterArr);
    }

    setPageActive(numberPage);
  };

  const handleSearchWord = (e) => {
    // console.log(searchWord)
    e.preventDefault();
    if (searchWord) {
      Utils.Petition.get(`/users/users-client/filter/${searchWord}`,
        (res) => {
          setDataUsers(res.data);
        },
        (err) => {
          console.log(err);
        }
      );
    }else{
      let url = `/users/users-client`;
      setTableIsLoaded(false);
      Utils.Petition.get(
        url,
        (res) => {
          setDataUsers(res.data);
          setTableIsLoaded(true);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  const handleChangeNegocio = (event) => {
    setUserBusiness(event)
  };

  const handleChangeDepartment = (event) => {
    setUserDepartment(event)
  };

  const handleChangePosition = (event) => {
    setUserPosition(event)
  };

  return (
    <>
      <div className="d-flex flex-wrap m-4">
        <div className="container-fluid mb-2">
          <div className="card">
            <div className="card-header">
              <b>Administrador de Usuarios</b>
            </div>
            {(configRoleByIdModule.create || userId) && <div className="card-body">
              <form onSubmit={saveDB}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="id"
                  value={userId}
                  readOnly
                  hidden
                />
                <div className="form-group">
                  <label>Nombre de Usuario</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Escribe un nombre de usuario"
                    onChange={handleUserName}
                    value={userName}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Nombre Completo</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Escribe tu nombre completo"
                    onChange={handleUserFullName}
                    value={userFullName}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Correo Electrónico</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Escribe el correo electrónico"
                    onChange={handleUserEmail}
                    value={userEmail}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Departamento o Area de la empresa</label>
                  <Select
                    placeholder="Seleccione una Opción"
                    options={departments}
                    onChange={handleChangeDepartment}
                    theme={theme => (styleTheme(theme))}
                    value={userDepartment }
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Cargo Laboral</label>

                  <Select
                    placeholder="Seleccione una Opción"
                    options={positions}
                    onChange={handleChangePosition}
                    theme={theme => (styleTheme(theme))}
                    value={userPosition }
                    required
                  />

                </div>
                {rolesLoaded && (
                  <>
                    <div className="form-group">
                      <label>Rol Administrativo</label>
                      <Select
                        placeholder="Seleccione una Opción"
                        options={rolesAdminOptions}
                        onChange={handleRoleAdminOptions}
                        theme={theme => (styleTheme(theme))}
                        value={userRoleAdmin}
                      />
                    </div>
                  </>
                )}
                {rolesLoaded && 
                    <div className="form-group">
                      <label>Rol de Contenido</label>
                      <Select
                        placeholder="Seleccione una Opción"
                        options={rolesContentOptions}
                        onChange={handleRoleContentOptions}
                        theme={theme => (styleTheme(theme))}
                        value={userRoleContent}
                      />
                    </div>
                }
                {isApproval && (
                  <>
                    <div class="form-group">
                      <label>Aprobador</label>
                      <Select
                        placeholder="Seleccione un Aprobador"
                        options={usersOptions}
                        onChange={handleUserOptions}
                        required
                      />
                    </div>
                  </>
                )}
                <div className="form-group">
                  <label>Negocio</label>
                  <Select
                    placeholder="Seleccione una Opción"
                    options={business}
                    onChange={handleChangeNegocio}
                    theme={theme => (styleTheme(theme))}
                    value={userBusiness }
                  />
                </div>
                {!userId && (
                  <>
                    <div className="form-group">
                      <label>Contraseña</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Escribe tu contraseña"
                        onChange={handleUserPassword}
                        value={userPassword}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Confirmar Contraseña</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Repite la contraseña"
                        onChange={handleConfirmPassword}
                        value={confirmPassword}
                        required
                      />
                    </div>
                  </>
                )}

                {userPassword === confirmPassword &&
                  userPassword &&
                  confirmPassword && (
                    <div className="alert alert-success mt-3" role="alert">
                      Las contraseñas coinciden
                    </div>
                  )}
                {userPassword !== confirmPassword &&
                  userPassword &&
                  confirmPassword && (
                    <div className="alert alert-danger mt-3" role="alert">
                      Las contraseñas <b>no</b> coinciden
                    </div>
                  )}
                <Button type="submit">
                  Guardar <FaSave />
                </Button>
                <Button color={Theme1.grayText} className="mr-2" onClick={cleanFields}>
                  Limpiar <FaEraser />
                </Button>
              </form>
            </div>}
          </div>
        </div>
        <div className="container-fluid">
          <h2 className="py-4 text-center">Lista de Usuarios</h2>
          <form>
          <InputGroup className="mb-4" style={{width: "50%", margin: "auto"}} >
            <FormControl type="text" placeholder="Escribe algo para buscar" value={searchWord}  onChange={(e)=>setSearchWord(e.target.value)}/>
            <Button type="submit" color={Theme1.grayText}  onClick={(e) => handleSearchWord(e)} > 🔍 Buscar </Button>
          </InputGroup>
          </form>           
         
          <table className="table table-striped text-center"> 
            <thead className="bg-dark text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Usuario</th>
                <th scope="col">Nombre Completo</th>
                <th scope="col">E-mail</th>
                <th scope="col">Negocio</th>
                <th scope="col" style={{width: "120px"}}>Estado <span><IconQuestion textTooltip={`El estado activo indica si un usuario ha tenido interacción con la pagina en los ultimos ${JSON.parse(localStorage.getItem("config")).deactivate_user_months} meses`} /></span></th>
                <th scope="col" colSpan={2}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {tableIsLoaded && 
                searchPagination.map((value, key) => (
                  <tr key={key}>
                    <td >{value.id}</td>
                    <td>{value.username}</td>
                    <td>{value.full_name}</td>
                    <td>{value.email}</td>
                    <td>
                      { 
                      business.length > 0 && 
                      value.idbusiness !== undefined &&
                      value.idbusiness !== null &&
                        business.filter(e => e.value == value.idbusiness)[0]?.label 
                     }</td>
                     <td>{value.status === "active" ? <IconSuccess textTooltip={`Conectado ${relativeTime(value.last_activity)}`}/>: <IconError textTooltip={`Conectado ${relativeTime(value.last_activity)}`}/>}</td>
                    <td className="pl-3">
                      {configRoleByIdModule.update && 
                        <IconEdit link onClick={(e) =>
                          fillFields(
                            e,
                            value.id,
                            value.username,
                            value.full_name,
                            value.email,
                            value.idrol_admin,
                            value.idrol_content,
                            value.idbusiness,
                            value.iddepartment,
                            value.idposition
                          )} />
                      }
                      <IconConfig textTooltip="Cambiar la contraseña" link onClick={() => handleShowModal(value.id, value.username)}/>             
                      </td>
                      
                  </tr>
                ))}
            </tbody>
          </table>
          {!tableIsLoaded && <Loading />}
          {dataUsers.length > 20 && <Pagination className='justify-content-center'>
            <Pagination.First
            data-name='first'
            onClick={handlePagination}
            />
            <Pagination.Prev
            data-name='prev'
            onClick={handlePagination}
            />
            {getPages()}
            <Pagination.Next
            data-name='next'
            onClick={handlePagination}
            />
            <Pagination.Last
            data-name='last'
            onClick={handlePagination}
            />
          </Pagination>}
        </div>
      </div>
      <CustomModal
        title={"Cambio de contraseña"}
        show={showModal}
        setShow={setShowModal}
        handleSaveButton={savePassword}
        size="md"
      >
        {showModal && (
          <>
            <div className="container d-flex justify-content-center flex-wrap">
              <h3 className="text-center" style={{fontSize: "18px"}}>Cambio de contraseña para <span className="text-primary">{`${userSend}`}</span></h3>
              <input className="form-control" type="password" onChange={handlePasswdChanger} required/>
            </div>
          </>
        )}
      </CustomModal>
    </>
  );
}

export default Forms;
