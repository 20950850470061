import { useState } from "react";
import { Form, FormGroup, Table } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import Select from "react-select";
import { ButtonLoading } from "../../../components/Loading/ButtonLoading";
import { Button } from "../../../styles/CommonStyles";
import { styleTheme } from "../../../styles/SelectTheme";
import ItemPortal from "./ItemPortal";
import PortalConfig from "./PortalConfig";

function ListPortal({reloadInterval, setReloadInterval, portalOptions, businesses, setBusinesses, index}){
    
    const clearPortalForm = ()=>{
        return (
            {
                portal:"",
                fromPortal:null,
                idbusiness:businesses[index].id,
                id:0,
            }
        );
    }
    
    const [portalForm, setPortalForm] = useState(clearPortalForm());
    const [portalConfigSaveControl, setPortalConfigSaveControl] = useState(false);
    const [loadIdPortalToConfig, setLoadIdPortalToConfig] = useState(false);
    const [portalEditTitle, setPortalEditTitle] = useState('');
    const [indexPortal, setIndexPortal] = useState(0);
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    //Configuraciones necesarias del portal para llevarlo al módulo PortalConfig, él se encargará de guardar
    const portalMethods = {
        reloadInterval, 
        setReloadInterval,
        portalForm,
        setPortalForm,
        clearPortalForm,
        businesses,
        setBusinesses,
        index,
        portalConfigSaveControl, 
        setPortalConfigSaveControl,
        loadIdPortalToConfig, 
        setLoadIdPortalToConfig,
        indexPortal,
        portalEditTitle,
        setIsLoadingSave

    }

    const handlePortalForm = (event)=>{
        if (event && event.target) {
            const value = event.target.value;
            setPortalForm({ ...portalForm, [event.target.name]: value });
        } else {
            const value = event;
            setPortalForm({ ...portalForm, fromPortal: value });
        }
    }
    const handlePortalSave = (event)=>{
        event.preventDefault();
        setIsLoadingSave(true);
        setPortalConfigSaveControl(true);
    }
    const handleEditPortal = (indexPortal)=>{
        setPortalForm({
            portal: businesses[index].portals[indexPortal].name,
            id:businesses[index].portals[indexPortal].id,
            idbusiness:businesses[index].id,
        });
        setIndexPortal(indexPortal);
        setPortalEditTitle(businesses[index].portals[indexPortal].name);
        setLoadIdPortalToConfig(businesses[index].portals[indexPortal].id);
    }
    const handleCancelEdit = () =>  {
        setPortalForm(clearPortalForm());
        setIndexPortal(0);
    }
    return (
        <div className="d-flex flex-wrap m-4">
            <div className="container-fluid mb-2">
                <div className="card">
                    <div className="card-header">
                        <b>{portalForm.id ? 'Editando el portal: '+portalEditTitle : 'Crear nuevo portal'}</b>
                    </div>
                    <div className="card-body">
                        <Form>
                            <FormGroup>
                                <Form.Label>
                                    <b>Nombre del portal</b>
                                </Form.Label>
                                <Form.Control
                                type="text"
                                id="portal"
                                name="portal"
                                value={portalForm.portal}
                                onChange={handlePortalForm}
                                />
                            </FormGroup>
                            {
                                portalForm.id === 0 &&
                                <FormGroup>
                                    <label><b>Copiar desde el portal</b></label>
                                    <Select
                                        placeholder="Ninguno"
                                        name="fromPortal"
                                        isClearable
                                        value={portalForm.fromPortal}
                                        onChange={handlePortalForm}
                                        options={portalOptions}
                                        theme={theme => (styleTheme(theme))}
                                    />
                                    <Form.Text className="text-muted">
                                    Si selecciona un portal, se duplicaran todas las páginas y contenido del portal
                                    seleccionado. En caso de crear un portal vacío, no seleccione ningún portal
                                    </Form.Text>
                                </FormGroup>
                            }
                            <PortalConfig portalMethods={portalMethods}/>

                            {
                                !isLoadingSave ? 
                                <Button className="mr-2" onClick={handlePortalSave}> { portalForm.id !==0 ? 'Editar': 'Guardar'}  <FaSave /></Button> :
                                <Button disabled><ButtonLoading /></Button>
                            }
                            {
                                portalForm.id !== 0 &&
                                <Button className="mr-2" onClick={handleCancelEdit}>
                                    Cancelar
                                </Button>
                            }
                        </Form>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <h2 className="text-center my-3">Portales</h2>
                <Table hover className="editable-table">
                    <thead className="bg-dark text-white">
                        <tr>
                            <th>Portal</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            businesses[index].portals.map((portal, indexPortal)=>{
                                return (
                                    <ItemPortal businesses={businesses} setBusinesses={setBusinesses} index={index} indexPortal={indexPortal} handleEditPortal={handleEditPortal} />
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
export default ListPortal;