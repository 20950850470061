import { useEffect, useState } from "react";


export const useConfigRole = (idModule = null) => {

  const [configRole, setConfigRole] = useState([]);
  const [configRoleByIdModule, setConfigRoleByIdModule] = useState({});
  
  /*
  retorna todas las configuraciones del usuario
 */
  useEffect(() => {
    let configRol = JSON.parse(localStorage.getItem("admin_permissions"));
    setConfigRole(configRol || []);
  },[])

  /*
  configuracion por id del modulo retorna los permisos
 */
  useEffect(() => {
    if(idModule) {
      let findConfigRole = JSON.parse(localStorage.getItem("admin_permissions"))?.find(el => el.idmodule === idModule);
      setConfigRoleByIdModule(findConfigRole ? findConfigRole.permissions : {})
    }
  }, [idModule])
 
  /*
    funcion que valida si un permiso es permitido o restringuido.
    recibe el idModule y el permiso
  */
  const validateView = (idmodule, permission = "find") => {
    return configRole.find(el => el.idmodule === idmodule)?.permissions[permission] || false;
  }

  return {configRole, configRoleByIdModule, validateView};

}