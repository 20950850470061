import { useEffect, useState } from "react";
import { Form, Table, Col, Row, Container } from "react-bootstrap";
import { useParams } from "react-router";
import Select from "react-select";
import Utils from "../../utils";
import { APP_URL } from "../../config";
// import { Link } from 'react-router-dom'
import { FaTrashAlt, FaEdit, FaSave } from "react-icons/fa";
import { styleTheme } from "../../styles/SelectTheme";
import { StyleCheckbox, Button } from "../../styles/CommonStyles";
import { AlertForm, ICON } from "../../utils/SweetAlert";
import { ButtonLoading } from "../../components/Loading/ButtonLoading";
import { IconEdit } from "../../components/iconos/IconEdit";
import { IconTrash } from "../../components/iconos/IconTrash";


function Forms() {
  const [approvalResult, setapprovalResult] = useState();
  const [isLoaded, setisLoaded] = useState(false);
  let { guid,approval } = useParams();
  useEffect(() => {

    try {
      Utils.Petition.publicGet(
        `/approvals/update/${guid}/${approval}`,
        (res) => {
          if (res.data) {
            if (res.data[0] === 1) AlertForm(ICON.SUCCESS, `${res.message}`);
            setapprovalResult(res.message)
          } else {
            AlertForm(ICON.INFO, `${res.message}`);
            setapprovalResult(res.message)
          }
        },
        (err) => {
          if (err.response.data.message) {
            AlertForm(ICON.ERROR, `${err.response.data.message}`);
            setapprovalResult(err.response.data.message)
          } else {
            console.log(err);
          }
        }
      );
    } catch (err) {
      console.log("Error:", err);
    }
    setisLoaded(true)
  }, []);

  return (isLoaded &&
    <>
      <h1>{approvalResult}</h1>
    </>
  );
}

export default Forms;
