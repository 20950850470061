import { useEffect, useState } from "react";

import { Container, Button, ButtonMin } from "../../styles/CommonStyles";
import Utils from "../../utils";
import { AlertForm, ICON } from "../../utils/SweetAlert";
import Buttons from "./buttons/Buttons";
import Expresion from "./Expresion";
import { ContainerCalc, DisplayCalculator } from "./styles";
import { ButtonLoading } from '../Loading/ButtonLoading';
import { Compare } from "./buttons/Compare";

function Calculator({ type, idindicator, idstage, handleAfterSave, isCompare = false, isDisplay = false, textDisplay= ""}) {
    const [formulaComponents, setFormulaComponents] = useState([]);
    const [formula, setFormula] = useState(isCompare || isDisplay ? ' ': 'Cargando...');
    const [stageName, setStageName] = useState('');
    const [btnDisabling, setBtnDisabling] = useState({});
    const [messageValidation, setMessageValidation] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(isCompare || isDisplay) return; 
        Utils.Petition.get('/stages/id/' + idstage, (response) => {
            setStageName(response.data.name);
            if (type === 'indicator') {
                Utils.Petition.get('/indicator-stage/indicator/' + idindicator + '/stage/' + idstage, (response) => {
                    if (response.data) {
                        setFormula(response.data.formula);
                    } else {
                        setFormula(null);
                    }
                });
            } else {
                setFormula(response.data.formula);
            }
        });
    }, []);

    useEffect(() => {
        if(isDisplay && textDisplay){
            setFormula(textDisplay);
        }
    }, [textDisplay, isDisplay])
    useEffect(() => {
        fromFormulaToComponents(formula);
    }, [formula]);
    function fromFormulaToComponents(formula) {
        setIsValid(false);
        let _messageValidation = '';
        let formulaComponentsTemp = [];
        if (formula === '' || formula === null || formula === ' ') {
            setBtnDisabling({
                disabledBtnOperator: true,
                disabledBtnDelete: true,
                disabledBtnParentesisClose: true,
            });
            setIsValid(true);
        } else {
            const expresions = formula.split('||');
            let type = '';
            let _parentesisBalance = 0;
            for (let i = 0; i < expresions.length; i++) {
                if (expresions[i].indexOf('@A>B') === 0) {
                    type = "compare"
                } else if (expresions[i].indexOf('@M') === 0) {
                    type = 'month';
                } else if (expresions[i].indexOf('@I') === 0) {
                    type = 'indicator';
                } else if (expresions[i] === " + " || expresions[i] === " - ")
                    type = 'unique';
                else if (expresions[i] === '(') {
                    ++_parentesisBalance;
                    type = 'parentesis-open';
                } else if (expresions[i] === ')') {
                    --_parentesisBalance;
                    if (_parentesisBalance < 0) {
                        _messageValidation = 'Los parentesis no estan correctamente balanceados';
                    }
                    type = 'parentesis-close';
                } else if (expresions[i] === '+' || expresions[i] === '-' || expresions[i] === '*' || expresions[i] === '/') {
                    type = 'operator';
                } else {
                    type = 'number';
                }
                formulaComponentsTemp.push({
                    type,
                    expresion: expresions[i],
                })
            }
            if (type === 'operator') {
                setBtnDisabling({

                    disabledBtnOperator: true,
                    disabledBtnParentesisClose: true,
                    disabledUnique: true,
                })
            }
            if (type === 'number' || type === 'month') {
                setBtnDisabling({
                    disabledBtnIndicator: true,
                    disabledBtnNumber: true,
                    disabledBtnParentesisOpen: true,
                    disabledBtnMonth: true,
                    disabledUnique: true,
                })
            }
            if (type === 'indicator' || type === 'compare') {
                setBtnDisabling({
                    disabledBtnIndicator: true,
                    disabledBtnNumber: true,
                    disabledBtnParentesisOpen: true,
                    disabledBtnMonth: true,
                    disabledUnique: true,
                });
            }
            if (type === 'parentesis-open') {
                setBtnDisabling({
                    disabledBtnOperator: true,
                    disabledBtnParentesisClose: true,
                    disabledUnique: true,
                });
            }
            if (type === 'parentesis-close') {
                setBtnDisabling({
                    disabledBtnIndicator: true,
                    disabledBtnNumber: true,
                    disabledBtnParentesisOpen: true,
                    disabledBtnMonth: true,
                    disabledUnique: true,
                });
            }
            if( type === "unique") {
                setBtnDisabling({
                    disabledBtnIndicator: true,
                    disabledBtnNumber: true,
                    disabledBtnParentesisOpen: true,
                    disabledBtnMonth: true,
                    disabledBtnOperator: true,
                    disabledBtnParentesisClose: true,
                    disabledUnique: true,
                });
            }
            if (_parentesisBalance !== 0 && _messageValidation === '') {
                _messageValidation = 'Sin finalizar: debes cerrar los mismos parentesis que abres';
            }
            if (type === 'operator' && _messageValidation === '') {
                _messageValidation = 'Sin finalizar: agrega un número o indicador';
            }
            if (_messageValidation === '') {
                setMessageValidation('');
                setIsValid(true);
            } else {
                setMessageValidation(_messageValidation);
            }


        }
        if(!isCompare) {
            if (formula === null) {
                setFormulaComponents(null);
            } else if (formula === '') {
                setFormulaComponents('');
            } else {
                setFormulaComponents(formulaComponentsTemp);
            }
        } else {
            if (formula === null || formula === '' ) {
                setFormulaComponents([]);
            } else {
                setFormulaComponents(formulaComponentsTemp);
            }
        }
        
    }

    const handleSaveForm = () => {

        setIsLoading(true);
        if (type === 'indicator') {
            Utils.Petition.post('/indicator-stage',
                { idindicator, idstage, formula },
                function () {
                    AlertForm(ICON.SUCCESS, 'Formula guardado correctamente', '', 'center', 6000, true);
                    handleAfterSave();

                });
        } else {
            Utils.Petition.post('/stages/update',
                { id: idstage, formula },
                function (data) {

                    AlertForm(ICON.SUCCESS, 'Formula guardado correctamente', '', 'center', 6000, true);
                    handleAfterSave();

                });
        }
        setIsLoading(false);
    }

    const handleSaveExpression = () => {
        handleAfterSave(formula, formulaComponents);
    }
    return (
        <ContainerCalc display={isDisplay}>
            <DisplayCalculator>
                {formulaComponents === null && type === 'indicator' && <span className="btn-sm btn btn-warning disabled">Formula heredada del escenario {stageName}</span>}
                {(formulaComponents === '' || (formulaComponents === null && type === 'stage')) && <span disabled className="btn-sm btn btn-warning disabled">Actualización manual</span>}

                {
                    Array.isArray(formulaComponents) &&
                    formulaComponents.map((component,idx) => {

                        return (<span className={component.type} key={`formulaComp-${idx}`}>
                            {component.type === 'indicator' && <Expresion expresion={component.expresion} />}
                            {component.type === 'month' && <span className="mr-2 mb-2">Mes Actual</span>}
                            {component.type === 'compare' && <Compare formula={formula} setFormula={setFormula} isView={true} component={component}/>}
                            {component.type !== 'indicator' && component.type !== 'compare' && component.type !== 'month' && <span className="mr-2 mb-2">{component.expresion}</span>}
                        </span>)
                    })
                }
            </DisplayCalculator>
            {!isDisplay && 
                <div className="mt-3">
                {
                    messageValidation !== '' &&
                    <div className="alert alert-warning" role="alert">{messageValidation}</div>
                }
                <div className="calculator-buttons">
                    <Buttons type={type} btnDisabling={btnDisabling} formula={formula} setFormula={setFormula} isCompare={isCompare}/>
                </div>
                {!isCompare &&<div className="actions text-center mt-3">
                    <Button disabled={!isValid || isLoading} onClick={handleSaveForm}>{isLoading ? <ButtonLoading text="Guardando...." /> : 'Guardar formula'}</Button>
                </div>}
                {isCompare &&<div className="actions text-center mt-3">
                    <Button disabled={!isValid || isLoading} onClick={handleSaveExpression}>{isLoading ? <ButtonLoading text="Guardando...." /> : 'Guardar formula'}</Button>
                </div>}
                </div>
            }
           
        </ContainerCalc>
    )
}
export default Calculator;