import styled from 'styled-components';
import { Accordion } from 'react-bootstrap';

export const ContainerGraphic = styled.div`
    min-height: 400px;

    & canvas {
        height: 400px;
    }
`;

export const AccordionStyle = styled(Accordion)`
    & button {
        border: none;
        background-color: transparent;
    }
`;