import { useEffect, useState } from 'react';
import { Container, Form, FormControl, Table, Row, Col, Accordion, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import Objectives from './Objectives';
import Utils from '../../utils';
import { AlertConfirm, AlertForm, ICON } from "../../utils/SweetAlert";
import { styleTheme } from "../../styles/SelectTheme";
import './strategic-map.css';
import { FaSave } from 'react-icons/fa';
import CustomModal from '../../components/CustomModal';
import { DivActions, DivContent } from './style';
import { Button, ButtonLink, ButtonLinkAccordeon, StyleCheckbox } from '../../styles/CommonStyles';
import { IconEdit } from '../../components/iconos/IconEdit';
import { IconTrash } from '../../components/iconos/IconTrash';
import { IconSave } from '../../components/iconos/IconSave';
import { IconDown } from '../../components/iconos/IconDown';
import { IconUndo } from '../../components/iconos/IconUndo';
import { IconUp } from '../../components/iconos/IconUp';


function FormStrategicMap() {
    const { id } = useParams();
    const [showAddForm, setShowAddForm] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [formPerspective, setFormPerspective] = useState({ perspective: '' })
    const [editIndex, setEditIndex] = useState(-1);
    const [stages, setStages] = useState([]);
    const [formComment, setFormComment] = useState({ comment: '' })
    const [form, setForm] = useState({
        data: [],
        stages: {
            sign: {},
            col1: {},
            col2: {},
            col3: {},
            col4: {},
        }
    });

    const [indicatorData, setIndicatorData] = useState([]);

    useEffect(() => {
        Utils.Petition.get(
            '/pages-modules/id/' + id, (response) => {

                if (!response.data.config.data) response.data.config.data = [];
                if (!response.data.config.stages) {
                    response.data.config.stages = {
                        sign: {},
                        col1: {},
                        col2: {},
                        col3: {},
                        col4: {},
                    };
                }
                let ids = [];
                for (let i in response.data.config.data) {
                    for (let j in response.data.config.data[i].data) {
                        for (let k in response.data.config.data[i].data[j].data) {
                            if (!ids.includes(response.data.config.data[i].data[j].data[k])) {
                                ids.push(response.data.config.data[i].data[j].data[k]);
                            }
                        }
                    }
                }

                Utils.Petition.get(
                    '/indicators/idindicators/' + ids.join(','), (response) => {
                        setIndicatorData(response.data);
                    }
                );

                setForm(response.data.config);
            }
        );
        Utils.Petition.get(
            '/stages_relation/select',
            (response) => {
                setStages(response.data);
            }
        );
        Utils.Petition.get('/comments/type-model/page_module/type-id/' + id + '/year/' + localStorage.getItem('year') + '/month/' + localStorage.getItem('month') + '/view/' + localStorage.getItem('view'), (response) => {
            if (response) {
                setFormComment({ comment: response.data.comment });
            } else {
                setFormComment({ comment: '' });
            }
        })
    }, []);

    const selectStage = (data, key) => {
        let _form = JSON.parse(JSON.stringify(form));
        _form.stages[key].data = data;
        setForm(_form);
    }

    const handleShowAddForm = () => {
        setEditIndex(-1);
        setShowAddForm(!showAddForm);
        setFormPerspective({ perspective: '' });

    }
    const handleChangeForm = (event) => {
        const name = event.target.name.split('-')[0];
        setFormPerspective({ ...formPerspective, [name]: event.target.value })
    }
    const handleAdd = (event) => {
        event.preventDefault()
        let _form = { ...form };
        if (formPerspective.perspective === '') {
            AlertForm(ICON.WARNING, 'Ingrese un nombre por favor');
        } else {
            _form.data.push({ name: formPerspective.perspective, data: [] });
            setForm(_form);
            setFormPerspective({ perspective: '' })
        }
    }
    const handleEdit = (index) => {
        setShowAddForm(false);
        setEditIndex(index);
        setFormPerspective({ perspective: form.data[index].name });
    }
    const handleSaveAdd = () => {
        let _form = { ...form };
        _form.data[editIndex].name = formPerspective.perspective;
        setForm(_form);
        setEditIndex(-1);
    }
    const handleBack = () => {
        setEditIndex(-1);
    }

    const deleteConfirm = (index) => {
        let _form = { ...form };
            _form.data.splice(index, 1)
            setForm(_form);
    }
    const handleDelete = (index) => {
        AlertConfirm(ICON.WARNING, '¿Seguro que desea eliminar esta perspectiva?', "",
        () => deleteConfirm(index));
    }

    const handleOrderUp = (index) => {
        let _form = JSON.parse(JSON.stringify(form));
        _form.data[index - 1] = _form.data[index];
        _form.data[index] = form.data[index - 1];
        setForm(_form);
    }
    const handleOrderDown = (index) => {
        let _form = JSON.parse(JSON.stringify(form));
        _form.data[index + 1] = _form.data[index];
        _form.data[index] = form.data[index + 1];
        setForm(_form);
    }
    const handlePerspectiveActions = (event) => {
        const name = event.target.name.split('-');
        let _form = JSON.parse(JSON.stringify(form));
        _form.data[name[2]][name[1]] = event.target.checked;
        setForm(_form);
    }
    const handleSave = () => {
        const data = {
            id: id,
            config: form
        };
        Utils.Petition.post(
            '/pages-modules/update', data,
            (response) => {
                if (window.parent) {
                    AlertForm(ICON.SUCCESS, response.message);
                }
            }
        );
    }
    const handleStagesName = (event) => {
        let _form = JSON.parse(JSON.stringify(form));
        _form.stages[event.target.name].name = event.target.value;
        
        if(event.target.value === ""){
            let dataObj = {value: [0,0],view: ["M","A"]}
            _form.stages[event.target.name].data = dataObj;
        }
        setForm(_form);
    }

    const handleCheckPercent = (event) => {
        let _form = JSON.parse(JSON.stringify(form));
        _form.stages[event.target.name].percent = event.target.checked;
        setForm(_form);
    }
    const handleFormComment = (event) => {
        setFormComment({ ...formComment, [event.target.name]: event.target.value })
    }
    const handleCommentModal = () => {
        setShowModal(true);
    }
    const handleSaveComment = () => {
        Utils.Petition.post('/comments', {
            comment: formComment.comment,
            year: localStorage.getItem('year'),
            month: localStorage.getItem('month'),
            typeModel: 'page_module',
            idtype: id,
            view: localStorage.getItem('view'),
        },
            (response) => {
                AlertForm(ICON.SUCCESS, response.message);
                setShowModal(false);
            })
    }
    const selectStrategicMap = (data,key) => {
        let _form = JSON.parse(JSON.stringify(form));
        _form.stages.designStrategicMap = data;
        setForm(_form);
    }

    const handleAdvanceConfig = (e,key) => {
        let _form = JSON.parse(JSON.stringify(form));
        if(e.target.type === "checkbox") {
            _form.stages[key] = e.target.checked;
            if(key === "includeYellow") {
                _form.stages.percentYellow = (_form.stages.includeYellow) ? "90": "";
            }
        } else if(key === "descendingIndicator") {
            _form.stages.percentYellow = "";
            _form.stages.includeYellow = false;
            _form.stages[key] = e.target.value;
        } else {
            _form.stages[key] = e.target.value;
        }
        setForm(_form);
    }


    return (
        <Container fluid>
            {
                showModal &&
                <CustomModal title={'Comentario del mes actual'} show={showModal} setShow={setShowModal} handleSaveButton={handleSaveComment} saveButtonText="Guardar comentario">
                    <Form>
                        <h5>Comentario</h5>
                        <textarea className="form-control" onChange={handleFormComment} id="comment" name="comment" value={formComment.comment} />
                    </Form>
                </CustomModal>
            }
            <Row>
                <Col sm={3}>
                    <h5>Semaforo</h5>
                    <Select
                        value={form.stages.sign.data}
                        cacheOptions
                        defaultOptions
                        options={stages}
                        onChange={(data) => selectStage(data, 'sign')}
                        theme={theme => (styleTheme(theme))}
                        placeholder="Escenario de cumplimiento"
                    />

                    <h5 className="mt-3">Diseño</h5>
                    <Select
                        value={form.stages.designStrategicMap === undefined ? {label: "Default", value: "Default"}:form.stages.designStrategicMap}
                        options={[{label: "Default", value: "Default"}, {label: "Jerarquico", value: "Jerarquico"}]}
                        onChange={(data) => selectStrategicMap(data, 'designStrategicMap')}
                        theme={theme => (styleTheme(theme))}
                        placeholder="Seleccionar Diseño"
                    />  
                    <Accordion className="mt-3">
                        <Card>
                            <Card.Header>
                            <Accordion.Toggle as={ButtonLinkAccordeon} variant="link" eventKey="0">
                                Configuración Avanzada
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <StyleCheckbox className="custom-checkbox ml-4">
                                    <input className="custom-control-input" type="checkbox"  id={"include-semaforo"} disabled={form.stages.descendingIndicator} onChange={(e) => handleAdvanceConfig(e,"includeYellow")} checked={form.stages.includeYellow}/>
                                    <label className="custom-control-label" htmlFor={"include-semaforo"}>
                                        Incluir semaforo Amarillo
                                    </label>
                                </StyleCheckbox>

                                <label className="mt-3">
                                    <b>Porcentaje para color Amarillo (%)</b>
                                </label>
                                <FormControl type="number" className="w-50" disabled={!form.stages.includeYellow} onChange={(e) => handleAdvanceConfig(e,"percentYellow")} value={form.stages.percentYellow}/>
                                <StyleCheckbox className="custom-checkbox ml-4 mt-3">
                                    <input className="custom-control-input" type="checkbox"  id={"unit-percent"} onChange={(e) => handleAdvanceConfig(e,"unitPercent")} checked={form.stages.unitPercent}/>
                                    <label className="custom-control-label" htmlFor={"unit-percent"}>
                                        Si un indicador tiene unidad de medida %, cumplir si es mayor o igual a 0
                                    </label>
                                </StyleCheckbox>
                                <StyleCheckbox className="custom-checkbox ml-4 mt-3">
                                    <input className="custom-control-input" type="checkbox"  id={"descending-indicator"} disabled={form.stages.includeYellow} onChange={(e) => handleAdvanceConfig(e,"descendingIndicator")} checked={form.stages.descendingIndicator}/>
                                    <label className="custom-control-label" htmlFor={"descending-indicator"}>
                                        Invertir semaforo cuando el indicador es descendente
                                    </label>
                                </StyleCheckbox>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                </Col>
                <Col sm={9}>
                    <h4>Configuración del indicador</h4>
                    <Table>
                        <tr>
                            <th>Títulos</th>
                            <th><FormControl name="col1" placeholder="Título Columna 1" value={form.stages.col1.name} onChange={handleStagesName} /></th>
                            <th><FormControl name="col2" placeholder="Título Columna 2" value={form.stages.col2.name} onChange={handleStagesName} /></th>
                            <th><FormControl name="col3" placeholder="Título Columna 3" value={form.stages.col3.name} onChange={handleStagesName} /></th>
                            <th><FormControl name="col4" placeholder="Título Columna 4" value={form.stages.col4.name} onChange={handleStagesName} /></th>
                        </tr>
                        <tr>
                            <td><b>Escenarios</b></td>
                            <td>
                                <Select
                                    value={form.stages.col1.data}
                                    cacheOptions
                                    defaultOptions
                                    options={stages}
                                    onChange={(data) => selectStage(data, 'col1')}
                                    theme={theme => (styleTheme(theme))}
                                    placeholder="Col 1"
                                />
                            </td>
                            <td>
                                <Select
                                    value={form.stages.col2.data}
                                    cacheOptions
                                    defaultOptions
                                    options={stages}
                                    onChange={(data) => selectStage(data, 'col2')}
                                    theme={theme => (styleTheme(theme))}
                                    placeholder="Col 2"
                                />
                            </td>
                            <td>
                                <Select
                                    value={form.stages.col3.data}
                                    cacheOptions
                                    defaultOptions
                                    options={stages}
                                    onChange={(data) => selectStage(data, 'col3')}
                                    theme={theme => (styleTheme(theme))}
                                    placeholder="Col 3"
                                />
                            </td>
                            <td>
                                <Select
                                    value={form.stages.col4.data}
                                    cacheOptions
                                    defaultOptions
                                    options={stages}
                                    onChange={(data) => selectStage(data, 'col4')}
                                    theme={theme => (styleTheme(theme))}
                                    placeholder="Col 4"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Porcentaje</th>
                            {
                                ["col1", "col2", "col3", "col4"].map(el => {
                                    return(
                                        <th key={el}>
                                            <StyleCheckbox className="custom-checkbox ml-4">
                                                <input className="custom-control-input" type="checkbox" name={el} id={el}  onChange={handleCheckPercent} checked={form.stages[el].percent} />
                                                <label className="custom-control-label" htmlFor={el}>
                                                    Incluir
                                                </label>
                                            </StyleCheckbox>
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </Table>
                </Col>
            </Row>
            <DivContent>
                <h3>Configuración del mapa</h3>
                <div className="button-group">
                    <Button onClick={handleCommentModal}>Incluir comentario</Button> <Button onClick={handleShowAddForm}>Nueva Perpectiva</Button>
                </div>

            </DivContent>
            <div>
                {showAddForm &&
                    <Form className="px-3">
                        <Button variant="close" className="close" onClick={() => { setShowAddForm(false) }}>×</Button>
                        <Form.Group>
                            <Form.Label>
                                <b>Nombre</b>
                            </Form.Label>
                            <FormControl type="text" onChange={handleChangeForm} value={formPerspective.perspective} id="perspective" name="perspective" />
                        </Form.Group>
                        <Form.Group>
                            <Button onClick={handleAdd}>Agregar</Button>
                        </Form.Group>
                    </Form>
                }
            </div>
            <div className="container-group">
                {
                    form.data.map((perspective, index) => {
                        return (
                            <div key={index} className={(perspective.grouped && 'is-grouped') + ' p-4 ' + (perspective.horizontal && 'is-horizontal')}>
                                <StyleCheckbox className="custom-checkbox ml-4">
                                    <input className="custom-control-input" type="checkbox" name={"perspective-grouped-" + index} id={"perspective-grouped-" + index} onChange={handlePerspectiveActions} checked={perspective.grouped} />
                                    <label className="custom-control-label" htmlFor={"perspective-grouped-" + index}>
                                        Mostrar perspectiva agrupada
                                    </label>
                                </StyleCheckbox>
                                <StyleCheckbox className="custom-checkbox ml-4 mb-4">
                                    <input className="custom-control-input" type="checkbox" name={"perspective-horizontal-" + index} id={"perspective-horizontal-" + index} onChange={handlePerspectiveActions} checked={perspective.horizontal} />
                                    <label className="custom-control-label" htmlFor={"perspective-horizontal-" + index}>
                                        Mostrar perspectiva horizontal
                                    </label>
                                </StyleCheckbox>
                                <DivActions>
                                    {
                                        index === editIndex ?
                                            <>
                                                <FormControl type="text" onChange={handleChangeForm} value={formPerspective.perspective} id={"perspective-" + index} name={'perspective-' + index} />
                                                <IconSave color="black" link onClick={handleSaveAdd} />
                                                <IconUndo link onClick={handleBack} />
                                            </>
                                            :
                                            <>
                                                <span>{perspective.name}</span>
                                                <IconEdit link onClick={() => handleEdit(index)} />
                                            </>
                                    }


                                    {index !== 0 && <IconUp link onClick={() => handleOrderUp(index)} />}
                                    {form.data.length - 1 !== index && <IconDown link onClick={() => handleOrderDown(index)} />}
                                    <IconTrash link onClick={() => handleDelete(index)} />
                                </DivActions>
                                <Objectives form={form} setForm={setForm} indicatorData={indicatorData} setIndicatorData={setIndicatorData} indexPerspective={index} />

                            </div>
                        )
                    })
                }
            </div>
            <div className="mt-4 text-center">
                <Button onClick={handleSave}>Guardar cambios <FaSave /></Button>
            </div>

        </Container>
    );
}
export default FormStrategicMap;