import React, { useState, useEffect } from "react";
import Utils from "../../utils";
import { ButtonGroup, Form, Col, Row, InputGroup, FormControl } from 'react-bootstrap';
import { FaSave, FaEraser } from "react-icons/fa";
import { Button, Container, StyleInput } from "../../styles/CommonStyles";
import { IconEdit } from '../../components/iconos/IconEdit';
import { IconTrash } from '../../components/iconos/IconTrash';
import { IconSave } from "../../components/iconos/IconSave";
import { IconConfig } from "../../components/iconos/IconConfig";
import { IconClose } from "../../components/iconos/IconClose";
import { AlertConfirm, AlertForm, ICON} from "../../utils/SweetAlert";

//popUps
import CustomModal from "../../components/CustomModal";
import { Theme1 } from "../../styles/Theme1";
import { Loading } from "../../components/Loading";
import { ButtonLoading } from "../../components/Loading/ButtonLoading";
import { useConfigRole } from "../../Hooks/configRole";

function Forms() {

  const [lists, setLists] = useState([]);
  const [tableIsLoaded, setTableIsLoaded] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const [newList, setNewList] = useState("");
  const [edit, setEdit] = useState(false);
  const [aux, setAux] = useState('');
  const [aux2, setAux2] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [newValue, setNewValue] = useState({});
  const [newDescription, setNewDescription] = useState({});
  const [listOption, setListOption] = useState([]);
  const [newOptions, setNewOptions] = useState([]);
  const { configRoleByIdModule } = useConfigRole(9);

const updateList = () => {
  setTableIsLoaded(false);
    Utils.Petition.get(`/lists/`,
      (res) => {
        setLists(res.data);
      },
      (err) => {
        console.log(err);
        AlertForm(ICON.ERROR, "Error al traer información Lista de listas");
      },
      () => {
        setTableIsLoaded(true);
      }
    );
}
  useEffect(() => {
    updateList();
  },[])

  const saveDB = (e) =>{
    e.preventDefault()
    setIsLoadingSave(true);
    Utils.Petition.post(`/lists/${newList}`,
      null,
      (res) => {
        updateList();
        AlertForm(ICON.SUCCESS, res.message);
      },
      (err) => {
        console.log(err);
        AlertForm(ICON.ERROR, "Error al guardar información");
      },
      () => {
        setIsLoadingSave(false);
      }
    );
  }

  const confirmDelete = (id) => {
    Utils.Petition.post(`/lists/delete-list/${id}`, null,
      (res) => {
        AlertForm(ICON.SUCCESS, res.message);
        updateList();
      },
      (err) => {
        AlertForm(ICON.ERROR, err.message);
      }
    );
  }
  const handleDelete = (id) => {
    AlertConfirm(ICON.WARNING, "Eliminar lista", "Deseas continuar ?",  () => confirmDelete(id));
  }

  const handleUpdate = (id, key) => {
    setEdit(!edit)
    if (aux2.value !== aux.value || aux2.description !== aux.description) {
      Utils.Petition.post(`/list-options/update-list-options/`, {id, aux2}, (res) => {
        let _listOption = [...listOption]
        _listOption[key].value = aux2.value
        _listOption[key].description = aux2.description
        setListOption(_listOption)
        AlertForm(ICON.SUCCESS, res.message);
        updateList();
      },
      (err) => {
        AlertForm(ICON.ERROR, "Error al actualizar información");
      })
    }
    else { 
      Utils.Petition.post(`/lists/update-list/${id}/${aux2}`, null, (res) => {
        AlertForm(ICON.SUCCESS, res.message);
        updateList();
      },
      (err) => {
        AlertForm(ICON.ERROR, "Error al actualizar información");
      })
    }
  }

  const handleListOptions = (id) => {
    setListOption([]);
    Utils.Petition.get(`/list-options/value-attribute/id-list/${id}`,
      (res) => {
        setListOption(res.data)
      },
      (err) => {
        console.log(err);
        AlertForm(ICON.ERROR, "Error al consultar Lista de opciones");
      }
    );
    // setNewOptions([...newOptions, id])
    setShowModal({modal:true, idlist:id})
  }

  const handleAddListOptions = (e) => {
    e.preventDefault();
    if (newValue.value !== undefined && newDescription.description !== undefined) {
      let data = [...newOptions]
      let idlist = showModal.idlist // id de la lista
      data.push({id:null, idlist, value:newValue.value, description:newDescription.description})
      setNewOptions(data)
      setListOption([...listOption, {id:null, idlist, value:newValue.value, description:newDescription.description}])
      setNewValue({value: ""});
      setNewDescription({description: ""});

    }else{
      AlertForm(ICON.WARNING, "Símbolo y Descripción no pueden estar vacios");
    }
  }

  const handleSaveListOptions = () => {
    let data = []
    for (const key in newOptions) {
      data.push({idlist:newOptions[key].idlist, value:newOptions[key].value ,description:newOptions[key].description})
    } 
    Utils.Petition.post(`/list-options/`, data,
      (res) => {
        AlertForm(ICON.SUCCESS, res.message);
        setNewOptions([]);
        setShowModal(false);
        updateList();
      },
      (err) => {
        console.log(err);
        AlertForm(ICON.SUCCESS, "Error al guardar Lista de Opciones");
      }
    );
  }

  const handleDeleteListOption = (id, key) => {
    Utils.Petition.post(`/list-options/delete-list-options`, {id},
      (res) => {
        let _listOption = [...listOption]
        _listOption.splice(key,1)
        setListOption(_listOption)
        AlertForm(ICON.SUCCESS, res.message);
      },
      (err) => {
        console.log(err);
        AlertForm(ICON.ERROR, "Error al eliminar Lista de opciones");
      }
    );
  }

  return (
    <Container>
      <div className="d-flex flex-wrap m-4">
        <div className="container-fluid mb-2">
          <div className="card">
            <div className="card-header">
              <b>Administrador de Listas</b>
            </div>
            {configRoleByIdModule.create && 
              <div className="card-body">
              <form>
                <input
                  type="text"
                  className="form-control"
                  placeholder="id"
                  // value={userId}
                  hidden
                />
                <div className="form-group">
                  <label>Nombre de la lista</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Escribe el nuevo item de Lista"
                    onChange={(e)=>setNewList(e.target.value)}
                    value={newList}
                    required
                  />
                </div>
                {
                  !isLoadingSave ? <Button onClick={(e)=>saveDB(e)}>Guardar <FaSave /></Button> :
                  <Button disabled><ButtonLoading /></Button>
                }
                
                <Button color={Theme1.grayText} className="mr-2" onClick={() => setNewList("")}>
                  Limpiar <FaEraser />
                </Button>
              </form>
            </div>
            }
          </div>
        </div>
        <div className="container-fluid">
          <h2 className="py-4 text-center">Lista de listas</h2>
          <table className="table table-striped text-center">
            <thead className="bg-dark text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre de la lista</th>
                <th className="text-right pr-5" style={{width: "200px"}}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {tableIsLoaded && 
                lists.map((value, key) => (
                  <tr key={key}>
                    {value.delete !== 1 && <>
                    <td>{value.id}</td>
                      {value.name === aux && edit
                        ?<td> <StyleInput
                          type="text"
                          name="path" 
                          className="form-control"
                          onChange={(event)=>setAux2(event.target.value)}
                          value={aux2}
                        /></td>
                        : <td>{value.name}</td>
                      }
                      {/* <td>{value.name}</td> */}
                      <td className="text-right pr-5">
                        {value.name === aux && edit
                          ? <ButtonGroup aria-label="Basic example">
                            <span style={{marginTop: "-0.2rem"}} ><IconSave link color={Theme1.success} onClick={()=>handleUpdate(value.id, key)} /></span>
                            <IconClose textTooltip="Cancelar" color={Theme1.red} link  onClick={()=>setEdit(!edit)} />
                          </ButtonGroup>
                          : value.editable !== 0 && configRoleByIdModule.update && <IconEdit link onClick={()=>{setEdit(!edit); setAux(value.name); setAux2(value.name)}} />}
                        {value.editable !== 0 && configRoleByIdModule.delete && <IconTrash color="red" link onClick={()=>handleDelete(value.id)} />}
                        {configRoleByIdModule.update && <IconConfig textTooltip="Opciones" link onClick={()=>handleListOptions(value.id)} />}
                    </td></>}
                  </tr>
                ))}
            </tbody>
          
          </table>
          {!tableIsLoaded && <Loading />}
        </div>
      </div>
      <CustomModal
        title={"Lista de opciones"}
        show={showModal.modal}
        setShow={setShowModal}
        handleSaveButton={handleSaveListOptions}
        size="xl"
      >
        {showModal && (
          <>
              <Row>
                <Col sm={4}>
                  <Form>
                    <Form.Label>Símbolo</Form.Label>
                      <InputGroup className="mb-3" >
                        <FormControl type="text" value={newValue.value} onChange={(e)=>setNewValue({value:e.target.value})} placeholder="Escribe el símbolo para la opción de la lista"/>
                      </InputGroup>
                    <Form.Label>Descripción</Form.Label>
                      <InputGroup className="mb-3" >
                        <FormControl type="text" value={newDescription.description} onChange={(e)=>setNewDescription({description:e.target.value})} placeholder="Escribe la descripción del símbolo"/>
                      </InputGroup>
                      <Button onClick={(e)=>handleAddListOptions(e)} >Agregar</Button>
                  </Form>
                </Col>
                <Col sm={8}>
                  <table className="table table-striped text-center">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Identificador</th>
                        <th scope="col">Símbolo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableIsLoaded && listOption.map((e, key)=>(
                        <tr key={key}>  {e.deleted!=1 && <>
                          <td scope="row">{key+1}</td>
                          <td>{e.id}</td>
                          {e.value === aux.value&&edit
                            ? <td><StyleInput
                              type="text"
                              name="path" 
                              className="form-control"
                              onChange={(event)=>setAux2({...aux2, value:event.target.value})}
                              value={aux2.value}
                            /></td>
                            : <td>{e.value}</td>
                          }
                          {e.value === aux.value&&edit
                            ? <td><StyleInput
                              type="text"
                              name="path" 
                              className="form-control"
                              onChange={(event)=>setAux2({...aux2, description:event.target.value})}
                              value={aux2.description}
                            /></td>
                            : <td>{e.description}</td>
                          }
                          <td style={{width: "200px"}}>
                            {e.value === aux.value && edit
                              ? <ButtonGroup>
                                <span style={{marginTop: "-0.2rem"}} ><IconSave color={Theme1.success} onClick={()=>handleUpdate(e.id, key)} /></span>
                                <IconClose textTooltip="Cancelar" color={Theme1.red} onClick={()=>setEdit(!edit)} />
                                </ButtonGroup>
                              : <IconEdit link onClick={()=>{setEdit(!edit); setAux({value:e.value, description:e.description}); setAux2({value:e.value, description:e.description})}} />
                            }
                            {e.id>81&&<IconTrash color="red" link onClick={()=>handleDeleteListOption(e.id, key)} />}
                          </td>
                          </>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {!listOption.length && "No hay resultados"}
                </Col>
              </Row>

          </>
        )}
      </CustomModal>
    </Container>
  );
}

export default Forms;