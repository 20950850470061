import styled from "styled-components";

export const DivContent = styled.div`
    text-align: center;
    margin-top: 1rem;
    & .button-group {
        margin: 2rem 0;
    }

    & label {
        font-weight: bold;
    }
`;

export const DivActions = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    & > span {
        font-weight: bold;
        margin-right: 1.5rem;
        font-size: 1.2rem;
    }
    & > input {
        width: 150px;
        margin-right:1rem;
    }
`;

export const AddFormStyle = styled.div`
    & .close {
        color: black;
    }
`;