import React, { useState, useEffect } from "react";
import Select from "react-select";
import Utils from "../../../utils";

//Buttons
import { IconEdit } from "../../../components/iconos/IconEdit";
import { IconTrash } from "../../../components/iconos/IconTrash";
import { FaSave, FaEraser, FaSearch } from "react-icons/fa";
import { Theme1 } from "../../../styles/Theme1";
import { Button, StyleCheckbox } from "../../../styles/CommonStyles";
import { styleTheme } from "../../../styles/SelectTheme";
import { Loading } from "../../../components/Loading";
import { ButtonLoading } from "../../../components/Loading/ButtonLoading";
import { AlertConfirm, AlertForm, ICON } from "../../../utils/SweetAlert";
import { useConfigRole } from "../../../Hooks/configRole";

function List() {
  //data table
  const [dataAttrs, setDataAttrs] = useState("");
  const [dataBusiness, setDataBusiness] = useState("");

  //Loaders States
  const [tableIsLoaded, setTableIsLoaded] = useState(false);
  const [listsIsLoaded, setListsIsLoaded] = useState(false);
  const [businessIsLoaded, setBusinessIsLoaded] = useState(false);
  const [noTextLoaded, setNoTextLoaded] = useState(false);

  //React Select States
  const [typeOptions, setTypeOptions] = useState([
    { label: "Texto", value: "text" },
    { label: "Lista Basica", value: "list" },
    { label: "Lista Multiple", value: "list-multiple" },
  ]);
  const [listOptions, setListOptions] = useState([]);
  //const [businessOptions, setBusinessOptions] = useState([])

  //dataConfig
  const [idAttr, setIdAttr] = useState("");
  const [nameAttr, setNameAttr] = useState("");
  const [typeList, setTypeList] = useState([]);
  const [order, setOrder] = useState("");
  const [selectedList, setSelectedList] = useState("");
  const [checkMandatory, setCheckMandatory] = useState(false);
  const [checkEdit, setCheckEdit] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const { configRoleByIdModule } = useConfigRole(5);

  useEffect(() => {
    setTableIsLoaded(false);
    //List of atributes
    let urlIndicatorsAttrs = `/indicator-atribute/`; //obtener por id bisne
    Utils.Petition.get(
      urlIndicatorsAttrs,
      (res) => {
        setDataAttrs(res.data);
        setTableIsLoaded(true);
      },
      (err) => {
        console.log(err);
      }
    );

    //list of lists
    let urlListOptions = `/lists/`; //agregar id busines
    Utils.Petition.get(
      urlListOptions,
      (res) => {
        let data = res.data;
        let options = [];

        for (let pos in data) {
          options.push({ label: data[pos].name, value: data[pos].id });
        }
        setListOptions(options);
        setListsIsLoaded(true);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  //function that init or updates the view of the list

  const handleNameAttr = (event) => {
    setNameAttr(event.target.value);
    console.log("Nombre", nameAttr);
  };

  const handleOrder = (event) => {
    setOrder(event.target.value);
  };

  const handleTypeList = (event) => {
    setTypeList(event);
    setNoTextLoaded(true);
    console.log("Tipo Lista", typeList);
  };

  const handleSelectedList = (event) => {
    setSelectedList(event);
    console.log("Tipo Lista", selectedList);
  };

  const handleCheckMandatory = (event) => setCheckMandatory(!checkMandatory);
  const handleCheckEdit = (event) => setCheckEdit(!checkEdit);

  const updateView = () => {
    let urlIndicatorsAttrs = `/indicator-atribute/`; //obtener por id bisne
    Utils.Petition.get(
      urlIndicatorsAttrs,
      (res) => {
        setTableIsLoaded(false);
        setDataAttrs(res.data);
        setTableIsLoaded(true);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const deleteData = (event, id, name) => {
    event.preventDefault();
    let urlIndicatorsAttrs = `/indicator-atribute/delete-attr/${id}`; //obtener por id bisne
    Utils.Petition.delete(
      urlIndicatorsAttrs,
      (res) => {
        AlertForm(ICON.SUCCESS, `El atributo ${name} ha sido eliminado`);
        updateView();
        cleanFields();
      },
      (err) => {
        console.log(err);
        AlertForm(ICON.ERROR, "No se elimino la información", "Intentelo nuevamente");
      }
    );
   
  };

  const saveDB = (event) => {
    event.preventDefault();
    setIsLoadingSave(true);
    //congigurates the body that will be send to de endpoint
    const config = {
      name: nameAttr,
      object_type: typeList.value,
      isrequired: checkMandatory ? 1 : 0,
      editable: checkEdit ? 1 : 0,
      order: order, //este deberia ingresrlo el admin
    };
    if (typeList !== "text") config.idlist = selectedList.value;

    //only updates the data if the object contains the id of atribute
    //if id not exist the function going to create a new atribute
    if (idAttr) {
      Utils.Petition.post(
        `/indicator-atribute/update-attr/${idAttr}`,
        config,
        (res) => {
          AlertForm(ICON.SUCCESS, "Actualizado Correctamente");
        },
        (err) => {
          console.log(err);
          AlertForm(ICON.ERROR, "No se actualizo la información", "Intentelo nuevamente");
        }, () => {
          setIsLoadingSave(false);
        }
      );
    } else {
      Utils.Petition.post(
        "/indicator-atribute/save-attr",
        config,
        (res) => {
          AlertForm(ICON.SUCCESS, "Guardado Correctamente");
        },
        (err) => {
          console.log(err);
          AlertForm(ICON.ERROR, "No se guardo la información", "Intentelo nuevamente");
        }, () => {
          setIsLoadingSave(false);
        }
      );
    }
    updateView();
    cleanFields();
  };

  const cleanFields = (e) => {
    if(e) e.preventDefault();
    //dataConfig restart
    setIdAttr("");
    setNameAttr("");
    setTypeList(null);
    setOrder("");
    setSelectedList("");
    setCheckMandatory(false);
    setCheckEdit(false);
  };

  const fillFields = (e, id, name, listType, order, list, mandatory, edit) => {
    e.preventDefault();
    //dataConfig fill with id
    setIdAttr(id);
    setNameAttr(name);
    setTypeList(typeOptions.find(el => el.value === listType));
    setOrder(order);
    setSelectedList(listOptions.find(el => el.value === list));
    setCheckMandatory(mandatory === "1");
    setCheckEdit(edit === "1");
  };

  return (
    <>
      <div className="d-flex flex-wrap m-4">
        <div className="container-fluid mb-2">
          <div className="card">
            <div className="card-header">
              <b>Administrador de Atributos</b>
            </div>
            {(configRoleByIdModule.create || idAttr) && <div className="card-body">
              <form>
                <input
                  type="text"
                  className="form-control"
                  placeholder="id"
                  value={idAttr}
                  hidden
                />
                <div className="form-group">
                  <label>Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre del Atributo"
                    onChange={handleNameAttr}
                    value={nameAttr}
                  />
                </div>
                <div className="form-group">
                  <label>Orden</label>
                  <input
                    type="number"
                    max="99"
                    className="form-control"
                    placeholder="Orden del atributo"
                    onChange={handleOrder}
                    value={order}
                  />
                </div>
                <div className="form-group">
                  <label>Tipo de Lista</label>
                  <Select
                    placeholder="Seleccione un Opci&oacute;n"
                    options={typeOptions}
                    onChange={handleTypeList}
                    value={typeList}
                    theme={theme => (styleTheme(theme))}
                  />
                </div>
                <div className="form-group">
                  {listsIsLoaded && typeList && typeList.value !== "text" && (
                    <>
                      <label>Selecci&oacute;n de Lista</label>
                      <Select
                        placeholder="Seleccione una lista"
                        options={listOptions}
                        onChange={handleSelectedList}
                        value={selectedList}
                        theme={theme => (styleTheme(theme))}
                      />
                    </>
                  )}
                </div>
                <div className="form-group ml-3">
                  <StyleCheckbox className="custom-checkbox">
                    <input className="custom-control-input" type="checkbox" name="obligatorio" id="obligatorio" checked={checkMandatory}
                      onClick={handleCheckMandatory} />
                    <label className="custom-control-label" htmlFor="obligatorio">
                    Obligatorio
                    </label>
                  </StyleCheckbox>
                  <StyleCheckbox className="custom-checkbox">
                    <input className="custom-control-input" type="checkbox" name="editable" id="editable" checked={checkEdit}
                      onClick={handleCheckEdit}/>
                    <label className="custom-control-label" htmlFor="editable">
                    Editable
                    </label>
                  </StyleCheckbox>
                </div>
                <Button onClick={saveDB} className="mr-2" disabled={isLoadingSave}>
                  {isLoadingSave ? <ButtonLoading text="Guardando..." /> : <>Guardar <FaSave /></>} 
                </Button>

                <Button color={Theme1.grayText}
                  className="mr-2" onClick={cleanFields}>
                  Limpiar <FaEraser />
                </Button>
              </form>
            </div>}
          </div>
        </div>
        <div className="container-fluid">
          <h2 className="py-5 text-center">Atributos de indicadores</h2>
          <table className="table table-striped text-center">
            <thead className="bg-dark text-white">
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Nombre</th>
                <th scope="col">Tipo</th>
                <th scope="col">Lista</th>
                <th scope="col">Obligatorio</th>
                <th scope="col">Editable</th>
                <th scope="col">Orden</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {tableIsLoaded &&
                dataAttrs.map((value, key) => (
                  <tr key={key}>
                    <td>{value.id}</td>
                    <td>{value.name}</td>
                    <td>{value.object_type}</td>
                    <td>{value.idlist ? value.idlist : "Sin lista"}</td>
                    <td>{value.isrequired === `0` ? `No` : `Si`}</td>
                    <td>{value.editable === `0` ? `No` : `Si`}</td>
                    <td>{value.order}</td>
                    <td>
                      {configRoleByIdModule.update && <IconEdit
                      link
                        onClick={(e) =>
                          fillFields(
                            e,
                            value.id,
                            value.name,
                            value.object_type,
                            value.order,
                            value.idlist,
                            value.isrequired,
                            value.editable
                          )
                        }
                      /> }
                      {configRoleByIdModule.delete && <IconTrash
                      link
                        onClick={(e) =>
                          AlertConfirm(ICON.WARNING,
                            "¿Estás seguro que deseas eliminar este item?", "",
                            () => deleteData(e, value.id, value.name)
                          )
                        }
                      />}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!tableIsLoaded && <Loading />}
          {tableIsLoaded && !dataAttrs.length && "No hay resultados."}
        </div>
      </div>
    </>
  );
}

export default List;
