import styled from 'styled-components';

export const GraphicPalette = styled.div`
    border-color: ${(props => props.borderColor ? 'var(--c-orange)': 'transparent')};
    border-width: 2px;
    border-style:solid; 
    border-radius:6px;
    width:30px;
    height:30px; 
    margin:3px;
    padding:2px;
    display :inline-block;
    cursor: pointer;

    & .styled-background {
        width:100%;
        height:100%;
        background-color: ${(props => props.color)};
        border-radius: 4px;
    }
`;

export const CustomMenu = styled.div`
    & ul > li {
        list-style: none;
        margin-bottom: 0.4rem;

        & svg {
            font-size: 1rem;
        }
    }

    & label, & h5 {
        font-weight: bold;
    }
`;

export const PreviewImage = styled.div`
    max-width: 300px;
    height: 160px;
    width: 100%;

    & .img-preview {
        background-image: url(${props => props.image});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 160px;
    }

`;