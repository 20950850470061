import { Svg } from '../../styles/CommonStyles';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const IconSuccess = ({ textTooltip = "Activo" }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-indicadores`}>
          {textTooltip}
        </Tooltip>
      }
    >
      <span>✅</span>
    </OverlayTrigger>
  )
}
