import { useState, useEffect } from 'react';
import CustomModal from '../../components/CustomModal';
import { Col, Container, Row, Form, ButtonGroup } from 'react-bootstrap';
import { FaTrash, FaClone, FaSave } from 'react-icons/fa';
import { ADMIN_URL } from '../../config';
import AsyncSelect from 'react-select/async';
import Utils from '../../utils';
import React from "react";
import { Stage, Layer, Group, Text, Rect, Transformer, Image } from 'react-konva';
import Mapa from "./mapa-e.png";
import { useParams } from 'react-router-dom';
import TransformerComponent from "./Components/TransformerComponent";
import "./style.css";
import { Button, ButtonMin } from '../../styles/CommonStyles';
import { IconTrash } from '../../components/iconos/IconTrash';
import { IconColor } from '../../components/iconos/IconColor';
import { IconConfig } from '../../components/iconos/IconConfig';
import { styleTheme } from '../../styles/SelectTheme';
import { AlertForm, ICON } from '../../utils/SweetAlert';


function FormMap() {

    const { id } = useParams();
    const [module, setModule] = useState({});
    const [_idObjetive, setIdObjetive] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showIndicatorsModal, setIndicatorsModal] = useState(false);
    const [isMenuModule, setIsMenuModule] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [dataObjetive, setDataObjetive] = useState([]);
    const [dataIndicators, setDataIndicators] = useState([]);
    const [image, setImage] = useState(new window.Image());
    const [state, setState] = useState({
        selectedShapeName: ""
    });


    useEffect(() => {
        const img = new window.Image();
        img.src = Mapa;
        setImage(img);
        Utils.Petition.get(
            '/pages-modules/id/' + id,
            (response) => {
                if(Object.keys(response.data.config).length === 0){
                    response.data.config={
                        indicator: [],
                        objetives: [
                            {
                                ID: 1,
                                DSText: "CRECIENTE GENERACION DE VALOR",
                                DSColor: "transparent",
                                NMWidth: "130",
                                DSNumber: 1,
                                NMX: 397.0747315647202,
                                NMY: 346.45202961257945,
                                NMXo: 50,
                                NMYo: 50,
                                NMRotation: -0.061069424297005605,
                                SNDelete: 0,
                                DSType: "default",
                                IDRegion: 6
                            },
                        ]
                    }
                }
                setDataObjetive([...response.data.config.objetives]);
                setModule(response.data)
            }
        );
    }, []);


    function maxNumber(objetive) {
        let max = 0;
        objetive.map((data) => {
            if (data.DSNumber > max) {
                max = data.DSNumber
            }
        })
        return max;
    }
    function maxId(objetive) {
        let max = 0;
        objetive.map((data) => {
            if (data.ID > max) {
                max = data.ID
            }
        })
        return max;
    }
    function deleteById(id) {
        const deleteData = module.config.objetives.filter((data) => data.ID !== id)

        const moduleData = module;
        moduleData.config.objetives = deleteData;
        setModule(moduleData);
        setDataObjetive(deleteData);
    }
    function deleteIndicatorById(id, idObjetive) {

        module.config.indicator.map((data, index) => {
            if (module.config.indicator[index].IDObjetive === idObjetive && module.config.indicator[index].IDIndicator == id) {
                module.config.indicator.splice(index, 1);
            }
        })
        asyncUpdateIndicator(idObjetive);
    }

    const globalSave = () => {
        setIsMenuModule(false);
        setUpdateData(true)
    }

    const handlePopup = (event) => {
        event.preventDefault();
        setShowModal(true);
    }

    const handlePopupIndicators = (id, event) => {
        setIdObjetive(id)
        asyncUpdateIndicator(id);
        setIndicatorsModal(true);
        event.preventDefault();
    }

    const handleMenuObjetive = () => {
        setIsMenuModule(!isMenuModule);
    }

    const handleDeleteObjetive = (id) => {
        deleteById(id)
        setIsMenuModule(true);
    }

    const handleDeleteIndicator = (id, idObjetive) => {
        deleteIndicatorById(id, idObjetive)
    }

    const handleChange = (color, pos, event) => {
        console.log(color, pos, event)
        let name = "";
        let index = "";
        let value = "";
        if (color === "") {
            name = event.target.name.split('-')[0];
            index = event.target.name.split('-')[1];
            value = event.target.value;
        } else {
            name = "DSColor";
            index = pos;
            if (color === "black") { value = "white" } else { value = "black" }
        }

        let newDataObjetive = [...dataObjetive];
        newDataObjetive[index] = { ...newDataObjetive[index], [name]: value }
        module.config.objetives = newDataObjetive;
        setDataObjetive(newDataObjetive)
    }

    const handleSaveObjetive = (e) => {
        module.config.objetives = dataObjetive;
        setIsMenuModule(true);
    }

    const handleUpdateObjetive = (event) => {
        console.log('update')
        event.preventDefault();
        let id = parseInt(maxId(module.config.objetives) + 1);
        let number = parseInt(maxNumber(module.config.objetives) + 1);
        const updateData = module;
        updateData.config.objetives = [...module.config.objetives, {
            "ID": id,
            "DSText": "Nuevo",
            "DSColor": "black",
            "NMWidth": 150,
            "DSNumber": number,
            "NMX": 50,
            "NMY": 50,
            "NMXo": 50,
            "NMYo": 50,
            "NMRotation": 0,
            "SNDelete": 0,
            "DSType": "default",
            "IDRegion": 6
        }]
        setModule(updateData);
        setDataObjetive(updateData.config.objetives);
    }

    function asyncUpdateIndicator(id) {
        let newDataObjetive = [];
        module.config.indicator.map((data, index) => {
            if (module.config.indicator[index].IDObjetive == id) {
                newDataObjetive.push(module.config.indicator[index])
            }
        })
        setDataIndicators(newDataObjetive);
    }

    function changePos(id, x, y, r) {
        if(id !== undefined){
            let _dataObjetive=[...dataObjetive];
            if (r !== "") {
                _dataObjetive[id].NMRotation = r;
            }
            if(x!=="" && y !== ""){
                _dataObjetive[id].NMX = x;
                _dataObjetive[id].NMY = y;
            }
            
            module.config.objetives=_dataObjetive;
            setDataObjetive(_dataObjetive);
        }
        
    }

    useEffect(() => {
        if (updateData) {
            const pageModule = {
                id: module.id,
                idpage: module.idpage,
                section: module.section,
                name: module.name,
                module_code: module.module_code,
                config: module.config,
                order: module.order
            };
            Utils.Petition.post('/pages-modules/update',
            pageModule,
            (response) => {
                setDataObjetive([...module.config.objetives]);
                AlertForm(ICON.SUCCESS, "Información Guardada", '');
            })
            setUpdateData(false);
        }
    }, [updateData])

    const loadIndicators = (inputValue) => {

        if (inputValue.length > 3) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    Utils.Petition.get(
                        '/indicators/name-code-indicator/' + inputValue,
                        (response) => {
                            resolve(response.data)
                        }
                    )
                }, 100);
            });
        }

        return new Promise((resolve) => resolve([]));
    }

    const selectIndicators = (data, flag) => {
        let _dataIndicators = [...dataIndicators];
        let newData = {
            "IDIndicator": data.id,
            "IDObjetive": _idObjetive,
            "NMValue": data.value,
            "NMCode": data.code,
            "NMName": data.name,
            "SNDelete": 0
        };
        module.config.indicator.push(newData);
        _dataIndicators.push(newData);
        setDataIndicators(_dataIndicators);
    }

    const handleDraggEnd =(e) => {
        if(e.target.attrs!== undefined && e.target.attrs.name!== undefined){
            let posX = e.target.attrs.x;
            let posY = e.target.attrs.y;
            let rotation = e.target.attrs.rotation;
            changePos(e.target.attrs.name.split('-')[1], posX, posY, rotation)
        }
    }

    const handleStageMouseDown = (e) => {
        console.log(e)
        if (e.target.parent !== undefined&& e.target.parent !== null && e.target.parent.attrs !== undefined) {
            
            // clicked on stage - cler selection
            if (e.target === e.target.getStage()) {
                setState({
                    selectedShapeName: ""
                });
                return;
            }
            // clicked on transformer - do nothing
            const clickedOnTransformer = e.target.getParent().className === "Transformer";
            if (clickedOnTransformer) {
                return;
            }

            // find clicked rect by its name
            const name = e.target.name();
            // const rect = this.state.rectangles.find(r => r.name === name);
            if (name) {
                setState({
                    selectedShapeName: name
                });
            } else {
                setState({
                    selectedShapeName: ""
                });
            }
        }

    };


    return (
        <Container fluid>
            <CustomModal title={"Indicadores"} show={showIndicatorsModal} setShow={setIndicatorsModal}>
                <Container>
                    <input type="hidden" value={_idObjetive} id="objetive_value" />
                    <Row>
                        <Col><b>Indicadores</b></Col>
                        <Col>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={loadIndicators}
                                onChange={e => selectIndicators(e, 0)}
                                theme={theme => (styleTheme(theme))}
                            />
                        </Col>
                    </Row>
                    <hr />
                    {
                        dataIndicators.map((indicator, index) => {
                            return (<>
                                <Row>
                                    <Col xs={4}><Form.Control disabled value={indicator.NMCode} type="text" maxLength="350" /></Col>
                                    <Col xs={6}><Form.Control disabled value={indicator.NMName} type="text" /></Col>
                                    <Col xs={2} >
                                        <ButtonGroup aria-label="Basic example">
                                            <IconTrash color="black" link hover="red" onClick={handleDeleteIndicator.bind(this, indicator.IDIndicator, indicator.IDObjetive)} id={indicator.IDIndicator} />

                                        </ButtonGroup>
                                    </Col>
                                </Row><hr /></>)
                        })
                    }
                </Container>
            </CustomModal>
            <div className="component-map">

                <Stage
                    width={window.innerWidth}
                    height={window.innerHeight}
                    onDragEnd={handleDraggEnd}
                    onMouseDown={handleStageMouseDown}
                >
                    <Layer>
                        <Image x={0} y={0} image={image}/>{
                            dataObjetive.map((objetive,index)=>{
                                if(objetive.ID != 1){
                                    return (
                                    <> 
                                        <Group
                                            name={"group-"+index}
                                            x={objetive.NMX}
                                            y={objetive.NMY}
                                            rotation={objetive.NMRotation}
                                            width={parseInt(objetive.NMWidth)}
                                            fill="transparent"
                                            draggable
                                            align="center"
                                        >
                                            <Rect
                                            name={"rect-"+index}
                                            fill="transparent"
                                            width={parseInt(objetive.NMWidth)}
                                            shadowColor="black"
                                            shadowBlur={5}
                                            shadowOpacity={1}
                                            align="center"
                                            />
                                            <Text
                                            name={"text-"+index}
                                            fontSize={12}
                                            fontFamily="Calibri"
                                            fill={objetive.DSColor}
                                            width={parseInt(objetive.NMWidth)}
                                            padding={10}
                                            align="center"
                                            text={"("+objetive.DSNumber+")"+objetive.DSText}
                                            />
                                        </Group>
                                    </>
                                    )
                                }
                            })
                        }
                        <TransformerComponent
                            selectedShapeName={state.selectedShapeName}
                            changePos={changePos}
                        />
                    </Layer>

                </Stage>

                <div className="menu-admin-map">

                    <Button onClick={globalSave} className="mr-3">Guardar Cambios <FaSave /></Button>
                    <Button color="green" onClick={handleMenuObjetive}> {isMenuModule ? 'Ocultar' : 'Mostrar'} administrador</Button>
                    {
                        isMenuModule &&
                        <div id="admin-map">
                            <ButtonMin onClick={handlePopupIndicators.bind(this, 1)} data-id="33" className="mr-3" >Creciente Generacion De Valor</ButtonMin>
                            <ButtonMin onClick={handleUpdateObjetive}>Crear objetivo</ButtonMin>

                            {
                                dataObjetive.map((objetive, index) => {
                                    if (objetive.ID !== 1) {
                                        return (
                                            <div className="container">
                                                <Row className="component-item-layer mt-3">
                                                    <Col xs={6}><Form.Control name={"DSText-" + index} value={objetive.DSText} type="text" maxLength="350" onChange={handleChange.bind(this, "", index)} /></Col>
                                                    <Col xs={2}><Form.Control name={"NMWidth-" + index} value={objetive.NMWidth} type="text" onChange={handleChange.bind(this, "", index)} /></Col>
                                                    <Col xs={1}><Form.Control name={"DSNumber-" + index} value={objetive.DSNumber} type="text" onChange={handleChange.bind(this, "", index)} /></Col>
                                                    <Col xs={3} >
                                                        <ButtonGroup aria-label="Basic example" className="align-items-center">
                                                            <IconTrash color="black" link hover="red" id={objetive.ID} onClick={handleDeleteObjetive.bind(this, objetive.ID)} />
                                                            <IconConfig link id={"indicadores_" + objetive.ID} onClick={handlePopupIndicators.bind(this, objetive.ID)} hover="orange" />
                                                            <IconColor link id={objetive.DSColor} value={objetive.DSNumber} onClick={handleChange.bind(this, objetive.DSColor, index)} hover="green" />
                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>

                                            </div>)
                                    }
                                })
                            }

                        </div>
                    }
                </div>
            </div>
        </Container >
    );
}
export default FormMap;