import { FaSearch } from "react-icons/fa";
import AsyncSelect from 'react-select/async';
import Utils from "../../utils";
import { useEffect, useState } from "react";
import { formatDate } from "../../utils/formatDate"
import { Pagination,  } from "react-bootstrap";
import { TooltipIcon } from "../../components/styles/tooltip";
import { Button, Container } from "../../styles/CommonStyles";
import { styleTheme } from "../../styles/SelectTheme";

function Form () {

    const [form, setForm] = useState({
        from:"",
        to:""
    });
    const [searchPagination, setSearchPagination] = useState([]);
    const [pageActive, setPageActive] = useState(null);
    const [auditLog, setAuditLog] = useState([]);

    const typesLogsIndicator = [
        {
            value:"request-update-indicator-value",
            code:"SP-AVI",
            text:"Solicitud pendiente: Actualización de valor de indicador"
        },
        {
            value:null,
            code:"",
            text:""
        },
        {
            value:"rejected-update-indicator-value",
            code:"SR-AVI",
            text:"Solicitud rechazada: Actualización de valor de indicador"
        },
        {
            value:"updated-indicator-value",
            code:"AVI",
            text:"Actualización de valor de indicador"
        },
        {
            value:"approved-update-indicator-value",
            code:"SA-AVI",
            text:"Solicitud aprobada: Actualización de valor de indicador"
        },
        {
            value:"created-indicator-value",
            code:"CVI",
            text:"Creación de valor de indicador"
        },
        {
            value:"created-indicator",
            code:"CI",
            text:"Creación de indicador"
        },
        {
            value:"updated-indicator",
            code:"AI",
            text:"Actualización de indicador"
        },
        {
            value:"updated-massive-import-indicator-value",
            code:"M-AVI",
            text:"Importación masiva: Actualización de valor de indicador "
        },
        {
            value:"created-massive-import-indicator-value",
            code:"M-CVI",
            text:"Importación masiva: Creación de valor de indicador"
        }
    ]

    const handleUser = (user) => {
        setForm({...form, user})
    }
    const handleIndicator = (indicator) => {
        setForm({...form, indicator})
    }

    const handleForm = (event) => {
        setForm({...form,[event.target.name] : event.target.value})
    }

    //UseEfect Pagination
    useEffect(() => {
        if (searchPagination === null && auditLog) {
        getSearch(1);
        }

    }, [searchPagination, auditLog]);

    useEffect(() => {
        if (!auditLog) return;
        getSearch(1);
    }, [auditLog])
    //

    const loadUsers = (inputValue) => {
        if (inputValue.length > 3) {
            return new Promise((resolve)=>{
                setTimeout(()=>{
                    Utils.Petition.get(
                        `/users/users-client/filter/${inputValue}`,
                        (response) => {
                            const listUsers = []
                            response.data.forEach(e => {
                                const obj = {}
                                obj.value = e.id;
                                obj.label = `${e.full_name} | ${e.username} | ${e.email}`;
                                listUsers.push(obj)
                            });
                            resolve(listUsers)
                        }
                    )
                },100)
            })
        }
        return new Promise((resolve) => resolve([]));
    }

    const loadIndicators = (inputValue) => {

        if (inputValue.length > 3) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    Utils.Petition.get(
                        '/indicators/name-code-indicator/' + inputValue,
                        (response) => {
                            resolve(response.data)
                        }
                    )
                }, 100);
            });
        }

        return new Promise((resolve) => resolve([]));
    }

    const searchDB = (event) => {
        event.preventDefault();
        
        let data = {}
        data.idindicator = form.indicator == undefined ?  0 : form.indicator.value;
        data.iduser = form.user == undefined ?  0 : form.user.value;
        
        return new Promise((resolve) => {
            Utils.Petition.get(
                `/indicator-logs/idindicator/${data.idindicator}/iduser/${data.iduser}/dateFrom/${form.from}/dateTo/${form.to}`,
                (response) => {
                    resolve(response.data)
                    setAuditLog(response.data)
                }
            )
        })
    }
    const getPages = () => {
        if (auditLog) {
          const last = auditLog.length / 20;
          let active = parseInt(pageActive);
    
          let pages = [];
          let numberPage = active < 11 ? 1 : active - 10;
          for (let i = 1; i <= 20; i++) {
            if (numberPage < last) {
              pages.push(
                <Pagination key={numberPage}>
                  <Pagination.Item
                    id={numberPage}
                    active={numberPage === active}
                    onClick={handlePagination}
                  >
                    {numberPage}
                  </Pagination.Item>
                </Pagination>
              );
              numberPage++;
            }
          }
          return pages;
        }
    };
    const getSearch = (numberPage) => {
        const arr = [];
        const start = (numberPage - 1) * 20;
        const end = start + 20;
        for (let idx = start; idx < end; idx++) {
            arr.push(auditLog[idx]);
        }
        const filterArr = arr.filter(el => el !== undefined);
        if (auditLog.length === 0) {
            setSearchPagination([]);
        } else {
            setSearchPagination(filterArr);
        }

        setPageActive(numberPage);
    };

    const handlePagination = (e) => {
        //console.log(e.target.id)
        if (e.target.id) {
            getSearch(e.target.id);
        } else if (e.target.dataset.name) {
            if (e.target.dataset.name === 'first') {
            getSearch(1);
            } else if (e.target.dataset.name === 'prev') {
            if (pageActive - 1 >= 1) {
                getSearch(pageActive - 1);
            }
            } else if (e.target.dataset.name === 'next') {
            if (pageActive + 1 <= auditLog.length / 20) {
                getSearch(pageActive + 1);
            }
            } else if (e.target.dataset.name === 'last') {
            getSearch(auditLog.length / 20);
            }
        }
    };
    
    return (
        <Container>
            <div className="d-flex flex-wrap m-4">
                <div className="container-fluid mb-2">
                    <div className="card">
                        <div className="card-header">
                            <b>Auditoría</b>
                        </div>
                        <div className="card-body">
                            <form onSubmit={searchDB} >
                                <div className="form-group">
                                    <label>Desde</label>
                                    <input
                                        type="date"
                                        name="from"
                                        className="form-control"
                                        placeholder="Escribe la fecha inicial "
                                        onChange={handleForm}
                                        value={form.from}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Hasta</label>
                                    <input
                                        type="date"
                                        name="to"
                                        className="form-control"
                                        placeholder="Escribe la fecha final"
                                        onChange={handleForm}
                                        value={form.to}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Usuario</label>
                                    <AsyncSelect
                                        value={form.user}
                                        cacheOptions
                                        defaultOptions
                                        isClearable
                                        placeholder="Nombre de usuario"
                                        loadOptions={loadUsers}
                                        onChange={handleUser}
                                        theme={theme => (styleTheme(theme))}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Indicador</label>
                                    <AsyncSelect
                                        value={form.indicator}
                                        cacheOptions
                                        defaultOptions
                                        isClearable
                                        placeholder="Indicador"
                                        loadOptions={loadIndicators}
                                        onChange={handleIndicator}
                                        theme={theme => (styleTheme(theme))}
                                    />
                                </div>
                                <Button type="submit" >
                                    Buscar <FaSearch/>
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <h2 className="py-4 text-center">Resultados</h2>
                    <table className="table table-striped text-center">
                        <thead className="bg-dark text-white">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Idicador</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Escenario</th>
                                <th scope="col">Observacion</th>                                
                                <th scope="col">Año</th>
                                <th scope="col">Mes</th>
                                <th scope="col">Usuario</th>
                                <th scope="col">Valor antiguo</th>
                                <th scope="col">Valor nuevo</th>
                                <th scope="col">Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {   
                                searchPagination.map((value, key) =>(
                                    <tr key={key}>
                                        <td scope="row">{value.id}</td>
                                        <td>{value.indicator === undefined ? 0 : value.indicator.name}</td>
                                        <td>
                                        <TooltipIcon>
                                        <span className="badge badge-secondary">{typesLogsIndicator.find(e => e.value === value.type?.trim() === "" ? null : value.type)?.code }</span>
                                        <div className="show">
                                            <p>{typesLogsIndicator.find(e => e.value === value.type?.trim() === "" ? null : value.type)?.text }</p>
                                        </div>
                                        </TooltipIcon>                                           
                                        </td>
                                        <td>{value.stage === undefined ? 0 : value.stage.name}</td>
                                        <td>{value.observation}</td>                                        
                                        <td>{value.year}</td>
                                        <td>{value.month}</td>
                                        <td>{value.user === undefined ? 0 : value.user.full_name}</td>
                                        <td>{value.old_value}</td>
                                        <td>{value.new_value}</td>
                                        <td>{formatDate(value.createdAt)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    
                    {!searchPagination.length && "No hay resultados de Busqueda"}
                    {
                        auditLog.length > 20 &&
                        <Pagination className='justify-content-center'>
                            <Pagination.First
                            data-name='first'
                            onClick={handlePagination}
                            />
                            <Pagination.Prev
                            data-name='prev'
                            onClick={handlePagination}
                            />
                            {getPages()}
                            <Pagination.Next
                            data-name='next'
                            onClick={handlePagination}
                            />
                            <Pagination.Last
                            data-name='last'
                            onClick={handlePagination}
                            />
                        </Pagination>
                    }
                </div>
            </div>
        </Container>
    )
}

export default Form;