import { Button } from "react-bootstrap";

function ManualUpdate({ setFormula }) {
    const handleManualUpdate = (event) => {
        event.preventDefault();
        setFormula('');

    }
    return (
        <Button variant="warning" className="mb-2" onClick={handleManualUpdate}>Actualizar valor manualmente</Button>
    )
}
export default ManualUpdate;