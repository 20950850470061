import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import CustomModal from "../../CustomModal";

function Numbers({ disabledBtnNumber, formula, setFormula }) {
    const [number, setNumber] = useState('');
    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal(true)
    }
    const handleSaveButton = (event) => {
        event.preventDefault();
        if (number !== '') {
            let concatenation = (formula) ? formula + '||' : '';
            setFormula(concatenation + number);
            setNumber('');
        }
        setShowModal(false);
    }
    const handleAddNumber = (event) => {
        setNumber(number + event.target.dataset.number);
    }
    const handleDeleteNumbers = () => {
        setNumber('');
    }
    const handleNumber = (event) => {
        setNumber(event.target.value);
    }
    return (
        <div>
            <Button variant="warning" className="mb-2" disabled={disabledBtnNumber} onClick={handleModal}>Agregar numero</Button>
            <CustomModal title={"Agregar numero"} show={showModal} setShow={setShowModal} handleSaveButton={handleSaveButton} saveButtonText="Agregar">

                <Form.Group className="text-center">
                    <label><input className="form-control" type="number" step="1" max="1000" value={number} onChange={handleNumber} /></label>
                </Form.Group>
                <Form.Group className="text-center">
                    <div className="button-number-calculator">
                        <Button variant="outline-warning text-dark" data-number="1" onClick={handleAddNumber}>1</Button>
                        <Button variant="outline-warning text-dark" data-number="2" onClick={handleAddNumber}>2</Button>
                        <Button variant="outline-warning text-dark" data-number="3" onClick={handleAddNumber}>3</Button>
                    </div>
                    <div className="button-number-calculator">
                        <Button variant="outline-warning text-dark" data-number="4" onClick={handleAddNumber}>4</Button>
                        <Button variant="outline-warning text-dark" data-number="5" onClick={handleAddNumber}>5</Button>
                        <Button variant="outline-warning text-dark" data-number="6" onClick={handleAddNumber}>6</Button>
                    </div>
                    <div className="button-number-calculator">
                        <Button variant="outline-warning text-dark" data-number="7" onClick={handleAddNumber}>7</Button>
                        <Button variant="outline-warning text-dark" data-number="8" onClick={handleAddNumber}>8</Button>
                        <Button variant="outline-warning text-dark" data-number="9" onClick={handleAddNumber}>9</Button>
                    </div>
                    <div className="button-number-calculator">
                        <Button variant="outline-warning text-dark" data-number="0" onClick={handleAddNumber}>0</Button>
                        <Button onClick={handleDeleteNumbers}>Borrar</Button>
                    </div>
                </Form.Group>
            </CustomModal>
        </div>
    );
}

export default Numbers;