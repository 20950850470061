import { Button } from "react-bootstrap";
import { Fragment } from 'react';

function Operators({ disabledBtnOperator, formula, setFormula }) {
    const handleAsignOperator = (event) => {
        event.preventDefault();
        let concatenation = (formula) ? formula + '||' : '';
        setFormula(concatenation + event.target.dataset.operator);
    }
    return (
        <span className="pr-4">
            <Button variant="warning" className="mb-2 mr-2" onClick={handleAsignOperator} data-operator="+" disabled={disabledBtnOperator}>+</Button>
            <Button variant="warning" className="mb-2 mr-2" onClick={handleAsignOperator} data-operator="-" disabled={disabledBtnOperator}>-</Button>
            <Button variant="warning" className="mb-2 mr-2" onClick={handleAsignOperator} data-operator="*" disabled={disabledBtnOperator}>*</Button>
            <Button variant="warning" className="mb-2 mr-2" onClick={handleAsignOperator} data-operator="/" disabled={disabledBtnOperator}>/</Button>
        </span>
    );
}
export default Operators;