import { Button } from "react-bootstrap";

function Inherit({ setFormula }) {
    const handleInherit = (event) => {
        event.preventDefault();
        setFormula(null);

    }
    return (
        <Button variant="warning" className="mb-2 mr-2" onClick={handleInherit}>Heredar formula del escenario</Button>
    )
}
export default Inherit;