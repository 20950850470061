import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Utils from '../../utils';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import GetDataTable from './GetDataTable';
import { APP_URL } from "../../config";
import { DivWrapper, StyleProperty } from '../style';
import { StyleCheckbox, Button } from '../../styles/CommonStyles';

import { FaSave } from "react-icons/fa";
import { AlertForm, ICON } from "../../utils/SweetAlert";
function Form(){

    const { id } = useParams();
    const { idpage } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [loadedPre, setLoadedPre] = useState(false);
    const [msj, setMsj] = useState('');
    const [stages, setStages] = useState([]);
    const [popups, setPopups] = useState([]);
    const [pages, setPages] = useState([]);
    const [optionsView, setOptionsView] = useState([])
    
    const [form, setForm] = useState({
        title: '',
        title_column: '',
        reverse: false,
        indicators: [],
        stages: [],
        marginTop: ""
    });

    useEffect( () => {
        const idPortal = localStorage.getItem('idportal');
        Utils.Petition.get(
            '/stages_relation/select',
            (response) => {
                for( let i=0; i<response.data.length; i++) {
                    response.data[i].values = response.data[i].value;
                    response.data[i].value = response.data[i].label;
                }
                setStages(response.data);
            }
        );
        
        Utils.Petition.get(
            '/pages-modules/id/'+id,
            (response) => { 
                if (Object.keys(response.data.config).length > 0 ) {
                    setForm({
                        ...form,
                        title: response.data.config.title,
                        title_column: response.data.config.title_column,
                        reverse: response.data.config.reverse,
                        indicators: response.data.config.indicators,
                        stages: response.data.config.stages,
                        otherStyle: response.data.config.otherStyle,
                        disableDesendingSign: response.data.config.disableDesendingSign,
                        view: response.data.config.view,
                        marginTop: response.data.config.marginTop,
                        widthFirstCol:response.data.config.widthFirstCol
                    });
                    setLoadedPre(true)
                }   
                setLoaded(true);
            }
        );
            
        Utils.Petition.get(
            '/popups/idpage/' + idpage,
            (response) => {
                setPopups(response.data)
            }
        );

        Utils.Petition.get(
            '/pages/portal/' + idPortal,
            (response) => {
                setPages(response.data)
            }
        )

        Utils.Petition.get('/view-business', (response) => {
            let _views = [];
            response.data.forEach(element => {
                let obj = {}
                obj.value = element.code;
                obj.label = element.description;
                _views.push(obj);
            });
            setOptionsView(_views);
        })
        
        
    }, []);

    const loadIndicators = (inputValue) => {
        
        if( inputValue.length > 2 ) {
            return new Promise((resolve) => {
                setTimeout( () => {
                    Utils.Petition.get(
                        '/indicators/name-code-indicator/' + inputValue,
                        (response) => { 
                            resolve(response.data)
                        }   
                    )
                }, 100);
            });
        }

        return new Promise((resolve) => resolve([]));
    };

    useEffect( () => {
        const bootstrap = async () => {
            await setLoadedPre(false);

            if( form.indicators.length === 0 ) {
                await setMsj('Debe escoger por lo menos un indicador')
            } else if ( form.stages.length === 0 ) { 
                await setMsj('Debe escoger por lo menos un escenario')
            } else if (!verifyStages() ) {
                await setMsj('Revisar las opciones de Escenario')
            }else {
                await setLoadedPre(true);
            }   
        };
        bootstrap();
    }, [form])

    const verifyStages = () => {
        let temp = form.stages;
        for (let i=0; i<temp.length; i++) {
            if(temp[i].othersInd && !temp[i].flagInd ) {
                return false;
            }
        }
        return true;
    }

    const handleForm = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        });
    };
    

    const handleSubmit = (event) => {
        event.preventDefault();
        
        if( loadedPre ) {
            const data = {
                id: id,
                config: form
            };
   
            Utils.Petition.post(
                '/pages-modules/update', data,
                (response)=>{
                    if(window.parent){
                        AlertForm(ICON.SUCCESS, response.message);
                        //window.parent.postMessage('cerrar popup', APP_URL);
                    }
                }
            )
        } 
    }

    const verifyInfoSelect = (temp, aux) => {
        let i = 0;
        while( i < temp.length ) {
            const found = aux.filter(data => data.id === temp[i].id)
            if ( found.length === 0) {
                temp.splice(i, 1);
                i--;
            };
            i++;
        }

        while( i < aux.length ) {
            const found = temp.filter(data => data.id === aux[i].id)
            if ( found.length === 0) {
                temp.push(aux[i]);
            };
            i++;
        }  
        return temp;
    }

    const selectData = ({data, type}) => {
        let temp = JSON.parse(JSON.stringify(form.stages));
        if (type === 'indicators') {
            for (let i=0; i<temp.length; i++) {
                if(temp[i].othersInd && !temp[i].lessInd && (temp[i].newIndicators.length < data.length || temp[i].newIndicators.length > data.length )) {
                    temp[i].flagInd = false;
                }
                if(temp[i].othersInd && temp[i].lessInd) {
                    let aux = [];
                    for(let j=1; j <= (data.length - temp[i].amountLessInd) + 1; j++) {
                        aux.push(j);
                    }
                    //console.log(aux)
                    
                    temp[i].arrLessInd = aux;
                }
            }
        };
        setForm({
            ...form,
            [type]: data,
            stages: temp
        })
    };

    const selectStage = ({data, type}) => {

        let temp = JSON.parse(JSON.stringify(data));
        if( data.length > 0) temp[temp.length-1].value = data[data.length-1].value.split("-")[0] + "-" + Date.now();
        
        setForm({
            ...form,
            [type]: temp
        })
    }

    const selectNewInds = ({data, index}) => {

        let temp = JSON.parse(JSON.stringify(form.stages));

        if(temp[index].hasOwnProperty('newIndicators')) {
            temp[index].newIndicators = verifyInfoSelect(temp[index].newIndicators, data);
            if( temp[index].lessInd ) {
                if ( temp[index].newIndicators.length === parseInt(temp[index].amountLessInd) ) {
                    temp[index].flagInd = true;
                } else {
                    temp[index].flagInd = false;
                }
            } else {
                if ( temp[index].newIndicators.length === form.indicators.length ) {
                    temp[index].flagInd = true;
                } else {
                    temp[index].flagInd = false;
                }
            }
            
        
        } else {
            temp[index].newIndicators = data;
        }
    
        setForm({
            ...form,
            stages: temp
        })

    };

    const handleInfo = (event) => {
        const index = (event.target.name.split("-"))[2];
        const variable = (event.target.name.split("-"))[0];
        const type = (event.target.name.split("-"))[1];
        let temp = JSON.parse(JSON.stringify(form[type]));
        
        temp[index][variable] = event.target.value;

        if(variable === "amountLessInd") {
            let aux = [];
            for(let i=1; i <= (form.indicators.length - event.target.value) + 1; i++) {
                aux.push(i);
            }
            temp[index].arrLessInd = aux;
            if( parseInt(event.target.value) === temp[index].newIndicators.length ) {
                temp[index].flagInd = true;
            } else {
                temp[index].flagInd = false;
            }
            
        }
            
        setForm({
            ...form,
            [type]: temp
        })
    }

    const orderFunction = (temp, value, id) => {

        let aux = new Array(temp.length);
        
        if( value < id ) {
            for(let i=0; i<temp.length; i ++) {
                if(i < value ||  i > id) {
                    aux[i] = JSON.parse(JSON.stringify(temp[i]));
                } else if (i === value) {
                    aux[i] = JSON.parse(JSON.stringify(temp[id]));
                } else {
                    aux[i] = JSON.parse(JSON.stringify(temp[i-1]));
                }
            }
        } else if (value > id) {
            for(let i=temp.length-1; i>=0; i--) {
                if(i > value ||  i < id) {
                    aux[i] = JSON.parse(JSON.stringify(temp[i]));
                } else if (i === value) {
                    aux[i] = JSON.parse(JSON.stringify(temp[id]));
                } else {
                    aux[i] = JSON.parse(JSON.stringify(temp[i+1]));
                }
            }
        }

        return aux;
    }

    const orderArray = ({event, type = ''}) => {
        const value = event.target.value - 1;
        const typeArray = event.target.name.split('-')[1];
        const id = event.target.name.split('-')[2];
        let arr = JSON.parse(JSON.stringify(form));
        let temp = arr[typeArray];
        let aux = [];

        if( type !== '') {
            const indexS = event.target.name.split('-')[3];
            let arr = orderFunction(temp[indexS][type], value, id);
            temp[indexS][type] = arr;
            aux = temp;
        } else {
            aux = orderFunction(temp, value, id);
        }
    
        setForm({
            ...form,
            [typeArray]: aux
        })

    }

    const handleOptions = (event) => {
        const index = (event.target.name.split("-"))[1];
        const type = (event.target.name.split("-"))[0];
        let temp = [...form.indicators];

        if (event.target.checked) temp[index][type] = true;
        else temp[index][type] = false;

        setForm({
            ...form,
            indicators: temp
        })
    }
    const handleStageOptions = (event) => {
        const index = (event.target.name.split("-"))[2];
        const type = (event.target.name.split("-"))[1];
        const variable = (event.target.name.split("-"))[0];
        let temp = JSON.parse(JSON.stringify(form[type]));
        temp[index][variable] = event.target.checked;

        if(variable === 'othersInd') {
            temp[index]['newIndicators'] = [];
            temp[index]['flagInd'] = false;
        } else if( variable === "lessInd") {
            temp[index].amountLessInd = form.indicators.length;
            temp[index].posLessInd = 1;
            temp[index].arrLessInd = [1];
            if(!event.target.checked && form.indicators.length !== temp[index].newIndicators.length) {
                temp[index].flagInd = false;
            }
        }


        setForm({
            ...form,
            [type]: temp
        })
    }

    const handleOptionsSelect = (data, id, type) => {
        let temp = [...form.indicators]
        temp[id][type] = data;

        setForm({
            ...form,
            indicators: temp
        })
    }

    const handleChecks = (event) => {
        const variable = event.target.name
        setForm({
            ...form,
            [variable]: event.target.checked
        })
    }

    //console.log(form)

    return(
        <DivWrapper>
        <Container fluid>
            <Row>
                <Col sm={5}>
                    <h3>Configuración</h3>
                    <form onSubmit={handleSubmit}>
                        <Container fluid className="mb-1">
                        <div className="form-group">
                            <Row>
                                <Col>Título</Col>
                                <Col>
                                    <input type="text" className="form-control" id="title" name="title" onChange={handleForm} value={form.title} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>Título primera columna</Col>
                                <Col>
                                    <input type="text" className="form-control" id="title_column" name="title_column" onChange={handleForm} value={form.title_column} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col><b>Vista</b></Col>
                                <Col>
                                    <select className="form-control" id="view" name="view" value={form.view} onChange={handleForm}>
                                        <option key="0" value="PorDefecto">Seleccionar...</option>
                                        {
                                            optionsView.map( (ele, index) => {
                                                return(
                                                    <option key={index+1} value={ele.value}>{ele.label}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>Margen superior</Col>
                                <Col>
                                    <input type="number" className="form-control" id="marginTop" name="marginTop" onChange={handleForm} value={form.marginTop} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>Ancho de la primera columna</Col>
                                <Col>
                                    <input type="number"  className="form-control" id="widthFirstCol" name="widthFirstCol" onChange={handleForm} value={form.widthFirstCol} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                        <input className="custom-control-input" name="reverse" type="checkbox" checked={form.reverse} id="reverse" onChange={handleChecks} />
                                        <label className="custom-control-label" htmlFor="reverse">
                                            Invertir tabla
                                        </label>
                                    </StyleCheckbox>
                                </Col>
                                <Col>
                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                        <input className="custom-control-input" name="otherStyle" type="checkbox" checked={form.otherStyle} id="otherStyle" onChange={handleChecks} />
                                        <label className="custom-control-label" htmlFor="otherStyle">
                                            Cambiar Estilo
                                        </label>
                                    </StyleCheckbox>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                        <input className="custom-control-input" name="disableDesendingSign" type="checkbox" checked={form.disableDesendingSign} id="disableDesendingSign" onChange={handleChecks} />
                                        <label className="custom-control-label" htmlFor="disableDesendingSign">
                                            Deshabilitar semaforo descendiente
                                        </label>
                                    </StyleCheckbox>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group my-3">
                            <Row>
                                <Col sm={3}><b>Indicadores</b></Col>
                                <Col sm={9}>
                                    <AsyncSelect
                                        isMulti
                                        value={form.indicators}
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={loadIndicators}
                                        onChange={e => {selectData({data: e, type: "indicators"})}}
                                    />
                                </Col>
                            </Row>
                        </div>
                        {
                            form.indicators.length > 0 && 
                            form.indicators.map( (ind, index) => {
                                return (
                                    <StyleProperty key={index}>
                                        <h4>{"Propiedades " + ind.name}</h4>
                                        <div className="form-group">
                                            <Row>
                                                <Col><b>Label del Indicador</b></Col>
                                                <Col>
                                                    <input type="text" className="form-control" id={"labelIndicator-indicators-" + index} name={"labelIndicator-indicators-" + index} onChange={handleInfo} value={ind.labelIndicator} />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="form-group">
                                            <Row>
                                                <Col><b>Orden</b></Col>
                                                <Col>
                                                    <select name={"order-indicators-"+index} value={index+1} onChange={event => orderArray({event})}>
                                                        {
                                                            form.indicators.map( (ind, index) => {
                                                                return(
                                                                    <option key={index} value={index + 1}>{index + 1}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </Col>  
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col>
                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                    <input disabled={form.reverse} className="custom-control-input" name={"highlight-" + index} type="checkbox" checked={ind.highlight} id={"highlight-" + index} onChange={handleOptions} />
                                                    <label className="custom-control-label" htmlFor={"highlight-" + index}>
                                                        Resaltar fila
                                                    </label>
                                                </StyleCheckbox>
                                            </Col>
                                            <Col>
                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                    <input className="custom-control-input" name={"percent-indicators-" + index} type="checkbox" checked={ind.percent} id={"percent-indicators-" + index} onChange={handleStageOptions} />
                                                    <label className="custom-control-label" htmlFor={"percent-indicators-" + index}>
                                                        Porcentaje fila
                                                    </label>
                                                </StyleCheckbox>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                    <input disabled={ind.url} className="custom-control-input" name={"popup-" + index} type="checkbox" checked={ind.popup} id={"popup-" + index} onChange={handleOptions} />
                                                    <label className="custom-control-label" htmlFor={"popup-" + index}>
                                                        Insertar Pop-up
                                                    </label>
                                                </StyleCheckbox>
                                            </Col>
                                            <Col>
                                                <StyleCheckbox className="custom-checkbox ml-4 mb-3" >
                                                    <input disabled={ind.popup} className="custom-control-input" name={"url-" + index} type="checkbox" checked={ind.url} id={"url-" + index} onChange={handleOptions} />
                                                    <label className="custom-control-label" htmlFor={"url-" + index}>
                                                        Insertar URL
                                                    </label>
                                                </StyleCheckbox>
                                            </Col>
                                        </Row>
                                        {
                                            !form.reverse && 
                                            <>
                                            <Row>
                                                <Col>
                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                        <input className="custom-control-input" name={"spaceAbove-" + index} type="checkbox" checked={ind.spaceAbove} id={"spaceAbove-" + index} onChange={handleOptions} />
                                                        <label className="custom-control-label" htmlFor={"spaceAbove-" + index}>
                                                            Espacio arriba
                                                        </label>
                                                    </StyleCheckbox>
                                                </Col>
                                                <Col>
                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3" >
                                                        <input className="custom-control-input" name={"spaceBelow-" + index} type="checkbox" checked={ind.spaceBelow} id={"spaceBelow-" + index} onChange={handleOptions} />
                                                        <label className="custom-control-label" htmlFor={"spaceBelow-" + index}>
                                                            Espacio abajo
                                                        </label>
                                                    </StyleCheckbox>
                                                </Col>
                                            </Row>
                                            <div className="form-group">
                                                {
                                                    ind.spaceAbove && 
                                                    <Row className="my-2">
                                                        <Col><b>Título arriba</b></Col>
                                                        <Col>
                                                            <input type="text" className="form-control" id={"labelSpaceAbove-indicators-" + index} name={"labelSpaceAbove-indicators-" + index} onChange={handleInfo} value={ind.labelSpaceAbove} />
                                                        </Col>
                                                    </Row>
                                                }

                                                {
                                                    ind.spaceBelow && 
                                                    <Row>
                                                        <Col><b>Título abajo</b></Col>
                                                        <Col>
                                                            <input type="text" className="form-control" id={"labelSpaceBelow-indicators-" + index} name={"labelSpaceBelow-indicators-" + index} onChange={handleInfo} value={ind.labelSpaceBelow} />
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                            </>
                                        }
                                        
                                        {
                                            ind.popup &&
                                            <div className="form-group">
                                                <Row>
                                                    <Col><b>Pop-up</b></Col>
                                                    <Col>
                                                        <Select
                                                            value = {ind.popUpSelected}
                                                            getOptionLabel ={(option)=>option.name}
                                                            getOptionValue ={(option)=>option.id}
                                                            cacheOptions
                                                            defaultOptions
                                                            options={popups}
                                                            onChange={e => handleOptionsSelect(e, index, 'popUpSelected')}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        } 
                                        {
                                            ind.url &&
                                            <div className="form-group">
                                                <Row>
                                                    <Col><b>URL</b></Col>
                                                    <Col>
                                                        <Select
                                                            value = {ind.urlSelected}
                                                            getOptionLabel ={(option)=>option.name}
                                                            getOptionValue ={(option)=>option.id}
                                                            cacheOptions
                                                            defaultOptions
                                                            options={pages}
                                                            onChange={e => handleOptionsSelect(e, index, 'urlSelected')}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </StyleProperty>
                                )
                            }) 
                        }
                        <div className="form-group">
                            <Row>
                                <Col sm={3}><b>Escenarios</b></Col>
                                <Col sm={9}>
                                    <Select
                                        isMulti
                                        value = {form.stages}
                                        options={stages}
                                        hideSelectedOptions={false}
                                        onChange={e => selectStage({data: e, type: "stages"})}
                                    />
                                </Col>
                            </Row>
                        </div>
                        {
                            form.stages.length > 0 && 
                            form.stages.map( (stage, index) => {
                                return (
                                    <StyleProperty key={index}>
                                        <h4>{"Propiedades " + stage.label} </h4>
                                        <div className="form-group">
                                            <Row>
                                                <Col><b>Label del Escenario</b></Col>
                                                <Col>
                                                    <input type="text" className="form-control" id={"labelStage-stages-" + index} name={"labelStage-stages-" + index} onChange={handleInfo} value={stage.labelStage} />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="form-group">
                                            <Row>
                                                <Col><b>Orden</b></Col>
                                                <Col>
                                                    <select name={"order-stages-"+index} value={index+1} onChange={event=> orderArray({event})}>
                                                        {
                                                            form.stages.map( (stage, index) => {
                                                                return(
                                                                    <option key={index} value={index + 1}>{index + 1}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </Col>  

                                            </Row>
                                        </div>
                                        <div className="form-group">
                                            <Row>
                                                <Col>
                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                        <input className="custom-control-input" name={"percent-stages-" + index} type="checkbox" checked={stage.percent} id={"percent-stages-" + index} onChange={handleStageOptions} />
                                                        <label className="custom-control-label" htmlFor={"percent-stages-" + index}>
                                                            Porcentaje Columna
                                                        </label>
                                                    </StyleCheckbox>
                                                </Col>
                                                <Col>
                                                {
                                                    (stage.label.toLowerCase() === "cumplimiento presupuesto" || stage.label.toLowerCase() === "cumplimiento estimado" || stage.label.toLowerCase() === "cumplimiento rolling forecast") &&
                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                        <input className="custom-control-input" name={"semaphore-stages-" + index} type="checkbox" checked={stage.semaphore} id={"semaphore-stages-" + index} onChange={handleStageOptions} />
                                                        <label className="custom-control-label" htmlFor={"semaphore-stages-" + index}>
                                                            Agregar Semaforo
                                                        </label>
                                                    </StyleCheckbox>
                                                }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">   
                                                        <input className="custom-control-input" disabled={stage.lastYear} name={"lastDec-stages-" + index} type="checkbox" checked={stage.lastDec} id={"lastDec-stages-" + index} onChange={handleStageOptions} />
                                                        <label className="custom-control-label" htmlFor={"lastDec-stages-" + index}>
                                                            Diciembre anterior
                                                        </label>
                                                    </StyleCheckbox>
                                                </Col>
                                                <Col>
                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">   
                                                        <input className="custom-control-input" disabled={stage.lastDec} name={"lastYear-stages-" + index} type="checkbox" checked={stage.lastYear} id={"lastYear-stages-" + index} onChange={handleStageOptions} />
                                                        <label className="custom-control-label" htmlFor={"lastYear-stages-" + index}>
                                                            Mes del Año anterior
                                                        </label>
                                                    </StyleCheckbox>
                                                </Col>
                                            </Row>
                                            
                                            {
                                                form.reverse && 
                                                <>
                                                <Row>
                                                    <Col>
                                                        <StyleCheckbox className="custom-checkbox ml-4 mb-3">
                                                            <input className="custom-control-input" name={"spaceAbove-stages-" + index} type="checkbox" checked={stage.spaceAbove} id={"spaceAbove-stages-" + index} onChange={handleStageOptions} />
                                                            <label className="custom-control-label" htmlFor={"spaceAbove-stages-" + index}>
                                                                Espacio arriba
                                                            </label>
                                                        </StyleCheckbox>
                                                    </Col>
                                                    <Col>
                                                        <StyleCheckbox className="custom-checkbox ml-4 mb-3" >
                                                            <input className="custom-control-input" name={"spaceBelow-stages-" + index} type="checkbox" checked={stage.spaceBelow} id={"spaceBelow-stages-" + index} onChange={handleStageOptions} />
                                                            <label className="custom-control-label" htmlFor={"spaceBelow-stages-" + index}>
                                                                Espacio abajo
                                                            </label>
                                                        </StyleCheckbox>
                                                    </Col>
                                                </Row>
                                                <div className="form-group">
                                                    {
                                                        stage.spaceAbove && 
                                                        <Row className="my-2">
                                                            <Col><b>Título arriba</b></Col>
                                                            <Col>
                                                                <input type="text" className="form-control" id={"labelSpaceAbove-stages-" + index} name={"labelSpaceAbove-stages-" + index} onChange={handleInfo} value={stage.labelSpaceAbove} />
                                                            </Col>
                                                        </Row>
                                                    }

                                                    {
                                                        stage.spaceBelow && 
                                                        <Row>
                                                            <Col><b>Título abajo</b></Col>
                                                            <Col>
                                                                <input type="text" className="form-control" id={"labelSpaceBelow-stages-" + index} name={"labelSpaceBelow-stages-" + index} onChange={handleInfo} value={stage.labelSpaceBelow} />
                                                            </Col>
                                                        </Row>
                                                    }
                                                </div>
                                                </>
                                            }
                                            <Row>
                                                <Col>
                                                    <StyleCheckbox className="custom-checkbox ml-4 mb-3">   
                                                        <input disabled={form.indicators.length > 0 ? false : true} className="custom-control-input" name={"othersInd-stages-" + index} type="checkbox" checked={form.indicators.length > 0 ? stage.othersInd : false} id={"othersInd-stages-" + index} onChange={handleStageOptions} />
                                                        <label className="custom-control-label" htmlFor={"othersInd-stages-" + index}>
                                                            Diferentes Indicadores
                                                        </label>
                                                    </StyleCheckbox>
                                                </Col>
                                                {
                                                    stage.othersInd &&
                                                    <Col>
                                                        <StyleCheckbox className="custom-checkbox ml-4 mb-3">   
                                                            <input className="custom-control-input" name={"lessInd-stages-" + index} type="checkbox" checked={stage.lessInd} id={"lessInd-stages-" + index} onChange={handleStageOptions} />
                                                            <label className="custom-control-label" htmlFor={"lessInd-stages-" + index}>
                                                                Agregar menos indicadores
                                                            </label>
                                                        </StyleCheckbox>
                                                    </Col>
                                                }
                                            </Row>
                                            {
                                                stage.othersInd && stage.lessInd &&
                                                <>
                                                <div className="form-group">
                                                    <Row>
                                                        <Col sm={7}><b>Cantidad de indicadores</b></Col>
                                                        <Col sm={5}>
                                                            <select id={"amountLessInd-stages-" + index} name={"amountLessInd-stages-" + index} onChange={handleInfo} value={stage.amountLessInd} >
                                                                {
                                                                    form.indicators.map( (ind, index) => {
                                                                        return(
                                                                            <option key={index} value={index + 1}>{index + 1}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="form-group">
                                                    <Row>
                                                        <Col sm={7}><b>Posición inicial</b></Col>
                                                        <Col sm={5}>
                                                            <select id={"posLessInd-stages-" + index} name={"posLessInd-stages-" + index} onChange={handleInfo} value={stage.posLessInd} >
                                                                {
                                                                    stage.arrLessInd.map( (ind, index) => {
                                                                        return(
                                                                            <option key={index} value={index + 1}>{index + 1}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                </>
                                            }
                                            {
                                                stage.othersInd &&
                                                <>
                                                <div className="form-group">
                                                    <Row>
                                                        <Col><b>Nuevos Indicadores</b></Col>
                                                        <Col>
                                                            <AsyncSelect
                                                                isMulti
                                                                value={stage.newIndicators}
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={loadIndicators}
                                                                onChange={e => selectNewInds({data: e, index})}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {
                                                        !stage.lessInd ? stage.newIndicators.length <= form.indicators.length ? 
                                                        <div className="container alert alert-success my-2 mx-0 my-2 text-center" role="alert">
                                                            {`Debe escoger ${form.indicators.length} indicadores`} 
                                                        </div> : 
                                                        <div className="container alert alert-danger my-2 mx-0 my-2 text-center" role="alert">
                                                            {`No debe escoger más de ${form.indicators.length} indicadores`}
                                                        </div> : stage.newIndicators.length <= stage.amountLessInd ?
                                                        <div className="container alert alert-success my-2 mx-0 my-2 text-center" role="alert">
                                                        {`Debe escoger ${stage.amountLessInd} indicadores`} 
                                                        </div> : 
                                                        <div className="container alert alert-danger my-2 mx-0 my-2 text-center" role="alert">
                                                            {`No debe escoger más de ${stage.amountLessInd}  indicadores`}
                                                        </div>

                                                    }
                                                    
                                                    {
                                                        stage.newIndicators.length > 0 && 
                                                        stage.newIndicators.map( (newInd, indexNew) => {
                                                            return (
                                                                <StyleProperty className="my-2">
                                                                    <Row>
                                                                        <Col sm={10} className="text-center"><b>{newInd.name}</b></Col>
                                                                        <Col sm={2}>
                                                                            <select name={"order-stages-" + indexNew + "-" + index} value={indexNew + 1} onChange={event=> orderArray({event, type: 'newIndicators'})}>
                                                                                {
                                                                                    stage.newIndicators.map( (ind, index) => {
                                                                                        return(
                                                                                            <option key={index} value={index + 1}>{index + 1}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </StyleProperty>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                </>
                                            }
                                    
                                        </div>
                                    </StyleProperty>
                                )
                            }) 
                        }
                        </Container>
                        <Container className="p-2">
                            <Button className="btn" type="submit" disabled={!loadedPre}>Guardar <FaSave /> </Button>
                        </Container>
                        </form>
                </Col>
                <Col sm={7}>
                        <h3>Previsualización</h3>
                        {
                            loadedPre && 
                            <GetDataTable config={JSON.parse(JSON.stringify(form))} />   
                        }
                        {
                            !loadedPre && msj !== '' && 
                            <div className="container alert alert-danger my-2 mx-0 my-2 text-center" role="alert">
                                {msj}
                            </div>
                        }
                    </Col>
            </Row>
        </Container>
        </DivWrapper>
    )
}

export default Form