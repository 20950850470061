import { Svg } from '../../styles/CommonStyles';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const IconError = ({ textTooltip = "Inactivo" }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-indicadores`}>
          {textTooltip}
        </Tooltip>
      }
    >
      <span>⛔</span>
    </OverlayTrigger>
  )
}
