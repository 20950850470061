import { Container } from '../styles/CommonStyles';
import { CardCustom } from '../styles/CommonStyles';
import { Link } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import { dataMenu } from '../Constants/DataMenu';
import { useConfigRole } from '../Hooks/configRole';

function Home() {
    const { validateView } = useConfigRole();

    return (
        <Container>
            <h2>Bienvenido al administrador</h2>
            <p>Seleccione una página del menú para navegar a través del administrador:</p>

            <Row xs={1} md={2} lg={3} className="g-4">
                {dataMenu.map((item, idx) => (
                    validateView(item.idModule, item.permission) && 
                    <Col key={item.id}>
                        <CardCustom >
                            <Link to={item.url}>
                                <Card.Img variant="top" src={item.img} />
                                <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Text>
                                        {item.text}
                                    </Card.Text>
                                </Card.Body>
                            </Link>
                        </CardCustom>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}
export default Home;