import React, { useState, useEffect } from "react";
import { Loading } from "../../../components/Loading";
import { StyleCheckbox } from "../../../styles/CommonStyles";
import Utils from "../../../utils";
import Graphic from "../../reports/Graphic";

function ViewGraphic({indicator, codes}){
    const [stages, setStages] = useState([]);
    const [view, setView] = useState('A');
    const [loaded, setLoaded] = useState(false);

    const [config, setConfig] = useState({
        backgroundTotalLabel: undefined,
        columns: [],
        completeyear: undefined,
        indicators: [],
        indicatorsComplete: [],
        indicatorsStages: [],
        labelTotal: undefined,
        months: undefined,
        option: 'currentYear',
        series: [],
        stages: [],
        title: `${indicator.name.trim()} ${indicator.region}`,
        type: 'BarChart',
        years: undefined
    });

    const COLORS = {
        Real: "#f17507",
        Presupuesto: "#0aea5a",
        Cumplimiento: "#908d8f" 
    };

    const TYPECHART = {
        Real: "line",
        Presupuesto: "bar",
        Cumplimiento: "bar" 
    };
    
    useEffect(()=>{
        setLoaded(false);

        Utils.Petition.get(`/stages_relation/codes/${codes}/type/${view}`, 
            response => {
                setStages(response.data)
                const res = response.data;
                let ind = [];
                if( res.length > 0 ) {
                    res.map(value => {
                        const name = value.stage.name.split(' ')[0]
                        const colorStage = COLORS[name];
                        const typeStage = TYPECHART[name];

                        ind.push({
                            id: indicator.id,
                            labelIndicator: value.stage.name,
                            completeYearInd: 1,
                            color: colorStage,
                            type: typeStage,
                            stage: { id: value.idstage }
                        })
                    })
                    setConfig({
                        ...config,
                        indicators: ind
                    })
                    setLoaded(true)
                }
            }
        )       
    }, [view]);

    const handleView = (event)=>{
        if(event.target.checked) setView('M')
        else setView('A')
    }

    console.log("configuracion",config)
    return (
        <div className="container">
            {
                !loaded && <Loading height="400px"/>
            }
            {
                loaded && <Graphic config={config} />
            }
            
            <StyleCheckbox className="form-control custom-checkbox">
                <input className="custom-control-input" type="checkbox" id="detailView" checked={view==='M'} onChange={handleView}/>
                <label className="custom-control-label" htmlFor="detailView">
                    Vista mensual
                </label>
            </StyleCheckbox>
        </div>
    )
}

export default ViewGraphic

